import clsx from 'clsx';
import React from 'react';

const Checkbox = (props: Omit<React.ComponentProps<'input'>, 'type' | 'role' | 'readOnly'>) => (
  <div className="tw-relative tw-flex tw-items-center">
    <input
      type="checkbox"
      role="button"
      readOnly
      className={clsx(
        'tw-appearance-none tw-accent-perpul-light dark:tw-accent-perpul-dark',
        'tw-w-5 tw-h-5',
        'tw-rounded tw-bg-slate-100 dark:tw-bg-slate-800',
        'tw-border-[1.5px] tw-border-gray-500 dark:tw-border-gray-600',
        'checked:tw-bg-perpul-dark dark:checked:tw-bg-perpul-light',
        'tw-mr-2',
        props.className,
      )}
      {...props}
    />
    <svg
      className={`tw-absolute tw-top-0 tw-left-0 tw-w-5 tw-h-5 tw-fill-current tw-text-white ${
        props.checked ? 'tw-opacity-100' : 'tw-opacity-0'
      }`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M20.285 6.708l-11.285 11.285-5.285-5.285 1.415-1.415 3.87 3.87 9.87-9.87z" />
    </svg>
  </div>
);

export default Checkbox;
