import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { batch, useDispatch } from 'react-redux';

import { MainActionCreators } from '../state/reducer';

import FeedItem from './FeedItem';
import { useAuthedApiClient } from './hooks/useApiClient';
import { PageletGutter } from './Pagelet';

const RecommendedTopics = (props: {
  feeds: Map<string, api.feed.IFeedInfo>;
  header?: React.ReactNode;
  showIfEmpty?: boolean;
}) => {
  const dispatch = useDispatch();
  const apiClient = useAuthedApiClient();
  const { result: topicRecommendRes } = cfe.ApiHook.useApiRead(
    apiClient,
    apiClient.topicRecommend,
    undefined,
    res => res,
    undefined,
    {
      onResult: res => batch(() => res.recommendations.map(feed => dispatch(MainActionCreators.updateFeed(feed)))),
    },
  );
  if (!cfe.ApiData.hasData(topicRecommendRes)) {
    return null;
  }
  return (
    <>
      {props.header && topicRecommendRes.data.recommendations.length > 0 ? props.header : null}
      {topicRecommendRes.data.recommendations.map(feed => (
        <FeedItem key={feed.feed_id} feed={props.feeds.get(feed.feed_id) ?? feed} hideFollowers={true} sm />
      ))}
      {topicRecommendRes.data.recommendations.length === 0 && props.showIfEmpty ? (
        <PageletGutter>No recommendations at this time.</PageletGutter>
      ) : null}
    </>
  );
};

export default RecommendedTopics;
