import clsx from 'clsx';
import React from 'react';
import Select, { SelectProps } from './Select';

interface SelectFormProps extends SelectProps {
  label: string;
  blurb?: string | React.ReactNode;
  className?: string;
}

const SelectForm = (props: SelectFormProps) => (
  <div className={clsx('tw-flex tw-items-center tw-justify-between tw-gap-2', props.className)}>
    <div className="tw-flex tw-flex-col">
      <div className="tw-font-bold">{props.label}</div>
      {props.blurb ? (
        typeof props.blurb === 'string' ? (
          <div className="tw-text-sm">{props.blurb}</div>
        ) : (
          props.blurb
        )
      ) : null}
    </div>
    <div className="tw-flex">
      <Select {...props} />
    </div>
  </div>
);

export default SelectForm;
