import React, { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { MainActionCreators } from '../state/reducer';
import * as store from '../state/store';

import { ContentControlBoundary } from './ContentControlContext';
import FeedEntryCpcViewer from './FeedEntryCpcViewer';
import FeedEntryExplore from './FeedEntryExplore';
import useApiClient from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import useNav from './hooks/useNav';
import { useKeyPress } from './KeyPressContext';

interface RouteParams {
  entryId: string;
}

const FeedEntryExploreModal = () => {
  const dispatch = useDispatch();
  const { navToNewsfeed, popEntry } = useNav();

  const location = useLocation();
  const routeParams = useParams<keyof RouteParams>() as RouteParams;
  const apiClient = useApiClient();
  const exploredItem = useSelector<store.IAppState, store.ExploredItem | null>(state => state.exploredItem);
  const curFeed = useSelector<store.IAppState, store.CurFeed | undefined>(state => state.page.feedPage.curFeed);

  const { apiDo: apiFeedEntryGet, errToast } = useApiDo(apiClient, apiClient.feedEntryGet, { abortable: true });

  React.useEffect(() => {
    if (!routeParams.entryId) {
      throw Error('Unexpected condition.');
    }
    if (!exploredItem) {
      apiFeedEntryGet(
        { entry_id: routeParams.entryId },
        {
          onResult: res => {
            dispatch(
              MainActionCreators.setExploredItem({
                entry: res.entry,
                feed: res.feed,
              }),
            );
          },
          onRouteErr: (err, defaultErrToast) => {
            if (err['.tag'] === 'bad_entry_id') {
              errToast("Can't find what you're looking for");
            } else if (err['.tag'] === 'no_permission') {
              errToast("You don't have permission");
            } else {
              defaultErrToast();
            }
          },
        },
      );
    } else if (routeParams.entryId !== exploredItem.entry.entry_id) {
      dispatch(MainActionCreators.popExploredItem());
      // TODO#optimize: Rather than refetching the entry, use the version
      // cached in redux.
      apiFeedEntryGet(
        { entry_id: routeParams.entryId },
        {
          onResult: res => {
            dispatch(
              MainActionCreators.setExploredItem({
                entry: res.entry,
                feed: res.feed,
              }),
            );
          },
          onRouteErr: (_, defaultErrToast) => {
            navToNewsfeed();
            defaultErrToast();
          },
        },
      );
    }
  }, [routeParams.entryId]);

  const [reset, setReset] = useState(false);
  useKeyPress(
    'R',
    () => {
      // shift + r: Refresh feed.
      if (!exploredItem) {
        return;
      }
      batch(() => {
        dispatch(MainActionCreators.apiCacheClearStimCascade(exploredItem.entry));
        apiFeedEntryGet(
          { entry_id: routeParams.entryId },
          {
            onResult: res => {
              dispatch(MainActionCreators.updateFeedEntry(res.feed.feed_id, res.entry));
            },
          },
        );
      });
      setReset(true);
    },
    false,
    10,
  );
  React.useEffect(() => {
    setReset(false);
  }, [reset]);

  if (exploredItem && !reset) {
    // FIXME: In rare cases, the primaryUrl of an entry changes due to a late
    // attachment of a strong-ref url. In these cases, replace the entire
    // modal.
    const primaryUrl = exploredItem.entry.strong_ref?.url ?? exploredItem.entry.url;
    if (location.pathname.endsWith('/cpc')) {
      return (
        <FeedEntryCpcViewer
          key={exploredItem.entry.entry_id + primaryUrl}
          feed={exploredItem.feed}
          entry={exploredItem.entry}
          closeModal={popEntry}
        />
      );
    } else {
      return (
        <ContentControlBoundary>
          <FeedEntryExplore
            // Set key to have a unique component per entry when switching
            // the explored entry.
            key={exploredItem.entry.entry_id + primaryUrl}
            feed={exploredItem.feed}
            entry={exploredItem.entry}
            closeModal={popEntry}
            inNewsfeed={curFeed?.kind === 'newsfeed'}
          />
        </ContentControlBoundary>
      );
    }
  } else {
    return null;
  }
};

export default FeedEntryExploreModal;
