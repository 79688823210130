import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { useDispatch } from 'react-redux';

import { MainActionCreators } from '../../state/reducer';
import * as apiUtil from '../../util-api';

import EntryFeedbackModal from '../EntryFeedbackModal';
import { useModalManager } from '../ModalManagerContext';
import { EditPostModal } from '../PostModal';
import UpdateBlabberModal from '../UpdateBlabberModal';

import useApiDo from './useApiDo';
import useHistoryRecord from './useHistoryRecorder';
import useNav from './useNav';
import useStoryOpener from './useStoryOpener';
import useToast from './useToast';
import { AccountInfoMaybe } from './useUserMeInternal';

export interface FeedEntryOps {
  archive?: () => void;
  editPost?: () => void;
  ego?: () => void; // opens modal
  explore: () => void;
  extractMetadata?: () => void;
  open: (forceExternal?: boolean) => void;
  openDiscussionPreview?: () => void;
  openTracePreview?: () => void;
  opensToExplore: boolean;
  prune?: () => void;
  recordToHistory: () => void;
  remove?: () => void;
  saveForLater?: () => void;
  saveToLibrary?: () => void; // opens modal
  sendTo?: () => void;
  updateBlabber?: () => void;
}

const useFeedEntryOps = (
  apiClient: api.SuperegoClient,
  accountInfo: AccountInfoMaybe,
  feed: api.feed.IFeedInfo,
  entry: api.feed.IFeedEntryReference,
  agentMode: boolean,
): FeedEntryOps => {
  const dispatch = useDispatch();
  const { setToast } = useToast();
  const { navToEntry } = useNav();
  const { discussionPreviewOpener, doesOpenToExplore, storyOpener, tracePreviewOpener } = useStoryOpener();
  const historyRecorder = useHistoryRecord();
  const { pushModal } = useModalManager();
  const md = cfe.ApiHelpers.getEntryMetadata(entry);
  const primaryUrl = entry.strong_ref?.url ?? entry.url;

  const { apiDo: apiFeedEntryAdd, errToast } = useApiDo(apiClient, apiClient.feedEntryAdd);
  const { apiDo: apiFeedEntryArchive } = useApiDo(apiClient, apiClient.feedEntryArchive);
  const { apiDo: apiFeedEntryUnarchive } = useApiDo(apiClient, apiClient.feedEntryUnarchive);
  const { apiDo: apiFeedEntryRemove } = useApiDo(apiClient, apiClient.feedEntryRemove);
  const { apiDo: apiFeedEntryPrune } = useApiDo(apiClient, apiClient.feedEntryPrune);
  const { apiDo: apiFeedEntryRefresh } = useApiDo(apiClient, apiClient.feedEntryRefresh, { abortable: true });

  const archive = () => {
    dispatch(MainActionCreators.markFeedEntryAsArchivedProvisional(feed.feed_id, entry.entry_id));
    if (!accountInfo) {
      setToast({
        action: {
          fn: () => dispatch(MainActionCreators.markFeedEntryAsUnarchivedProvisional(feed.feed_id, entry.entry_id)),
          label: 'Undo',
          zKeyShortcut: true,
        },
        body: 'Sign in to persist archived entries.',
        header: 'Archived',
        icon: 'check',
      });
      return;
    }
    setToast({
      action: {
        fn: () => {
          dispatch(MainActionCreators.markFeedEntryAsUnarchivedProvisional(feed.feed_id, entry.entry_id));
          apiFeedEntryUnarchive(
            { entry_id: entry.entry_id },
            {
              onResult: res => dispatch(MainActionCreators.updateFeedEntry(feed.feed_id, res)),
            },
          );
        },
        label: 'Undo',
        zKeyShortcut: true,
      },
      header: 'Archived',
      icon: 'archive',
    });
    apiFeedEntryArchive(
      { entry_id: entry.entry_id },
      {
        onRespErr: () =>
          dispatch(MainActionCreators.markFeedEntryAsUnarchivedProvisional(feed.feed_id, entry.entry_id)),
        onResult: res => dispatch(MainActionCreators.updateFeedEntry(feed.feed_id, res)),
        onRouteErr: (err, defaultErrToast) => {
          dispatch(MainActionCreators.markFeedEntryAsUnarchivedProvisional(feed.feed_id, entry.entry_id));
          if (err['.tag'] === 'slow_down') {
            errToast('Slow down', `Try again in ${cfe.Formatter.secondsToHoursMinsSecsStr(err.wait_period)}`);
          } else {
            defaultErrToast();
          }
        },
      },
    );
  };

  const editablePost = md['.tag'] === 'ready' && accountInfo && accountInfo.user_id === md.post?.author?.user_id;
  const editPost = editablePost
    ? () => {
        pushModal({
          component: modalProps => <EditPostModal {...modalProps} feedId={feed.feed_id} entry={entry} isCpc={false} />,
          dupKey: 'cpc-editor',
          kind: 'generic',
        });
      }
    : undefined;

  const ego = () => {
    if (entry['.tag'] === 'notif') {
      setToast({ header: "Can't ego this", icon: 'frown' });
    } else if (feed.type['.tag'] === 'ego' && feed.publisher!.user_id === accountInfo?.user_id) {
      setToast({ header: "You've already egoed this", icon: 'check' });
    } else {
      pushModal({ kind: 'ego', src: [feed.feed_id, entry] });
    }
  };

  const explore = () => navToEntry(feed, entry);

  const extractMetadata = () => {
    setToast({ header: 'Requested re-extraction', icon: 'redo' });
    apiClient
      .feedEntryReqExtractMetadata({ entry_id: entry.entry_id })
      .then(resp => {
        if (resp.kind === api.StatusCode.Ok) {
          refreshEntryExtractionTs(4);
        } else {
          apiUtil.mkToastFromBadStatusCode(dispatch, resp);
        }
      })
      .catch(() => {
        apiUtil.mkToastFromHttpError(dispatch);
      });
  };

  const refreshEntryExtractionTs = (maxRetries: number) => {
    const tryRefresh = (retryCount: number) => {
      apiFeedEntryRefresh(
        {
          entry_id: entry.entry_id,
        },
        {
          onResult: res => {
            dispatch(MainActionCreators.updateFeedEntry(feed.feed_id, res));
            if (
              (res.md['.tag'] !== 'ready' ||
                (entry.md['.tag'] === 'ready' && res.md.extracted_at === entry.md.extracted_at)) &&
              retryCount < maxRetries
            ) {
              setTimeout(() => tryRefresh(retryCount + 1), 1000 * (retryCount + 1));
            }
          },
        },
      );
    };
    setTimeout(() => tryRefresh(0), 500);
  };

  const remove = () => {
    setToast({ header: 'Removed', icon: 'check' });
    apiFeedEntryRemove(
      { entry_id: entry.entry_id },
      {
        onResult: () => dispatch(MainActionCreators.removeFeedEntry(feed.feed_id, entry.entry_id)),
      },
    );
  };

  const prune = () => {
    if (!entry.for_staff) {
      return;
    }
    setToast({ header: 'Pruned', icon: 'check' });
    apiFeedEntryPrune(
      { entry_id: entry.entry_id, prune: entry.for_staff.pruned === undefined },
      {
        onResult: res => dispatch(MainActionCreators.updateFeedEntry(feed.feed_id, res)),
      },
    );
  };

  const saveForLater = () => {
    if (!accountInfo) {
      setToast({ header: 'Cannot save for later', icon: 'frown', body: 'You must be signed in.' });
      return;
    }
    apiFeedEntryAdd(
      { feed_id: 'me/save-for-later', url: entry.strong_ref?.url ?? entry.url, via: entry.entry_id },
      {
        onResult: res => {
          if (res['.tag'] === 'new_entry') {
            dispatch(MainActionCreators.prependFeedEntry(accountInfo.save_for_later_feed_id, res.new_entry));
          }
        },
        onRouteErr: err => {
          if (err['.tag'] === 'slow_down') {
            errToast('Slow down', `Try again in ${cfe.Formatter.secondsToHoursMinsSecsStr(err.wait_period)}`);
          }
        },
      },
    );
    dispatch(
      MainActionCreators.mutateFeedEntry(
        feed.feed_id,
        entry.entry_id,
        entryToUpdate => (entryToUpdate.for_viewer.s4l = true),
      ),
    );
    setToast({
      action: {
        fn: () => pushModal({ kind: 'save', feedId: feed.feed_id, entry }),
        label: 'Change',
        zKeyShortcut: true,
      },
      header: 'Saved for later',
      icon: 'check',
    });
  };

  const saveToLibrary = () => {
    if (!accountInfo) {
      setToast({ header: 'Sign in to save to your library', icon: 'frown' });
    } else if (entry['.tag'] === 'notif') {
      setToast({ header: "Can't save this", icon: 'frown' });
    } else {
      pushModal({ kind: 'save', feedId: feed.feed_id, entry });
    }
  };

  const sendTo = () => {
    if (!accountInfo) {
      setToast({ header: 'Sign in to send to a friend', icon: 'frown' });
    } else if (entry['.tag'] === 'notif') {
      setToast({ header: "Can't send this", icon: 'frown' });
    } else {
      pushModal({ kind: 'send-to', entry });
    }
  };

  const canUpdateBlabber =
    (entry['.tag'] === 'ego' ||
      entry['.tag'] === 'curated' ||
      entry['.tag'] === 'stream' ||
      entry['.tag'] === 'favorites' ||
      entry['.tag'] === 'save_for_later' ||
      entry['.tag'] === 'works') &&
    accountInfo &&
    accountInfo.user_id === entry.added_by?.user_id;
  const updateBlabber = canUpdateBlabber
    ? () => {
        pushModal({
          component: modalProps => <UpdateBlabberModal {...modalProps} feedId={feed.feed_id} entry={entry} />,
          dupKey: 'update-blabber',
          kind: 'generic',
        });
      }
    : undefined;

  return React.useMemo(
    () => ({
      archive: feed.type['.tag'] !== 'archive' ? archive : undefined,
      editPost,
      ego,
      explore,
      extractMetadata: agentMode ? extractMetadata : undefined,
      open: (forceExternal?: boolean) => storyOpener(feed, entry, forceExternal),
      openDiscussionPreview: () => discussionPreviewOpener(feed.feed_id, entry),
      openTracePreview: () => tracePreviewOpener(entry),
      opensToExplore: doesOpenToExplore(entry),
      prune: agentMode ? prune : undefined,
      recordToHistory: () => historyRecorder(feed.feed_id, primaryUrl, entry.entry_id),
      remove: (feed.for_viewer.perm.write || agentMode) && feed.type['.tag'] !== 'notif' ? remove : undefined,
      saveForLater: feed.type['.tag'] !== 'notif' && feed.type['.tag'] !== 'save_for_later' ? saveForLater : undefined,
      saveToLibrary,
      sendTo,
      updateBlabber,
    }),
    [
      apiClient,
      accountInfo,
      feed,
      entry,
      agentMode,
      navToEntry,
      discussionPreviewOpener,
      tracePreviewOpener,
      storyOpener,
      historyRecorder,
      pushModal,
    ],
  );
};

export interface FeedEntryFeedbackOps {
  lessLikeThis: () => void;
  lessLikeThisToast: () => void;
  moreLikeThis: () => void;
  moreLikeThisToast: () => void;
  undoFeedback: () => void;
}

export const useFeedEntryFeedbackOps = (
  apiClient: api.SuperegoClient,
  feed: api.feed.IFeedInfo,
  entry: api.feed.IFeedEntryReference,
  inclusionReason?: api.newsfeed.InclusionReason,
): FeedEntryFeedbackOps => {
  const { setToast } = useToast();
  const { pushModal } = useModalManager();
  const lastFeedbackEntryId = React.useRef<string | null>(null);
  const { apiDo: apiFeedEntryMoreLikeThis } = useApiDo(apiClient, apiClient.feedEntryMoreLikeThis);
  const { apiDo: apiFeedEntryLessLikeThis } = useApiDo(apiClient, apiClient.feedEntryLessLikeThis);
  const { apiDo: apiFeedEntryRemove } = useApiDo(apiClient, apiClient.feedEntryRemove);
  return React.useMemo(
    () => ({
      lessLikeThis: () => {
        if (lastFeedbackEntryId.current) {
          apiFeedEntryRemove({ entry_id: lastFeedbackEntryId.current });
          lastFeedbackEntryId.current = null;
        }
        apiFeedEntryLessLikeThis(
          { entry_id: entry.entry_id },
          {
            onResult: res =>
              (lastFeedbackEntryId.current =
                res['.tag'] === 'new_entry'
                  ? res.new_entry.entry_id
                  : res['.tag'] === 'existing'
                    ? res.existing.entry_id
                    : null),
            onRouteErr: () => null, // Ignore errors
          },
        );
      },
      lessLikeThisToast: () => {
        setToast({
          action: {
            fn: () =>
              pushModal({
                component: modalProps => (
                  <EntryFeedbackModal
                    {...modalProps}
                    feedback="dislike"
                    feed={feed}
                    entry={entry}
                    inclusionReason={inclusionReason}
                  />
                ),
                dupKey: `feedback-dislike`,
                kind: 'generic',
              }),
            label: 'Options',
          },
          header: "You'll see fewer stories like this",
        });
      },
      moreLikeThis: () => {
        if (lastFeedbackEntryId.current) {
          apiFeedEntryRemove({ entry_id: lastFeedbackEntryId.current });
          lastFeedbackEntryId.current = null;
        }
        apiFeedEntryMoreLikeThis(
          { entry_id: entry.entry_id },
          {
            onResult: res =>
              (lastFeedbackEntryId.current =
                res['.tag'] === 'new_entry'
                  ? res.new_entry.entry_id
                  : res['.tag'] === 'existing'
                    ? res.existing.entry_id
                    : null),
            onRouteErr: () => null, // Ignore errors
          },
        );
      },
      moreLikeThisToast: () => {
        setToast({
          action: {
            fn: () =>
              pushModal({
                component: modalProps => (
                  <EntryFeedbackModal
                    {...modalProps}
                    feedback="like"
                    feed={feed}
                    entry={entry}
                    inclusionReason={inclusionReason}
                  />
                ),
                dupKey: `feedback-like`,
                kind: 'generic',
              }),
            label: 'Options',
          },
          header: "You'll see more stories like this",
        });
      },
      undoFeedback: () => {
        if (lastFeedbackEntryId.current) {
          apiFeedEntryRemove({ entry_id: lastFeedbackEntryId.current });
          lastFeedbackEntryId.current = null;
        }
      },
    }),
    [apiClient, feed, entry, inclusionReason],
  );
};

export default useFeedEntryOps;
