import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const AngleLeftIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={[320, 512]}
    d="M47 239c-9.4 9.4-9.4 24.6 0 33.9L207 433c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L97.9 256 241 113c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L47 239z"
  />
);

export default AngleLeftIcon;
