import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { useImmer } from 'use-immer';

import FeedItem from './FeedItem';
import { useAuthedApiClient } from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import useFeedMap from './hooks/useFeedMap';
import useLoggedInUserId from './hooks/useLoggedInUserId';
import useUserEgoFeed from './hooks/useUserEgoFeed';
import CheckIcon from './icon/CheckIcon';
import { useKeyPress } from './KeyPressContext';
import Alert from './lib/Alert';
import Button from './lib/Button';
import LayoutLine from './lib/LayoutLine';
import ListGroup from './lib/ListGroup';
import Modal from './lib/Modal';
import Spinner from './lib/Spinner';

const EntryFeedbackModal = (props: {
  feed: api.feed.IFeedInfo;
  entry: api.feed.IFeedEntryReference;
  inclusionReason?: api.newsfeed.InclusionReason;
  feedback: 'like' | 'dislike';
  close: () => void;
  isForeground: boolean;
}) => {
  const loggedInUserId = useLoggedInUserId();
  const apiClient = useAuthedApiClient();
  const userEgoFeed = useUserEgoFeed(
    props.feed.type['.tag'] === 'stream' && props.feed.publisher ? props.feed.publisher.user_id : null,
  );
  const feedMap = useFeedMap([props.feed.feed_id]);
  const feed = feedMap.get(props.feed.feed_id);
  const initiallyFollowingFeed = React.useRef(feed?.for_viewer.following);

  const { apiDo: apiFeedEntryFeedbackEffects } = useApiDo(apiClient, apiClient.feedEntryFeedbackEffects);
  const [feedbackEffectsResult, updateFeedbackEffectsResult] = useImmer<api.feed.IEntryFeedbackEffectsResult | null>(
    null,
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [isTimedOut, setIsTimedOut] = React.useState(false);

  const { apiDo: apiFeedMuteSet, okToast } = useApiDo(apiClient, apiClient.feedMuteSet);

  const fetchAttempts = React.useRef(0);

  const fetch = () => {
    setIsLoading(true);
    fetchAttempts.current += 1;
    apiFeedEntryFeedbackEffects(
      {
        entry_id: props.entry.entry_id,
        query_only: fetchAttempts.current > 1,
      },
      {
        onResult: res => {
          updateFeedbackEffectsResult(res);
          if (res.effects['.tag'] === 'ready') {
            setIsLoading(false);
          } else {
            if (fetchAttempts.current < 20) {
              setTimeout(fetch, 3000);
            } else {
              setIsLoading(false);
              setIsTimedOut(true);
            }
          }
        },
      },
    );
  };

  React.useEffect(() => {
    fetch();
  }, []);

  useKeyPress(
    ['u', 'Escape'],
    () => {
      props.close();
    },
    !props.isForeground,
    10,
  );
  return (
    <Modal.Container show={props.isForeground} close={props.close} animate="zoom">
      <Modal.Header>
        <Modal.Heading1>
          <CheckIcon size="2rem" offsetUp className="tw-mr-2" />
          {props.feedback === 'like' ? 'More' : 'Less'} like this
        </Modal.Heading1>
        <p>Your feedback helps us better personalize your front page.</p>
      </Modal.Header>
      <Modal.Body>
        <Modal.Gutter>
          {feed ? (
            <div>
              <Modal.Heading2>Why you saw this story</Modal.Heading2>
              {loggedInUserId === props.entry.added_by?.user_id ? (
                // Covers cases like Saved-for-later / archived / ego feeds.
                <>
                  <p>You added this story to {cfe.ApiHelpers.getFeedShortName(props.feed)}.</p>
                </>
              ) : initiallyFollowingFeed.current ? (
                <>
                  <p>You saw this story because you're following:</p>
                  <FeedItem
                    feed={feed}
                    sm
                    hidePublisher
                    hideFollowers
                    onClickOverride={() => window.open(cfe.ApiHelpers.getUrlPathnameForFeed(props.feed), '_blank')}
                  />
                </>
              ) : props.inclusionReason?.['.tag'] === 'peek' ? (
                <>
                  <p>You got a peek of this story stream because you're following the author's egos:</p>
                  {cfe.ApiData.hasData(userEgoFeed) ? (
                    <FeedItem
                      feed={userEgoFeed.data}
                      sm
                      hidePublisher
                      hideFollowers
                      onClickOverride={() =>
                        window.open(cfe.ApiHelpers.getUrlPathnameForFeed(userEgoFeed.data), '_blank')
                      }
                    />
                  ) : cfe.ApiData.isLoading(userEgoFeed) ? (
                    <Spinner sm />
                  ) : null}
                  {props.feedback === 'like' ? (
                    <>
                      <p className="tw-mt-4">To never miss a story from this stream, you can follow it directly:</p>
                      <FeedItem
                        feed={feed}
                        sm
                        hidePublisher
                        hideFollowers
                        onClickOverride={() => window.open(cfe.ApiHelpers.getUrlPathnameForFeed(props.feed), '_blank')}
                      />
                    </>
                  ) : (
                    <>
                      <p className="tw-mt-4">To never see this stream again, you can mute it:</p>
                      <FeedItemWithMute apiClient={apiClient} feed={feed} />
                    </>
                  )}
                </>
              ) : null}
            </div>
          ) : null}
          <LayoutLine />
          <Modal.Heading2 className="tw-mt-8">What related topics will be affected</Modal.Heading2>
          <Alert variant="info">
            <p>
              The following topics are related to this story. Based on your feedback we'll recommend{' '}
              <span className="tw-font-bold"> {props.feedback === 'like' ? 'more' : 'fewer'} </span> stories from them.
            </p>
            <p className="tw-mb-0">
              To stop receiving recommendations from a topic, <span className="tw-font-bold">mute</span> it.
            </p>
          </Alert>
        </Modal.Gutter>
        <Spinner.Float>
          <Spinner.Presence>
            <Spinner lg show={isLoading} />
          </Spinner.Presence>
        </Spinner.Float>
        <div className="tw-py-2" />
        {feedbackEffectsResult !== null && feedbackEffectsResult.effects['.tag'] === 'ready' ? (
          <ListGroup>
            {feedbackEffectsResult.effects.topics_with_feedback.map(topicWithFeedback => {
              return (
                <FeedItem
                  key={topicWithFeedback.topic.feed_id}
                  feed={topicWithFeedback.topic}
                  sm
                  hidePublisher
                  hideFollowers
                  hideFollowBtn
                  onClickOverride={() => {
                    window.open(cfe.ApiHelpers.getUrlPathnameForFeed(topicWithFeedback.topic), '_blank');
                  }}
                  rightEle={
                    <Button
                      sm
                      variant={topicWithFeedback.muted ? 'secondary' : 'primary'}
                      className="tw-self-center"
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (topicWithFeedback.muted) {
                          apiFeedMuteSet({ feed_id: topicWithFeedback.topic.feed_id, mute: false });
                          okToast('Topic unmuted', 'Recommendations may be sourced from this topic');
                        } else {
                          apiFeedMuteSet({ feed_id: topicWithFeedback.topic.feed_id, mute: true });
                          okToast('Topic muted', 'Recommendations WILL NOT be sourced from this topic');
                        }
                        updateFeedbackEffectsResult(draft => {
                          if (draft && draft.effects['.tag'] === 'ready') {
                            for (const maybeTopicWithFeedback of draft.effects.topics_with_feedback) {
                              if (maybeTopicWithFeedback.topic.feed_id === topicWithFeedback.topic.feed_id) {
                                maybeTopicWithFeedback.muted = !topicWithFeedback.muted;
                                break;
                              }
                            }
                          }
                        });
                      }}
                    >
                      {topicWithFeedback.muted ? 'Muted' : 'Mute'}
                    </Button>
                  }
                />
              );
            })}
          </ListGroup>
        ) : isTimedOut ? (
          <Alert variant="danger">Please try again later.</Alert>
        ) : null}
        <div className="tw-py-2" />
      </Modal.Body>
    </Modal.Container>
  );
};

const FeedItemWithMute = (props: { apiClient: api.SuperegoClient; feed: api.feed.IFeedInfo }) => {
  const { result: feedMuteGetResult, override: feedMuteGetOverride } = cfe.ApiHook.useApiRead(
    props.apiClient,
    props.apiClient.feedMuteGet,
    { feed_id: props.feed.feed_id },
    res => res,
  );
  const { apiDo: apiFeedMuteSet, okToast } = useApiDo(props.apiClient, props.apiClient.feedMuteSet);

  return (
    <FeedItem
      key={props.feed.feed_id}
      feed={props.feed}
      sm
      hidePublisher
      hideFollowers
      hideFollowBtn
      onClickOverride={() => {
        window.open(cfe.ApiHelpers.getUrlPathnameForFeed(props.feed), '_blank');
      }}
      rightEle={
        cfe.ApiData.hasData(feedMuteGetResult) ? (
          <Button
            sm
            variant={feedMuteGetResult.data.muted ? 'secondary' : 'primary'}
            className="tw-self-center"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              if (feedMuteGetResult.data.muted) {
                apiFeedMuteSet({ feed_id: props.feed.feed_id, mute: false });
                if (props.feed.type['.tag'] === 'stream') {
                  okToast('Stream unmuted', 'You may be recommended stories from it');
                }
              } else {
                apiFeedMuteSet({ feed_id: props.feed.feed_id, mute: true });
                if (props.feed.type['.tag'] === 'stream') {
                  okToast('Stream muted', 'You WILL NOT be recommended stories from it');
                }
              }
              feedMuteGetOverride({ muted: !feedMuteGetResult.data.muted });
            }}
          >
            {feedMuteGetResult.data.muted ? 'Muted' : 'Mute'}
          </Button>
        ) : (
          <Spinner show sm />
        )
      }
    />
  );
};

export default EntryFeedbackModal;
