import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const PlayIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={[384, 512]}
    d="M24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13V38.13zM48 432L336 256L48 80V432z"
  />
);

export default PlayIcon;
