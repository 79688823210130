import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import { produce } from 'immer';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MainActionCreators } from '../state/reducer';
import * as store from '../state/store';
import * as apiUtil from '../util-api';

import useApiDo from './hooks/useApiDo';
import useUserMeInternal from './hooks/useUserMeInternal';
import Button from './lib/Button';
import SectionHeaderTab from './SectionHeaderTab';
import SmartFeedLink from './SmartFeedLink';

const RssSection = (props: {
  entry: api.feed.IFeedEntryReference;
  apiClient: api.SuperegoClient;
  goToFeed: (feed: api.feed.IFeedInfo) => void;
  showAgentUI: boolean;
}) => {
  const dispatch = useDispatch();
  const accountInfo = useUserMeInternal();

  const primaryUrl = props.entry.strong_ref?.url ?? props.entry.url;

  const {
    result: rssOptions,
    refresh: rssOptionsRefresh,
    override: rssOptionsOverride,
  } = cfe.ApiHook.useApiReadCache(
    props.apiClient,
    props.apiClient.stimulusGetRss,
    { url: primaryUrl },
    res => res,
    value => dispatch(MainActionCreators.apiCacheSetStimRss(primaryUrl, value)),
    () =>
      useSelector<store.IAppState, cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.stimulus.IGetRssResult>>>(
        state => state.apiCache.stimRss.get(primaryUrl) ?? cfe.ApiHook.getCacheEmptySingleton(),
      ),
    !cfe.ApiHelpers.isUrlOpenable(primaryUrl),
    undefined,
    120,
  );

  const { apiDo: apiFeedFollowRss, okToast } = useApiDo(props.apiClient, props.apiClient.feedFollowRss);
  const feedFollowRss = (rssUrl: string, index: number) => {
    if (!accountInfo) {
      throw Error('Expected logged-in user');
    }
    apiFeedFollowRss(
      { rss_url: rssUrl },
      {
        onResult: res => {
          dispatch(MainActionCreators.addFeed(res.feed));
          dispatch(MainActionCreators.followFeed(accountInfo.user_id, res.feed, true));
          okToast('Following');
          if (cfe.ApiData.hasData(rssOptions)) {
            const updatedRssOptionsData = produce(rssOptions.data, draftState => {
              const rssFeed = draftState.rss_feeds[index];
              rssFeed.following = true;
              rssFeed.feed_id = res.feed.feed_id;
            });
            rssOptionsOverride(updatedRssOptionsData);
          }
        },
        onRouteErr: (err, defaultErrToast) => {
          if (err['.tag'] === 'already_following') {
            dispatch(MainActionCreators.addToast({ header: 'Already following', icon: 'check' }));
          } else {
            defaultErrToast();
          }
        },
      },
    );
  };

  const { apiDo: apiFeedUnfollow } = useApiDo(props.apiClient, props.apiClient.feedUnfollow);

  const feedUnfollow = (feedId: string) => {
    if (!accountInfo) {
      throw Error('Expected logged-in user');
    }
    apiFeedUnfollow(
      { feed_id: feedId },
      {
        onResult: res => {
          dispatch(MainActionCreators.unfollowFeed(accountInfo.user_id, res.feed, true));
          if (rssOptions.kind === 'loaded') {
            okToast(`Unfollowed ${res.feed.name}.`);
          }
        },
      },
    );
  };

  const { apiDo: apiRssAddSource } = useApiDo(props.apiClient, props.apiClient.rssAddSource);

  const parsedUrl = new URL(primaryUrl);
  const rootUrl = `${parsedUrl.protocol}//${parsedUrl.hostname}`;
  const rootUrlWithoutScheme = `//${parsedUrl.hostname}`;

  const [rootRssOptions, setRootRssOptions] = useState<cfe.ApiData.SimpleData<api.stimulus.IGetRssResult>>({
    kind: 'unknown',
  });

  const getRootRssOptions = async (url: string) => {
    setRootRssOptions({ kind: 'loading' });
    try {
      const resp = await props.apiClient.stimulusGetRss({ url });
      if (resp.kind === api.StatusCode.Ok) {
        if (resp.result.rss_feeds.length > 0) {
          setRootRssOptions({ kind: 'loaded', data: resp.result });
          return;
        }
      } else {
        setRootRssOptions({ kind: 'error' });
        return;
      }
    } catch (e) {
      setRootRssOptions({ kind: 'error' });
      return;
    }
    try {
      const resp = await props.apiClient.stimulusGetRss({ url: url + '/feed.xml' });
      if (resp.kind === api.StatusCode.Ok) {
        if (resp.result.rss_feeds.length > 0) {
          setRootRssOptions({ kind: 'loaded', data: resp.result });
          return;
        }
      } else {
        setRootRssOptions({ kind: 'error' });
        return;
      }
    } catch (e) {
      setRootRssOptions({ kind: 'error' });
      return;
    }
    try {
      const resp = await props.apiClient.stimulusGetRss({ url: url + '/feed/' });
      if (resp.kind === api.StatusCode.Ok) {
        setRootRssOptions({ kind: 'loaded', data: resp.result });
      } else {
        setRootRssOptions({ kind: 'error' });
      }
    } catch (e) {
      setRootRssOptions({ kind: 'error' });
    }
  };

  if (
    cfe.ApiHelpers.isUrlOpenable(primaryUrl) &&
    (props.showAgentUI || (cfe.ApiData.hasData(rssOptions) && rssOptions.data.rss_feeds.length > 0))
  ) {
    return (
      <div className="tw-mt-4">
        <SectionHeaderTab title="More from" />
        {cfe.ApiData.hasData(rssOptions) && rssOptions.data.rss_feeds.length > 0 ? (
          <div className="kb-pad-4-na tw-flex tw-flex-col tw-gap-y-2">
            {rssOptions.data.rss_feeds.map((rssFeed, index) => (
              <div key={rssFeed.url} className="tw-flex tw-items-center tw-justify-between tw-gap-x-2">
                <div className="tw-flex tw-flex-col">
                  {rssFeed.feed_id ? (
                    <SmartFeedLink goToFeed={props.goToFeed} apiClient={props.apiClient} feedRef={rssFeed.feed_id}>
                      <span>{rssFeed.title ?? 'RSS Feed'}</span>
                    </SmartFeedLink>
                  ) : (
                    <span>{rssFeed.title ?? 'RSS Feed'}</span>
                  )}
                  <span className="tw-text-xs tw-leading-none" title={cfe.ApiHelpers.stripUrlScheme(rssFeed.url)}>
                    {cfe.ApiHelpers.getHostnameForDisplay(rssFeed.url)}
                  </span>
                </div>
                <div className="tw-flex tw-gap-x-1">
                  <Button
                    sm
                    outline={rssFeed.following}
                    onClick={() => {
                      if (accountInfo && cfe.ApiData.hasData(rssOptions)) {
                        if (rssFeed.following) {
                          // Copy required due to immutability of object.
                          const rssOptionsData = Object.assign({}, rssOptions.data);
                          rssOptionsData.rss_feeds = [...rssOptions.data.rss_feeds];
                          rssOptionsData.rss_feeds[index] = Object.assign({}, rssOptions.data.rss_feeds[index]);
                          rssOptionsData.rss_feeds[index].following = false;
                          rssOptionsOverride(rssOptionsData);
                          feedUnfollow(rssOptionsData.rss_feeds[index].feed_id!);
                        } else {
                          feedFollowRss(rssFeed.url, index);
                        }
                      } else {
                        window.location.href = `/login?next=${window.location.pathname}`;
                      }
                    }}
                  >
                    {rssFeed.following ? 'Following' : 'Follow'}
                  </Button>
                  {props.showAgentUI && !rssFeed.feed_id ? (
                    <Button
                      sm
                      title="Adds RSS to Superego without forcing you to follow it."
                      onClick={() =>
                        apiRssAddSource(
                          { rss_url: rssFeed.url },
                          {
                            onResult: () => {
                              if (rssOptions.kind === 'loaded') {
                                rssOptionsRefresh();
                              }
                              dispatch(MainActionCreators.addToast({ header: 'Added rss to system', icon: 'check' }));
                            },
                          },
                        )
                      }
                    >
                      Add to system
                    </Button>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        ) : null}
        {props.showAgentUI && cfe.ApiHelpers.isUrlOpenable(primaryUrl) && rootUrl !== primaryUrl ? (
          <div className="kb-pad-4-na tw-mt-2">
            <Button
              sm
              onClick={() => {
                getRootRssOptions(rootUrl);
              }}
              disabled={cfe.ApiData.hasData(rootRssOptions)}
            >
              Scan domain
            </Button>
            {cfe.ApiData.hasData(rootRssOptions) ? (
              <div>
                {rootRssOptions.data.rss_feeds.length === 0 ? (
                  <span>&mdash;</span>
                ) : (
                  rootRssOptions.data.rss_feeds.map(rootRssOption => (
                    <div key={rootRssOption.url} className="tw-flex tw-mt-2 tw-mb-1 tw-items-center tw-justify-between">
                      <div className="tw-flex tw-flex-col">
                        <span>{rootRssOption.title ?? 'RSS Feed'}</span>
                        <a href={rootRssOption.url} target="_blank" className="tw-text-xs tw-leading-none">
                          {cfe.ApiHelpers.stripUrlScheme(rootRssOption.url)}
                        </a>
                      </div>
                      <Button
                        sm
                        onClick={async () => {
                          if (!rootRssOption.feed_id) {
                            try {
                              const resp = await props.apiClient.rssAddSource({ rss_url: rootRssOption.url });
                              if (resp.kind !== api.StatusCode.Ok) {
                                apiUtil.mkToastFromBadStatusCode(dispatch, resp);
                              }
                            } catch {
                              apiUtil.mkToastFromHttpError(dispatch);
                            }
                          }
                          try {
                            const resp = await props.apiClient.rssAddUrlPrefix({
                              rss_id: rootRssOption.url,
                              url_prefix: rootUrlWithoutScheme,
                            });
                            if (resp.kind === api.StatusCode.Ok) {
                              rssOptionsRefresh();
                            }
                          } catch {
                            apiUtil.mkToastFromHttpError(dispatch);
                          }
                        }}
                        disabled={
                          cfe.ApiData.hasData(rssOptions) &&
                          rssOptions.data.rss_feeds.filter(rssFeed => rssFeed.url === rootRssOption.url).length > 0
                        }
                      >
                        Assign URL Prefix
                      </Button>
                    </div>
                  ))
                )}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};

export default RssSection;
