import clsx from 'clsx';
import { AnimatePresence, HTMLMotionProps, m } from 'framer-motion';
import React from 'react';

const Table = (props: React.ComponentProps<'table'>) => (
  <div className="tw-overflow-x-auto">
    <table
      {...props}
      className={clsx(
        'tw-table-auto tw-w-full tw-rounded-3xl',
        'tw-border tw-border-solid tw-border-layout-line-light dark:tw-border-layout-line-dark',
        props.className,
      )}
    >
      {props.children}
    </table>
  </div>
);

export const TableHead = (props: React.ComponentProps<'thead'>) => (
  <thead {...props} className={props.className}>
    {props.children}
  </thead>
);

export const TableBody = (props: React.ComponentProps<'tbody'>) => (
  <tbody {...props} className={clsx(props.className)}>
    {props.children}
  </tbody>
);

export const TableBodyAnimated = (props: React.ComponentProps<'tbody'>) => (
  <tbody {...props} className={clsx(props.className)}>
    <AnimatePresence>{props.children}</AnimatePresence>
  </tbody>
);

export interface TableRowProps extends React.ComponentProps<'tr'> {
  striped?: boolean;
}

export const TableRow = (props: TableRowProps) => {
  const { striped, ...rest } = props;
  return (
    <tr
      {...rest}
      className={clsx(
        props.striped ? 'odd:tw-bg-highlight' : null,
        'tw-border-b tw-border-x-0 tw-border-t-0 tw-border-solid tw-border-layout-line-light dark:tw-border-layout-line-dark',
        props.className,
      )}
    >
      {props.children}
    </tr>
  );
};

export interface TableRowAnimatedProps extends HTMLMotionProps<'tr'> {
  striped?: boolean;
}

export const TableRowAnimated = (props: TableRowAnimatedProps) => {
  const { striped, ...rest } = props;
  return (
    <m.tr
      {...rest}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className={clsx(
        props.striped ? 'odd:tw-bg-highlight' : null,
        'tw-border-b tw-border-x-0 tw-border-t-0 tw-border-solid tw-border-layout-line-light dark:tw-border-layout-line-dark',
        props.className,
      )}
    >
      {props.children}
    </m.tr>
  );
};

export const TableHeadCell = (props: React.ComponentProps<'th'>) => (
  <th
    {...props}
    scope="col"
    className={clsx('tw-align-bottom tw-font-semibold tw-px-3 tw-pb-2 tw-pt-6', props.className)}
  >
    {props.children}
  </th>
);

export const TableHeadCellLeft = (props: React.ComponentProps<'th'>) => (
  <th {...props} scope="row" className={clsx('tw-text-left tw-font-semibold tw-p-3', props.className)}>
    {props.children}
  </th>
);

export const TableCell = (props: React.ComponentProps<'th'>) => (
  <td {...props} className={clsx('tw-p-3', props.className)}>
    {props.children}
  </td>
);

export default Table;
