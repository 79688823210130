import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { MainActionCreators } from '../state/reducer';
import * as store from '../state/store';

import FeedItem, { ShowProfileImageBehavior } from './FeedItem';
import useApiClient from './hooks/useApiClient';
import useNav from './hooks/useNav';
import SearchIcon from './icon/SearchIcon';
import { useKeyPress } from './KeyPressContext';
import ListGroup from './lib/ListGroup';
import Spinner from './lib/Spinner';
import TextInput from './lib/TextInput';
import SectionHeaderTab from './SectionHeaderTab';

let asyncSearchId: ReturnType<typeof setTimeout> | null = null;

type KeyboardSection = 'search' | 'people' | 'topics' | 'feeds';
type KeyboardDirection = 'going-up' | 'going-down';

interface FeedSearchInputProps {
  onClickOverride?: (feed: api.feed.IFeedInfo) => void;
  onClickAfter?: (feed: api.feed.IFeedInfo) => void;
  kbActive: boolean;
  targetWindow?: MutableRefObject<HTMLDivElement | null>;
  peopleOnly?: boolean;
  topicsOnly?: boolean;
  omitFeedId?: string;
  showProfileImage?: ShowProfileImageBehavior;
  overrideSearchBarClassName?: string;
  initialQuery?: string;
}

const FeedSearchInput = React.forwardRef<HTMLInputElement, FeedSearchInputProps>((props, parentRef) => {
  if (props.peopleOnly && props.topicsOnly) {
    throw Error('Cannot set both peopleOnly & topicsOnly');
  }
  const apiClient = useApiClient();
  const dispatch = useDispatch();

  const searchRef = useRef<HTMLInputElement | null>(null);
  const [searchQ, setSearchQ] = useState(props.initialQuery ?? '');
  const [searchRes, setSearchRes] = useState<cfe.ApiData.Data<api.search.IOmniResult>>({ kind: 'unknown' });
  const [kbSection, setKbSection] = useState<[KeyboardSection, KeyboardDirection]>(['search', 'going-down']);

  // Perform search immediately if there's a query already populated
  React.useEffect(() => {
    if (props.initialQuery) {
      queryApiSearchOmni(props.initialQuery);
    }
  }, []);

  useKeyPress(
    'Enter',
    () => {
      setKbSection(['people', 'going-down']);
      searchRef.current?.blur();
    },
    !props.kbActive || kbSection[0] !== 'search',
    10,
    {
      handleIfActiveElementIsInput: true,
    },
  );

  useKeyPress(
    'ArrowDown',
    () => {
      setKbSection(['people', 'going-down']);
      searchRef.current?.blur();
    },
    !props.kbActive || kbSection[0] !== 'search',
    10,
    {
      handleIfActiveElementIsInput: true,
    },
  );

  useKeyPress(
    'ArrowUp',
    () => {
      setKbSection(['feeds', 'going-up']);
      searchRef.current?.blur();
    },
    !props.kbActive || kbSection[0] !== 'search',
    10,
    {
      handleIfActiveElementIsInput: true,
    },
  );

  const searchOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    const q = e.currentTarget.value;
    queryApiSearchOmni(q);
  };

  const queryApiSearchOmni = (q: string) => {
    setSearchQ(q);
    if (q.length === 0) {
      // When clearing the search box, let's clear the results.
      setSearchRes({ kind: 'unknown' });
    }

    if (q.length < 3) {
      return;
    }
    if (cfe.ApiData.hasData(searchRes)) {
      setSearchRes({ kind: 'loading-data', data: searchRes.data });
    } else {
      setSearchRes({ kind: 'loading' });
    }
    if (asyncSearchId) {
      clearTimeout(asyncSearchId);
    }
    asyncSearchId = setTimeout(() => {
      apiClient.searchOmni({ q }).then(resp => {
        if (resp.kind === api.StatusCode.Ok) {
          batch(() => {
            resp.result.feeds.map(feed => dispatch(MainActionCreators.addFeed(feed)));
            resp.result.hashtags.map(feed => dispatch(MainActionCreators.addFeed(feed)));
            resp.result.users.map(feed => dispatch(MainActionCreators.addFeed(feed)));
          });
          setSearchRes({ kind: 'loaded', data: resp.result });
        }
      });
    }, 250);
  };

  return (
    <div>
      <div className={props.overrideSearchBarClassName ?? 'tw-px-6'}>
        <TextInput
          ref={ref => {
            searchRef.current = ref;
            if (typeof parentRef === 'function') {
              parentRef(ref);
            } else if (parentRef !== null) {
              parentRef.current = ref;
            }
          }}
          type="text"
          prefixEle={
            cfe.ApiData.isLoading(searchRes) ? (
              <div className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
                <Spinner sm />
              </div>
            ) : (
              <SearchIcon size={20} />
            )
          }
          placeholder={
            props.peopleOnly
              ? 'Search people'
              : props.topicsOnly
                ? 'Search topics '
                : 'Search people, feeds, and topics'
          }
          onChange={searchOnChange}
          value={searchQ}
          onFocus={() => setKbSection(['search', 'going-down'])}
        />
      </div>
      {cfe.ApiData.hasData(searchRes) ? (
        <div key={searchQ}>
          {!props.topicsOnly ? (
            <SearchResultSection
              feeds={searchRes.data.users.filter(user => user.feed_id !== props.omitFeedId)}
              onClickOverride={props.onClickOverride}
              onClickAfter={props.onClickAfter}
              header={<SectionHeaderTab title="People" className="tw-mt-4" />}
              searchQ={searchQ}
              kbSelected={props.kbActive && kbSection[0] === 'people' ? kbSection[1] : null}
              kbPrevSection={() => {
                setKbSection(['search', 'going-up']);
                // Add delay so that the keyboard press does not become an
                // input into the search box.
                setTimeout(() => searchRef.current?.focus(), 50);
              }}
              kbNextSection={() => setKbSection(['topics', 'going-down'])}
              targetWindow={props.targetWindow}
              showProfileImage={props.showProfileImage}
            />
          ) : null}
          {!props.peopleOnly ? (
            <SearchResultSection
              feeds={searchRes.data.hashtags.filter(hashtag => hashtag.feed_id !== props.omitFeedId)}
              onClickOverride={props.onClickOverride}
              onClickAfter={props.onClickAfter}
              header={<SectionHeaderTab title="Topics" className="tw-mt-4" />}
              searchQ={searchQ}
              kbSelected={props.kbActive && kbSection[0] === 'topics' ? kbSection[1] : null}
              kbPrevSection={() => setKbSection(['people', 'going-up'])}
              kbNextSection={() => setKbSection(['feeds', 'going-down'])}
              targetWindow={props.targetWindow}
              showProfileImage={props.showProfileImage}
            />
          ) : null}
          {!props.peopleOnly && !props.topicsOnly ? (
            <SearchResultSection
              feeds={searchRes.data.feeds.filter(feed => feed.feed_id !== props.omitFeedId)}
              onClickOverride={props.onClickOverride}
              onClickAfter={props.onClickAfter}
              header={<SectionHeaderTab title="Feeds" className="tw-mt-4" />}
              searchQ={searchQ}
              kbSelected={props.kbActive && kbSection[0] === 'feeds' ? kbSection[1] : null}
              kbPrevSection={() => setKbSection(['topics', 'going-up'])}
              kbNextSection={() => {
                setKbSection(['search', 'going-down']);
                setTimeout(() => searchRef.current?.focus(), 50);
              }}
              targetWindow={props.targetWindow}
              showProfileImage={props.showProfileImage}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
});

const SearchResultSection = (props: {
  feeds: api.feed.IFeedInfo[];
  onClickOverride?: (feed: api.feed.IFeedInfo) => void;
  onClickAfter?: (feed: api.feed.IFeedInfo) => void;
  header?: React.ReactNode;
  searchQ: string;
  kbSelected: KeyboardDirection | null;
  kbPrevSection: () => void;
  kbNextSection: () => void;
  targetWindow?: MutableRefObject<HTMLDivElement | null>;
  showProfileImage?: ShowProfileImageBehavior;
}) => {
  const { navToFeed } = useNav();
  const feeds = useSelector<store.IAppState, Map<string, api.feed.IFeedInfo>>(state => state.feeds);

  const [kbIndex, setKbIndex] = useState(0);

  useEffect(() => {
    if (props.kbSelected === null) {
      return;
    }
    if (props.kbSelected === 'going-down') {
      if (props.feeds.length > 0) {
        setKbIndex(0);
      } else {
        props.kbNextSection();
      }
    } else if (props.kbSelected === 'going-up') {
      if (props.feeds.length > 0) {
        setKbIndex(props.feeds.length - 1);
      } else {
        props.kbPrevSection();
      }
    }
  }, [props.kbSelected]);

  useKeyPress(
    'n',
    () => {
      // n: Select next discussion.
      if (kbIndex + 1 < props.feeds.length) {
        setKbIndex(kbIndex + 1);
      } else {
        props.kbNextSection();
      }
    },
    !props.kbSelected,
  );

  useKeyPress(
    'p',
    () => {
      // p: Select prev discussion.
      if (kbIndex - 1 >= 0) {
        setKbIndex(kbIndex - 1);
      } else {
        props.kbPrevSection();
      }
    },
    !props.kbSelected,
  );

  useKeyPress(
    'ArrowDown',
    () => {
      // ArrowDown: Select next discussion.
      if (kbIndex + 1 < props.feeds.length) {
        setKbIndex(kbIndex + 1);
      } else {
        props.kbNextSection();
      }
    },
    !props.kbSelected,
  );

  useKeyPress(
    'ArrowUp',
    () => {
      // ArrowUp: Select prev discussion.
      if (kbIndex - 1 >= 0) {
        setKbIndex(kbIndex - 1);
      } else {
        props.kbPrevSection();
      }
    },
    !props.kbSelected,
  );

  useKeyPress(
    'Enter',
    () => {
      if (kbIndex < props.feeds.length) {
        if (props.onClickOverride) {
          props.onClickOverride(props.feeds[kbIndex]);
        } else {
          navToFeed(props.feeds[kbIndex], false);
        }
        if (props.onClickAfter) {
          props.onClickAfter(props.feeds[kbIndex]);
        }
      }
    },
    !props.kbSelected,
  );

  useKeyPress(
    'o',
    () => {
      if (kbIndex < props.feeds.length) {
        if (props.onClickOverride) {
          props.onClickOverride(props.feeds[kbIndex]);
        } else {
          navToFeed(props.feeds[kbIndex], false);
        }
        if (props.onClickAfter) {
          props.onClickAfter(props.feeds[kbIndex]);
        }
      }
    },
    !props.kbSelected,
    10,
  );

  return (
    <>
      {props.header}
      <ListGroup>
        {props.feeds.length > 0 ? (
          props.feeds.map((feed, index) => (
            <div key={feed.feed_id}>
              <FeedItem
                key={feed.feed_id}
                feed={feeds.get(feed.feed_id)!}
                hideFollowers
                hideFollowBtn
                sm
                titleRight={<FeedItem.ContentIcon sm feed={feed} />}
                onClickOverride={props.onClickOverride ? () => props.onClickOverride!(feed) : undefined}
                onClickAfter={props.onClickAfter ? () => props.onClickAfter!(feed) : undefined}
                boldSubstr={props.searchQ}
                kbActive={props.kbSelected !== null && index === kbIndex}
                enableWindowScroll
                scrollTarget={props.targetWindow?.current ?? undefined}
                showProfileImage={props.showProfileImage}
              />
            </div>
          ))
        ) : (
          <div className="tw-mb-2 tw-px-3 tw-text-primary">&mdash;</div>
        )}
      </ListGroup>
    </>
  );
};

export default FeedSearchInput;
