import * as api from 'ego-sdk-js';
import { shallowEqual, useSelector } from 'react-redux';

import * as store from '../../state/store';

/**
 * This hooks efficiently selects only a subset of feeds so that it doesn't
 * trigger a re-render when any feed is updated.
 *
 * WARN: Changing the order of feedIds will result in a re-render.
 */
const useFeedMap = (feedIds: string[]) => {
  const matchedFeeds = useSelector<store.IAppState, api.feed.IFeedInfo[]>(
    state => feedIds.filter(feedId => state.feeds.has(feedId)).map(feedId => state.feeds.get(feedId)!),
    shallowEqual,
  );
  const feedMap = new Map<string, api.feed.IFeedInfo>();
  matchedFeeds.map(feed => feedMap.set(feed.feed_id, feed));
  return feedMap;
};

export default useFeedMap;
