import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MainActionCreators } from '../state/reducer';
import * as store from '../state/store';

import { useSessionDefaultApiClient } from './hooks/useApiClient';

// Optimization in HTML JS to fetch login-status and cache it before JS blob
// loads.
declare var htmlJsPrefetchLoginStatus: Promise<api.user.IMeInternalWebResult | 'error'> | null;

const UserGate = (props: { children: React.ReactNode; fallback: React.ReactNode }) => {
  const dispatch = useDispatch();
  const loginInfo = useSelector<store.IAppState, store.LoginInfo | null>(state => state.loginInfo);
  const apiClient = useSessionDefaultApiClient();
  const [precacheError, setPrecacheError] = React.useState(false);

  cfe.ApiHook.useApiRead(
    apiClient,
    apiClient.userMeInternalWeb,
    undefined,
    res => res,
    htmlJsPrefetchLoginStatus !== null && !precacheError,
    {
      onResult: res => dispatch(MainActionCreators.setLoginInfo(res.login_status)),
    },
  );

  React.useEffect(() => {
    if (htmlJsPrefetchLoginStatus) {
      htmlJsPrefetchLoginStatus.then(res => {
        if (res === 'error') {
          setPrecacheError(true);
        } else {
          dispatch(MainActionCreators.setLoginInfo(res.login_status));
        }
      });
    }
  }, []);

  return <>{loginInfo ? props.children : props.fallback}</>;
};

export default UserGate;
