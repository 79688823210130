import { createContext, useContextSelector } from 'use-context-selector';

interface PrexoSlideContextState {
  nextSlide: () => void;
  slideIndex: number;
}
const PrexoSlideContext = createContext<PrexoSlideContextState>({ nextSlide: () => null, slideIndex: -1 });
export default PrexoSlideContext;

export const usePrexoSlideContext = () => useContextSelector(PrexoSlideContext, v => v);
export const usePrexoSlideIndex = () => useContextSelector(PrexoSlideContext, v => v.slideIndex);
export const usePrexoNextSlide = () => useContextSelector(PrexoSlideContext, v => v.nextSlide);
