import React from 'react';

function useLocalStorage<T>(key: string, onUpdate: (newValue: T | null) => void) {
  const [value, setValue] = React.useState<T | null>(
    localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)!) : null,
  );
  React.useEffect(() => {
    onUpdate(value);
  }, []);
  React.useEffect(() => {
    const storageEventHandler = (e: StorageEvent) => {
      if (e.key === key) {
        const newValue = localStorage.getItem(key);
        const parsedValue = newValue ? JSON.parse(newValue) : null;
        setValue(parsedValue);
        onUpdate(parsedValue);
      }
    };
    window.addEventListener('storage', storageEventHandler);
    return () => {
      window.removeEventListener('storage', storageEventHandler);
    };
  }, []);
  return {
    clear: () => {
      localStorage.removeItem(key);
    },
    setValue: (newValue: T) => {
      setValue(newValue);
      localStorage.setItem(key, JSON.stringify(newValue));
    },
    value,
  };
}

export function useLocalStorageSetter<T>(key: string) {
  return (newValue: T) => {
    localStorage.setItem(key, JSON.stringify(newValue));
  };
}

export default useLocalStorage;
