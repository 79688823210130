"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.textColorFromBackgroundColor = void 0;
function hexColorToDecimal(hexColor) {
    if (hexColor.startsWith('#')) {
        hexColor = hexColor.substring(1);
    }
    return [
        parseInt(hexColor.substring(0, 2), 16) / 255,
        parseInt(hexColor.substring(2, 4), 16) / 255,
        parseInt(hexColor.substring(4, 6), 16) / 255,
    ];
}
function hexColorToLuminance(hexColor) {
    const rgb = hexColorToDecimal(hexColor);
    return 0.2126 * Math.pow(rgb[0], 2.2) + 0.7152 * Math.pow(rgb[1], 2.2) + 0.0722 * Math.pow(rgb[2], 2.2);
}
function textColorFromBackgroundColor(hexColor) {
    return hexColorToLuminance(hexColor) < Math.pow(0.5, 2.2) ? 'light' : 'dark';
}
exports.textColorFromBackgroundColor = textColorFromBackgroundColor;
