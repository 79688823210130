import clsx from 'clsx';
import React from 'react';

import Alert from './Alert';
import Button from './Button';

const FilePicker = (props: {
  onDrop: (file: File) => void;
  buttonLabel: string;
  containerClassName?: string;
  inputAccept?: string;
  dropAcceptExts?: string[];
  maxFileSize?: [number, string]; // [byte count, human-readable string]
}) => {
  const [isDraggedOver, setIsDraggedOver] = React.useState(false);

  const enterCount = React.useRef(0);
  const [errMsg, setErrMsg] = React.useState<string | null>(null);

  const checkFileSize = (file: File) => {
    if (props.maxFileSize && file.size > props.maxFileSize[0]) {
      setErrMsg(`Sorry, file too large (${props.maxFileSize[1]} limit)`);
      return false;
    } else {
      setErrMsg(null);
      return true;
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggedOver(true);
    enterCount.current += 1;
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    enterCount.current -= 1;
    if (enterCount.current === 0) {
      setIsDraggedOver(false);
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    // In some browsers, the onDrop event won’t fire unless the onDropOver
    // default action is explicitly cancelled.
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    enterCount.current = 0;
    setIsDraggedOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      if (props.dropAcceptExts) {
        for (const dropAcceptExt of props.dropAcceptExts) {
          if (file.name.toLocaleLowerCase().endsWith(dropAcceptExt)) {
            if (checkFileSize(file)) {
              props.onDrop(file);
            }
            return;
          }
        }
      } else {
        if (checkFileSize(file)) {
          props.onDrop(file);
        }
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (checkFileSize(file)) {
        props.onDrop(file);
      }
    }
  };

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  return (
    <div
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className={clsx(
        props.containerClassName,
        'tw-flex tw-flex-col tw-items-center tw-gap-y-2',
        'tw-rounded tw-border tw-border-dashed',
        isDraggedOver ? 'tw-border-green-700' : 'tw-border-gray-500 dark:tw-border-gray-400',
      )}
    >
      <input ref={fileInputRef} type="file" accept={props.inputAccept} onChange={handleChange} className="tw-hidden" />
      <Button
        onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        }}
      >
        {props.buttonLabel}
      </Button>
      <span className="tw-italic">drag-and-drop zone</span>
      {errMsg ? <Alert variant="warn">{errMsg}</Alert> : null}
    </div>
  );
};

export default FilePicker;
