import * as api from 'ego-sdk-js';
import React from 'react';

import { useAuthedApiClient } from './useApiClient';
import useApiDo from './useApiDo';

interface UploaderStatusUploading {
  kind: 'uploading';
  progress: number;
}

interface UploaderStatusError {
  kind: 'error';
  errMsg: string;
}

interface UploaderStatusWaiting {
  kind: 'waiting';
}

interface UploaderStatusDone {
  kind: 'done';
  uploadId: string;
  tempMediaUrl?: string;
}

export type UploaderStatus = UploaderStatusWaiting | UploaderStatusUploading | UploaderStatusDone | UploaderStatusError;

const useMediaUploader = (
  mediaType: 'image' | 'video',
  mediaPurpose: api.medialib.MediaPurpose,
  uploadedCallback?: (uploadId: string, tempMediaUrl?: string) => void,
  cpcVideoEntryId?: string,
) => {
  const [status, setStatus] = React.useState<UploaderStatus>({ kind: 'waiting' });

  const apiClient = useAuthedApiClient();
  const { apiDo: apiMedialibUploadStart, okToast, errToast } = useApiDo(apiClient, apiClient.medialibUploadStart);
  const { apiDo: apiMedialibUploadSetCpcVideoTarget } = useApiDo(apiClient, apiClient.medialibUploadSetCpcVideoTarget);
  const { apiDo: apiMedialibUploadDeposited } = useApiDo(apiClient, apiClient.medialibUploadDeposited);

  const xhrRef = React.useRef<XMLHttpRequest | null>(null);

  React.useEffect(() => {
    return () => {
      // Cancel upload if component destroyed.
      if (xhrRef.current) {
        xhrRef.current.abort();
        xhrRef.current = null;
      }
    };
  }, []);

  const depositMedia = React.useCallback((uploadStartRes: api.medialib.IUploadStartResult) => {
    apiMedialibUploadDeposited(
      { upload_id: uploadStartRes.upload_id },
      {
        onResult: depositRes => {
          if (uploadedCallback) {
            uploadedCallback(uploadStartRes.upload_id, depositRes.temp_media_url);
          }
          okToast('Upload complete');
          setStatus({ kind: 'done', uploadId: uploadStartRes.upload_id, tempMediaUrl: depositRes.temp_media_url });
        },

        onRouteErr: (err, defaultErrToast) => {
          if (err['.tag'] === 'bad_encoding') {
            const errMsg = mediaType === 'video' ? 'Video has bad encoding' : 'Image has bad encoding';
            setStatus({ kind: 'error', errMsg });
            errToast(errMsg);
          } else if (err['.tag'] === 'unsupported_format') {
            const errMsg = mediaType === 'video' ? 'Video format is unsupported' : 'Image format is unsupported';
            setStatus({ kind: 'error', errMsg });
            errToast(errMsg);
          } else if (err['.tag'] === 'file_size_limit') {
            const errMsg = mediaType === 'video' ? 'Video is too large (> 2GB)' : 'Image is too large (> 10MB)';
            setStatus({ kind: 'error', errMsg });
            errToast(errMsg);
          } else if (err['.tag'] === 'dimension_limit') {
            const errMsg = mediaType === 'video' ? 'Video dimensions are too large' : 'Image dimensions are too large';
            setStatus({ kind: 'error', errMsg });
            errToast(errMsg);
          } else if (err['.tag'] === 'dimension_too_small') {
            const errMsg = mediaType === 'video' ? 'Video dimensions are too small' : 'Image dimensions are too small';
            setStatus({ kind: 'error', errMsg });
            errToast(errMsg);
          } else if (err['.tag'] === 'unsupported_aspect_ratio') {
            const errMsg = mediaType === 'video' ? 'Video aspect ratio too skewed' : 'Image aspect ratio too skewed';
            setStatus({ kind: 'error', errMsg });
            errToast(errMsg);
          } else if (err['.tag'] === 'cpc_video_incompatible') {
            const errMsg =
              mediaType === 'video' ? 'Video duration or orientation does not match source' : 'Unexpected error';
            setStatus({ kind: 'error', errMsg });
            errToast(errMsg);
          } else {
            defaultErrToast();
          }
        },
      },
    );
  }, []);

  const uploadMedia = React.useCallback(
    (file: File) => {
      // The content-type sent to the upload/start API must match the
      // content-type of the body of the request made to the deposit_url.
      const contentType = getFileContentType(file) ?? 'binary/octet-stream';
      apiMedialibUploadStart(
        { purpose: mediaPurpose, content_type: contentType },
        {
          onResult: uploadStartRes => {
            setStatus({ kind: 'uploading', progress: 0 });

            const xhr = new XMLHttpRequest();
            xhrRef.current = xhr;
            xhr.upload.addEventListener('progress', event => {
              if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                setStatus({ kind: 'uploading', progress: Math.ceil(percentComplete) });
              }
            });
            xhr.onload = () => {
              xhrRef.current = null;
              if (xhr.status === 200) {
                if (cpcVideoEntryId) {
                  apiMedialibUploadSetCpcVideoTarget(
                    {
                      entry_id: cpcVideoEntryId,
                      upload_id: uploadStartRes.upload_id,
                    },
                    {
                      onResult: () => depositMedia(uploadStartRes),
                    },
                  );
                } else {
                  depositMedia(uploadStartRes);
                }
              } else {
                errToast(`Upload failed (${xhr.status})`, xhr.statusText);
              }
            };
            xhr.onerror = () => {
              xhrRef.current = null;
              setStatus({ kind: 'error', errMsg: 'Upload failed' });
              errToast('Upload failed');
            };
            xhr.open('PUT', uploadStartRes.deposit_url, true);
            xhr.setRequestHeader('Content-Type', contentType);
            xhr.send(file);
          },
        },
      );
    },
    [apiClient, mediaType, mediaPurpose],
  );
  return {
    uploadMedia,
    uploaderReset: () => {
      setStatus({ kind: 'waiting' });
      if (xhrRef.current) {
        xhrRef.current.abort();
        xhrRef.current = null;
      }
    },
    uploaderStatus: status,
  };
};

export const getFileContentType = (file: File): string | undefined => {
  if (file.type) {
    return file.type;
  } else {
    const nameLowered = file.name.toLowerCase();
    if (nameLowered.endsWith('.mov')) {
      return 'video/quicktime';
    } else if (nameLowered.endsWith('.mp4')) {
      return 'video/mp4';
    } else {
      return undefined;
    }
  }
};

export default useMediaUploader;
