import React from 'react';

/**
 * This generates a listener to be used with onWheel.
 *
 * Wheel measurements differ by OS and can't be easily normalize. To
 * accommodate, the listener takes the approach of measuring the amount of
 * time spent moving in a specific direction rather than relying on distances.
 *
 * Worse still, MacOS has a long inertial scroll that's difficult to
 * differentiate from a direct user scroll.
 */

interface TouchPadEvent {
  direction: 'left' | 'right';
  level: number;
}

let touchPadExlusiveEntryId: string | null = null;

export const useTouchPadHorizontal = (entryId: string, onTouchPadEvent: (e: TouchPadEvent) => void) => {
  const pan = React.useRef({ x: 0, y: 0 });
  const exclusiveResetTimeoutId = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  const wheelPrevTs = React.useRef(0);
  const wheelPrevSign = React.useRef(0);
  const wheelActiveSpan = React.useRef(0);
  const wheelDeltaXAbsHistory = React.useRef<number[]>([]);
  const wheelSlowingDownCount = React.useRef<number>(0);
  const wheelEventLevel = React.useRef(0);

  const onWheelEventHandler = React.useCallback(
    (e: React.WheelEvent<HTMLDivElement> | WheelEvent, preventDefaultIfActioned: boolean) => {
      // NOTE: Exclusitivity is given to a single entry at a time. This is to
      // address "touchpad scrolling inertia" (macOS) which can pass from one
      // entry to another. For example, an entry can be swiped-to-archive by
      // the user causing the entry to disappear and for a second entry to take
      // its place. This new entry will continue to receive scroll events due
      // to inertia, which can trigger another archive. We use a global var to
      // give a single entry exclusive onWheel handling to mitigate this.
      if (touchPadExlusiveEntryId === null) {
        touchPadExlusiveEntryId = entryId;
      } else if (touchPadExlusiveEntryId !== entryId) {
        return;
      }
      if (exclusiveResetTimeoutId.current) {
        clearTimeout(exclusiveResetTimeoutId.current);
      }
      exclusiveResetTimeoutId.current = setTimeout(() => {
        touchPadExlusiveEntryId = null;
      }, 1000);

      // Calc whether time has rendered current state stale.
      const now = performance.now();
      const dt = now - wheelPrevTs.current;
      const timeout = dt > 80;
      wheelPrevTs.current = now;

      // Calc whether swipe direction reversal has rendered current state stale.
      const sign = Math.sign(e.deltaX);
      const switchDirection = wheelPrevSign.current !== 0 && sign !== wheelPrevSign.current;
      wheelPrevSign.current = sign;

      // Calc whether this is more of a vertical move.
      pan.current.x += e.deltaX;
      pan.current.y += e.deltaY;
      const isVerticalMove = Math.abs(pan.current.x) < Math.abs(pan.current.y);
      if (!isVerticalMove && preventDefaultIfActioned) {
        // Prevent-default to avoid conflicting with horizontal scrollers
        // (horiz feed list) and overscroll history navigation.
        e.preventDefault();
      }

      if (timeout || switchDirection || isVerticalMove) {
        // Consider this a new touch-move. Reset everything and wait for
        // the next event to accumulate data.

        // If it's an exclusively vertical move, keep the y-pan position to
        // track in aggregate whether the gesture continues to be a vertical
        // scroll.
        const exclusivelyVerticalMove = !timeout && !switchDirection && isVerticalMove;
        if (exclusivelyVerticalMove) {
          if (Math.abs(pan.current.y) > 100) {
            // If there's been significant vertical scroll, zero out x-pan to
            // eliminate a diagonal-ish pan triggering an action far away from
            // pan-start-point.
            pan.current.x = 0;
          } else {
            // If the y-scroll is present, make it difficult but not impossible
            // for an x-pan action to be triggered.
            pan.current.x = pan.current.x / 2;
          }
        } else {
          pan.current = { x: 0, y: 0 };
        }
        wheelPrevSign.current = sign;
        wheelActiveSpan.current = 5;
        wheelDeltaXAbsHistory.current = [];
        wheelSlowingDownCount.current = 0;
        wheelEventLevel.current = 0;
        return;
      }

      //
      // Logic to counteract (to the best of our abilities) the large amount of
      // scrolling inertia on mac.
      //
      const deltaXAbs = Math.abs(e.deltaX);
      const deltaXAbsAvg =
        wheelDeltaXAbsHistory.current.reduce((a, b) => a + b, 0) / wheelDeltaXAbsHistory.current.length;
      if (deltaXAbsAvg + 1 >= deltaXAbs) {
        wheelSlowingDownCount.current += 1;
      } else {
        wheelSlowingDownCount.current = 0;
      }
      wheelDeltaXAbsHistory.current.push(deltaXAbs);
      if (wheelDeltaXAbsHistory.current.length > 3) {
        // Long history necessary because mac's have a long inertial period with
        // consistent non-decreasing values.
        wheelDeltaXAbsHistory.current.shift();
      }
      if (wheelSlowingDownCount.current <= 3) {
        wheelActiveSpan.current += dt;
      }

      const direction = wheelPrevSign.current > 0 ? 'left' : 'right';
      if (wheelEventLevel.current === 0 && wheelActiveSpan.current > 30) {
        onTouchPadEvent({ direction, level: wheelEventLevel.current });
        wheelEventLevel.current += 1;
      } else if (wheelEventLevel.current === 1 && wheelActiveSpan.current > 80) {
        onTouchPadEvent({ direction, level: wheelEventLevel.current });
        wheelEventLevel.current += 1;
      } else if (wheelEventLevel.current === 2 && wheelActiveSpan.current > 100) {
        onTouchPadEvent({ direction, level: wheelEventLevel.current });
        wheelEventLevel.current += 1;
      }
    },
    [onTouchPadEvent],
  );

  return {
    onWheelEventHandler,
  };
};
