"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthKind = void 0;
var AuthKind;
(function (AuthKind) {
    AuthKind[AuthKind["Bearer"] = 0] = "Bearer";
    AuthKind[AuthKind["Cookie"] = 1] = "Cookie";
    AuthKind[AuthKind["NoAuth"] = 2] = "NoAuth";
    AuthKind[AuthKind["AcceptCookie"] = 3] = "AcceptCookie";
})(AuthKind = exports.AuthKind || (exports.AuthKind = {}));
