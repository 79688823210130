import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { router } from '../../index';
import { MainActionCreators } from '../../state/reducer';
import * as store from '../../state/store';

import useUserMeInternal from './useUserMeInternal';

const useNav = () => {
  const accountInfo = useUserMeInternal();
  const curFeed = useSelector<store.IAppState, store.CurFeed | undefined>(state => state.page.feedPage.curFeed);
  const navigate = router.navigate;
  const dispatch = useDispatch();
  const navToFeed = React.useCallback(
    (feed: api.feed.IFeedInfo, replace?: boolean) => {
      dispatch(MainActionCreators.popExploredItem());
      navigate(cfe.ApiHelpers.getUrlPathnameForFeed(feed), { replace });
    },
    [navigate],
  );
  const navToNewsfeed = React.useCallback(
    (replace?: boolean, onboarded?: boolean) => {
      dispatch(MainActionCreators.popExploredItem());
      if (curFeed && curFeed.kind === 'newsfeed' && curFeed.asUsername) {
        navigate(`/as/${curFeed.asUsername}`, { replace });
      } else {
        navigate('/' + (onboarded ? '?onboarded=true' : ''), { replace });
      }
    },
    [navigate, accountInfo, curFeed],
  );
  const navToNewsfeedAsUser = React.useCallback(
    (asUsername: string, replace?: boolean) => {
      navigate(`/as/${asUsername}`, { replace });
    },
    [navigate],
  );
  const navToInbox = React.useCallback(
    (replace?: boolean) => {
      dispatch(MainActionCreators.popExploredItem());
      navigate('/inbox', { replace });
    },
    [navigate],
  );
  return React.useMemo(
    () => ({
      navToEntry: (feed: api.feed.IFeedInfo, entry: api.feed.IFeedEntryReference, replace?: boolean) => {
        batch(() => {
          dispatch(MainActionCreators.setExploredItem({ feed, entry }));
          dispatch(MainActionCreators.setAutoPlayMedia(false));
        });
        navigate(cfe.ApiHelpers.getUrlPathnameForEntry(entry.entry_id), { replace });
      },
      navToEntryCpc: (feed: api.feed.IFeedInfo, entry: api.feed.IFeedEntryReference, replace?: boolean) => {
        dispatch(MainActionCreators.setExploredItem({ feed, entry }));
        navigate(cfe.ApiHelpers.getUrlPathnameForEntry(entry.entry_id) + '/cpc', { replace });
      },
      navToFeed,
      navToInbox,
      navToNewsfeed,
      navToNewsfeedAsUser,
      newsfeedUrl: '/',
      popEntry: (replace?: boolean) => {
        if (window.location.pathname.startsWith('/e/')) {
          batch(() => {
            dispatch(MainActionCreators.popExploredItem());
            if (curFeed && curFeed.kind === 'feed') {
              navToFeed(curFeed.feed, replace);
            } else if (curFeed && curFeed.kind === 'inbox') {
              navToInbox(replace);
            } else {
              navToNewsfeed(replace);
            }
          });
        }
      },
    }),
    [navigate, accountInfo, curFeed],
  );
};

/**
 * Beware to only use this when necessary as it triggers a re-render when the
 * route changes.
 */
export const useAppLoc = () => {
  const routeParams = useParams<{ entryId?: string }>();
  return React.useMemo(
    () => ({
      isCpcViewerOpen: routeParams.entryId !== undefined && window.location.pathname.endsWith('/cpc'),
      isExploreOpen: routeParams.entryId !== undefined && window.location.pathname.startsWith('/e/'),
      isExploreOrCpcOpen:
        routeParams.entryId !== undefined &&
        (window.location.pathname.startsWith('/e/') || window.location.pathname.endsWith('/cpc')),
    }),
    [routeParams.entryId],
  );
};

export default useNav;
