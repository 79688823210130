import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { MainActionCreators } from '../state/reducer';
import * as store from '../state/store';

import FollowRssFeedModal from './FollowRssFeedModal';
import useApiClient from './hooks/useApiClient';
import useUserMeInternal from './hooks/useUserMeInternal';
import ChromeIcon from './icon/ChromeIcon';
import RssIcon from './icon/RssIcon';
import Button from './lib/Button';
import LayoutLine from './lib/LayoutLine';
import { useModalManager } from './ModalManagerContext';
import Pagelet, { PageletGutter, PageletNavItem } from './Pagelet';
import PopularFeeds from './PopularFeeds';
import PopularPeople from './PopularPeople';
import PopularTopics from './PopularTopics';

const DiscoverPage = () => {
  const dispatch = useDispatch();
  const apiClient = useApiClient();
  const accountInfo = useUserMeInternal();
  const feeds = useSelector<store.IAppState, Map<string, api.feed.IFeedInfo>>(state => state.feeds);
  const { pushModal } = useModalManager();

  //
  // If the user follows/unfollows feeds, clear the newsfeed so it refreshes
  // with their latest feeds when they return to it.
  //
  const followingCount = [...feeds.values()].filter(feed => feed.for_viewer.following).length;
  const prevFollowingCount = React.useRef(followingCount);
  React.useEffect(() => {
    if (followingCount !== prevFollowingCount.current) {
      dispatch(MainActionCreators.apiCacheClearNewsfeedEntries());
      prevFollowingCount.current = followingCount;
    }
  }, [followingCount]);

  const { result } = cfe.ApiHook.useApiRead(apiClient, apiClient.feedPopular3, undefined, res => res, false, {
    onResult: res => {
      batch(() => {
        res.categories.map(category => category.feeds.map(feed => dispatch(MainActionCreators.updateFeed(feed))));
        res.people.map(category => category.feeds.map(feed => dispatch(MainActionCreators.updateFeed(feed))));
        res.topics.map(category => category.feeds.map(feed => dispatch(MainActionCreators.updateFeed(feed))));
      });
    },
  });

  return (
    <Pagelet
      title="Discover"
      loading={cfe.ApiData.isLoading(result)}
      auxbox={
        <div className="tw-flex tw-flex-col">
          <DiscoverNav />
          <div className="tw-p-4 tw-grow tw-bg-highlight tw-rounded-lg tw-text-primary tw-text-sm">
            <Pagelet.Heading3 className="tw-mt-0">Other ways to follow...</Pagelet.Heading3>
            <p>YouTube, Medium, Substack, blogs, RSS, and more.</p>
            <p>Use our search bar to see if it's already in our system.</p>
            <p>If not, use our Chrome extension:</p>
            <Button
              block
              onClick={() => {
                if (accountInfo) {
                  window.location.href = '/get-extension';
                } else {
                  window.location.href = '/login?next=/get-extension';
                }
              }}
            >
              <span>
                Get <ChromeIcon size="1rem" /> Extension
              </span>
            </Button>
            <p className="tw-mt-4">Still having problems? Add using an RSS url:</p>
            <div className="tw-flex tw-mt-2">
              <Button
                block
                onClick={() => {
                  if (accountInfo) {
                    pushModal({
                      component: modalProps => <FollowRssFeedModal {...modalProps} />,
                      dupKey: 'follow-rss',
                      kind: 'generic',
                    });
                  } else {
                    window.location.href = '/login?next=/discover';
                  }
                }}
              >
                <span>
                  Follow RSS <RssIcon size="1rem" offsetUp />
                </span>
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <a id="topics" />
      <PageletGutter>
        <Pagelet.Heading1>Popular Topics</Pagelet.Heading1>
        <p className="tw-text-primary">Topics are filled with the best stories from anyone and anywhere.</p>
      </PageletGutter>
      <PopularTopics apiPopular3Res={result} feeds={feeds} includeRecommendations={accountInfo !== null} />
      <a id="authors" />
      <LayoutLine className="tw-mt-12" />
      <PageletGutter>
        <Pagelet.Heading1>Popular Authors and Outlets</Pagelet.Heading1>
        <p className="tw-text-primary">
          Follow your favorites from anywhere and everywhere including YouTube, Medium, Substack, blogs, and more.
        </p>
      </PageletGutter>
      <PopularFeeds apiPopular3Res={result} feeds={feeds} />
      <a id="people" />
      <LayoutLine className="tw-mt-12" />
      <PageletGutter>
        <Pagelet.Heading1>Friends and Egoists</Pagelet.Heading1>
        <p className="tw-text-primary">
          Following people lets you see the great stuff they ego. Search for your friends by their username.
        </p>
      </PageletGutter>
      <PopularPeople apiPopular3Res={result} feeds={feeds} />
      <div className="tw-mb-12" />
    </Pagelet>
  );
};

const DiscoverNav = () => (
  <div className="tw-flex tw-flex-col">
    <PageletNavItem
      onClick={() => {
        const ele = document.querySelector("a[id='topics']");
        ele?.scrollIntoView({
          behavior: 'smooth',
        });
      }}
    >
      Topics
    </PageletNavItem>
    <PageletNavItem
      onClick={() => {
        const ele = document.querySelector("a[id='authors']");
        ele?.scrollIntoView({
          behavior: 'smooth',
        });
      }}
    >
      Authors and outlets
    </PageletNavItem>
    <PageletNavItem
      onClick={() => {
        const ele = document.querySelector("a[id='people']");
        ele?.scrollIntoView({
          behavior: 'smooth',
        });
      }}
    >
      Friends and egoists
    </PageletNavItem>
  </div>
);

export default DiscoverPage;
