import React from 'react';

import { useKeyPress } from './KeyPressContext';
import CmdInput from './lib/CmdInput';
import Modal from './lib/Modal';

const KbShortcutInfo = (props: { cmd: string; help: string }) => (
  <div className="tw-mb-1">
    <CmdInput cmd={props.cmd} />
    <span className="tw-text-primary tw-text-sm tw-align-middle tw-ml-2">{props.help}</span>
  </div>
);

const KeyboardShortcutsModal = (props: { close: () => void; isForeground: boolean }) => {
  useKeyPress(
    ['u', 'Escape', '?'],
    () => {
      props.close();
    },
    false,
    10,
  );
  return (
    <Modal.Container show={props.isForeground} close={props.close}>
      <Modal.Header>
        <Modal.Heading1>Keyboard Shortcuts</Modal.Heading1>
      </Modal.Header>
      <Modal.Body gutter>
        <Modal.Heading2>Navigation</Modal.Heading2>
        <KbShortcutInfo cmd="J" help="Next story" />
        <KbShortcutInfo cmd="K" help="Previous story" />
        <KbShortcutInfo cmd="L" help="Move left or slide left" />
        <KbShortcutInfo cmd="H" help="Move right or slide right" />
        <KbShortcutInfo cmd="I" help="Open story metadata" />
        <KbShortcutInfo cmd="O" help="Open the story" />
        <KbShortcutInfo cmd="Shift + O" help="If applicable, open the discussion page (e.g. Reddit)" />
        <KbShortcutInfo cmd="U" help="Back to feed, or more generally, close pop up" />
        <KbShortcutInfo cmd="N" help="Next option (menu dependent)" />
        <KbShortcutInfo cmd="P" help="Previous option (menu dependent)" />
        <KbShortcutInfo cmd="Enter" help="Select option (menu dependent)" />
        <KbShortcutInfo cmd="G G" help="Go home" />
        <KbShortcutInfo cmd="G B" help="Go to inbox" />
        <KbShortcutInfo cmd="G L" help="Go to library" />
        <KbShortcutInfo cmd="/" help="Search" />
        <KbShortcutInfo cmd="C" help="Toggle compact mode" />
        <KbShortcutInfo cmd="V" help="Toggle videos only" />
        <KbShortcutInfo cmd="Shift + ?" help="Keyboard shortcuts (this menu)" />

        <Modal.Heading2>Actions</Modal.Heading2>
        <KbShortcutInfo cmd="1" help="Give ego" />
        <KbShortcutInfo cmd="2" help="Send story" />
        <KbShortcutInfo cmd="3" help="Save story" />
        <KbShortcutInfo cmd="E" help="Archive story" />
        <KbShortcutInfo cmd="Z" help="Undo (e.g. Un-archive)" />
        <KbShortcutInfo cmd="Shift + R" help="Refresh feed" />
      </Modal.Body>
    </Modal.Container>
  );
};

export default KeyboardShortcutsModal;
