import React from 'react';

const PaywallIcon = (props: { size: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={`${props.size}px`} height={`${props.size}px`}>
    <path
      fill="currentColor"
      d="M3,16H12V21H3V16M2,10H8V15H2V10M9,10H15V15H9V10M16,10H22V15H16V10M13,16H21V21H13V16M3,4H11V9H3V4M12,4H21V9H12V4Z"
    />
  </svg>
);

export default PaywallIcon;
