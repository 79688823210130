import * as React from 'react';

import MobileStoreOptions from './MobileStoreOptions';
import Pagelet from './Pagelet';

const GetAppPage = () => {
  return (
    <Pagelet title="Get the Superego Mobile App" gutter>
      <div className="tw-mt-6">
        <MobileStoreOptions />
      </div>
    </Pagelet>
  );
};

export default GetAppPage;
