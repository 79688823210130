import * as api from 'ego-sdk-js';
import React from 'react';
import { createPortal } from 'react-dom';
import { createContext, useContextSelector } from 'use-context-selector';

import type { AddTopicBy } from './AddTopicModal';
import EditProfileModal from './EditProfileModal';
import FeedEntryAddModal from './FeedEntryAddModal';
import GiveEgoModal from './GiveEgoModal';
import useKeyDownEvent from './hooks/useKeyDownEvent';
import { ModalAnimate, ModalContainer, ModalPresence } from './lib/Modal';
import Spinner from './lib/Spinner';
import PostModal from './PostModal';
import RelatedFeedsModal from './RelatedFeedsModal';
import SaveToLibraryModal from './SaveToLibraryModal';
import SearchModal from './SearchModal';
import SendToModal from './SendToModal';

// Lazy load since this is agent-only
const AddTopicModal = React.lazy(() => import('./AddTopicModal'));

interface AddTopicModalType {
  kind: 'add-topic';
  feedId: string;
  entryId: string;
  topic: AddTopicBy;
  done: () => void;
}

interface EditProfileModalType {
  kind: 'edit-profile';
  userInfo: api.user.IUserInfo;
  updateUserInfo: (userInfo: api.user.IUserInfo) => void;
}

interface FeedEntryAddModalType {
  kind: 'add';
  feed: api.feed.IFeedInfo;
  src?: api.feed.IFeedEntryReference;
}

interface GiveEgoModalType {
  kind: 'ego';
  // [FeedId, FeedEntry]
  src: [string, api.feed.IFeedEntryReference] | null;
}

interface PostModalType {
  kind: 'post';
  feedId: string;
}

interface RelatedFeedsModalType {
  kind: 'related-feeds';
  feedId: string;
}

interface SaveToLibraryModalType {
  kind: 'save';
  feedId: string;
  entry: api.feed.IFeedEntryReference;
}

interface SearchModalType {
  kind: 'search';
}

interface SendToModalType {
  kind: 'send-to';
  entry: api.feed.IFeedEntryReference;
}

export interface GenericModalProps {
  close: () => void;
  isForeground: boolean;
  animate?: ModalAnimate;
}

interface GenericModalType {
  kind: 'generic';
  component: (props: GenericModalProps) => React.ReactNode;
  animate?: ModalAnimate;
  dupKey: string;
}

type ModalType =
  | AddTopicModalType
  | EditProfileModalType
  | FeedEntryAddModalType
  | GenericModalType
  | GiveEgoModalType
  | PostModalType
  | RelatedFeedsModalType
  | SaveToLibraryModalType
  | SearchModalType
  | SendToModalType;

interface ModalState {
  modal: ModalType;
  modalId: number;
}

interface ModalManagerState {
  modals: ModalState[];
  popModal: (modalId: number, resetStack?: boolean) => void;
  pushModal: (modal: ModalType, opts?: { stackOk?: boolean }) => void;
}

export const ModalManagerContext = createContext<ModalManagerState>({
  modals: [],
  popModal: () => null,
  pushModal: () => null,
});

/**
 * Modals are mounted outside the default root DOM element and instead in a
 * parallel portal-root DOM tree. This avoids z-index issues.
 */
export const ModalManagerContextProvider = (props: {
  // The DOM element that modals will be mounted to.
  rootDomElementId: string;
  children: React.ReactNode;
}) => {
  const maxModalId = React.useRef(0);
  const [value, setValue] = React.useState<ModalManagerState>({
    modals: [],
    popModal: () => null,
    pushModal: () => null,
  });
  React.useEffect(() => {
    createPortalRootDomElement(props.rootDomElementId);
    setValue({
      modals: [],
      popModal: (modalId, resetStack) => {
        if (resetStack) {
          setValue(curValue => ({
            ...curValue,
            modals: [],
          }));
        } else {
          setValue(curValue => {
            return {
              ...curValue,
              modals: curValue.modals.filter(modalState => modalState.modalId !== modalId),
            };
          });
        }
      },
      pushModal: (modal, opts) =>
        setValue(curValue => {
          maxModalId.current += 1;
          let newModals;
          if (opts?.stackOk) {
            if (
              curValue.modals.filter(
                modalState =>
                  modalState.modal.kind === modal.kind &&
                  (modalState.modal.kind !== 'generic' ||
                    modal.kind !== 'generic' ||
                    modalState.modal.dupKey === modal.dupKey),
              ).length === 0
            ) {
              newModals = [...curValue.modals, { modal, modalId: maxModalId.current }];
            } else {
              newModals = curValue.modals;
            }
          } else {
            newModals = [{ modal, modalId: maxModalId.current }];
          }
          return {
            ...curValue,
            modals: newModals,
          };
        }),
    });
  }, []);
  useKeyDownEvent(code => {
    if (code === 'Escape' && value.modals.length > 0) {
      setValue(oldValue => ({ ...oldValue, modals: [] }));
    }
  });
  const portalRootElement = document.getElementById(props.rootDomElementId);
  if (!portalRootElement) {
    return null;
  }
  return (
    <ModalManagerContext.Provider value={value}>
      {createPortal(
        <ModalPresence>
          <React.Suspense
            fallback={
              <ModalContainer show close={() => null}>
                <div className="tw-flex tw-justify-center tw-py-8">
                  <Spinner lg />
                </div>
              </ModalContainer>
            }
          >
            {value.modals.map((modalState, index) => {
              const isForeground = index === value.modals.length - 1;
              if (modalState.modal.kind === 'ego') {
                return (
                  <GiveEgoModal
                    key="ego"
                    src={modalState.modal.src}
                    closeModal={() => {
                      value.popModal(modalState.modalId);
                    }}
                    isForeground={isForeground}
                  />
                );
              } else if (modalState.modal.kind === 'save') {
                return (
                  <SaveToLibraryModal
                    key="save"
                    feedId={modalState.modal.feedId}
                    entry={modalState.modal.entry}
                    closeModal={() => {
                      value.popModal(modalState.modalId);
                    }}
                    isForeground={isForeground}
                  />
                );
              } else if (modalState.modal.kind === 'add') {
                return (
                  <FeedEntryAddModal
                    key="add"
                    feed={modalState.modal.feed}
                    src={modalState.modal.src}
                    closeModal={() => {
                      value.popModal(modalState.modalId);
                    }}
                    isForeground={isForeground}
                  />
                );
              } else if (modalState.modal.kind === 'send-to') {
                return (
                  <SendToModal
                    key="send-to"
                    entry={modalState.modal.entry}
                    closeModal={() => {
                      value.popModal(modalState.modalId);
                    }}
                    isForeground={isForeground}
                  />
                );
              } else if (modalState.modal.kind === 'post') {
                return (
                  <PostModal
                    key="post"
                    feedId={modalState.modal.feedId}
                    closeModal={() => {
                      value.popModal(modalState.modalId);
                    }}
                    isForeground={isForeground}
                  />
                );
              } else if (modalState.modal.kind === 'edit-profile') {
                return (
                  <EditProfileModal
                    key="edit-profile"
                    userInfo={modalState.modal.userInfo}
                    updateUserInfo={modalState.modal.updateUserInfo}
                    closeModal={() => {
                      value.popModal(modalState.modalId);
                    }}
                    isForeground={isForeground}
                  />
                );
              } else if (modalState.modal.kind === 'add-topic') {
                return (
                  <AddTopicModal
                    key="add-topic"
                    feedId={modalState.modal.feedId}
                    entryId={modalState.modal.entryId}
                    topic={modalState.modal.topic}
                    done={modalState.modal.done}
                    closeModal={() => {
                      value.popModal(modalState.modalId);
                    }}
                    isForeground={isForeground}
                  />
                );
              } else if (modalState.modal.kind === 'related-feeds') {
                return (
                  <RelatedFeedsModal
                    key="related-feeds"
                    feedId={modalState.modal.feedId}
                    closeModal={() => {
                      value.popModal(modalState.modalId);
                    }}
                    isForeground={isForeground}
                  />
                );
              } else if (modalState.modal.kind === 'search') {
                return (
                  <SearchModal
                    key="search"
                    closeModal={(resetStack?: boolean) => {
                      value.popModal(modalState.modalId, resetStack);
                    }}
                    isForeground={isForeground}
                  />
                );
              } else if (modalState.modal.kind === 'generic') {
                return (
                  <React.Fragment key={modalState.modal.dupKey ?? 'generic'}>
                    {modalState.modal.component({
                      animate: modalState.modal.animate,
                      close: () => value.popModal(modalState.modalId),
                      isForeground,
                    })}
                  </React.Fragment>
                );
              } else {
                throw Error('Unexpected modal type');
              }
            })}
          </React.Suspense>
        </ModalPresence>,
        portalRootElement,
      )}

      {props.children}
    </ModalManagerContext.Provider>
  );
};

const createPortalRootDomElement = (elementId: string) => {
  if (document.getElementById(elementId)) {
    return;
  }

  const portalRootElement = document.createElement('div');
  portalRootElement.setAttribute('id', elementId);
  document.body.appendChild(portalRootElement);
};

export const useModalManager = () => {
  const pushModal = useContextSelector(ModalManagerContext, v => v.pushModal);
  return React.useMemo(() => ({ pushModal }), [pushModal]);
};

export const useModalListener = () => {
  return useContextSelector(ModalManagerContext, v => (v.modals.length > 0 ? v.modals[v.modals.length - 1] : null));
};

export const useShowUnmanagedModal = () => {
  return useContextSelector(ModalManagerContext, v => v.modals.length === 0);
};
