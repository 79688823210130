import clsx from 'clsx';
import React from 'react';

const ListGroup = (props: { className?: string; children: React.ReactNode }) => (
  <div className={clsx('tw-flex tw-flex-col', props.className)}>{props.children}</div>
);

export interface ListGroupItemProps extends React.ComponentProps<'div'> {
  sm?: boolean;
  className?: string;
  disableKb?: boolean;
  kbActive?: boolean;
  mutedHover?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  children: React.ReactNode;
}

export const ListGroupItem = (props: ListGroupItemProps) => {
  const { sm, className, disableKb, kbActive, mutedHover, disabled, children, ...rest } = props;
  return (
    <div
      {...rest}
      className={clsx(
        'tw-flex tw-gap-x-2 tw-px-5',
        props.icon ? (props.sm ? 'tw-py-1' : 'tw-py-1.5') : props.sm ? 'tw-py-2' : 'tw-py-3',
        props.icon ? 'tw-items-center' : null,
        props.disabled
          ? 'tw-opacity-60'
          : props.mutedHover
            ? 'hover:tw-bg-neutral-100 dark:hover:tw-bg-neutral-800'
            : 'hover:!tw-bg-yellow-light dark:hover:!tw-bg-yellow-dark',
        props.disableKb ? null : props.kbActive ? 'kb-pad-4 kb-sel' : 'kb-pad-4',
        props.disabled ? 'tw-cursor-default' : props.onClick ? 'tw-cursor-pointer' : null,
        props.className,
      )}
      onClick={!props.disabled && props.onClick ? props.onClick : undefined}
    >
      {props.icon ? props.icon : null}
      {props.children}
    </div>
  );
};

interface ListGroupItemLinkExternalProps extends ListGroupItemProps {
  href: string;
  target?: string;
}

export const ListGroupItemLinkExternal = React.forwardRef<HTMLAnchorElement, ListGroupItemLinkExternalProps>(
  (props, ref) => {
    const { href, target, ...rest } = props;
    return (
      <a
        ref={ref}
        href={props.href}
        target={props.target}
        className="tw-text-primary hover:tw-text-primary hover:tw-no-underline"
      >
        <ListGroupItem {...rest} />
      </a>
    );
  },
);

export const ListGroupItemHeading = (props: React.ComponentProps<'h6'>) => (
  <div {...props} className={clsx('tw-text-base tw-font-medium tw-leading-tight tw-mb-1', props.className)}>
    {props.children}
  </div>
);

ListGroupItem.Heading = ListGroupItemHeading;

ListGroup.Item = ListGroupItem;

ListGroup.ItemLinkExternal = ListGroupItemLinkExternal;

export default ListGroup;
