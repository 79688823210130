import clsx from 'clsx';
import React from 'react';

const StoryDivider = (props: { compact: boolean; xl?: boolean }) =>
  props.compact ? <StoryDividerThin /> : <StoryDividerThick xl={props.xl} />;

export const StoryDividerThick = (props: { xl?: boolean }) => (
  <div role="button" className={clsx('tw-bg-canvas', props.xl ? 'tw-h-[12px]' : 'tw-h-[4px]')} />
);
export const StoryDividerThin = () => <div role="button" className="tw-bg-canvas tw-h-[2px]" />;

export default StoryDivider;
