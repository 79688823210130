import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MainActionCreators } from '../state/reducer';
import * as apiUtil from '../util-api';

import useAgentMode from './hooks/useAgentMode';
import { useAuthedApiClient } from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import SettingsIcon from './icon/SettingsIcon';
import Alert from './lib/Alert';
import Button from './lib/Button';
import InputLabel from './lib/InputLabel';
import LayoutLine from './lib/LayoutLine';
import Modal from './lib/Modal';
import SelectForm from './lib/SelectForm';
import Spinner from './lib/Spinner';
import TextAreaInput from './lib/TextAreaInput';
import TextEditInput from './lib/TextEditInput';
import { TextInputFooterForOptional } from './lib/TextInput';
import ToggleForm from './lib/ToggleForm';
import ProfilePictureUpdate from './ProfilePictureUpdate';

const FeedSettingsAclUI = React.lazy(() => import('./FeedSettingsAclUI'));
const FeedSettingsDefaultEphemeralityAgentUI = React.lazy(() => import('./FeedSettingsDefaultEphemeralityAgentUI'));
const FeedSettingsForwardAgentUI = React.lazy(() => import('./FeedSettingsForwardAgentUI'));
const FeedSettingsPrismAgentUI = React.lazy(() => import('./FeedSettingsPrismAgentUI'));
const FeedSettingsRssAgentUI = React.lazy(() => import('./FeedSettingsRssAgentUI'));
const FeedSettingsStyleAgentUI = React.lazy(() => import('./FeedSettingsStyleAgentUI'));
const FeedSettingsTopicAgentUI = React.lazy(() => import('./FeedSettingsTopicAgentUI'));
const FeedSettingsRelatedTopicsAgentUI = React.lazy(() => import('./FeedSettingsRelatedTopicsAgentUI'));

const FeedSettingsModal = (props: {
  feed: api.feed.IFeedInfo;
  closeModal: () => void;
  removeFeedCallback: (feed: api.feed.IFeedInfo) => void;
  updateFeedCallback: (feed: api.feed.IFeedInfo) => void;
  renameFeedUrlUpdate: (feed: api.feed.IFeedInfo) => void;
}) => {
  const agentMode = useAgentMode();
  const apiClient = useAuthedApiClient();
  const dispatch = useDispatch();
  const [inFlight, setInFlight] = useState(false);

  const [deleteError, setDeleteError] = useState('');

  const handleClose = () => {
    props.closeModal();
  };

  const [blurb, setBlurb] = useState(props.feed.blurb);
  const blurbOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    event.preventDefault();
    if (event.currentTarget.value.length <= 100) {
      setBlurb(event.currentTarget.value);
    }
  };
  const saveBlurb = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setInFlight(true);
    apiClient
      .feedUpdateBlurb({ feed_id: props.feed.feed_id, blurb })
      .then(resp => {
        if (resp.kind === api.StatusCode.Ok) {
          props.updateFeedCallback(props.feed);
          dispatch(MainActionCreators.updateFeed(resp.result));
          // The API may have modified the blurb.
          setBlurb(resp.result.blurb);
        } else if (resp.kind === api.StatusCode.Error) {
          apiUtil.mkToastFromBadStatusCode(dispatch, resp);
        }
      })
      .catch(() => {
        apiUtil.mkToastFromHttpError(dispatch);
      })
      .finally(() => {
        setInFlight(false);
      });
  };

  const [permPublicRead, setPermPublicRead] = useState(props.feed.perm_public_read);
  const [permPublicReadError, setPermPublicReadError] = useState('');

  const [defaultOrderingByScore, setDefaultOrderingByScore] = useState(props.feed.default_ordering['.tag'] === 'score');
  const [defaultOrderingByScoreError, setDefaultOrderingByScoreError] = useState('');

  const handleUpdatePermPublicRead = (ok: () => void, err: () => void) => {
    const newPermPublicRead = !permPublicRead;
    setPermPublicRead(newPermPublicRead);
    setPermPublicReadError('');
    apiClient
      .feedUpdatePermPublicRead({ feed_id: props.feed.feed_id, perm_public_read: newPermPublicRead })
      .then(resp => {
        if (resp.kind === api.StatusCode.Ok) {
          props.updateFeedCallback(props.feed);
          dispatch(MainActionCreators.updateFeed(resp.result));
        } else if (resp.kind === api.StatusCode.Error) {
          if (resp.error['.tag'] === 'feed_not_found') {
            setPermPublicReadError('Sorry, unexpected error: Feed does not exist.');
          } else if (resp.error['.tag'] === 'no_permission') {
            setPermPublicReadError('You do not have permission to do this.');
          } else {
            setPermPublicReadError('Sorry, unexpected error.');
            apiUtil.mkToastFromBadStatusCode(dispatch, resp);
          }
          err();
        } else {
          err();
        }
      })
      .catch(() => {
        apiUtil.mkToastFromHttpError(dispatch);
        err();
      })
      .finally(() => {
        ok();
      });
  };

  const handleUpdateDefaultOrdering = (ok: () => void, err: () => void) => {
    const newDefaultOrderingByScore = !defaultOrderingByScore;
    setDefaultOrderingByScore(newDefaultOrderingByScore);
    const defaultOrdering: api.feed.EntryOrderBy = newDefaultOrderingByScore ? { '.tag': 'score' } : { '.tag': 'time' };
    apiClient
      .feedUpdateDefaultOrdering({ feed_id: props.feed.feed_id, default_ordering: defaultOrdering })
      .then(resp => {
        if (resp.kind === api.StatusCode.Ok) {
          props.updateFeedCallback(props.feed);
          dispatch(MainActionCreators.updateFeed(resp.result));
        } else if (resp.kind === api.StatusCode.Error) {
          if (resp.error['.tag'] === 'feed_not_found') {
            setDefaultOrderingByScoreError('Sorry, unexpected error: Feed does not exist.');
          } else if (resp.error['.tag'] === 'no_permission') {
            setDefaultOrderingByScoreError('You do not have permission to do this.');
          } else {
            setDefaultOrderingByScoreError('Sorry, unexpected error.');
            apiUtil.mkToastFromBadStatusCode(dispatch, resp);
          }
          err();
        } else {
          err();
        }
      })
      .catch(() => {
        apiUtil.mkToastFromHttpError(dispatch);
        err();
      })
      .finally(() => {
        ok();
      });
  };

  const handleDeleteSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setInFlight(true);
    apiClient
      .feedDelete({ feed_id: props.feed.feed_id })
      .then(resp => {
        if (resp.kind === api.StatusCode.Ok) {
          props.removeFeedCallback(props.feed);
          dispatch(
            MainActionCreators.addToast({
              header: `${cfe.ApiHelpers.getFeedShortName(props.feed)} has been deleted.`,
              icon: 'check',
            }),
          );
          handleClose();
        } else if (resp.kind === api.StatusCode.Error) {
          if (resp.error['.tag'] === 'feed_not_found') {
            // Must have been deleted somewhere else.
            props.removeFeedCallback(props.feed);
            dispatch(
              MainActionCreators.addToast({
                header: `${cfe.ApiHelpers.getFeedShortName(props.feed)} already deleted.`,
                icon: 'info',
              }),
            );
            handleClose();
          } else if (resp.error['.tag'] === 'no_permission') {
            // Generally shouldn't happen since the UI wouldn't show the option.
            // Can also be due to a race condition.
            setInFlight(false);
            setDeleteError('You do not have permission to delete this feed.');
          } else {
            setInFlight(false);
            setDeleteError('Sorry, unexpected error.');
            throw Error(`Unexpected error ${resp.error['.tag']}`);
          }
        }
      })
      .catch(() => {
        setInFlight(false);
      });
  };

  const { apiDo: apiFeedSetDisplayMode, okToast } = useApiDo(apiClient, apiClient.feedSetDisplayMode);
  const handleUpdateDisplayMode = (displayMode: string) => {
    if (displayMode !== 'default' && displayMode !== 'headlines') {
      return;
    }
    apiFeedSetDisplayMode(
      {
        display_mode: {
          '.tag': displayMode,
        },
        feed_id: props.feed.feed_id,
      },
      {
        onResult: res => {
          props.updateFeedCallback(res.feed);
          dispatch(MainActionCreators.updateFeed(res.feed));
          okToast('Updated display mode');
        },
      },
    );
  };

  return (
    <Modal.Container
      show={true}
      close={handleClose}
      // noAnimation b/c the modal is long which makes the scale animation look bad.
    >
      <Modal.Header>
        <Modal.Heading1>
          <SettingsIcon size="1.3rem" offsetUp className="tw-mr-1" />
          {cfe.ApiHelpers.getFeedShortName(props.feed)}
        </Modal.Heading1>
      </Modal.Header>
      <Modal.Body gutter>
        <form className="tw-mt-2">
          <fieldset disabled={inFlight}>
            <div>
              <InputLabel>Profile Picture</InputLabel>
              <ProfilePictureUpdate feed={props.feed} />
              <div className="tw-mb-4" />
            </div>
            <TextEditInput
              controlId="formName"
              className="tw-mb-4"
              label="Name"
              caution="Changing the feed name will change its link."
              placeholder="Set feed name"
              initial={cfe.ApiHelpers.getFeedShortName(props.feed)}
              maxLength={64}
              minLength={1}
              onUpdate={(val, done, error) => {
                apiClient
                  .feedRename({ feed_id: props.feed.feed_id, name: val! })
                  .then(resp => {
                    done();
                    if (resp.kind === api.StatusCode.Ok) {
                      // This ordering is precise. The URL must be changed before the feed is
                      // updated.
                      props.renameFeedUrlUpdate(resp.result.feed);
                      props.updateFeedCallback(resp.result.feed);
                    } else if (resp.kind === api.StatusCode.Error) {
                      if (resp.error['.tag'] === 'name_conflict') {
                        error('Name conflicts with another feed.');
                      } else if (resp.error['.tag'] === 'feed_not_found') {
                        error('Invalid feed');
                      } else if (resp.error['.tag'] === 'reserved') {
                        error('This name is reserved.');
                      } else if (resp.error['.tag'] === 'no_permission') {
                        error("You don't have permission to do this.");
                      } else {
                        error('Unknown error');
                      }
                    } else {
                      apiUtil.mkToastFromBadStatusCode(dispatch, resp);
                    }
                  })
                  .catch(() => {
                    apiUtil.mkToastFromHttpError(dispatch);
                  });
              }}
            />
            <div className="tw-mb-4">
              <InputLabel>Blurb</InputLabel>
              <TextAreaInput
                rows={blurb?.split('\n').length ?? 1}
                placeholder="Short description"
                onChange={blurbOnChange}
                value={blurb}
                maxLength={100}
              />
              <TextInputFooterForOptional value={blurb ?? ''} maxLength={100} />
              {blurb !== props.feed.blurb ? (
                <Button block className="tw-mt-1" type="submit" onClick={saveBlurb}>
                  Save
                </Button>
              ) : null}
            </div>
            {props.feed.type['.tag'] === 'rss' || agentMode ? (
              <TextEditInput
                controlId="formPrimaryDomain"
                className="tw-mb-4"
                label="Primary Domain"
                description="Hides an entry's source domain if it matches this."
                placeholder="Set primary domain"
                initial={props.feed.primary_domain}
                maxLength={100}
                optional={true}
                onUpdate={(val, done, error) => {
                  apiClient
                    .feedSetPrimaryDomain({ feed_id: props.feed.feed_id, primary_domain: val })
                    .then(resp => {
                      done();
                      if (resp.kind === api.StatusCode.Ok) {
                        props.updateFeedCallback(resp.result.feed);
                      } else if (resp.kind === api.StatusCode.Error) {
                        if (resp.error['.tag'] === 'bad_domain') {
                          error('Invalid domain name');
                        } else if (resp.error['.tag'] === 'bad_feed_id') {
                          error('Invalid feed');
                        } else if (resp.error['.tag'] === 'no_permission') {
                          error("You don't have permission to do this.");
                        } else {
                          error('Unknown error');
                        }
                      } else {
                        apiUtil.mkToastFromBadStatusCode(dispatch, resp);
                      }
                    })
                    .catch(() => {
                      apiUtil.mkToastFromHttpError(dispatch);
                    });
                }}
              />
            ) : null}
            {permPublicReadError !== '' ? <Alert variant="danger">{permPublicReadError}</Alert> : null}
            {agentMode ? (
              <ToggleForm
                className="tw-mb-4"
                label="Public"
                blurb="Can anyone see this feed"
                on={permPublicRead}
                onToggle={(_, ok, err) => handleUpdatePermPublicRead(ok, err)}
              />
            ) : null}
            {defaultOrderingByScoreError !== '' ? (
              <div className="row">
                <div className="col">
                  <Alert variant="danger">{defaultOrderingByScoreError}</Alert>
                </div>
              </div>
            ) : null}
            {agentMode ? (
              <ToggleForm
                className="tw-mb-4"
                label="Order by best"
                blurb="Trust us to order entries smartly. If not, orders by newest."
                on={defaultOrderingByScore}
                onToggle={(_, ok, err) => handleUpdateDefaultOrdering(ok, err)}
              />
            ) : null}
            {agentMode || props.feed.type['.tag'] === 'stream' ? (
              <SelectForm
                className="tw-mb-4"
                label="Display mode"
                blurb="How to show stories in the feed."
                options={[
                  { value: 'default', name: 'Default' },
                  { value: 'headlines', name: 'Headlines' },
                ]}
                value={props.feed.display.mode['.tag']}
                onChange={e => handleUpdateDisplayMode(e.currentTarget.value)}
              />
            ) : null}
            <React.Suspense fallback={<Spinner lg />}>
              {agentMode ? (
                <>
                  <LayoutLine />
                  <FeedSettingsStyleAgentUI
                    apiClient={apiClient}
                    feed={props.feed}
                    updateFeedCallback={props.updateFeedCallback}
                  />
                </>
              ) : null}
              {agentMode && props.feed.type['.tag'] === 'hashtag' ? (
                <>
                  <LayoutLine />
                  <FeedSettingsTopicAgentUI apiClient={apiClient} feed={props.feed} />
                  <LayoutLine />
                  <FeedSettingsPrismAgentUI apiClient={apiClient} feed={props.feed} />
                </>
              ) : null}
              {agentMode && props.feed.type['.tag'] === 'rss' ? (
                <>
                  <LayoutLine />
                  <FeedSettingsRssAgentUI apiClient={apiClient} feed={props.feed} />
                  <LayoutLine />
                  <FeedSettingsRelatedTopicsAgentUI apiClient={apiClient} feed={props.feed} />
                </>
              ) : null}
              {agentMode ? <FeedSettingsForwardAgentUI apiClient={apiClient} feed={props.feed} /> : null}
            </React.Suspense>
          </fieldset>
        </form>
        {agentMode ? (
          <React.Suspense fallback={<Spinner lg />}>
            <LayoutLine />
            <FeedSettingsAclUI apiClient={apiClient} feed={props.feed} />
            <LayoutLine />
            <FeedSettingsDefaultEphemeralityAgentUI apiClient={apiClient} feed={props.feed} />
          </React.Suspense>
        ) : null}
        <LayoutLine />
        <form onSubmit={handleDeleteSubmit}>
          <fieldset disabled={inFlight}>
            {deleteError !== '' ? <Alert variant="danger">{deleteError}</Alert> : null}
            <Button variant="danger" block type="submit">
              Delete {cfe.ApiHelpers.getFeedShortName(props.feed)} permanently
            </Button>
          </fieldset>
        </form>
      </Modal.Body>
    </Modal.Container>
  );
};

export default FeedSettingsModal;
