import React from 'react';
import { useDispatch } from 'react-redux';

import { MainActionCreators } from '../../state/reducer';

import useApiClient from './useApiClient';
import useUserMeInternal from './useUserMeInternal';

const useHistoryRecorder = () => {
  const dispatch = useDispatch();
  const apiClient = useApiClient();
  const accountInfo = useUserMeInternal();

  return React.useCallback(
    (feedId: string, urlToRecord: string, via?: string) => {
      if (accountInfo) {
        apiClient.feedEntryAdd({ feed_id: 'me/visited', url: urlToRecord, via });
      }
      dispatch(MainActionCreators.markFeedEntryAsVisited(feedId, urlToRecord));
    },
    [accountInfo, apiClient],
  );
};

export default useHistoryRecorder;
