"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFeedTypeLetter = exports.getBylineParts = exports.getFromlineParts = void 0;
const api_helpers_1 = require("./api-helpers");
function getFromlineParts(feed, entry) {
    const fromline = {
        n1: fromlineGetN1(feed, entry),
    };
    if (fromlineIsP1(feed, entry)) {
        fromline.p1 = {
            v1: fromlineGetV1(feed, entry),
            n2: fromlineGetN2(feed, entry),
            n3: fromLineGetN3(entry),
            redundantN1V1: feed.type['.tag'] === 'archive' || feed.type['.tag'] === 'save_for_later' || feed.type['.tag'] === 'visited',
        };
    }
    return fromline;
}
exports.getFromlineParts = getFromlineParts;
function fromlineGetN1(feed, entry) {
    const hgcInfo = (0, api_helpers_1.getHomegrownContentInfo)(entry);
    if (feed.type['.tag'] === 'rss' ||
        feed.type['.tag'] === 'hashtag' ||
        feed.type['.tag'] === 'notif' ||
        !entry.added_by) {
        return {
            kind: 'feed',
            s: (0, api_helpers_1.getFeedShortName)(feed),
            bold: false,
            feed,
        };
    }
    else {
        return {
            kind: 'user',
            s: entry.added_by.name,
            bold: feed.type['.tag'] === 'ego' || (hgcInfo !== null && hgcInfo.author !== undefined && hgcInfo.debut),
            user: entry.added_by,
        };
    }
}
function fromlineIsP1(feed, entry) {
    if (feed.type['.tag'] === 'rss' ||
        feed.type['.tag'] === 'hashtag' ||
        feed.type['.tag'] === 'notif' ||
        !entry.added_by) {
        return false;
    }
    else {
        return true;
    }
}
function fromlineGetV1(feed, entry) {
    const md = (0, api_helpers_1.getEntryMetadata)(entry);
    const hgcInfo = (0, api_helpers_1.getHomegrownContentInfo)(entry);
    if (feed.type['.tag'] === 'ego' && entry['.tag'] === 'ego') {
        if (entry.no_agree) {
            return 'egoed w/o agreeing';
        }
        else {
            return 'egoed';
        }
    }
    else if (hgcInfo && hgcInfo.author && hgcInfo.debut) {
        if (md['.tag'] === 'ready' && md.content_type['.tag'] === 'video') {
            return 'posted a video';
        }
        else {
            return 'posted';
        }
    }
    else {
        return 'shared';
    }
}
function fromlineGetN2(feed, entry) {
    if (feed.type['.tag'] === 'ego') {
        return undefined;
    }
    else if (feed.type['.tag'] === 'share' && feed.members && entry.added_by) {
        const feedName = feed.members
            .filter(member => { var _a; return member.user_id !== ((_a = entry.added_by) === null || _a === void 0 ? void 0 : _a.user_id); })
            .map(member => member.name)
            .join(', ');
        return {
            kind: 'feed',
            s: feedName,
            bold: false,
            feed,
        };
    }
    else {
        return {
            kind: 'feed',
            s: (0, api_helpers_1.getFeedShortName)(feed),
            bold: false,
            feed,
        };
    }
}
function fromLineGetN3(entry) {
    if (entry.via === undefined) {
        return undefined;
    }
    else {
        return {
            kind: 'feed',
            s: (0, api_helpers_1.getFeedShortName)(entry.via.feed),
            bold: false,
            feed: entry.via.feed,
        };
    }
}
function getBylineParts(feed, entry) {
    const parts = {
        n1: bylineN1(feed, entry),
    };
    if (parts.n1) {
        const v1 = bylineGetV1(entry);
        if (v1) {
            parts.p1 = {
                v1,
            };
            const n2 = bylineGetN2(feed, entry);
            if (n2) {
                parts.p1.n2 = n2;
            }
        }
    }
    return parts;
}
exports.getBylineParts = getBylineParts;
function bylineN1(feed, entry) {
    var _a;
    const hgcInfo = (0, api_helpers_1.getHomegrownContentInfo)(entry);
    if (!hgcInfo) {
        return undefined;
    }
    else if (!hgcInfo.author) {
        return {
            kind: 'feed',
            s: feed.type['.tag'] === 'notif' || ((_a = entry.via) === null || _a === void 0 ? void 0 : _a.feed.type['.tag']) === 'notif'
                ? 'Notification'
                : (0, api_helpers_1.getFeedShortName)(feed),
            bold: true,
            feed,
        };
    }
    else {
        return {
            kind: 'user',
            s: hgcInfo.author.name,
            bold: true,
            user: hgcInfo.author,
        };
    }
}
function bylineGetV1(entry) {
    const md = (0, api_helpers_1.getEntryMetadata)(entry);
    const hgcInfo = (0, api_helpers_1.getHomegrownContentInfo)(entry);
    if (hgcInfo && hgcInfo.author) {
        if (md['.tag'] === 'ready' && md.content_type['.tag'] === 'video') {
            return 'posted a video';
        }
        else {
            return 'posted';
        }
    }
    else {
        return undefined;
    }
}
function bylineGetN2(feed, entry) {
    const hgcInfo = (0, api_helpers_1.getHomegrownContentInfo)(entry);
    if (hgcInfo && hgcInfo.author && hgcInfo.debut && feed.type['.tag'] !== 'ego') {
        return {
            kind: 'feed',
            s: (0, api_helpers_1.getFeedShortName)(feed),
            bold: true,
            feed,
        };
    }
    else {
        return undefined;
    }
}
function getFeedTypeLetter(feed) {
    return feed.type['.tag'] === 'ego'
        ? 'U'
        : feed.type['.tag'] === 'rss'
            ? 'S'
            : feed.type['.tag'] === 'hashtag'
                ? 'T'
                : feed.type['.tag'] === 'share'
                    ? 'G'
                    : feed.type['.tag'] === 'works'
                        ? 'W'
                        : null;
}
exports.getFeedTypeLetter = getFeedTypeLetter;
