import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';

import FeedItem from './FeedItem';
import SectionHeaderTab from './SectionHeaderTab';

const PopularPeople = (props: {
  apiPopular3Res: cfe.ApiData.Data<api.feed.IPopular3Result>;
  feeds: Map<string, api.feed.IFeedInfo>;
}) => {
  return (
    <div>
      {cfe.ApiData.hasData(props.apiPopular3Res) ? (
        props.apiPopular3Res.data.people.map(category => (
          <div key={category.category} className="tw-flex tw-flex-col">
            <SectionHeaderTab title={category.category} className="tw-mt-4" />
            {category.feeds.map(feed => (
              <FeedItem
                key={feed.feed_id}
                feed={props.feeds.get(feed.feed_id)!}
                hideFollowers
                showProfileImage="yes-if-available"
                sm
              />
            ))}
          </div>
        ))
      ) : cfe.ApiData.isError(props.apiPopular3Res) ? (
        <div>Error fetching. Try reloading.</div>
      ) : null}
    </div>
  );
};

export default PopularPeople;
