import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const QnAIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={[448, 512]}
    d="M257.1 128h-52C167 128 136 159 136 198c0 13 11 24 24 24s24-11 24-24C184 186 193.1 176 205.1 176h52C269.1 176 280 186 280 198c0 8-4 15-11 19L212 251C204 256 200 264 200 272V288c0 13 11 24 24 24S248 301 248 288V286l45.1-28C315 245 328 222 328 198C328 159 297 128 257.1 128zM224 336c-18 0-32 14-32 32s13.1 32 32 32c17.1 0 32-14 32-32S241.1 336 224 336zM384 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V96C448 60.65 419.3 32 384 32zM400 416c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V96c0-8.822 7.178-16 16-16h320c8.822 0 16 7.178 16 16V416z"
  />
);

export default QnAIcon;
