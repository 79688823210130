import React from 'react';

const LibraryIcon = (props: { size: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={`${props.size}px`} height={`${props.size}px`}>
    <path
      fill="currentColor"
      d="M510.4 435.4l-107.7-399.9C396.9 14.07 377.5 0 356.4 0c-4.111 0-8.296 .5313-12.46 1.641l-61.82 16.48c-1.281 .3438-2.375 .9922-3.592 1.445C269.7 7.758 255.8 0 240 0h-64c-12.35 0-23.49 4.797-32 12.46C135.5 4.797 124.3 0 112 0h-64C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h64c12.35 0 23.49-4.797 32-12.46C152.5 507.2 163.7 512 176 512h64c26.51 0 48-21.49 48-48V224.7l67.8 251.9C361.6 497.9 380.9 512 402.1 512c4.111 0 8.293-.5313 12.46-1.641l61.82-16.48C502 487 517.2 460.8 510.4 435.4zM32 48C32 39.18 39.18 32 48 32h64C120.8 32 128 39.18 128 48l.0011 48L32 96V48zM32 128l96 .0014v255.1L32 384V128zM112 480h-64C39.18 480 32 472.8 32 464V416l96 0L128 464C128 472.8 120.8 480 112 480zM256 464c0 8.824-7.178 16-16 16h-64C167.2 480 160 472.8 160 464l.0011-47.1L256 416V464zM256 384l-95.1 .0002V128L256 128V384zM256 96L160 96L160 48C160 39.18 167.2 32 176 32h64C248.8 32 256 39.18 256 48V96zM288 101.6V50.29c.834-.3906 1.322-.9727 2.322-1.242l61.82-16.48C353.5 32.19 354.1 32 356.4 32c7.225 0 13.57 4.828 15.43 11.74l12.4 46.07l-92.71 24.73L288 101.6zM366 391.4l-66.26-246.1l92.71-24.73l66.26 246.1L366 391.4zM477.9 455.5c-1.23 2.133-4.133 5.934-9.729 7.43l-61.82 16.48C404.1 479.8 403.5 480 402.1 480c-7.227 0-13.57-4.828-15.43-11.74l-12.4-46.07l92.71-24.73l12.44 46.22C480.9 449.1 479.1 453.4 477.9 455.5z"
    />
  </svg>
);

export default LibraryIcon;
