import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const InboxIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={[512, 512]}
    d="M508.3 304.9l-61.25-248.7C443.5 42 430.7 31.1 416 31.1H96c-14.69 0-27.47 10-31.03 24.25L3.715 304.9C1.248 314.9 0 325.2 0 335.5v96.47c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48v-96.47C512 325.2 510.8 314.9 508.3 304.9zM96 64h319.1l55.18 224H368c-6.062 0-11.59 3.438-14.31 8.844L326.1 352H185.9L158.3 296.8C155.6 291.4 150.1 288 144 288H40.84L96 64zM480 432c0 8.822-7.178 16-16 16h-416C39.18 448 32 440.8 32 432v-96.47C32 330.3 33.04 325.2 33.88 320h100.2l27.58 55.16C164.4 380.6 169.9 384 176 384h160c6.062 0 11.59-3.438 14.31-8.844L377.9 320h100.2C478.1 325.2 480 330.3 480 335.5V432z"
  />
);

export default InboxIcon;
