import * as api from 'ego-sdk-js';
import React from 'react';

import QnAIcon from './icon/QnAIcon';
import Thread, { ThreadProps } from './Thread';

const ThreadQnA = (
  props: Omit<ThreadProps, 'headerOpts' | 'rootCommentOps' | 'viaEntryId'> & {
    viaEntry?: api.feed.IFeedEntryReference;
    aiAssist?: boolean;
  },
) => {
  const { viaEntry, ...rest } = props;
  const help = React.useMemo(
    () => (
      <p>
        This section is for questions and answers. If you'd like to blurt out a comment, first post a question that it
        could conceivably answer, and then post it as a reply.
      </p>
    ),
    [],
  );
  const headerOpts = React.useMemo(
    () => ({
      addCommentTitle: 'Ask a Question',
      icon: <QnAIcon size="1rem" />,
      title: 'Q & A',
    }),
    [],
  );
  const rootCommentOpts = React.useMemo(
    () => ({
      help,
      maxLength: 127,
      simpleTextOnly: true,
      singleLine: true,
    }),
    [],
  );
  return (
    <Thread
      {...rest}
      key={props.threadId}
      headerOpts={headerOpts}
      rootCommentOpts={rootCommentOpts}
      viaEntryId={viaEntry?.entry_id}
      enableAiPrompt={props.aiAssist}
    />
  );
};

export default ThreadQnA;
