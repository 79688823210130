import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';

import Thread, { ThreadProps } from './Thread';

const FeedEntryFfaThread = (
  props: Omit<
    ThreadProps,
    | 'threadId'
    | 'headerOpts'
    | 'rootCommentOps'
    | 'size'
    | 'authorUserId'
    | 'showQuickCommentInput'
    | 'watermark'
    | 'seenWatermark'
  > & {
    feed: api.feed.IFeedInfo;
    entry: api.feed.IFeedEntryReference;
    hideSectionHeaderTab?: boolean;
  },
) => {
  if (props.entry['.tag'] !== 'share') {
    throw Error('Unexpected entry type');
  }
  const { entry, ...rest } = props;
  const rootCommentOpts = React.useMemo(
    () => ({
      maxLength: 240,
    }),
    [],
  );
  const headerOpts = React.useMemo(
    () =>
      props.accountInfo
        ? {
            hide: props.hideSectionHeaderTab,
            title: 'Conversation with ' + cfe.ApiHelpers.getShareFeedName(props.feed, props.accountInfo.user_id),
          }
        : undefined,
    [props.accountInfo, props.hideSectionHeaderTab],
  );
  const size = React.useMemo(
    () => ({
      depth: 8,
      initDepth: 8,
      initRepliesPerLevel: 10,
      repliesPerLevel: 10,
    }),
    [],
  );
  return (
    <Thread
      {...rest}
      key={props.entry.ffa_thread_id}
      threadId={props.entry.ffa_thread_id}
      viaEntryId={props.entry.entry_id}
      showQuickCommentInput
      size={size}
      headerOpts={headerOpts}
      rootCommentOpts={rootCommentOpts}
      authorUserId={props.entry.added_by?.user_id}
      watermark={props.entry.for_viewer.seen?.watermark ?? 0}
      seenWatermark={props.entry.for_viewer.seen?.seen_watermark ?? 0}
    />
  );
};

export default FeedEntryFfaThread;
