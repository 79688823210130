import clsx from 'clsx';
import React from 'react';
import Toggle, { ToggleProps } from './Toggle';

interface ToggleFormProps extends ToggleProps {
  label: string;
  labelSuffixEle?: React.ReactNode;
  blurb?: string | React.ReactNode;
  className?: string;
}

const ToggleForm = (props: ToggleFormProps) => (
  <div className={clsx('tw-flex tw-items-center tw-justify-between tw-gap-2', props.className)}>
    <div className="tw-flex tw-flex-col">
      <div className="tw-flex tw-flex-wrap tw-gap-x-2">
        <div className="tw-font-bold">{props.label}</div>
        {props.labelSuffixEle}
      </div>
      {props.blurb ? (
        typeof props.blurb === 'string' ? (
          <div className="tw-text-sm">{props.blurb}</div>
        ) : (
          props.blurb
        )
      ) : null}
    </div>
    <div className="tw-flex">
      <Toggle {...props} />
    </div>
  </div>
);

export default ToggleForm;
