import { domAnimation, LazyMotion } from 'framer-motion';
import React from 'react';
import { Route, Routes, useLocation, useNavigationType } from 'react-router-dom';

import AccountPage from './AccountPage';
import DiscoverPage from './DiscoverPage';
import FeedEntryExploreModal from './FeedEntryExploreModal';
import FeedPage from './FeedPage';
import FollowsPage from './FollowsPage';
import GetAppPage from './GetAppPage';
import GetExtensionPage from './GetExtensionPage';
import { KeyPressContextProvider } from './KeyPressContext';
import LayoutWithSidebar from './LayoutWithSidebar';
import LocalStorageReduxBridge from './LocalStorageReduxBridge';
import { ModalManagerContextProvider } from './ModalManagerContext';
import SubscriptionPage from './SubscriptionPage';
import ThemeModeGate from './ThemeModeGate';
import ToastGroup from './ToastGroup';
import UserGate from './UserGate';

const AgentPage = React.lazy(() => import('./AgentPage'));
const AuthorPage = React.lazy(() => import('./AuthorPage'));
const ChooseUsernameOnboarding = React.lazy(() => import('./ChooseUsernameOnboarding'));
const ForgotPasswordPage = React.lazy(() => import('./ForgotPasswordPage'));
const JoinPage = React.lazy(() => import('./JoinPage'));
const LibraryPage = React.lazy(() => import('./LibraryPage'));
const MobileTextEditorPage = React.lazy(() => import('./MobileTextEditorPage'));
const OnboardingPage = React.lazy(() => import('./OnboardingPage'));
const ResetPasswordPage = React.lazy(() => import('./ResetPasswordPage'));
const SignInPage = React.lazy(() => import('./SignInPage'));
const TermsPage = React.lazy(() => import('./TermsPage'));

// Prefetch video player because it's important for videos to load snappily.
import(/* webpackPrefetch: true */ './VideoPlayer');

/**
 * Manages when to scroll new pages to the top since react-router v6 doesn't
 * attempt to solve this. Basic strategy is to scroll-to-top when a page is
 * pushed into the browser history, but to retain the scroll when history is
 * popped. See SEP 138.
 *
 * FIXME: This code is fragile. If a new modal route (e.g. explore's /e/) is
 * added, this will need to be updated to prevent unnecessary scrolls.
 */
const ScrollReset = () => {
  const location = useLocation();
  const [prevLocationPathname, setPrevLocationPathname] = React.useState<string | null>(null);
  const [lastNonExplorePathname, setLastNonExplorePathname] = React.useState<string | null>(null);
  const navigationType = useNavigationType();
  React.useEffect(() => {
    // Don't auto-scroll when browser POPs from history (forward / back)
    // Don't auto-scroll when opening the explore modal.
    if (
      !location.pathname.startsWith('/e/') &&
      (prevLocationPathname === null ||
        !prevLocationPathname.startsWith('/e/') ||
        (lastNonExplorePathname !== null && lastNonExplorePathname !== location.pathname)) &&
      navigationType !== 'POP'
    ) {
      window.scrollTo(0, 0);
    }
    if (!location.pathname.startsWith('/e/')) {
      setLastNonExplorePathname(location.pathname);
    }
    setPrevLocationPathname(location.pathname);
  }, [location.pathname]);
  return null;
};

const MindApp = () => (
  <KeyPressContextProvider>
    <ThemeModeGate>
      {/* strict ensures that only the slimmer version `m` is imported. */}
      <LazyMotion strict features={domAnimation}>
        <ToastGroup />
        <ModalManagerContextProvider rootDomElementId="portal-root">
          <LocalStorageReduxBridge>
            <UserGate fallback={null}>
              <ScrollReset />
              <Routes>
                <Route path="e/:entryId" element={<FeedEntryExploreModal />} />
                <Route path="e/:entryId/cpc" element={<FeedEntryExploreModal />} />
                <Route path="*" element={null} />
              </Routes>
              <React.Suspense fallback={null}>
                <Routes>
                  <Route path="z/text-editor" element={<MobileTextEditorPage />} />
                  <Route element={<LayoutWithSidebar />}>
                    <Route path="terms" element={<TermsPage />} />
                    <Route path="login" element={<SignInPage />} />
                    <Route path="choose_username" element={<ChooseUsernameOnboarding />} />
                    <Route path="alterego" element={<JoinPage />} />
                    <Route path="forgot_password" element={<ForgotPasswordPage />} />
                    <Route path="reset_password" element={<ResetPasswordPage />} />
                    <Route path="author/*" element={<AuthorPage />} />
                    <Route path="creator" element={<AuthorPage />} />
                    <Route path="subscription" element={<SubscriptionPage />} />
                    <Route path="get-app" element={<GetAppPage />} />
                    <Route path="get-extension" element={<GetExtensionPage />} />
                    <Route path="home" element={<FeedPage />} />
                    <Route path="inbox" element={<FeedPage />} />
                    <Route path="as/:newsfeedAs" element={<FeedPage />} />
                    <Route path="e/:entryId" element={<FeedPage />} />
                    <Route path="e/:entryId/cpc" element={<FeedPage />} />
                    <Route path="discover" element={<DiscoverPage />} />
                    <Route path="onboarding" element={<OnboardingPage />} />
                    <Route path="onboarding/:section" element={<OnboardingPage />} />
                    <Route path="follows" element={<FollowsPage />} />
                    <Route path="library" element={<LibraryPage />} />
                    <Route path="account/*" element={<AccountPage />} />
                    <Route path="z/agent/*" element={<AgentPage />} />
                    <Route path=":username" element={<FeedPage />} />
                    <Route path=":username/:urlName" element={<FeedPage />} />
                    <Route index element={<FeedPage />} />
                  </Route>
                </Routes>
              </React.Suspense>
            </UserGate>
          </LocalStorageReduxBridge>
        </ModalManagerContextProvider>
      </LazyMotion>
    </ThemeModeGate>
  </KeyPressContextProvider>
);
export default MindApp;
