import * as api from 'ego-sdk-js';
import React from 'react';
import { useDispatch } from 'react-redux';

import { MainActionCreators } from '../state/reducer';

import { useAuthedApiClient } from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import { useKeyPress } from './KeyPressContext';
import Button from './lib/Button';
import Modal from './lib/Modal';
import Spinner from './lib/Spinner';
import TextAreaInput from './lib/TextAreaInput';
import { TextInputFooterForOptional } from './lib/TextInput';

const UpdateBlabberModal = (props: {
  feedId: string;
  entry: api.feed.IFeedEntryReference;
  close: () => void;
  isForeground: boolean;
}) => {
  if (
    props.entry['.tag'] !== 'ego' &&
    props.entry['.tag'] !== 'curated' &&
    props.entry['.tag'] !== 'stream' &&
    props.entry['.tag'] !== 'favorites' &&
    props.entry['.tag'] !== 'save_for_later' &&
    props.entry['.tag'] !== 'works'
  ) {
    throw Error('Unexpected entry type');
  }
  const dispatch = useDispatch();
  const apiClient = useAuthedApiClient();
  const [inFlight, setInFlight] = React.useState(false);
  const [blabber, setBlabber] = React.useState(props.entry.blabber ?? '');

  const { apiDo: apiFeedEntryUpdateBlabber, okToast, errToast } = useApiDo(apiClient, apiClient.feedEntryUpdateBlabber);

  useKeyPress(
    ['u', 'Escape'],
    () => {
      props.close();
    },
    !props.isForeground,
    10,
  );

  return (
    <Modal.Container show={props.isForeground} close={props.close}>
      <Modal.Header>
        <Modal.Heading1>Update Note</Modal.Heading1>
      </Modal.Header>
      <Modal.Body gutter>
        <TextAreaInput
          className="tw-mt-2"
          autoHeight={300}
          placeholder=""
          onChange={e => setBlabber(e.currentTarget.value)}
          value={blabber}
          maxLength={500}
        />
        <TextInputFooterForOptional value={blabber} maxLength={500} />
        <Button
          block
          disabled={blabber === props.entry.blabber}
          mimicDisabled={inFlight}
          className="tw-mb-4 tw-mt-8"
          onClick={() => {
            setInFlight(true);
            apiFeedEntryUpdateBlabber(
              { blabber: blabber.length === 0 ? undefined : blabber, entry_id: props.entry.entry_id },
              {
                onFinally: () => setInFlight(false),
                onResult: res => {
                  dispatch(MainActionCreators.updateFeedEntry(props.feedId, res.updated_entry));
                  okToast('Updated note');
                  props.close();
                },
                onRouteErr: (err, defaultErrToast) => {
                  if (err['.tag'] === 'bad_blabber') {
                    errToast('Disallowed markup');
                  } else {
                    defaultErrToast();
                  }
                },
              },
            );
          }}
        >
          <div className="tw-flex tw-items-center tw-justify-center tw-gap-x-2">
            <span>Update</span>
            <Spinner.Presence>{inFlight ? <Spinner color="light" /> : null}</Spinner.Presence>
          </div>
        </Button>
      </Modal.Body>
    </Modal.Container>
  );
};

export default UpdateBlabberModal;
