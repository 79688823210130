import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const NoteIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={[448, 512]}
    d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H288V352c0-17.7 14.3-32 32-32h80V96c0-8.8-7.2-16-16-16H64zM288 480H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V320v5.5c0 17-6.7 33.3-18.7 45.3l-90.5 90.5c-12 12-28.3 18.7-45.3 18.7H288z"
  />
);

export default NoteIcon;
