import * as api from 'ego-sdk-js';
import { useDispatch } from 'react-redux';

import { MainActionCreators } from '../../state/reducer';
import * as apiUtil from '../../util-api';

const useFeedEntryRefresher = (apiClient: api.SuperegoClient) => {
  const dispatch = useDispatch();
  const refreshFeedEntry = (
    feedId: string,
    entry: api.feed.IFeedEntryReference,
    maxRetries: number,
    stopCond: (entry: api.feed.IFeedEntryReference) => boolean,
  ) => {
    if (stopCond(entry)) {
      return;
    }
    const tryRefresh = (retryCount: number) => {
      apiClient
        .feedEntryRefresh({
          entry_id: entry.entry_id,
        })
        .then(resp => {
          if (resp.kind === api.StatusCode.Ok) {
            dispatch(MainActionCreators.updateFeedEntry(feedId, resp.result));
            if (!stopCond(resp.result) && retryCount < maxRetries) {
              setTimeout(() => tryRefresh(retryCount + 1), 1000 * (retryCount + 1));
            }
          } else {
            apiUtil.mkToastFromBadStatusCode(dispatch, resp);
          }
        })
        .catch(() => {
          apiUtil.mkToastFromHttpError(dispatch);
        });
    };
    setTimeout(() => tryRefresh(0), 500);
  };
  return {
    refreshFeedEntry,
  };
};

export default useFeedEntryRefresher;
