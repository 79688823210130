import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import { Root } from 'hast';
import React from 'react';
import ReactMarkdown, { defaultUrlTransform } from 'react-markdown';
import { Components } from 'react-markdown/lib';
import { Link } from 'react-router-dom';
import remarkGfm from 'remark-gfm';
import { visit } from 'unist-util-visit';

import { ContentControlBoundary } from './ContentControlContext';
import FeedEntryItemEmbed from './FeedEntryItemEmbed';
import FeedItemEmbed from './FeedItemEmbed';
import useApiClient from './hooks/useApiClient';
import useStoryOpener from './hooks/useStoryOpener';
import ExploreIcon from './icon/ExploreIcon';
import ImageSlider from './ImageSlider';
import ImageWithViewer from './ImageWithViewer';
import SmartFeedLink from './SmartFeedLink';
import TwitterTweet from './TwitterTweet';
import { VideoEmbedFromSpec } from './VideoEmbed';
import YouTubeEmbed from './YouTubeEmbed';

/**
 * Detects when <code> is within a <pre> versus when it's standalone (inline).
 * This detection can be used to ignore <code> when it isn't inline since <pre>
 * can apply the block styling.
 */
const rehypePluginInlineCode = () => {
  return (tree: Root) => {
    visit(tree, 'element', (node, _, parent) => {
      if (node.tagName === 'code' && parent?.type === 'element') {
        node.properties.inline = parent.tagName !== 'pre';
      }
    });
  };
};

/**
 * Detects when a node is at the top-level of a document. This can be used to
 * apply special styling (e.g. gutters) for only the top-level nodes.
 */
const rehypePluginTopLevelElement = () => {
  return (tree: Root) => {
    visit(tree, 'element', (node, _, parent) => {
      node.properties.topLevel = parent?.type === 'root';
    });
  };
};

const EgoMarkdown = React.memo(
  React.forwardRef(
    (
      props: {
        variant: 'blabber' | 'blurb' | 'post' | 'cpc' | 'comment';
        content: string;
        contentImageSpecs?: api.feed.IContentImageSpec[];
        contentVideoSpecs?: api.feed.IContentVideoSpec[];
        contentLinkSpecs?: api.feed.IContentLinkSpec[];
        goToFeed: (feed: api.feed.IFeedInfo) => void;
        parentSlideIndex?: number;
        gutterClassName?: string;
        isSlide?: boolean;
        addContentControlBoundaries?: boolean;
      },
      ref: React.Ref<HTMLDivElement>,
    ) => {
      const apiClient = useApiClient();
      const { storyOpener } = useStoryOpener();

      // With the switch to using flex-col and gaps, the logic below has
      // simplified greatly.
      let className;
      if (props.variant === 'cpc') {
        if (props.isSlide) {
          // Shrink text in mobile-web experiences because there's very little
          // room per slide.
          className = 'tw-text-sm tw-leading-tight sm:tw-text-base sm:tw-leading-7 tw-font-normal';
        } else {
          className = 'tw-text-base tw-leading-7 tw-font-normal';
        }
      } else if (props.variant === 'blabber' || props.variant === 'post') {
        className = 'tw-text-sm';
      } else if (props.variant === 'blurb') {
        className = null;
      } else if (props.variant === 'comment') {
        className = 'tw-text-[0.90rem]';
      } else {
        className = null;
      }
      const imageInfoMap: Map<string, api.feed.IContentImageSpec> = new Map();
      if (props.contentImageSpecs) {
        for (const imageInfo of props.contentImageSpecs) {
          imageInfoMap.set(imageInfo.url, imageInfo);
        }
      }
      const videoInfoMap: Map<string, api.feed.IContentVideoSpec> = new Map();
      if (props.contentVideoSpecs) {
        for (const videoInfo of props.contentVideoSpecs) {
          videoInfoMap.set(videoInfo.url, videoInfo);
        }
      }
      let isPortraitVideoSlide = false;
      if (props.isSlide && props.contentVideoSpecs) {
        for (const videoSpec of props.contentVideoSpecs) {
          if (props.content.indexOf(videoSpec.url) >= 0 && videoSpec.portrait) {
            isPortraitVideoSlide = true;
            break;
          }
        }
      }
      const linkInfoMap: Map<string, api.feed.IContentLinkSpec> = new Map();
      if (props.contentLinkSpecs) {
        for (const linkInfo of props.contentLinkSpecs) {
          linkInfoMap.set(linkInfo.url, linkInfo);
        }
      }

      // HACK: If renderRules isn't memoized, the entire tree is replaced on
      // every re-render. There is some underlying issue causing the tree
      // diff-ing to mark all nodes stale. Might be a `key` issue that requires
      // investigation of react-markdown. The issue with replacing the tree is
      // that the YouTube & Twitter embeds are reloaded.
      const renderRules: Components = React.useMemo(
        () => ({
          a: ({ href, children }) => {
            if (!href) {
              return null;
            }
            const res = cfe.PostHelpers.parseMarkdownLink(href);
            if (res === null) {
              const linkInfo = linkInfoMap.get(href);
              const linkExploreBtn = linkInfo ? (
                <span
                  role="button"
                  className={clsx(
                    'tw-ml-1',
                    'tw-bg-slate-100 dark:tw-bg-slate-700',
                    'tw-text-muted hover:tw-text-current',
                    'tw-rounded tw-border tw-border-layout-line-light dark:tw-border-layout-line-dark',
                    'hover:tw-border-layout-line-dark hover:dark:tw-border-layout-line-light',
                  )}
                  onClick={() => {
                    window.open(cfe.ApiHelpers.getUrlPathnameForEntry(linkInfo.main_entry.entry.entry_id), '_blank');
                  }}
                >
                  <ExploreIcon size="0.9rem" className="tw-mx-2" offsetUp />
                </span>
              ) : null;

              const localUrl = `${window.location.protocol}//${window.location.hostname}`;
              if (href.startsWith(localUrl + '/')) {
                return (
                  <>
                    <Link to={href.substring(localUrl.length)} onClick={e => e.stopPropagation()}>
                      {children}
                    </Link>
                    {linkExploreBtn}
                  </>
                );
              }
              return (
                <>
                  <a
                    href={href}
                    target="_blank"
                    rel="noopener"
                    onClick={e => {
                      e.stopPropagation();
                      if (linkInfo) {
                        e.preventDefault();
                        storyOpener(linkInfo.main_entry.feed, linkInfo.main_entry.entry, true);
                      }
                    }}
                  >
                    {children}
                  </a>
                  {linkExploreBtn}
                </>
              );
            }
            const [feedRef, displayHref] = res;
            return (
              <SmartFeedLink
                goToFeed={props.goToFeed}
                apiClient={apiClient}
                feedRef={feedRef}
                displayHref={displayHref}
              >
                {children}
              </SmartFeedLink>
            );
          },
          blockquote: ({ children, node }) => {
            if (!node) {
              return null;
            }
            return (
              <div className={clsx(node.properties.topLevel ? props.gutterClassName : null)}>
                <blockquote
                  className={clsx(
                    'tw-flex tw-flex-col tw-gap-y-2 sm:tw-gap-y-4',
                    'tw-bg-highlight',
                    'tw-ml-[2px]',
                    'tw-pl-8 tw-pr-2 tw-py-3',
                    'tw-border-l-4 tw-rounded-lg tw-border-l-body-light dark:tw-border-l-body-dark',
                  )}
                >
                  {children}
                </blockquote>
              </div>
            );
          },
          code: ({ children, node }) => {
            if (!node) {
              return null;
            }
            // HACK: Without this, code blocks are rendered as
            // <pre><code></code></pre>, which causes conflicts between <pre>
            // styling and <code> styling.
            if (node.properties.inline) {
              return (
                <code className="tw-bg-gray-200 dark:tw-bg-gray-800 tw-text-primary tw-px-2 tw-py-0.5 tw-rounded-md tw-text-xs tw-leading-tight">
                  {children}
                </code>
              );
            } else {
              return <>{children}</>;
            }
          },
          h1: ({ children, node }) => {
            if (!node) {
              return null;
            }
            return (
              <h1
                className={clsx(
                  node.properties.topLevel ? props.gutterClassName : null,
                  'tw-mt-2 sm:tw-mt-4 first:tw-mt-0 tw-text-[1.3rem] tw-font-bold',
                )}
              >
                {children}
              </h1>
            );
          },
          h2: ({ children, node }) => {
            if (!node) {
              return null;
            }
            return (
              <h2
                className={clsx(
                  node.properties.topLevel ? props.gutterClassName : null,
                  'tw-mt-2 sm:tw-mt-4 first:tw-mt-0 tw-text-[1.25rem] tw-font-bold',
                )}
              >
                {children}
              </h2>
            );
          },
          h3: ({ children, node }) => {
            if (!node) {
              return null;
            }
            return (
              <h3
                className={clsx(
                  node.properties.topLevel ? props.gutterClassName : null,
                  'tw-mt-2 sm:tw-mt-4 first:tw-mt-0 tw-text-[1.2rem] tw-font-bold',
                )}
              >
                {children}
              </h3>
            );
          },
          h4: ({ children, node }) => {
            if (!node) {
              return null;
            }
            return (
              <h4
                className={clsx(
                  node.properties.topLevel ? props.gutterClassName : null,
                  'tw-mt-2 sm:tw-mt-4 first:tw-mt-0 tw-text-[1.15rem] tw-font-bold',
                )}
              >
                {children}
              </h4>
            );
          },
          h5: ({ children, node }) => {
            if (!node) {
              return null;
            }
            return (
              <h5
                className={clsx(
                  node.properties.topLevel ? props.gutterClassName : null,
                  'tw-mt-2 sm:tw-mt-4 first:tw-mt-0 tw-text-[1.1rem] tw-font-bold',
                )}
              >
                {children}
              </h5>
            );
          },
          h6: ({ children, node }) => {
            if (!node) {
              return null;
            }
            return (
              <h6
                className={clsx(
                  node.properties.topLevel ? props.gutterClassName : null,
                  'tw-mt-2 sm:tw-mt-4 first:tw-mt-0 tw-text-[1.05rem] tw-font-bold',
                )}
              >
                {children}
              </h6>
            );
          },
          hr: ({ node }) => {
            if (!node) {
              return null;
            }
            return <hr className={clsx(node.properties.topLevel ? props.gutterClassName : null)} />;
          },
          img: ({ node, src, title }) => {
            if (!src) {
              return null;
            }
            const imageInfo = imageInfoMap.get(src);
            if (imageInfo?.width === 1 && imageInfo?.height === 1) {
              // Remove tracking pixels.
              return null;
            }
            return (
              <div className="tw-relative tw-group/img">
                {imageInfo ? (
                  <ImageWithViewer
                    src={src}
                    imageInfo={imageInfo}
                    className="tw-mx-auto tw-max-h-[90vh] tw-object-contain"
                  />
                ) : (
                  <img
                    src={src}
                    onLoad={x => {
                      if (!imageInfo && x.currentTarget.naturalHeight === 1 && x.currentTarget.naturalWidth === 1) {
                        // Keep tracking pixels miniature.
                        x.currentTarget.style.height = '1px';
                        x.currentTarget.style.width = '1px';
                      }
                    }}
                    className="tw-mx-auto tw-max-h-[90vh] tw-object-contain"
                  />
                )}
                {title ? (
                  <div
                    className={clsx(
                      'tw-py-2 tw-bg-highlight tw-text-center',
                      node?.properties.topLevel ? props.gutterClassName : null,
                    )}
                  >
                    <span>{title}</span>
                  </div>
                ) : null}
              </div>
            );
          },
          li: ({ children }) => {
            // Indents sublists
            return <li className="[&>ul]:tw-pl-5 [&>ol]:tw-pl-5">{children}</li>;
          },
          ol: ({ children, node }) => {
            if (!node) {
              return null;
            }
            // Padding compensates for marker position outside of list.
            return (
              <div className={node.properties.topLevel ? props.gutterClassName : undefined}>
                <ol className={clsx('tw-pl-5', 'tw-list-decimal tw-list-outside', 'tw-flex tw-flex-col tw-gap-y-1')}>
                  {children}
                </ol>
              </div>
            );
          },
          p: ({ children, node }) => {
            if (!node) {
              return null;
            }
            if (
              node.children.length === 1 &&
              node.children[0].type === 'element' &&
              node.children[0].tagName === 'a' &&
              node.children[0].children.length === 0
            ) {
              const href: string | undefined = node.children[0].properties?.href as string;
              if (!href) {
                return null;
              }

              if (props.variant === 'cpc' || props.variant === 'comment') {
                const tweetId = cfe.ApiHelpers.parseTwitterTweetId(href);
                if (tweetId) {
                  return (
                    <div className={props.variant === 'cpc' ? 'tw-my-2 sm:tw-my-4' : undefined}>
                      <TwitterTweet
                        tweetId={tweetId}
                        center={props.variant === 'cpc'}
                        xsOverlayCaptureSwipe={props.variant === 'cpc' && props.isSlide}
                        hideCards={props.variant === 'cpc' && props.isSlide}
                      />
                    </div>
                  );
                }
                // SEP 161: Prioritize AppVideo over YouTube because an
                // AppVideo may be specified as a cpc-video replacing a YouTube
                // video.
                const youtubeVideoInfo = cfe.ApiHelpers.parseYouTubeVideoInfoFromUrl(href);
                const videoInfo = videoInfoMap.get(href);
                if (
                  videoInfo &&
                  videoInfo.duration &&
                  videoInfo.portrait !== undefined &&
                  // If there's no video-data-url but it's not a YouTube video,
                  // we passthrough to VideoEmbed expecting it to show a "Video
                  // is processing" message.
                  (videoInfo.hls || !youtubeVideoInfo)
                ) {
                  return (
                    <VideoEmbedFromSpec
                      videoSpec={videoInfo}
                      noOverflow={props.parentSlideIndex !== undefined || props.variant === 'comment'}
                      gutterClassName={props.gutterClassName}
                      parentSlideIndex={props.parentSlideIndex}
                      maxHeightShort={props.variant === 'comment'}
                    />
                  );
                }
                if (youtubeVideoInfo) {
                  return (
                    <ContentControlBoundary noop={!props.addContentControlBoundaries}>
                      <div className={props.variant === 'cpc' ? 'tw-my-2 sm:tw-my-4' : undefined}>
                        <YouTubeEmbed
                          ytVideoId={youtubeVideoInfo.videoId}
                          startProgress={cfe.ApiHelpers.selectMediaStartProgress(
                            undefined,
                            youtubeVideoInfo.offset,
                            videoInfo?.duration,
                          )}
                          duration={videoInfo?.duration}
                          portrait={videoInfo?.portrait}
                          gutterClassName={props.gutterClassName}
                          parentSlideIndex={props.parentSlideIndex}
                          maxHeightShort={props.variant === 'comment'}
                          mainEntry={videoInfo?.main_entry}
                        />
                      </div>
                    </ContentControlBoundary>
                  );
                }
              }
              const feedId = cfe.PostHelpers.parseFeedIdFromUrl(
                href,
                `${window.location.protocol}//${window.location.hostname}`,
              );
              if (feedId) {
                return (
                  <div
                    className={clsx(
                      props.variant === 'cpc' || props.variant === 'comment'
                        ? 'tw-my-2 sm:tw-my-4 tw-max-w-md'
                        : undefined,
                      props.variant === 'cpc' && node.properties.topLevel ? props.gutterClassName : null,
                      props.variant === 'cpc' ? 'tw-self-center' : null,
                    )}
                  >
                    <FeedItemEmbed feedId={feedId} showProfileImage="yes-if-available" />
                  </div>
                );
              }
              const entryId = cfe.PostHelpers.parseFeedEntryIdFromUrl(
                href,
                `${window.location.protocol}//${window.location.hostname}`,
              );
              if (entryId) {
                return (
                  <div
                    className={clsx(
                      'tw-max-w-md',
                      props.variant === 'cpc' && node.properties.topLevel ? props.gutterClassName : null,
                      props.variant === 'cpc' ? 'tw-my-2 sm:tw-my-4 tw-self-center' : null,
                    )}
                  >
                    <FeedEntryItemEmbed apiClient={apiClient} navToFeed={props.goToFeed} entryId={entryId} />
                  </div>
                );
              }
            } else if (
              node.children.length >= 1 &&
              node.children[0].type === 'element' &&
              node.children.length ===
                node.children.filter(
                  child =>
                    (child.type === 'text' && child.value === '\n') ||
                    (child.type === 'element' && child.tagName === 'img'),
                ).length
            ) {
              const imgNodes = node.children.filter(child => child.type === 'element' && child.tagName === 'img');
              return (
                <ImageSlider
                  imgNodes={imgNodes}
                  contentImageSpecs={props.contentImageSpecs}
                  gutterClassName={props.gutterClassName}
                />
              );
            }
            return <p className={clsx(node.properties.topLevel ? props.gutterClassName : null)}>{children}</p>;
          },
          pre: ({ children, node }) => {
            if (!node) {
              return null;
            }
            return (
              <div className={clsx(node.properties.topLevel ? props.gutterClassName : null)}>
                <pre className="tw-bg-gray-800 tw-text-light tw-p-3 tw-rounded-md tw-text-sm tw-leading-tight tw-overflow-x-scroll">
                  {children}
                </pre>
              </div>
            );
          },
          table: ({ children, node }) => {
            if (!node) {
              return null;
            }
            return (
              // HACK: Don't use justify-center for mobile-web. It would cause
              // wide tables to be clipped on both sides.
              <div className="tw-flex sm:tw-justify-center tw-overflow-auto">
                <table
                  className={clsx(
                    'tw-border tw-border-layout-line-light dark:tw-border-layout-line-dark',
                    node.properties.topLevel ? props.gutterClassName : null,
                  )}
                >
                  {children}
                </table>
              </div>
            );
          },
          td: ({ children, node }) => {
            if (!node) {
              return null;
            }
            return <td className="tw-py-2 tw-px-3 tw-text-left">{children}</td>;
          },
          th: ({ children, node }) => {
            if (!node) {
              return null;
            }
            return <th className="tw-bg-highlight tw-pt-4 tw-pb-1 tw-px-3 tw-text-left tw-font-bold">{children}</th>;
          },
          tr: ({ children, node }) => {
            if (!node) {
              return null;
            }
            return (
              <tr className="tw-border-b tw-border-layout-line-light dark:tw-border-layout-line-dark">{children}</tr>
            );
          },
          ul: ({ children, node }) => {
            if (!node) {
              return null;
            }
            // Padding compensates for marker position outside of list.
            return (
              <div className={node.properties.topLevel ? props.gutterClassName : undefined}>
                <ul className={clsx('tw-pl-5', 'tw-list-disc tw-list-outside', 'tw-flex tw-flex-col tw-gap-y-1')}>
                  {children}
                </ul>
              </div>
            );
          },
        }),
        [
          props.variant,
          props.content,
          props.contentImageSpecs,
          props.contentVideoSpecs,
          props.goToFeed,
          props.parentSlideIndex,
        ],
      );
      const urlTransform = React.useCallback((url: string) => {
        // Allows ego:// scheme urls
        if (!cfe.ApiHelpers.isUrlOpenable(url)) {
          return url;
        } else {
          return defaultUrlTransform(url);
        }
      }, []);
      return (
        <div
          ref={ref}
          className={clsx(
            'tw-break-words',
            '[&_p]:tw-mb-0',
            'tw-flex tw-flex-col',
            // Shrink gaps in mobile-web experiences because there's very
            // little room per slide.
            props.isSlide ? 'tw-gap-y-2 sm:tw-gap-y-4' : 'tw-gap-y-4',
            isPortraitVideoSlide ? 'tw-h-full' : null,
            // SEP 159: Needed along with min-h-0 for <img> to properly resize
            // vertically.
            'tw-min-h-0',
            className,
          )}
        >
          <ReactMarkdown
            skipHtml // NOTE: Unclear if this has an effect. HTML appears to be skipped regardless.
            rehypePlugins={[rehypePluginTopLevelElement, rehypePluginInlineCode]}
            remarkPlugins={[remarkGfm]}
            children={props.content}
            components={renderRules}
            urlTransform={urlTransform}
          />
        </div>
      );
    },
  ),
  (prevProps, nextProps) =>
    prevProps.variant === nextProps.variant &&
    prevProps.content === nextProps.content &&
    prevProps.contentImageSpecs === nextProps.contentImageSpecs &&
    prevProps.contentVideoSpecs === nextProps.contentVideoSpecs &&
    prevProps.goToFeed === nextProps.goToFeed &&
    prevProps.parentSlideIndex === nextProps.parentSlideIndex &&
    prevProps.gutterClassName === nextProps.gutterClassName &&
    prevProps.isSlide === nextProps.isSlide &&
    prevProps.addContentControlBoundaries === nextProps.addContentControlBoundaries,
);

export default EgoMarkdown;
