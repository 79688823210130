import * as api from 'ego-sdk-js';
import React from 'react';
import { useDispatch } from 'react-redux';

import { MainActionCreators } from '../state/reducer';

import useApiClient from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import useFeedMap from './hooks/useFeedMap';
import { useUserMeInternalRaw } from './hooks/useUserMeInternal';
import { useKeyPress } from './KeyPressContext';
import InputLabel from './lib/InputLabel';
import Modal from './lib/Modal';
import TextEditInput from './lib/TextEditInput';
import ProfilePictureUpdate from './ProfilePictureUpdate';

const EditProfileModal = (props: {
  userInfo: api.user.IUserInfo;

  closeModal: () => void;
  updateUserInfo: (userInfo: api.user.IUserInfo) => void;
  isForeground: boolean;
}) => {
  const dispatch = useDispatch();
  const apiClient = useApiClient();
  const [_, refreshUserMeInternal] = useUserMeInternalRaw();

  const feedMap = useFeedMap([props.userInfo.ego_feed_id]);

  const { apiDo: apiUserUpdateName, okToast } = useApiDo(apiClient, apiClient.userUpdateName);
  const { apiDo: apiUserUpdateBio } = useApiDo(apiClient, apiClient.userUpdateBio);
  const { apiDo: apiUserUpdateWebsite } = useApiDo(apiClient, apiClient.userUpdateWebsite);
  const { apiDo: apiFeedGet } = useApiDo(apiClient, apiClient.feedGet, { abortable: true });

  useKeyPress(
    ['u', 'Escape'],
    () => {
      props.closeModal();
    },
    !props.isForeground,
    10,
  );

  const [userInfo, setUserInfo] = React.useState(props.userInfo);
  const updateUserInfo = (newUserInfo: api.user.IUserInfo) => {
    // NOTE: A user's info shows up in different local & api objects and they
    // all must be updated.
    props.updateUserInfo(newUserInfo);
    setUserInfo(newUserInfo);
    refreshUserMeInternal();
    apiFeedGet(
      { feed_id: userInfo.ego_feed_id },
      {
        onResult: res => dispatch(MainActionCreators.updateFeed(res)),
      },
    );
  };

  const egoFeed = feedMap.get(props.userInfo.ego_feed_id);
  return (
    <Modal.Container show={props.isForeground} close={props.closeModal}>
      <Modal.Header>
        <Modal.Heading1>Edit Profile</Modal.Heading1>
      </Modal.Header>
      <Modal.Body gutter>
        <InputLabel>Profile Photo</InputLabel>
        {egoFeed ? (
          <div className="tw-mb-4">
            <ProfilePictureUpdate feed={egoFeed} />
          </div>
        ) : null}
        <TextEditInput
          controlId="formName"
          className="tw-mb-4"
          label="Name"
          placeholder="What do you want to be called?"
          initial={userInfo.name}
          maxLength={128}
          minLength={1}
          onUpdate={(val, done, error) => {
            apiUserUpdateName(
              { name: val! },
              {
                onFinally: done,
                onResult: res => {
                  done(res.name);
                  updateUserInfo(res);
                  okToast(`Hi ${userInfo.name.substring(0, 2)}... ${res.name}`);
                },
                onRouteErr: (err, defaultErrToast) => {
                  if (err['.tag'] === 'bad_name') {
                    error('Not a valid name.');
                  } else {
                    defaultErrToast();
                  }
                },
              },
            );
          }}
        />
        <TextEditInput
          controlId="formBio"
          className="tw-mb-4"
          label="Bio"
          placeholder="Set your bio"
          initial={userInfo.bio}
          maxLength={128}
          optional={true}
          onUpdate={(bio, done, error) => {
            apiUserUpdateBio(
              { bio },
              {
                onFinally: done,
                onResult: res => {
                  done(res.bio);
                  updateUserInfo(res);
                  okToast('Set!');
                },
                onRouteErr: (err, defaultErrToast) => {
                  if (err['.tag'] === 'bad_bio') {
                    error('Not a valid bio.');
                  } else {
                    defaultErrToast();
                  }
                },
              },
            );
          }}
        />
        <TextEditInput
          controlId="formWebsite"
          className="tw-mb-4"
          label="Website"
          placeholder="Set your website"
          initial={userInfo.website}
          maxLength={100}
          optional={true}
          onUpdate={(website, done, error) => {
            apiUserUpdateWebsite(
              { website },
              {
                onFinally: done,
                onResult: res => {
                  done(res.website);
                  updateUserInfo(res);
                  okToast('Set!');
                },
                onRouteErr: (err, defaultErrToast) => {
                  if (err['.tag'] === 'bad_url') {
                    error('Not a valid URL.');
                  } else {
                    defaultErrToast();
                  }
                },
              },
            );
          }}
        />
      </Modal.Body>
    </Modal.Container>
  );
};
export default EditProfileModal;
