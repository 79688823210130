import clsx from 'clsx';
import React from 'react';

const Card = (props: { className?: string; children: React.ReactNode }) => (
  <div
    className={clsx(
      'tw-overflow-hidden',
      'tw-border tw-border-solid tw-border-layout-line-light dark:tw-border-layout-line-dark tw-rounded-md',
      props.className,
    )}
  >
    {props.children}
  </div>
);

const CardHeader = (props: { className?: string; children: React.ReactNode }) => (
  <div
    className={clsx(
      'tw-bg-highlight tw-py-3 tw-px-5',
      'tw-border-solid tw-border tw-border-x-0 tw-border-t-0 tw-border-layout-line-light dark:tw-border-layout-line-dark',
      props.className,
    )}
  >
    {props.children}
  </div>
);

Card.Header = CardHeader;

const CardBody = (props: { className?: string; children: React.ReactNode }) => (
  <div className={clsx('tw-p-5', props.className)}>{props.children}</div>
);

Card.Body = CardBody;

export default Card;
