import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';

import CaretRightIcon from './icon/CaretRightIcon';
import Spinner, { SpinnerPresence } from './lib/Spinner';
import { useThemeMode } from './ThemeModeGate';

const PageHeader = (props: { title: string; working?: boolean; bgColor?: string }) => {
  const themeMode = useThemeMode();
  const themeTextColorCls = themeMode === 'dark' ? 'tw-text-light' : 'tw-text-dark';
  const bgTextColorCls =
    props.bgColor === undefined
      ? undefined
      : cfe.Color.textColorFromBackgroundColor(props.bgColor) === 'light'
        ? 'tw-text-light'
        : 'tw-text-dark';
  const titleParts = props.title.split(' -> ');
  return (
    <div
      className={clsx(
        'tw-sticky tw-top-0 tw-z-20',
        'tw-pl-4 tw-pr-1 tw-flex tw-items-center tw-justify-between',
        'tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-b tw-border-layout-line-light dark:tw-border-layout-line-dark',
        bgTextColorCls ?? themeTextColorCls,
        props.bgColor ? null : 'tw-bg-canvas',
      )}
      style={{ backgroundColor: props.bgColor }}
    >
      <div className="tw-flex tw-items-center tw-gap-x-2">
        {titleParts.map((titlePart, index) => {
          return (
            <React.Fragment key={`${index}:${titlePart}`}>
              <span className="tw-py-2 tw-text-lg tw-font-semibold">{titlePart}</span>
              {index < titleParts.length - 1 ? <CaretRightIcon size="0.8rem" /> : null}
            </React.Fragment>
          );
        })}
        <SpinnerPresence>{props.working ? <Spinner show={true} /> : null}</SpinnerPresence>
      </div>
    </div>
  );
};

/**
 * @param props.title: If title contains " -> ", it's converted to a
 *    right-caret for better UI.
 */
const Pagelet = (props: {
  title: string;
  gutter?: boolean;
  loading?: boolean;
  working?: boolean;
  children?: React.ReactNode;
  auxbox?: React.ReactNode;
  hideAuxBoxSmBreakpoint?: boolean;
  customStyleCfg?: {
    header_bg_color?: string;
    body_bg_color?: string;
  };
  noBgPrimary?: boolean;
  skipHelmet?: boolean;
  noHeader?: boolean;
}) => {
  return (
    <div>
      <div className="tw-grid tw-grid-cols-12 tw-gap-x-2">
        <div
          className={clsx(
            'tw-col-span-12 md:tw-col-span-9',
            'tw-min-h-screen',
            props.noBgPrimary ? null : 'tw-bg-primary',
          )}
        >
          {
            // NOTE: When a pagelet is loaded as a fallback for a lazy-loaded
            // module, helmet has difficulty updating the title on subsequent
            // route changes.
            // See: https://github.com/staylor/react-helmet-async/issues/169
            !props.skipHelmet ? (
              <Helmet>
                <title>{props.title} | Superego</title>
              </Helmet>
            ) : null
          }
          {!props.noHeader ? (
            <PageHeader title={props.title} working={props.working} bgColor={props.customStyleCfg?.header_bg_color} />
          ) : null}
          <SpinnerPresence>
            {props.loading ? (
              <div className="tw-flex tw-w-full tw-justify-center tw-mt-12">
                <Spinner show={true} lg />
              </div>
            ) : null}
          </SpinnerPresence>
          {!props.loading ? (
            <>
              {props.auxbox && !props.hideAuxBoxSmBreakpoint ? (
                <div className="tw-bg-canvas tw-block md:tw-hidden">{props.auxbox}</div>
              ) : null}
              <div className={clsx('tw-text-primary', props.gutter ? pageletGutterClassName : undefined)}>
                {props.children}
              </div>
            </>
          ) : null}
        </div>
        {props.auxbox ? (
          <div className="tw-hidden md:tw-block md:tw-col-span-3">
            <div className="tw-overflow-y-scroll tw-sticky tw-top-12" style={{ height: 'calc(100vh - 3rem)' }}>
              {props.auxbox}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const pageletGutterClassName = 'tw-px-4 sm:tw-px-6';

/**
 * Use this when the simpler approach of enabling gutter on the Pagelet itself
 * isn't viable. This scenario happens when there is a full-width item inside
 * the page so the all-encompassing gutter cannot be used and applicable
 * components must be wrapped independently.
 */
export const PageletGutter = (props: { children: React.ReactNode }) => (
  <div className={pageletGutterClassName}>{props.children}</div>
);

Pagelet.Gutter = PageletGutter;

export const AuxbarHeading = (props: { className?: string; children: React.ReactNode }) => (
  <div className={clsx('tw-text-primary tw-text-lg tw-font-semibold tw-mb-1', props.className)}>{props.children}</div>
);

export const PageletNav = (props: { children: React.ReactNode }) => (
  <div className="tw-flex tw-flex-col tw-w-full">{props.children}</div>
);

export const PageletNavItem = (props: { onClick?: () => void; children: React.ReactNode }) => (
  <div
    className={clsx(
      'tw-flex tw-items-center tw-justify-start',
      'tw-font-base tw-font-semibold',
      'tw-py-3 tw-px-2',
      'hover:!tw-bg-yellow-light dark:hover:!tw-bg-yellow-dark hover:tw-text-primary tw-text-primary',
    )}
    role="button"
    onClick={props.onClick}
  >
    <div className="tw-ml-4">{props.children}</div>
  </div>
);

export const PageletNavItemLink = (props: { to: string; children: React.ReactNode }) => (
  <NavLink
    to={props.to}
    // Match exact link (not prefix) for isActive flag
    end
    className={({ isActive }) => clsx('hover:tw-no-underline', isActive ? 'tw-bg-highlight' : null)}
  >
    <PageletNavItem>{props.children}</PageletNavItem>
  </NavLink>
);

export const PageletHeading1 = (props: { className?: string; children: React.ReactNode }) => (
  <div className={clsx('tw-text-2xl tw-font-bold tw-mt-6 tw-mb-2', props.className)}>{props.children}</div>
);

Pagelet.Heading1 = PageletHeading1;

export const PageletHeading2 = (props: { className?: string; children: React.ReactNode }) => (
  <div className={clsx('tw-text-xl tw-font-bold tw-mt-6 tw-mb-2', props.className)}>{props.children}</div>
);

Pagelet.Heading2 = PageletHeading2;

export const PageletHeading3 = (props: { className?: string; children: React.ReactNode }) => (
  <div className={clsx('tw-text-lg tw-font-bold tw-mt-3 tw-mb-2', props.className)}>{props.children}</div>
);

Pagelet.Heading3 = PageletHeading3;

export const PageletHeading4 = (props: { className?: string; children: React.ReactNode }) => (
  <div className={clsx('tw-text-lg tw-font-semibold tw-mb-2', props.className)}>{props.children}</div>
);

Pagelet.Heading4 = PageletHeading4;

export default Pagelet;
