import clsx from 'clsx';
import React from 'react';

const ProgressBar = (props: { progress: number }) => (
  <div style={{ backgroundColor: '#b22222', height: '6px', width: `${props.progress}%` }}></div>
);

/**
 * Use LiveProgressBar when a bar needs to be continuously updated as a user
 * scrolls. The component maintains the state of the progress bar so that when
 * the progress is updated, only it needs to rerender and not its parent.
 *
 * To use correctly, make sure the parent does not maintain progress in a state
 * variable which would trigger its own rerendering continuously defeating the
 * purpose of this component.
 */

interface LiveProgressBarProps {
  slowTransition?: boolean;
  colorOverride?: string;
}

export interface LiveProgressBarMethods {
  update: (progress: number) => void;
}

export const LiveProgressBar = React.forwardRef<LiveProgressBarMethods, LiveProgressBarProps>((props, ref) => {
  const [progress, setProgress] = React.useState(0);
  React.useImperativeHandle(ref, () => ({
    update: (newProgress: number) => setProgress(newProgress),
  }));
  return (
    <div
      className={clsx('tw-h-[6px] tw-w-full', props.colorOverride ?? 'tw-bg-red-700')}
      style={{
        transform: `scaleX(${progress / 100})`,
        transformOrigin: 'top left',
        transition: props.slowTransition ? 'all 0.4s ease-out' : undefined,
      }}
    ></div>
  );
});

interface VideoProgressBarProps {
  seek: (progress: number) => void;
}

export interface VideoProgressBarMethods {
  update: (progress: number, duration: number) => void;
}

export const VideoProgressBar = React.forwardRef<VideoProgressBarMethods, VideoProgressBarProps>((props, ref) => {
  const [progress, setProgress] = React.useState(0);
  const [transitionDuration, setTransitionDuration] = React.useState(0.2);
  React.useImperativeHandle(ref, () => ({
    update: (newProgress: number, newTransitionDuration: number) => {
      setProgress(Math.min(newProgress, 100));
      setTransitionDuration(newTransitionDuration);
    },
  }));
  return (
    <div className="tw-h-[6px] tw-relative tw-w-full">
      <div
        className="tw-h-[6px] group-hover:tw-h-[18px] tw-bg-gray-900 group-hover:tw-opacity-90 tw-w-full tw-absolute tw-bottom-0 tw-left-0"
        style={{
          transitionDuration: `0.1s`,
          transitionProperty: 'height',
          transitionTimingFunction: 'linear',
        }}
        onClick={e => {
          e.stopPropagation();
          const xPos = e.clientX - e.currentTarget.getBoundingClientRect().left;
          const width = e.currentTarget.offsetWidth;
          props.seek(xPos / width);
          setTransitionDuration(0);
        }}
      >
        <div
          className="tw-w-full tw-h-full tw-bg-red-700 tw-rounded-r-sm"
          style={{
            transform: `scaleX(${progress / 100})`,
            transformOrigin: 'top left',
            transitionDuration: `${transitionDuration}s`,
            transitionProperty: 'all',
            transitionTimingFunction: 'linear',
          }}
        ></div>
      </div>
    </div>
  );
});

export default ProgressBar;
