import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import { useDispatch, useSelector } from 'react-redux';

import { MainActionCreators } from '../../state/reducer';
import * as store from '../../state/store';

import { useAuthedApiClient } from './useApiClient';

const useInboxUnread = () => {
  const dispatch = useDispatch();
  const apiClient = useAuthedApiClient();
  const { result, refresh } = cfe.ApiHook.useApiReadCache(
    apiClient,
    apiClient.inboxGetUnread,
    undefined,
    res => res,
    value => dispatch(MainActionCreators.apiCacheSetInboxUnread(value)),
    () =>
      useSelector<store.IAppState, cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.inbox.IGetUnreadResult>>>(
        state => state.apiCache.inboxUnread,
      ),
    undefined,
    undefined,
    300,
    false,
  );
  return {
    refresh,
    result: cfe.ApiData.getData(result),
  };
};

export default useInboxUnread;
