import React from 'react';

import RefreshIcon from './icon/RefreshIcon';
import Button from './lib/Button';

const TryAgainButton = (props: { onClick: () => void }) => {
  return (
    <Button sm variant="danger" onClick={props.onClick}>
      <RefreshIcon size="1rem" className="tw-mr-1" /> Try again
    </Button>
  );
};

export default TryAgainButton;
