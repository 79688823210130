import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const SendToIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={[512, 512]}
    d="M152 184h271.5l-127.2-109.8c-10.03-8.656-11.12-23.81-2.469-33.84c8.688-10.06 23.85-11.21 33.85-2.487l176 151.1C508.1 194.4 512 201 512 208c0 6.968-3.029 13.58-8.31 18.14l-176 151.1c-4.531 3.937-10.13 5.847-15.69 5.847c-6.719 0-13.41-2.812-18.16-8.312c-8.656-10.03-7.562-25.19 2.469-33.84l127.2-109.8H152c-57.34 0-104 46.65-104 103.1v119.1C48 469.3 37.25 480 24 480S0 469.3 0 456v-119.1C0 252.2 68.19 184 152 184z"
  />
);

export default SendToIcon;
