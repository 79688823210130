"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusCode = void 0;
var StatusCode;
(function (StatusCode) {
    StatusCode[StatusCode["Ok"] = 0] = "Ok";
    StatusCode[StatusCode["BadRequest"] = 1] = "BadRequest";
    StatusCode[StatusCode["BadAuth"] = 2] = "BadAuth";
    StatusCode[StatusCode["Error"] = 3] = "Error";
    StatusCode[StatusCode["RateLimit"] = 4] = "RateLimit";
    StatusCode[StatusCode["InternalError"] = 5] = "InternalError";
})(StatusCode = exports.StatusCode || (exports.StatusCode = {}));
