import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const EllipsisIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={[448, 512]}
    d="M416 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM48 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"
  />
);

export default EllipsisIcon;
