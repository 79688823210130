import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { useSelector } from 'react-redux';

import * as store from '../state/store';

import { useFeedList } from './hooks/useFeedList';

import FeedItemAnimated from './FeedItemAnimated';
import useUserMeInternal from './hooks/useUserMeInternal';
import { useKeyPress } from './KeyPressContext';
import Pagelet from './Pagelet';
import SectionHeaderTab from './SectionHeaderTab';

const FollowsPage = () => {
  const accountInfo = useUserMeInternal();
  if (!accountInfo) {
    throw Error('Expected logged-in user.');
  }
  const feedsMap = useSelector<store.IAppState, Map<string, api.feed.IFeedInfo>>(state => state.feeds);
  const { isRefreshing, refresh, userFeedIds } = useFeedList({ '.tag': 'id', id: accountInfo.user_id });
  const followedFeeds = cfe.ApiData.sort(
    cfe.ApiData.filter(
      cfe.ApiData.map(userFeedIds, feedId => feedsMap.get(feedId)!),
      feed => feed.for_viewer.following,
    ),
    cfe.ApiHelpers.feedCmp,
  );
  const followedUsers = cfe.ApiData.filter(followedFeeds, feed => feed.type['.tag'] === 'ego');
  const followedCurated = cfe.ApiData.filter(
    followedFeeds,
    feed => feed.type['.tag'] === 'curated' || feed.type['.tag'] === 'rss',
  );
  const followedTopics = cfe.ApiData.filter(followedFeeds, feed => feed.type['.tag'] === 'hashtag');
  useKeyPress('R', () => {
    // shift + r: Refresh follows.
    refresh();
  });
  return (
    <Pagelet title="Your follows" gutter={false} working={isRefreshing}>
      <FollowSection header="People" feeds={followedUsers} />
      <FollowSection header="Topics" feeds={followedTopics} />
      <FollowSection header="Feeds" feeds={followedCurated} />
    </Pagelet>
  );
};

const FollowSection = (props: { header: string; feeds: cfe.ApiData.Data<api.feed.IFeedInfo[]> }) => {
  return (
    <div className="tw-mt-6">
      <SectionHeaderTab title={props.header} />
      {cfe.ApiData.hasData(props.feeds) && props.feeds.data.length > 0 ? (
        <AnimatePresence initial={false}>
          {props.feeds.data.map(feed => (
            <FeedItemAnimated key={feed.feed_id} feed={feed} hideFollowers={true} sm />
          ))}
        </AnimatePresence>
      ) : cfe.ApiData.hasData(props.feeds) ? (
        <span className="tw-mb-2 tw-px-4">&mdash;</span>
      ) : null}
      {cfe.ApiData.isError(props.feeds) ? <span>Error loading</span> : null}
    </div>
  );
};

export default FollowsPage;
