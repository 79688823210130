import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React, { useEffect, useState } from 'react';

import { useAuthedApiClient } from './hooks/useApiClient';
import Button from './lib/Button';
import Card from './lib/Card';
import Spinner from './lib/Spinner';
import Table, { TableBody, TableCell, TableHead, TableHeadCell, TableHeadCellLeft, TableRow } from './lib/Table';
import Pagelet from './Pagelet';

declare var stripe: any;

const SubscriptionPage = () => {
  // The subscription page needs to be loaded from the server in order to
  // populate the stripe JS library. The page cannot be loaded dynamically via
  // react-router, otherwise the library is missing. This is a common error
  // to make, so fail hard to catch it quickly.
  if (typeof stripe === 'undefined') {
    return (
      <Pagelet title="Subscription" gutter>
        Unexpected error. Please try again later.
      </Pagelet>
    );
  }
  const apiClient = useAuthedApiClient();

  const [subStatus, setSubStatus] = useState<cfe.ApiData.SimpleData<api.billing.ISubscriptionGetStatus2Result>>({
    kind: 'unknown',
  });
  const [tippingPeriodCurrentStmt, setTippingPeriodCurrentStmt] = useState<
    cfe.ApiData.SimpleData<api.billing.ITippingPeriodStatementCurrentResult>
  >({ kind: 'unknown' });
  const [tippingPeriodStmts, setTippingPeriodStmts] = useState<
    cfe.ApiData.SimpleData<api.billing.ITippingPeriodStatementAllResult>
  >({ kind: 'unknown' });

  useEffect(() => {
    apiClient
      .billingSubscriptionGetStatus2()
      .then(resp => {
        if (resp.kind === api.StatusCode.Ok) {
          setSubStatus({ kind: 'loaded', data: resp.result });
        } else {
          setSubStatus({ kind: 'error' });
        }
      })
      .catch(() => setSubStatus({ kind: 'error' }));
    setTippingPeriodCurrentStmt({ kind: 'loading' });
    apiClient
      .billingTippingPeriodStatementCurrent()
      .then(resp => {
        if (resp.kind === api.StatusCode.Ok) {
          setTippingPeriodCurrentStmt({ kind: 'loaded', data: resp.result });
        } else {
          setTippingPeriodCurrentStmt({ kind: 'error' });
        }
      })
      .catch(() => setTippingPeriodCurrentStmt({ kind: 'error' }));

    setTippingPeriodStmts({ kind: 'loading' });
    apiClient
      .billingTippingPeriodStatementAll()
      .then(resp => {
        if (resp.kind === api.StatusCode.Ok) {
          setTippingPeriodStmts({ kind: 'loaded', data: resp.result });
        } else {
          setTippingPeriodStmts({ kind: 'error' });
        }
      })
      .catch(() => setTippingPeriodStmts({ kind: 'error' }));
  }, []);

  const isSubscriptionActive = cfe.ApiData.hasData(subStatus) && subStatus.data.status['.tag'] === 'active';
  const isSubscriptionEnding =
    cfe.ApiData.hasData(subStatus) &&
    subStatus.data.status['.tag'] === 'active' &&
    subStatus.data.status.current.end_date !== undefined;
  const hasNextSubscription =
    cfe.ApiData.hasData(subStatus) &&
    subStatus.data.status['.tag'] === 'active' &&
    subStatus.data.status.next !== undefined;

  const goToCheckoutSession = (planName: 'starter' | 'supporter' | 'big_supporter') => {
    apiClient.billingStripeCreateCheckoutSession({ plan: { '.tag': planName } }).then(resp => {
      if (resp.kind === api.StatusCode.Ok) {
        stripe
          .redirectToCheckout({
            sessionId: resp.result.session_id,
          })
          .then((result: any) => {
            // From Stripe's docs:
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            throw new Error('Unhandled: ' + result.error.message);
          });
      } else {
        throw new Error('Bad response.');
      }
    });
  };

  const endSubscription = () => {
    apiClient.billingSubscriptionEnd().then(resp => {
      if (resp.kind === api.StatusCode.Ok) {
        apiClient.billingSubscriptionGetStatus2().then(statusResp => {
          if (statusResp.kind === api.StatusCode.Ok) {
            setSubStatus({ kind: 'loaded', data: statusResp.result });
          }
        });
      }
    });
  };

  const mkSubName = (sub: api.billing.ISubscriptionReference): string => {
    if (sub['.tag'] === 'promo') {
      return `$${sub.tip_amount / 100} promo`;
    } else if (sub['.tag'] === 'stripe') {
      return `$${sub.price / 100} / 4-weeks`;
    } else {
      return 'unknown';
    }
  };

  return (
    <Pagelet title="Subscription" gutter>
      {cfe.ApiData.isLoading(subStatus) ? (
        <p>
          <Spinner />
        </p>
      ) : null}
      <div className="tw-mt-4"></div>
      {cfe.ApiData.hasData(subStatus) && subStatus.data.status['.tag'] === 'active' ? (
        <div>
          <Pagelet.Heading1>Your Subscription</Pagelet.Heading1>
          {subStatus.data.status.next ? (
            <div>
              <p>
                Your current subscription ({mkSubName(subStatus.data.status.current)}) ends on{' '}
                {new Date(subStatus.data.status.current.end_date!).toLocaleDateString(undefined, {
                  timeZone: 'UTC',
                })}
                . Your new subscription ({mkSubName(subStatus.data.status.current)}) will begin the day after.
              </p>
              <Button block variant="danger" onClick={endSubscription}>
                Cancel New Subscription
              </Button>
            </div>
          ) : subStatus.data.status.current.end_date ? (
            <div>
              {subStatus.data.status.current['.tag'] === 'stripe' ? (
                <p>
                  Because you canceled your subscription, you will not be billed again, and your subscription will{' '}
                  <b>end</b> on{' '}
                  {new Date(subStatus.data.status.current.end_date).toLocaleDateString(undefined, {
                    timeZone: 'UTC',
                  })}
                  .
                </p>
              ) : subStatus.data.status.current['.tag'] === 'promo' ? (
                <p>
                  Your promotional subscription <b>ends</b> on{' '}
                  {new Date(subStatus.data.status.current.end_date).toLocaleDateString(undefined, {
                    timeZone: 'UTC',
                  })}
                  .
                </p>
              ) : null}
            </div>
          ) : (
            <div>
              <p>
                You have an <b>active</b> subscription: {mkSubName(subStatus.data.status.current)}.
                {subStatus.data.status.current['.tag'] === 'stripe' &&
                subStatus.data.status.current.next_billing_period_start_date ? (
                  <>
                    {' '}
                    You will be billed again on{' '}
                    <b>
                      {new Date(subStatus.data.status.current.next_billing_period_start_date).toLocaleDateString(
                        undefined,
                        {
                          timeZone: 'UTC',
                        },
                      )}
                    </b>
                    .
                  </>
                ) : null}
              </p>
              <Button block variant="danger" onClick={endSubscription}>
                End Subscription
              </Button>
            </div>
          )}
        </div>
      ) : null}
      {cfe.ApiData.hasData(subStatus) && subStatus.data.status['.tag'] === 'active' ? (
        <>
          <div className="tw-mt-6">
            <SubscriptionTable sub={subStatus.data.status.current} />
          </div>
          {subStatus.data.status.next ? (
            <div className="tw-mt-6">
              <SubscriptionTable sub={subStatus.data.status.next} />
            </div>
          ) : null}
        </>
      ) : null}

      {cfe.ApiData.hasData(subStatus) && (!isSubscriptionActive || (isSubscriptionEnding && !hasNextSubscription)) ? (
        <>
          {isSubscriptionEnding && subStatus.data.status['.tag'] === 'active' ? (
            <Pagelet.Heading1 className="tw-mt-4">
              {subStatus.data.status.current['.tag'] === 'stripe' ? (
                <>Subscribe Again</>
              ) : (
                <>Upgrade to a Paid Subscription</>
              )}
            </Pagelet.Heading1>
          ) : (
            <Pagelet.Heading1 className="tw-mt-4">Choose a Plan</Pagelet.Heading1>
          )}
          {isSubscriptionEnding ? (
            <p>You'll be charged for a new subscription only after your current subscription expires.</p>
          ) : null}
          <div className="tw-my-4 tw-grid tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4">
            <Card>
              <Card.Header className="font-weight-bold">Starter</Card.Header>
              <Card.Body>
                <div className="tw-text-center tw-my-4">
                  <span className="tw-text-2xl">$</span>
                  <span className="tw-text-5xl tw-font-semibold">5</span>
                </div>
                <div className="tw-flex tw-flex-col tw-items-center tw-text-sm">
                  <div className="tw-text-sm">every 4 weeks</div>
                  <div>$4 for authors</div>
                  <div>$1 for Superego</div>
                </div>
                <div className="tw-mt-3" />
                <Button block onClick={() => goToCheckoutSession('starter')}>
                  Choose
                </Button>
                <div className="tw-text-center tw-mt-1 tw-text-xs">Cancel anytime</div>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header className="tw-font-bold">Supporter</Card.Header>
              <Card.Body>
                <div className="tw-text-center tw-my-4">
                  <span className="tw-text-2xl">$</span>
                  <span className="tw-text-5xl tw-font-semibold">10</span>
                </div>
                <div className="tw-flex tw-flex-col tw-items-center tw-text-sm">
                  <div className="tw-text-sm">every 4 weeks</div>
                  <div>$8 for authors</div>
                  <div>$2 for Superego</div>
                </div>
                <div className="tw-mt-3" />
                <Button block onClick={() => goToCheckoutSession('supporter')}>
                  Choose
                </Button>
                <div className="tw-text-center tw-mt-1 tw-text-xs">Cancel anytime</div>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header className="tw-font-bold">Big Supporter</Card.Header>
              <Card.Body>
                <div className="tw-text-center tw-my-4">
                  <span className="tw-text-2xl">$</span>
                  <span className="tw-text-5xl tw-font-semibold">15</span>
                </div>
                <div className="tw-flex tw-flex-col tw-items-center tw-text-sm">
                  <div className="tw-text-sm">every 4 weeks</div>
                  <div>$12 for authors</div>
                  <div>$3 for Superego</div>
                </div>
                <div className="tw-mt-3" />
                <Button block onClick={() => goToCheckoutSession('big_supporter')}>
                  Choose
                </Button>
                <div className="tw-text-center tw-mt-1 tw-text-xs">Cancel anytime</div>
              </Card.Body>
            </Card>
          </div>
          <Pagelet.Heading2>Payout Periods</Pagelet.Heading2>
          <p>We distribute half your subscription every two weeks so that authors get paid faster.</p>
          <Pagelet.Heading2>Carryover</Pagelet.Heading2>
          <p>If you don't ego during a period, it gets carried over to the next one. We take no further cuts.</p>
        </>
      ) : null}

      {cfe.ApiData.isLoading(subStatus) ? (
        <Spinner />
      ) : !cfe.ApiData.hasData(subStatus) ? null : subStatus.data.status['.tag'] === 'active' &&
        cfe.ApiData.hasData(tippingPeriodCurrentStmt) ? (
        <div>
          <Pagelet.Heading2>Current Period Statement</Pagelet.Heading2>
          <p>
            There are two periods per billing cycle, two weeks each, first half and second half. We do this to get
            authors paid faster.
          </p>
          <Table>
            <TableBody>
              <TableRow>
                <TableHeadCellLeft>Period</TableHeadCellLeft>
                <TableCell>
                  #{tippingPeriodCurrentStmt.data.billing_period}.{tippingPeriodCurrentStmt.data.half_index}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableHeadCellLeft>
                  Start Date
                  <br />
                  <span className="tw-text-xs">The first day an ego will count towards this period.</span>
                </TableHeadCellLeft>
                <TableCell>
                  {new Date(tippingPeriodCurrentStmt.data.start_date).toLocaleDateString(undefined, {
                    timeZone: 'UTC',
                  })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableHeadCellLeft>
                  End Date
                  <br />
                  <span className="tw-text-xs">The final day an ego will count towards this period.</span>
                </TableHeadCellLeft>
                <TableCell>
                  {new Date(tippingPeriodCurrentStmt.data.end_date).toLocaleDateString(undefined, {
                    timeZone: 'UTC',
                  })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableHeadCellLeft>
                  Grace Period End Date
                  <br />
                  <span className="tw-text-xs">The final day an ego from this period can be modified or removed.</span>
                </TableHeadCellLeft>
                <TableCell>
                  {new Date(tippingPeriodCurrentStmt.data.grace_period_end_date).toLocaleDateString(undefined, {
                    timeZone: 'UTC',
                  })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableHeadCellLeft>
                  Egos Given
                  <br />
                  <span className="tw-text-xs">The number of sites you've ego-ed.</span>
                </TableHeadCellLeft>
                <TableCell>{tippingPeriodCurrentStmt.data.tips_given}</TableCell>
              </TableRow>
              <TableRow>
                <TableHeadCellLeft>Slices Given</TableHeadCellLeft>
                <TableCell>{tippingPeriodCurrentStmt.data.slices_given}</TableCell>
              </TableRow>
              <TableRow>
                <TableHeadCellLeft>
                  Ego Size
                  <br />
                  <span className="tw-text-xs">The amount that will be split among authors.</span>
                </TableHeadCellLeft>
                <TableCell>${(tippingPeriodCurrentStmt.data.amount / 100).toFixed(2)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      ) : null}

      <Pagelet.Heading2>Previous Period Statements</Pagelet.Heading2>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>Period Id</TableHeadCell>
            <TableHeadCell>Start Date</TableHeadCell>
            <TableHeadCell>End Date</TableHeadCell>
            <TableHeadCell>Grace Period End Date</TableHeadCell>
            <TableHeadCell>Amount</TableHeadCell>
            <TableHeadCell>Carryover to Next Period</TableHeadCell>
            <TableHeadCell>Settled At</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cfe.ApiData.isLoading(tippingPeriodStmts) ? (
            <TableRow>
              <TableCell colSpan={7}>
                <Spinner />
              </TableCell>
            </TableRow>
          ) : !cfe.ApiData.hasData(tippingPeriodStmts) ? null : tippingPeriodStmts.data.statements.length === 0 ? (
            <TableRow>
              <TableCell colSpan={7}>No statements.</TableCell>
            </TableRow>
          ) : (
            tippingPeriodStmts.data.statements.map(stmt => {
              return (
                <TableRow>
                  <TableCell>
                    #{stmt.billing_period}.{stmt.half_index}
                  </TableCell>
                  <TableCell>
                    {new Date(stmt.start_date).toLocaleDateString(undefined, {
                      timeZone: 'UTC',
                    })}
                  </TableCell>
                  <TableCell>
                    {new Date(stmt.end_date).toLocaleDateString(undefined, {
                      timeZone: 'UTC',
                    })}
                  </TableCell>
                  <TableCell>
                    {new Date(stmt.grace_period_end_date).toLocaleDateString(undefined, {
                      timeZone: 'UTC',
                    })}
                  </TableCell>
                  <TableCell>${(stmt.amount / 100).toFixed(2)}</TableCell>
                  <TableCell>${(stmt.carryover_amount / 100).toFixed(2)}</TableCell>
                  <TableCell>
                    {new Date(stmt.settled_at).toLocaleDateString(undefined, {
                      timeZone: 'UTC',
                    })}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </Pagelet>
  );
};

const SubscriptionTable = (props: { sub: api.billing.ISubscriptionReference }) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableHeadCellLeft>Plan</TableHeadCellLeft>
          <TableCell>
            {props.sub['.tag'] === 'stripe' ? (
              <>${props.sub.price / 100} / 4-weeks</>
            ) : props.sub['.tag'] === 'promo' ? (
              <>Promotion: ${props.sub.tip_amount / 100} for 4-weeks</>
            ) : null}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableHeadCellLeft>Subscription Started On</TableHeadCellLeft>
          <TableCell>
            {new Date(props.sub.start_date).toLocaleDateString(undefined, {
              timeZone: 'UTC',
            })}
          </TableCell>
        </TableRow>
        {props.sub.end_date ? (
          <TableRow>
            <TableHeadCellLeft>Subscription Ending On</TableHeadCellLeft>
            <TableCell>
              {new Date(props.sub.end_date).toLocaleDateString(undefined, {
                timeZone: 'UTC',
              })}
            </TableCell>
          </TableRow>
        ) : null}
        {props.sub['.tag'] === 'stripe' && props.sub.card_details ? (
          <TableRow>
            <TableHeadCellLeft>Card Details</TableHeadCellLeft>
            <TableCell>
              <span className="tw-uppercase">{props.sub.card_details.brand}</span> {props.sub.card_details.last4}
              <br />
              <span className="tw-text-muted tw-text-xs">
                {props.sub.card_details.exp_month}/{props.sub.card_details.exp_year}
              </span>
            </TableCell>
          </TableRow>
        ) : null}
      </TableBody>
    </Table>
  );
};

export default SubscriptionPage;
