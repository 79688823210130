import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';

import useInboxSend from './hooks/useInboxSend';
import useToast from './hooks/useToast';
import useUserMeInternal from './hooks/useUserMeInternal';
import Button from './lib/Button';
import TextInput from './lib/TextInput';

const FeedEntryReply = (props: { entry: api.feed.IFeedEntryReference; author: api.feed.IFeedEntryAddedBy }) => {
  const accountInfo = useUserMeInternal();
  if (accountInfo) {
    return <FeedEntryReplyLoggedIn entry={props.entry} author={props.author} />;
  } else {
    return (
      <div className="tw-grow tw-relative tw-flex" onClick={(e: React.MouseEvent) => e.stopPropagation()}>
        <TextInput
          sm
          className="!tw-bg-transparent !tw-rounded-lg tw-pr-[4rem] placeholder:tw-italic"
          containerClassName="tw-grow"
          id="entry-reply"
          type="text"
          placeholder={`Sign up to start a conversation with ${props.author.name}`}
          disabled
        />
        <Button
          sm
          className="tw-absolute tw-top-[2px] tw-right-[2px] tw-z-[4] !tw-rounded-lg !tw-min-h-[calc(1.75rem-4px)]"
          type="submit"
          disabled
          variant="secondary"
        >
          Send
        </Button>
      </div>
    );
  }
};

const FeedEntryReplyLoggedIn = (props: { entry: api.feed.IFeedEntryReference; author: api.feed.IFeedEntryAddedBy }) => {
  const { setToast } = useToast();
  const [reply, setReply] = React.useState('');
  const primaryUrl = cfe.ApiHelpers.getEntryPrimaryUrl(props.entry);
  const { onSend, inFlight } = useInboxSend(primaryUrl, props.entry);

  const onReply = () => {
    if (!props.entry.added_by) {
      return;
    }
    onSend(
      {
        kind: 'users',
        userIds: [props.entry.added_by.user_id],
      },
      reply.length > 0 ? reply : undefined,
      () => {
        setToast({ header: 'Note sent!' });
        setReply('');
      },
    );
  };

  return (
    <div className="tw-grow tw-relative tw-flex">
      <TextInput
        sm
        className="!tw-bg-transparent !tw-rounded-lg tw-pr-[4rem] placeholder:tw-italic"
        containerClassName="tw-grow"
        id="entry-reply"
        type="text"
        placeholder={`Start a conversation with ${props.author.name}`}
        onChange={e => setReply(e.currentTarget.value)}
        value={reply}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
      />
      <Button
        sm
        className="tw-absolute tw-top-[2px] tw-right-[2px] tw-z-[4] !tw-rounded-lg !tw-min-h-[calc(1.75rem-4px)]"
        type="submit"
        disabled={reply.length === 0 || inFlight}
        variant={reply.length === 0 ? 'secondary' : 'primary'}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          onReply();
        }}
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          onReply();
        }}
      >
        Send
      </Button>
    </div>
  );
};

export default FeedEntryReply;
