import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MainActionCreators } from '../../state/reducer';
import * as store from '../../state/store';

import useApiClient from './useApiClient';

/**
 * Always fetches fresh version from API.
 */
const useUserEgoFeed = (userId: string | null): cfe.ApiData.Data<api.feed.IFeedInfo, api.feed.GetError> => {
  const dispatch = useDispatch();
  const apiClient = useApiClient();

  const [feedId, setFeedId] = React.useState<string | null>(null);

  const feed = useSelector<store.IAppState, api.feed.IFeedInfo | undefined>(state =>
    feedId ? state.feeds.get(feedId) : undefined,
  );

  const { result: feedGetResult } = cfe.ApiHook.useApiRead(
    apiClient,
    apiClient.feedGet,
    { feed_id: `u/${userId}` },
    res => res,
    userId === null,
    {
      onResult: res => {
        setFeedId(res.feed_id);
        dispatch(MainActionCreators.addFeed(res));
      },
    },
  );

  return React.useMemo(() => (feed ? { kind: 'loaded', data: feed } : feedGetResult), [feed]);
};

export default useUserEgoFeed;
