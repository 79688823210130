import clsx from 'clsx';
import React from 'react';

export interface SvgIconProps {
  size: string;
  offsetUp?: boolean;
  className?: string;
}

const SvgIcon = (props: SvgIconProps & { viewBox: [number, number]; d: string }) => (
  <svg
    viewBox={`0 0 ${props.viewBox[0]} ${props.viewBox[1]}`}
    height={props.size}
    width={props.size}
    className={clsx('tw-relative', props.offsetUp ? '-tw-top-[0.08em]' : null, props.className)}
  >
    <path fill="currentColor" d={props.d} />
  </svg>
);

export default SvgIcon;
