import React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const SortIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={[320, 512]}
    d="M271.1 288H48.15c-42.62 0-64.24 51.76-33.87 81.9l111.9 112c18.62 18.75 49.12 18.75 67.86 0l111.1-112C335.1 339.8 314.7 288 271.1 288zM160 448l-111.1-112h223.1L160 448zM48.02 223.1h223.8c42.62 0 64.24-51.76 33.87-81.9L193.9 30.07c-18.62-18.75-49.12-18.75-67.86 0l-111.1 112C-15.96 172.2 5.281 223.1 48.02 223.1zM160 63.97l111.1 112H48.02L160 63.97z"
  />
);

export default SortIcon;
