"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mkNewsfeedBlobs = exports.fuzzyMatchEntryAndUrl = exports.filterNewsfeedData = exports.dedupeEntries = void 0;
const api_data_1 = require("./api-data");
const api_helpers_1 = require("./api-helpers");
function dedupeEntries(entriesToDedup) {
    const uniqueEntries = [];
    const urlSet = new Set();
    for (const entryToDedup of entriesToDedup) {
        if (!urlSet.has(entryToDedup.entry.url) &&
            !(entryToDedup.entry.url_canonical && urlSet.has(entryToDedup.entry.url_canonical)) &&
            !(entryToDedup.entry.strong_ref && urlSet.has(entryToDedup.entry.strong_ref.url))) {
            uniqueEntries.push(entryToDedup);
        }
        urlSet.add(entryToDedup.entry.url);
        if (entryToDedup.entry.url_canonical) {
            urlSet.add(entryToDedup.entry.url_canonical);
        }
        if (entryToDedup.entry.strong_ref) {
            urlSet.add(entryToDedup.entry.strong_ref.url);
        }
    }
    return uniqueEntries;
}
exports.dedupeEntries = dedupeEntries;
function filterNewsfeedData(newsfeedEntries, showArchived, filterVideosOnly, 
// Filter out pruned entries that non-agents wouldn't see.
filterNonAgentPruned, fuzzyMatchUrlToSkip, startEntryId) {
    if (!(0, api_data_1.hasData)(newsfeedEntries)) {
        return newsfeedEntries;
    }
    // NOTE: If the start entry can't be found (-1), assume that it starts after
    // the available entries, which means everything is filtered out. If there is
    // no start entry set (-2), then include all entries.
    const startEntryIndex = startEntryId
        ? newsfeedEntries.data.findIndex(nfEntry => nfEntry.entry.entry_id === startEntryId)
        : -2;
    return (0, api_data_1.filter)(newsfeedEntries, (nfEntry, index) => {
        var _a, _b;
        if (startEntryIndex === -1) {
            return false;
        }
        if (index < startEntryIndex) {
            return false;
        }
        if (fuzzyMatchUrlToSkip && fuzzyMatchEntryAndUrl(nfEntry, fuzzyMatchUrlToSkip)) {
            return false;
        }
        let title = nfEntry.entry.md['.tag'] === 'ready' ? nfEntry.entry.md.title : undefined;
        if (nfEntry.entry.strong_ref &&
            nfEntry.entry.strong_ref.md['.tag'] === 'ready' &&
            nfEntry.entry.strong_ref.md.title) {
            title += ' ' + nfEntry.entry.strong_ref.md.title;
        }
        title = title === null || title === void 0 ? void 0 : title.toLowerCase().replace(' ', '_');
        if (filterNonAgentPruned && ((_a = nfEntry.entry.for_staff) === null || _a === void 0 ? void 0 : _a.pruned)) {
            const pruned = (_b = nfEntry.entry.for_staff) === null || _b === void 0 ? void 0 : _b.pruned;
            if (pruned.reason['.tag'] === 'staff' || pruned.reason['.tag'] === 'rss_stim_gone') {
                return false;
            }
        }
        const md = (0, api_helpers_1.getEntryMetadata)(nfEntry.entry);
        const isVideo = md['.tag'] === 'ready' && md.content_type['.tag'] === 'video';
        return ((showArchived || nfEntry.entry.for_viewer.archived === undefined || nfEntry.plugged === true) &&
            (!filterVideosOnly || isVideo));
    });
}
exports.filterNewsfeedData = filterNewsfeedData;
function fuzzyMatchEntryAndUrl(nfEntry, url) {
    var _a, _b;
    return (nfEntry.entry.url === url ||
        nfEntry.entry.url_canonical === url ||
        ((_a = nfEntry.entry.strong_ref) === null || _a === void 0 ? void 0 : _a.url) === url ||
        ((_b = nfEntry.entry.strong_ref) === null || _b === void 0 ? void 0 : _b.url_canonical) === url);
}
exports.fuzzyMatchEntryAndUrl = fuzzyMatchEntryAndUrl;
function mkNewsfeedBlobs(newsfeedEntries, embeds, showArchived, filterVideosOnly, dedupeByUrl, filterArchivedNotifs, 
// Filter out pruned entries that non-agents wouldn't see.
filterNonAgentPruned) {
    if (!(0, api_data_1.hasData)(newsfeedEntries)) {
        return { blobs: newsfeedEntries, filterCount: { archived: 0, notVideo: 0 } };
    }
    const dedupedNewsfeedEntries = dedupeByUrl ? (0, api_data_1.apply)(newsfeedEntries, dedupeEntries) : newsfeedEntries;
    let newsfeedBlobs = (0, api_data_1.map)(dedupedNewsfeedEntries, nfEntry => {
        if (nfEntry.plugged) {
            return {
                feedId: nfEntry.feed.feed_id,
                kind: 'feed',
                startEntryId: nfEntry.entry.entry_id,
            };
        }
        else {
            return {
                kind: 'story',
                story: nfEntry,
            };
        }
    });
    for (const embed of embeds) {
        newsfeedBlobs = (0, api_data_1.splice)(newsfeedBlobs, embed.pos, 0, {
            kind: 'feed',
            feedId: embed.feed.feed_id,
            subtitle: embed.subtitle,
        });
    }
    let archived = 0;
    let notVideo = 0;
    const filteredBlobs = (0, api_data_1.filter)(newsfeedBlobs, blob => {
        var _a, _b;
        if (blob.kind === 'feed') {
            return true;
        }
        const nfEntry = blob.story;
        let title = nfEntry.entry.md['.tag'] === 'ready' ? nfEntry.entry.md.title : undefined;
        if (nfEntry.entry.strong_ref &&
            nfEntry.entry.strong_ref.md['.tag'] === 'ready' &&
            nfEntry.entry.strong_ref.md.title) {
            title += ' ' + nfEntry.entry.strong_ref.md.title;
        }
        title = title === null || title === void 0 ? void 0 : title.toLowerCase().replace(' ', '_');
        if (nfEntry.plugged === true) {
            return true;
        }
        if (filterNonAgentPruned && ((_a = nfEntry.entry.for_staff) === null || _a === void 0 ? void 0 : _a.pruned)) {
            const pruned = (_b = nfEntry.entry.for_staff) === null || _b === void 0 ? void 0 : _b.pruned;
            if (pruned.reason['.tag'] === 'staff' || pruned.reason['.tag'] === 'rss_stim_gone') {
                return false;
            }
        }
        if ((!showArchived ||
            (filterArchivedNotifs && nfEntry.feed.type['.tag'] === 'notif' && nfEntry.entry['.tag'] === 'notif')) &&
            nfEntry.entry.for_viewer.archived !== undefined) {
            archived += 1;
            return false;
        }
        const md = (0, api_helpers_1.getEntryMetadata)(nfEntry.entry);
        const isVideo = md['.tag'] === 'ready' && md.content_type['.tag'] === 'video';
        if (filterVideosOnly && !isVideo) {
            notVideo += 1;
            return false;
        }
        return true;
    });
    return { blobs: filteredBlobs, filterCount: { archived, notVideo } };
}
exports.mkNewsfeedBlobs = mkNewsfeedBlobs;
