import clsx from 'clsx';
import React from 'react';

export interface SelectProps extends React.ComponentProps<'select'> {
  options: Array<{ value: string; name: string }>;
  sm?: boolean;
  block?: boolean;
}

const Select = (props: SelectProps) => {
  const { options, sm, block, ...rest } = props;
  return (
    <select
      {...rest}
      className={clsx(
        'tw-select-arrow tw-pr-10',
        'tw-appearance-none tw-rounded-sm focus:tw-rounded-sm tw-bg-zinc-100 dark:tw-bg-zinc-800 tw-text-primary tw-text-sm tw-placeholder-gray-600 dark:tw-placeholder-gray-400 tw-border tw-border-solid tw-border-[#858585] dark:tw-border-[#767676] focus:tw-outline focus:tw-outline-zinc-800 dark:focus:tw-outline-zinc-100',
        props.sm ? 'tw-py-[0.3125rem] tw-px-2 tw-leading-none' : 'tw-p-2',
        props.block ? 'tw-w-full' : null,
      )}
    >
      {props.options.map(option => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

export default Select;
