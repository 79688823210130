import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MainActionCreators } from '../state/reducer';
import { addUrlScheme } from '../util';
import { useAuthedApiClient } from './hooks/useApiClient';

import useFeedEntryRefresher from './hooks/useFeedEntryRefresher';
import useToast from './hooks/useToast';
import { useKeyPress } from './KeyPressContext';
import Alert from './lib/Alert';
import Button from './lib/Button';
import InputLabel from './lib/InputLabel';
import Modal from './lib/Modal';
import TextInput, { TextInputFooterForOptional } from './lib/TextInput';

const FeedEntryAddModal = (props: {
  feed: api.feed.IFeedInfo;
  src?: api.feed.IFeedEntryReference;
  closeModal: () => void;
  isForeground: boolean;
}) => {
  const dispatch = useDispatch();
  const { setToast } = useToast();
  const apiClient = useAuthedApiClient();
  const { refreshFeedEntry } = useFeedEntryRefresher(apiClient);

  useKeyPress(
    ['u', 'Escape'],
    () => {
      props.closeModal();
    },
    !props.isForeground,
    10,
  );

  const [inFlight, setInFlight] = useState(false);

  const [addError, setAddError] = useState('');

  const [url, setUrl] = useState(props.src?.url ?? '');
  const urlOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    setUrl(e.currentTarget.value);
  };

  const [blabber, setBlabber] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setInFlight(true);
    const fullyQualifiedUrl = addUrlScheme(url);
    const apiBlabber = blabber.length === 0 ? undefined : blabber;
    const setVia = props.src
      ? {
          entry_id: props.src.entry_id,
          quote_source: true,
        }
      : undefined;
    apiClient
      .feedEntryAdd({ feed_id: props.feed.feed_id, url: fullyQualifiedUrl, blabber: apiBlabber, set_via: setVia })
      .then(resp => {
        if (resp.kind === api.StatusCode.Ok) {
          if (resp.result['.tag'] === 'existing') {
            setAddError('This link was already added recently to this feed.');
          } else if (resp.result['.tag'] === 'new_entry') {
            setToast({ header: 'Added', icon: 'check' });
            dispatch(MainActionCreators.prependFeedEntry(props.feed.feed_id, resp.result.new_entry));
            refreshFeedEntry(props.feed.feed_id, resp.result.new_entry, 5, entry => entry.md['.tag'] !== 'not_ready');
          }
          props.closeModal();
        } else if (resp.kind === api.StatusCode.Error) {
          if (resp.error['.tag'] === 'bad_url') {
            setAddError("Not a valid link. Make sure it starts with 'http'.");
          } else if (resp.error['.tag'] === 'bad_blabber') {
            setAddError('Your note uses disallowed markup.');
          } else if (resp.error['.tag'] === 'no_permission') {
            // Should only happen if a user just lost access as otherwise the
            // UI wouldn't present the option.
            setAddError('You do not have permission to add to this feed.');
          } else if (resp.error['.tag'] === 'slow_down') {
            setAddError(`Try again in ${cfe.Formatter.secondsToHoursMinsSecsStr(resp.error.wait_period)}`);
          } else {
            setAddError('Sorry, unexpected error.');
            throw Error(`Unexpected error ${resp.error['.tag']}`);
          }
        }
      })
      .finally(() => {
        setInFlight(false);
      });
  };

  return (
    <Modal.Container show={props.isForeground} close={props.closeModal}>
      <Modal.Header className="flex-column">
        <Modal.Heading1>Add to {cfe.ApiHelpers.getFeedShortName(props.feed)}</Modal.Heading1>
        {props.src ? <div className="tw-text-xs tw-text-muted">{cfe.ApiHelpers.getEntryTitle(props.src)}</div> : null}
      </Modal.Header>
      <Modal.Body gutter>
        <form onSubmit={handleSubmit}>
          <fieldset disabled={inFlight}>
            {addError !== '' ? <Alert variant="danger">{addError}</Alert> : null}
            {!props.src ? (
              <div className="tw-mb-4">
                <InputLabel>Link</InputLabel>
                <TextInput
                  type="text"
                  placeholder="Link to add e.g. www.youtube.com/watch?v=dQw4w9WgXcQ"
                  onChange={urlOnChange}
                  value={url}
                  required
                />
              </div>
            ) : null}
            <div className="tw-mb-4">
              <InputLabel>Note</InputLabel>
              <TextInput
                type="text"
                placeholder="Add a note about this"
                onChange={e => setBlabber(e.currentTarget.value)}
                value={blabber}
              />
              <TextInputFooterForOptional value={blabber} maxLength={500} />
            </div>
            <Button block type="submit">
              Add
            </Button>
          </fieldset>
        </form>
      </Modal.Body>
    </Modal.Container>
  );
};
export default FeedEntryAddModal;
