import clsx from 'clsx';
import { AnimatePresence, m } from 'framer-motion';
import React from 'react';

const Spinner = (props: {
  className?: string;
  show?: boolean;
  sm?: boolean;
  lg?: boolean;
  color?: 'text' | 'inverted-text' | 'light';
}) => (
  <m.div
    className={clsx('tw-inline-flex', props.className)}
    initial="hide"
    variants={{
      hide: {
        opacity: 0,
      },
      show: {
        opacity: 1,
      },
    }}
    animate={props.show || props.show === undefined ? 'show' : 'hide'}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
  >
    <div
      className={clsx('tw-inline-flex', props.lg ? 'tw-w-10 tw-h-10' : props.sm ? 'tw-w-4 tw-h-4' : 'tw-w-5 tw-h-5')}
    >
      <m.div
        variants={{
          hide: { scale: 1, x: '0%', y: '0%' },
          show: {
            scale: [1, 0.7, 1, 0.7, 1],
            x: ['0%', '50%', '50%', '0%', '0%'],
            y: ['0%', '0%', '50%', '50%', '0%'],
          },
        }}
        transition={{ duration: 2, ease: 'linear', repeat: Infinity }}
        className={'tw-inline-flex tw-w-1/2 tw-h-1/2'}
      >
        <m.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={{ scale: { duration: 0.2 } }}
          className={clsx(
            'tw-inline-flex',
            'tw-w-full tw-h-full tw-rounded-sm',
            props.show || props.show === undefined ? 'tw-animate-spin' : null,
            props.color === 'text'
              ? 'tw-bg-zinc-700 dark:tw-bg-zinc-300'
              : props.color === 'inverted-text'
                ? 'tw-bg-zinc-300 dark:tw-bg-zinc-700'
                : props.color === 'light'
                  ? 'tw-bg-zinc-300'
                  : 'tw-bg-perpul-dark dark:tw-bg-perpul-light',
          )}
        ></m.div>
      </m.div>
    </div>
  </m.div>
);

export const SpinnerPresence = (props: { children: React.ReactNode }) => (
  <AnimatePresence mode="popLayout">
    <React.Fragment key="spinner">{props.children}</React.Fragment>
  </AnimatePresence>
);

Spinner.Presence = SpinnerPresence;

export const SpinnerFloat = (props: { className?: string; children: React.ReactNode }) => (
  <div className="tw-relative">
    <div className={clsx('tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2', props.className)}>
      {props.children}
    </div>
  </div>
);

Spinner.Float = SpinnerFloat;

export default Spinner;
