import React from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

export interface ContentControlHandle {
  seek: (progress: number) => void;
  getProgress: () => number;
  getProgressOffset?: () => number;
}

interface ContentControlContextState {
  handle?: ContentControlHandle;
  registerHandle: (handle: ContentControlHandle) => void;
}
const ContentControlContext = createContext<ContentControlContextState>({
  handle: { seek: () => null, getProgress: () => 0 },
  registerHandle: () => null,
});
export default ContentControlContext;

export const useContentControlRegisterHandle = () => useContextSelector(ContentControlContext, v => v.registerHandle);
export const useContentControlHandle = () => useContextSelector(ContentControlContext, v => v.handle);

export const ContentControlBoundary = (props: { children: React.ReactNode; noop?: boolean }) => {
  const [contentCtrlHandle, setContentCtrlHandle] = React.useState<ContentControlHandle>();
  if (props.noop) {
    return <>{props.children}</>;
  }
  return (
    <ContentControlContext.Provider value={{ registerHandle: setContentCtrlHandle, handle: contentCtrlHandle }}>
      {props.children}
    </ContentControlContext.Provider>
  );
};
