import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const PruneIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={[512, 512]}
    d="M506.4 439.4c-8.5 10.2-23.7 11.5-33.8 3L312.4 308.2l37.4-31.3L503.4 405.6c10.2 8.5 11.5 23.7 3 33.8zM112 48a64 64 0 1 0 0 128 64 64 0 1 0 0-128zm0-48c61.9 0 112 50.1 112 112c0 17.9-4.2 34.8-11.6 49.8l75.1 62.9L472.6 69.6c10.2-8.5 25.3-7.2 33.8 3s7.2 25.3-3 33.8l-291 243.8c7.4 15 11.6 31.9 11.6 49.8c0 61.9-50.1 112-112 112S0 461.9 0 400s50.1-112 112-112c26.6 0 51.1 9.3 70.3 24.8L250.1 256l-67.8-56.8C163.1 214.7 138.6 224 112 224C50.1 224 0 173.9 0 112S50.1 0 112 0zm64 400A64 64 0 1 0 48 400a64 64 0 1 0 128 0z"
  />
);

export default PruneIcon;
