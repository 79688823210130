import { m } from 'framer-motion';
import React from 'react';

const Collapse = (props: { open: boolean; initialOpen?: boolean; children: React.ReactNode }) => (
  <m.div
    initial={props.initialOpen ? { opacity: 1, height: 'auto' } : { opacity: 0, height: 0 }}
    animate={{ opacity: props.open ? 1 : 0, height: props.open ? 'auto' : 0 }}
    exit={{ opacity: 0, height: 0 }}
    transition={{ opacity: { duration: 0.15 }, height: { duration: 0.2 } }}
    className="tw-overflow-hidden"
    // Use inert so that the children of collapsed elements are not focusable
    // and find-in-page ignores the contents.
    // HACK from https://github.com/facebook/react/issues/17157
    // @ts-ignore
    inert={!props.open ? '' : undefined}
  >
    {props.children}
  </m.div>
);

export default Collapse;
