import clsx from 'clsx';
import { m } from 'framer-motion';
import React from 'react';

export interface ToggleProps {
  on: boolean;
  // ok() must be called if the state change succeeded because the new parent
  // state may not reflect the toggle (e.g. the API does not honor the toggle
  // and doesn't treat it as an error)
  // err() must be called if the state change failed.
  onToggle: (next: boolean, ok: () => void, err: () => void) => void;
  labelType?: 'yes-no' | 'no-yes' | 'on-off';
  offLabel?: string;
  onLabel?: string;
  variant?: 'danger';
  disabled?: boolean;
}

const Toggle = (props: ToggleProps) => {
  const [state, setState] = React.useState(props.on);
  const syncing = React.useRef(false);
  React.useEffect(() => {
    // NOTE: In the happy path, a change in `on` indicates that a toggle was
    // correctly propagated (parent state, api, ...).

    // It's possible that a change occurs that's separate from the change
    // caused by a toggle, but if that happens, it's likely an ambiguous state
    // and it makes sense to update to the last known state.
    setState(props.on);
    syncing.current = false;
  }, [props.on]);
  return (
    <div
      className={clsx(
        'tw-w-[3.5rem] tw-h-[30px] tw-flex tw-items-center tw-justify-center',
        'tw-cursor-pointer',
        'tw-rounded-sm tw-p-[3px] tw-border tw-border-solid tw-border-layout-line-light dark:tw-border-layout-line-dark',
        'tw-shadow-inner',
        state
          ? props.disabled
            ? 'tw-bg-zinc-500'
            : props.variant === 'danger'
              ? 'tw-bg-red-700 dark:tw-bg-red-700'
              : 'tw-bg-green-600 dark:tw-bg-green-600'
          : props.disabled
            ? 'tw-bg-zinc-500'
            : 'tw-bg-gray-600 dark:tw-bg-gray-600',
        'tw-text-sm tw-font-medium tw-text-light',
        'tw-gap-x-2',
        'tw-select-none', // Avoid selecting text on toggle
      )}
      onClick={() => {
        // Don't allow toggling while change is in flight.
        if (!syncing.current && !props.disabled) {
          syncing.current = true;
          setState(!state);
          props.onToggle(
            !state,
            () => {
              syncing.current = false;
            },
            () => {
              setState(state);
              syncing.current = false;
            },
          );
        }
      }}
    >
      <m.div
        animate={{
          x: state ? 23 : -23,
        }}
        transition={{
          damping: 40,
          stiffness: 700,
          type: 'spring',
        }}
        className={clsx(
          'tw-absolute tw-z-10 tw-h-[30px] tw-w-[10px] tw-rounded-sm tw-shadow-md',
          'tw-border tw-border-solid tw-border-zinc-800 dark:tw-border-zinc-300',
          state ? 'tw-bg-white dark:tw-bg-zinc-500' : 'tw-bg-zinc-300 dark:tw-bg-zinc-700',
        )}
      />
      {state ? (
        <m.div
          key="on"
          initial={{ opacity: 0.3, x: -13 }}
          animate={{ opacity: 1, x: -3 }}
          transition={{ duration: 0.2 }}
        >
          {props.onLabel ?? (props.labelType === 'on-off' ? 'On' : props.labelType === 'no-yes' ? 'No' : 'Yes')}
        </m.div>
      ) : (
        <m.div
          key="off"
          initial={{ opacity: 0.3, x: 13 }}
          animate={{ opacity: 1, x: 3 }}
          transition={{ duration: 0.2 }}
        >
          {props.offLabel ?? (props.labelType === 'on-off' ? 'Off' : props.labelType === 'no-yes' ? 'Yes' : 'No')}
        </m.div>
      )}
    </div>
  );
};

export default Toggle;
