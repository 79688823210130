import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const SaveIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={[384, 512]}
    d="M336 0h-288C21.49 0 0 21.49 0 48v431.9c0 24.7 26.79 40.08 48.12 27.64L192 423.6l143.9 83.93C357.2 519.1 384 504.6 384 479.9V48C384 21.49 362.5 0 336 0zM336 452L192 368l-144 84V54C48 50.63 50.63 48 53.1 48h276C333.4 48 336 50.63 336 54V452z"
  />
);

export default SaveIcon;
