import * as api from 'ego-sdk-js';
import React from 'react';

import { useAuthedApiClient } from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import Alert from './lib/Alert';
import Button from './lib/Button';
import InputLabel from './lib/InputLabel';
import Modal from './lib/Modal';
import TextAreaInput from './lib/TextAreaInput';
import TextInput, { TextInputFooterForOptional } from './lib/TextInput';

const StreamCreateModal = (props: { createFeedCallback: (newFeed: api.feed.IFeedInfo) => void; close: () => void }) => {
  const apiClient = useAuthedApiClient();
  const [inFlight, setInFlight] = React.useState(false);

  const { apiDo: apiFeedCreateStream, okToast, errToast } = useApiDo(apiClient, apiClient.feedCreateStream);

  const [name, setName] = React.useState('');
  const [blurb, setBlurb] = React.useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setInFlight(true);
    apiFeedCreateStream(
      { name, blurb },
      {
        onFinally: () => setInFlight(false),
        onResult: res => {
          props.createFeedCallback(res);
          okToast('New stream created');
          props.close();
        },
        onRouteErr: (err, defaultErrToast) => {
          if (err['.tag'] === 'name_conflict') {
            errToast('You already have a stream or collection with a similar name');
          } else {
            defaultErrToast();
          }
        },
      },
    );
  };

  return (
    <Modal.Container show close={props.close} animate="slide-from-top">
      <Modal.Header>
        <Modal.Heading1>New Stream</Modal.Heading1>
        <p className="tw-mb-0">
          Use streams to share your writings, photos, and videos publicly on Superego. Followers of your ego feed will
          see snippets from your streams and can choose to follow the streams for more content.
        </p>
      </Modal.Header>
      <Modal.Body gutter>
        <Alert variant="info" className="tw-my-4">
          <Alert.Heading>What can my stream be about?</Alert.Heading>
          <p>We encourage you to create streams about areas in which you are actively involved:</p>
          <ol className="tw-list-disc tw-list-outside tw-ml-4 tw-mb-4">
            <li>
              <span className="tw-font-bold">Educational Content:</span> Share knowledge on topics you are passionate
              about.
            </li>
            <li>
              <span className="tw-font-bold">Personal Projects:</span> Document and discuss the projects you are
              currently working on.
            </li>
            <li>
              <span className="tw-font-bold">Current Events:</span> Provide insights and report on current events that
              you have expertise in.
            </li>
          </ol>
          <p>Additionally, there are a few exceptions to these guidelines:</p>
          <ol className="tw-list-disc tw-list-outside tw-ml-4 tw-mb-4">
            <li>
              <span className="tw-font-bold">Organizations:</span> An announcement channel for an organization or brand.
            </li>
            <li>
              <span className="tw-font-bold">Stream of Consciousness:</span> Stream must be named "Consciousness".
              Suitable for musings without a central theme.{' '}
              <a
                className="tw-text-primary tw-underline"
                href="https://en.wikipedia.org/wiki/Stream_of_consciousness"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
            </li>
          </ol>
        </Alert>
        <form onSubmit={handleSubmit} className="tw-my-2">
          <fieldset disabled={inFlight}>
            <InputLabel htmlFor="create-feed-name">Name</InputLabel>
            <TextInput
              type="text"
              placeholder="A name for the stream"
              onChange={e => setName(e.currentTarget.value)}
              value={name}
              required
            />
            <div className="tw-mt-4" />
            <InputLabel htmlFor="create-feed-blurb">Blurb</InputLabel>
            <TextAreaInput
              rows={blurb?.split('\n').length ?? 1}
              placeholder="A short description"
              onChange={e => {
                if (e.currentTarget.value.length <= 100) {
                  setBlurb(e.currentTarget.value);
                }
              }}
              value={blurb}
              maxLength={100}
            />
            <TextInputFooterForOptional value={blurb} maxLength={100} hideMaxLengthUntilHalfway />
            <div className="tw-mt-4" />
            <Button block type="submit">
              Create
            </Button>
          </fieldset>
        </form>
      </Modal.Body>
    </Modal.Container>
  );
};

export default StreamCreateModal;
