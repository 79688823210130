import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import { AnimatePresence, m } from 'framer-motion';

import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Link, Params, useNavigate, useNavigationType, useParams } from 'react-router-dom';
import { scrollTo } from 'scroll-js';

import * as Config from '../config';
import { MainActionCreators } from '../state/reducer';
import * as store from '../state/store';
import { getSearchParameter, removeSearchParameter } from '../util';
import * as apiUtil from '../util-api';

import useApiDo from './hooks/useApiDo';
import { useFeedList } from './hooks/useFeedList';
import useFeedMap from './hooks/useFeedMap';
import useNav, { useAppLoc } from './hooks/useNav';
import useToast from './hooks/useToast';

import AppVideoPostModal from './AppVideoPostModal';
import FeedEntryHorizontalList from './FeedEntryHorizontalList';
import FeedEntryListItem from './FeedEntryListItem';
import { DateLine } from './FeedEntryMatter';
import FeedFab from './FeedFab';
import FeedProfileImage, { UserFeedProfileImage } from './FeedProfileImage';
import FeedRefreshButton from './FeedRefreshButton';
import FeedSettingsModal from './FeedSettingsModal';
import FollowButton from './FollowButton';
import useAgentMode from './hooks/useAgentMode';
import useApiClient from './hooks/useApiClient';
import { useFeedEntryStandaloneMap } from './hooks/useFeedEntryMap';
import { useUserMeInternalWithRefresher } from './hooks/useUserMeInternal';
import ArchiveIcon from './icon/ArchiveIcon';
import CompactIcon from './icon/CompactIcon';
import DiscoverIcon from './icon/DiscoverIcon';
import EgoSlice from './icon/EgoSlice';
import ExploreIcon from './icon/ExploreIcon';
import LightBulbIcon from './icon/LightBulbIcon';
import LockIcon from './icon/LockIcon';
import MobileIcon from './icon/MobileIcon';
import NotifIcon, { NotifSlashedIcon } from './icon/NotifIcon';
import PlayIcon from './icon/PlayIcon';
import SendToIcon from './icon/SendToIcon';
import SettingsIcon from './icon/SettingsIcon';
import SortIcon from './icon/SortIcon';
import SubscriberIcon from './icon/SubscriberIcon';
import VideoIcon from './icon/VideoIcon';
import { useKeyPress } from './KeyPressContext';
import Alert from './lib/Alert';
import Button, { ButtonLink } from './lib/Button';
import LayoutLine from './lib/LayoutLine';
import ListGroup from './lib/ListGroup';
import Spinner from './lib/Spinner';
import MobileStoreOptions from './MobileStoreOptions';
import { useModalManager } from './ModalManagerContext';
import Pagelet, { AuxbarHeading } from './Pagelet';
import SmartFeedLink, { FeedLink } from './SmartFeedLink';
import StoryDivider from './StoryDivider';
import StreamCreateModal from './StreamCreateModal';
import { useThemeMode } from './ThemeModeGate';
import TryAgainButton from './TryAgainButton';

const AgentUserCalendar = React.lazy(() => import('./AgentUserCalendar'));

type FeedOrderBy = 'default' | 'time' | 'score';

/**
 * SpotCurFeed represents the feed inferred by route parameters which may be
 * distinct from the feed that's currently being rendered.
 */
interface SpotCurFeedRef {
  kind: 'feed';
  feedRef: string;
  publisher: string;
  urlName: string;
  cachedFeed?: api.feed.IFeedInfo;
}
interface SpotCurFeedNewsfeed {
  kind: 'newsfeed';
  asUsername?: string;
}
interface SpotCurFeedInbox {
  kind: 'inbox';
}
interface SpotCurFeedUnknown {
  // If entry is selected, spot cur feed is unknown b/c it can't be derived
  // from the url/route path.
  kind: 'unknown';
}
type SpotCurFeed = SpotCurFeedRef | SpotCurFeedNewsfeed | SpotCurFeedInbox | SpotCurFeedUnknown;

function spotCurFeedFromRouteParams(routeParams: Params<RouteParams>): SpotCurFeed {
  let spotCurFeed: SpotCurFeed;
  if (routeParams.entryId) {
    spotCurFeed = { kind: 'unknown' };
  } else {
    if (routeParams.newsfeedAs) {
      spotCurFeed = { kind: 'newsfeed', asUsername: routeParams.newsfeedAs };
    } else if (routeParams.username && routeParams.urlName) {
      spotCurFeed = {
        feedRef: `${routeParams.username}/${routeParams.urlName}`,
        kind: 'feed',
        publisher: routeParams.username,
        urlName: routeParams.urlName,
      };
    } else if (routeParams.username) {
      spotCurFeed = {
        feedRef: `${routeParams.username}/ego`,
        kind: 'feed',
        publisher: routeParams.username,
        urlName: 'ego',
      };
    } else if (window.location.pathname === '/inbox') {
      spotCurFeed = { kind: 'inbox' };
    } else {
      spotCurFeed = { kind: 'newsfeed' };
    }
  }
  return spotCurFeed;
}

/**
 * If curFeed doesn't match spotCurFeed, curFeed needs to be updated to match,
 * which may require fetching entry or feed info from the API.
 */
const curFeedMatchSpotCurFeed = (spotCurFeed: SpotCurFeed, curFeed: store.CurFeed | undefined): boolean => {
  if (!curFeed) {
    return false;
  } else if (
    curFeed.kind === 'newsfeed' &&
    spotCurFeed.kind === 'newsfeed' &&
    curFeed.asUsername === spotCurFeed.asUsername
  ) {
    return true;
  } else if (curFeed.kind === 'inbox' && spotCurFeed.kind === 'inbox') {
    return true;
  } else if (curFeed.kind === 'feed' && spotCurFeed.kind === 'feed' && curFeed.feed.url_name === spotCurFeed.urlName) {
    const publisher =
      curFeed.feed.type['.tag'] === 'hashtag'
        ? 't'
        : curFeed.feed.type['.tag'] === 'rss'
          ? 's'
          : curFeed.feed.type['.tag'] === 'share'
            ? 'g'
            : curFeed.feed.publisher?.username;
    if (publisher === spotCurFeed.publisher) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

type RouteParams = 'username' | 'urlName' | 'entryId' | 'newsfeedAs';

const FeedPage = () => {
  const routeParams = useParams<RouteParams>();

  const dispatch = useDispatch();
  const apiClient = useApiClient();
  const exploredItem = useSelector<store.IAppState, store.ExploredItem | null>(state => state.exploredItem);
  const curFeed = useSelector<store.IAppState, store.CurFeed | undefined>(state => state.page.feedPage.curFeed);

  const navigationType = useNavigationType();

  // FIXME: Grows without bound
  const entryIdToCurFeedRef = useRef<Map<string, SpotCurFeed>>(new Map());

  let spotCurFeed: SpotCurFeed;
  if (navigationType === 'POP' && routeParams.entryId && entryIdToCurFeedRef.current.has(routeParams.entryId)) {
    // This is a special case to handle the browser back button. If
    // transitioning from a feed to an entry, this checks which feed the entry
    // was overlayed on top of since it might be the newsfeed/inbox rather than
    // the entry's container feed.
    spotCurFeed = entryIdToCurFeedRef.current.get(routeParams.entryId)!;
  } else {
    spotCurFeed = spotCurFeedFromRouteParams(routeParams);
  }

  const spotCurFeedCachedFeed = useSelector<store.IAppState, api.feed.IFeedInfo | undefined>(state => {
    if (spotCurFeed.kind !== 'feed') {
      return undefined;
    }
    for (const feed of state.feeds.values()) {
      if (
        spotCurFeed.urlName === feed.url_name &&
        ((spotCurFeed.publisher === 't' && feed.type['.tag'] === 'hashtag') ||
          (spotCurFeed.publisher === 's' && feed.type['.tag'] === 'rss') ||
          (spotCurFeed.publisher === 'g' && feed.type['.tag'] === 'share') ||
          (feed.publisher && feed.publisher.username === spotCurFeed.publisher))
      ) {
        return feed;
      }
    }
    return undefined;
  });
  if (spotCurFeed.kind === 'feed') {
    spotCurFeed.cachedFeed = spotCurFeedCachedFeed;
  }

  const [feedGetError, setFeedGetError] = useState<api.feed.GetError | api.feed.EntryGetError | null>(null);

  useEffect(() => {
    if (routeParams.entryId && curFeed && (curFeed.kind === 'newsfeed' || curFeed.kind === 'inbox')) {
      // Keeps track of the feed in the background of a shown entry.
      // FIXME: This doesn't work for more complex cases where the browser
      // history includes multiple entries for a given entryId each with a
      // different background feed / curFeedRef.
      if (curFeed.kind === 'inbox') {
        entryIdToCurFeedRef.current.set(routeParams.entryId, { kind: 'inbox' });
      } else if (curFeed.kind === 'newsfeed') {
        entryIdToCurFeedRef.current.set(routeParams.entryId, { kind: 'newsfeed', asUsername: curFeed.asUsername });
      }
    }
  }, [routeParams.entryId]);

  // The purpose of this useEffect is to reconcile the differences between the
  // spotCurFeed (what the URL suggests the FeedPage should show) vs. curFeed
  // (what the FeedPage is currently showing).
  useEffect(() => {
    setFeedGetError(null);
    if (spotCurFeed.kind === 'newsfeed') {
      if (!curFeed || curFeed.kind !== 'newsfeed' || spotCurFeed.asUsername !== curFeed.asUsername) {
        dispatch(MainActionCreators.setFeedPageCurFeed({ kind: 'newsfeed', asUsername: spotCurFeed.asUsername }));
      }
      return;
    } else if (spotCurFeed.kind === 'inbox') {
      if (!curFeed || curFeed.kind !== 'inbox') {
        dispatch(MainActionCreators.setFeedPageCurFeed({ kind: 'inbox' }));
      }
      return;
    } else if (curFeedMatchSpotCurFeed(spotCurFeed, curFeed)) {
      return;
    }
    if (spotCurFeed.kind === 'feed') {
      if (spotCurFeed.cachedFeed) {
        dispatch(MainActionCreators.setFeedPageCurFeed({ kind: 'feed', feed: spotCurFeed.cachedFeed }));
        return;
      }
      apiClient
        .feedGet({ feed_id: spotCurFeed.feedRef })
        .then(resp => {
          if (resp.kind === api.StatusCode.Ok) {
            batch(() => {
              dispatch(MainActionCreators.addFeed(resp.result));
              dispatch(MainActionCreators.setFeedPageCurFeed({ kind: 'feed', feed: resp.result }));
            });
          } else if (resp.kind === api.StatusCode.Error) {
            setFeedGetError(resp.error);
          } else {
            apiUtil.mkToastFromBadStatusCode(dispatch, resp);
          }
        })
        .catch(() => {
          apiUtil.mkToastFromHttpError(dispatch);
        });
    } else if (routeParams.entryId) {
      if (routeParams.entryId === exploredItem?.entry.entry_id) {
        // HACK: Prevent refetch of entry if it's already set as the explored
        // item.
        return;
      }
      apiClient
        .feedEntryGet({ entry_id: routeParams.entryId })
        .then(resp => {
          if (resp.kind === api.StatusCode.Ok) {
            batch(() => {
              dispatch(MainActionCreators.addFeed(resp.result.feed));
              if (curFeed === undefined) {
                // If there's already a set feed, don't override it. However,
                // if there isn't one, assume this is a fresh page load and the
                // feed must be set.
                if (resp.result.feed.type['.tag'] === 'notif') {
                  // NOTE: If the entry is from a notif, take the user to their
                  // inbox rather than their notif feed to prevent confusion.
                  dispatch(MainActionCreators.setFeedPageCurFeed({ kind: 'inbox' }));
                } else {
                  dispatch(MainActionCreators.setFeedPageCurFeed({ kind: 'feed', feed: resp.result.feed }));
                }
              }
            });
          } else if (resp.kind === api.StatusCode.Error) {
            setFeedGetError(resp.error);
          } else {
            apiUtil.mkToastFromBadStatusCode(dispatch, resp);
          }
        })
        .catch(() => {
          apiUtil.mkToastFromHttpError(dispatch);
        });
    } else {
      throw Error('Unexpected');
    }
  }, [JSON.stringify(curFeed), JSON.stringify(spotCurFeed)]);

  if (feedGetError) {
    return (
      <Pagelet title="Something is amiss" gutter noBgPrimary>
        <div className="tw-mt-6 tw-text-center">
          {feedGetError['.tag'] === 'no_permission' ? (
            <>
              <Pagelet.Heading1>No permission</Pagelet.Heading1>
              <span>Ask the admin for access.</span>
            </>
          ) : spotCurFeed.kind === 'feed' && spotCurFeed.publisher === 't' && feedGetError['.tag'] === 'not_found' ? (
            <>
              <Pagelet.Heading1>{'#' + spotCurFeed.urlName} does not exist yet</Pagelet.Heading1>
              <span>Items only show up here once they've been ego-ed.</span>
            </>
          ) : (
            <>
              <Pagelet.Heading1>Page does not exist</Pagelet.Heading1>
              <span>It may have been moved or deleted.</span>
            </>
          )}
        </div>
      </Pagelet>
    );
  } else if (curFeed) {
    // IMPORTANT: `key` ensures that there's a new component when switching
    // feeds.
    return <NewsfeedInner key={getCurFeedKey(curFeed)} curFeed={curFeed} entryId={routeParams.entryId} />;
  } else {
    return (
      <Pagelet title="" gutter loading noBgPrimary>
        <Spinner />
      </Pagelet>
    );
  }
};

const getCurFeedKey = (curFeed: store.CurFeed) => {
  if (curFeed.kind === 'newsfeed' || curFeed.kind === 'inbox') {
    return JSON.stringify(curFeed);
  } else {
    return JSON.stringify({ kind: 'feed', feed_id: curFeed.feed.feed_id });
  }
};

const PublishedCollectionsSection = (props: {
  userId: string;
  showNewStreamBtn: boolean;
  navToFeed: (feed: api.feed.IFeedInfo) => void;
}) => {
  const { pushModal } = useModalManager();
  const { userFeedIds } = useFeedList({ '.tag': 'id', id: props.userId });
  const feedMap = useFeedMap(cfe.ApiData.getData(userFeedIds) ?? []);
  const curatedFeeds = cfe.ApiData.hasData(userFeedIds)
    ? userFeedIds.data
        .map(feedId => feedMap.get(feedId)!)
        .filter(
          feed =>
            feed.publisher?.user_id === props.userId &&
            feed.type['.tag'] !== 'ego' &&
            feed.type['.tag'] !== 'reports' &&
            feed.perm_public_read,
        )
    : [];

  if (curatedFeeds.length > 0 || props.showNewStreamBtn) {
    return (
      <div className="tw-flex tw-flex-wrap tw-gap-3">
        {props.showNewStreamBtn ? (
          <div
            role="button"
            className={clsx(
              'tw-flex tw-items-center',
              'tw-bg-perpul-light dark:tw-bg-perpul-dark !tw-bg-opacity-[0.85] hover:!tw-bg-opacity-100',
              'tw-text-light hover:tw-no-underline ',
              'tw-rounded-2xl tw-pr-4 tw-h-10',
              'tw-pl-3 tw-gap-x-2',
            )}
            onClick={() => {
              pushModal(
                {
                  component: modalProps => <StreamCreateModal createFeedCallback={props.navToFeed} {...modalProps} />,
                  dupKey: 'feed-stream-create',
                  kind: 'generic',
                },
                { stackOk: true },
              );
            }}
          >
            <span>New Stream</span>
          </div>
        ) : null}
        {curatedFeeds.map(feed => {
          return (
            <FeedLink
              key={feed.feed_id}
              navToFeed={props.navToFeed}
              feed={feed}
              className={clsx(
                'tw-flex tw-items-center',
                'tw-bg-gray-200 hover:tw-bg-gray-300 dark:tw-bg-gray-800 dark:hover:tw-bg-gray-700',
                'tw-text-primary hover:tw-text-primary hover:tw-no-underline ',
                'tw-rounded-2xl tw-pr-4 tw-h-10',
                feed.profile_image ? 'tw-pl-3' : 'tw-pl-4',
              )}
            >
              {feed.type['.tag'] === 'works' ? (
                <>
                  <LightBulbIcon size="1.5rem" className="tw-mr-1.5" />
                  <span>Works</span>
                </>
              ) : (
                <>
                  {feed.profile_image ? <FeedProfileImage feed={feed} sm className="tw-mr-1.5" /> : null}
                  <span>{cfe.ApiHelpers.getFeedShortName(feed)}</span>
                </>
              )}
            </FeedLink>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

const NewsfeedInner = (props: { curFeed: store.CurFeed; entryId?: string }) => {
  const { navToFeed, navToEntry, navToNewsfeed, navToNewsfeedAsUser, newsfeedUrl, popEntry } = useNav();
  const { isExploreOrCpcOpen } = useAppLoc();

  const dispatch = useDispatch();
  const themeMode = useThemeMode();
  const { setToast } = useToast();

  const [accountInfo, accountInfoRefresh] = useUserMeInternalWithRefresher();
  const apiClient = useApiClient();
  const agentMode = useAgentMode();
  const [newsfeedDefaultAgentOverride, setNewsfeedDefaultAgentOverride] = useState<boolean | null>(null);
  const keyboardControlsActive = useSelector<store.IAppState, boolean>(state => state.keyboardControlsActive);

  const { pushModal } = useModalManager();
  const [orderBy, setOrderBy] = useState<FeedOrderBy>('default');
  const [showArchived, setShowArchived] = useState<boolean>(
    props.curFeed.kind === 'inbox' ||
      (props.curFeed.kind !== 'newsfeed' && cfe.ApiHelpers.defaultShowArchive(props.curFeed.feed)),
  );
  const [compact, setCompact] = useState(
    props.curFeed.kind === 'feed' && props.curFeed.feed.display.mode['.tag'] === 'headlines',
  );
  const [filterVideosOnly, setFilterVideosOnly] = useState(false);

  const curExtendedFeedId: store.ExtendedFeedId =
    props.curFeed.kind === 'feed' ? props.curFeed.feed.feed_id : props.curFeed.kind;

  const isAccountEgoFeed =
    accountInfo &&
    props.curFeed.kind === 'feed' &&
    props.curFeed.feed.type['.tag'] === 'ego' &&
    accountInfo.user_id === props.curFeed.feed.publisher?.user_id;

  useEffect(() => {
    if (isAccountEgoFeed) {
      accountInfoRefresh();
    }
  }, [isAccountEgoFeed]);

  // Sets the default value for whether archived entries are shown.
  useEffect(
    () => {
      if (props.curFeed.kind === 'feed') {
        setShowArchived(cfe.ApiHelpers.defaultShowArchive(props.curFeed.feed));
      } else if (props.curFeed.kind === 'inbox') {
        setShowArchived(true);
      } else {
        setShowArchived(false);
      }
    },
    props.curFeed.kind === 'feed' ? ['feed', props.curFeed.feed.feed_id] : [props.curFeed.kind, null],
  );

  const toggleShowArchived = () => {
    setShowArchived(!showArchived);
    if (showArchived) {
      setToast({ header: 'Hiding Archived', icon: 'archive' });
    } else {
      setToast({ header: 'Showing Archived', icon: 'archive' });
    }
  };

  const [resultTs, setResultTs] = useState<number | null>(null);
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  const lastBlur = useRef<Date | null>(null);

  const apiOrderBy = orderBy === 'default' ? undefined : { '.tag': orderBy };

  const newsfeedEntryIterRoute =
    accountInfo && !newsfeedDefaultAgentOverride ? apiClient.newsfeedEntryIter : apiClient.newsfeedEntryIterDefault;

  const newsfeedIter = cfe.ApiHook.useApiReadIterCache(
    apiClient,
    newsfeedEntryIterRoute,
    { limit: 30, order_by: apiOrderBy },
    apiClient.newsfeedEntryIterNext,
    { limit: 20 },
    res => res,
    value => dispatch(MainActionCreators.apiCacheSetNewsfeedEntries(value)),
    () =>
      useSelector<store.IAppState, cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.newsfeed.IEntryIterResult[]>>>(
        appState => appState.apiCache.newsfeedEntries,
      ),
    props.curFeed.kind !== 'newsfeed' || props.curFeed.asUsername !== undefined,
    {
      onQuery: () => {
        setResultTs(null);
        setShowRefreshButton(false);
      },
      onResult: res => {
        setResultTs(Date.now());
        batch(() => {
          res.entries.map(({ feed }) => dispatch(MainActionCreators.addFeed(feed)));
          res.entries.map(({ entry }) => entry.via && dispatch(MainActionCreators.addFeed(entry.via.feed)));
          res.entries.map(
            ({ entry }) => entry.natural_topic && dispatch(MainActionCreators.addFeed(entry.natural_topic)),
          );
          res.entries.map(({ entry }) => entry.works_feed && dispatch(MainActionCreators.addFeed(entry.works_feed)));
          res.infrequent_feeds.map(feedEntryPair => dispatch(MainActionCreators.addFeed(feedEntryPair.feed)));
          res.infrequent_feeds.map(feedEntryPair =>
            dispatch(
              MainActionCreators.apiCacheSetFeedEntryStandalone(
                feedEntryPair.entry.entry_id,
                cfe.ApiHook.mkCacheUnit({ kind: 'loaded', data: feedEntryPair }),
              ),
            ),
          );
          res.embeds.map(embed => dispatch(MainActionCreators.addFeed(embed.feed)));
        });
      },
    },
    undefined,
    false,
    true,
  );

  const asUsername =
    props.curFeed.kind === 'newsfeed' && props.curFeed.asUsername ? props.curFeed.asUsername : 'UNEXPECTED';
  const newsfeedAsUserIter = cfe.ApiHook.useApiReadIterCache(
    apiClient,
    apiClient.newsfeedEntryIterAsUser,
    {
      limit: 30,
      order_by: apiOrderBy,
      user_ref: {
        '.tag': 'username',
        username: asUsername,
      },
    },
    apiClient.newsfeedEntryIterNext,
    { limit: 20 },
    res => res,
    value => dispatch(MainActionCreators.apiCacheSetNewsfeedAsUserEntries(asUsername, value)),
    () =>
      useSelector<store.IAppState, cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.newsfeed.IEntryIterResult[]>>>(
        appState => {
          if (asUsername === appState.apiCache.newsfeedAsUserEntries[0]) {
            return appState.apiCache.newsfeedAsUserEntries[1];
          } else {
            return cfe.ApiHook.getCacheEmptySingleton();
          }
        },
      ),
    props.curFeed.kind !== 'newsfeed' || props.curFeed.asUsername === undefined,
    {
      onQuery: () => setShowRefreshButton(false),
      onResult: res => {
        batch(() => {
          res.entries.map(({ feed }) => dispatch(MainActionCreators.addFeed(feed)));
          res.entries.map(({ entry }) => entry.via && dispatch(MainActionCreators.addFeed(entry.via.feed)));
          res.entries.map(
            ({ entry }) => entry.natural_topic && dispatch(MainActionCreators.addFeed(entry.natural_topic)),
          );
          res.entries.map(({ entry }) => entry.works_feed && dispatch(MainActionCreators.addFeed(entry.works_feed)));
          res.infrequent_feeds.map(feedEntryPair => dispatch(MainActionCreators.addFeed(feedEntryPair.feed)));
          res.infrequent_feeds.map(feedEntryPair =>
            dispatch(
              MainActionCreators.apiCacheSetFeedEntryStandalone(
                feedEntryPair.entry.entry_id,
                cfe.ApiHook.mkCacheUnit({ kind: 'loaded', data: feedEntryPair }),
              ),
            ),
          );
          res.embeds.map(embed => dispatch(MainActionCreators.addFeed(embed.feed)));
        });
      },
    },
    undefined,
    false,
    true,
  );

  const inboxIter = cfe.ApiHook.useApiReadIterCache(
    apiClient,
    apiClient.newsfeedEntryIterInbox,
    { limit: 30, order_by: apiOrderBy },
    apiClient.newsfeedEntryIterNext,
    { limit: 20 },
    res => res,
    value => dispatch(MainActionCreators.apiCacheSetInboxEntries(value)),
    () =>
      useSelector<store.IAppState, cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.newsfeed.IEntryIterResult[]>>>(
        appState => appState.apiCache.inboxEntries,
      ),
    props.curFeed.kind !== 'inbox',
    {
      onQuery: () => setShowRefreshButton(false),
      onResult: res => {
        batch(() => {
          res.entries.map(({ feed }) => dispatch(MainActionCreators.addFeed(feed)));
          res.entries.map(({ entry }) => entry.via && dispatch(MainActionCreators.addFeed(entry.via.feed)));
          res.infrequent_feeds.map(feedEntryPair => dispatch(MainActionCreators.addFeed(feedEntryPair.feed)));
          res.infrequent_feeds.map(feedEntryPair =>
            dispatch(
              MainActionCreators.apiCacheSetFeedEntryStandalone(
                feedEntryPair.entry.entry_id,
                cfe.ApiHook.mkCacheUnit({ kind: 'loaded', data: feedEntryPair }),
              ),
            ),
          );
          res.embeds.map(embed => dispatch(MainActionCreators.addFeed(embed.feed)));
        });
      },
    },
    undefined,
    false,
    true,
  );

  const forceInboxRefresh = useSelector<store.IAppState, boolean>(state => state.forceInboxRefresh);
  useEffect(() => {
    if (forceInboxRefresh) {
      inboxIter.refresh();
      dispatch(MainActionCreators.setForceInboxRefresh(false));
    }
  }, [forceInboxRefresh]);

  const arg = {
    feed_id: curExtendedFeedId,
    limit: 30,
    order_by: apiOrderBy,
  };
  const argKey = JSON.stringify(arg);
  const feedIter = cfe.ApiHook.useApiReadIterCache(
    apiClient,
    apiClient.feedEntryIter,
    arg,
    apiClient.feedEntryIterNext,
    { limit: 20 },
    res => res,
    value => dispatch(MainActionCreators.apiCacheSetFeedEntries(curExtendedFeedId, argKey, value)),
    () =>
      useSelector<store.IAppState, cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.feed.IEntryIterResult[]>>>(
        appState => appState.apiCache.feedEntries.get(argKey) ?? cfe.ApiHook.getCacheEmptySingleton(),
      ),
    props.curFeed.kind !== 'feed',
    {
      onQuery: () => setShowRefreshButton(false),
      onResult: res => {
        batch(() => {
          dispatch(MainActionCreators.addFeed(res.feed));
          res.entries.map(entry => entry.via && dispatch(MainActionCreators.addFeed(entry.via.feed)));
        });
      },
    },
    undefined,
    false,
    true,
  );

  let hasMore: boolean;
  let next: () => void;
  let refresh: (clear?: boolean) => void;
  let newsfeedEntries: cfe.ApiData.Data<api.newsfeed.INewsfeedEntry[]>;
  let entriesOrderedBy: api.feed.EntryOrderBy | null = null;
  let newStoriesFrom: api.feed.IFeedEntryPair[] = [];
  let feedEmbeds: api.newsfeed.IFeedEmbed[] = [];
  if (props.curFeed.kind === 'newsfeed') {
    const newsfeedIterGeneric = props.curFeed.asUsername ? newsfeedAsUserIter : newsfeedIter;
    ({ hasMore, next, refresh } = newsfeedIterGeneric);
    newsfeedEntries = cfe.ApiData.reduce(
      cfe.ApiData.map(newsfeedIterGeneric.result, res => res.entries),
      [],
      (curVal, prevVal) => {
        return curVal.concat(prevVal);
      },
    );
    if (cfe.ApiData.hasData(newsfeedIterGeneric.result)) {
      entriesOrderedBy = newsfeedIterGeneric.result.data[0].ordered_by;
      newStoriesFrom = newsfeedIterGeneric.result.data[0].infrequent_feeds;
      feedEmbeds = newsfeedIterGeneric.result.data[0].embeds;
    }
  } else if (props.curFeed.kind === 'inbox') {
    ({ hasMore, next, refresh } = inboxIter);
    newsfeedEntries = cfe.ApiData.reduce(
      cfe.ApiData.map(inboxIter.result, res => res.entries),
      [],
      (curVal, prevVal) => {
        return curVal.concat(prevVal);
      },
    );
    if (cfe.ApiData.hasData(inboxIter.result)) {
      entriesOrderedBy = inboxIter.result.data[0].ordered_by;
      newStoriesFrom = inboxIter.result.data[0].infrequent_feeds;
      feedEmbeds = inboxIter.result.data[0].embeds;
    }
  } else {
    ({ hasMore, next, refresh } = feedIter);
    newsfeedEntries = cfe.ApiData.reduce(
      cfe.ApiData.map(feedIter.result, res =>
        res.entries.map(entry => {
          if (props.curFeed.kind !== 'feed') {
            throw Error('Unexpected');
          }
          return { feed: props.curFeed.feed, entry };
        }),
      ),
      [],
      (curVal, prevVal) => {
        return curVal.concat(prevVal);
      },
    );
    if (cfe.ApiData.hasData(feedIter.result)) {
      entriesOrderedBy = feedIter.result.data[0].ordered_by;
    }
  }

  useEffect(() => {
    if (newsfeedDefaultAgentOverride !== null) {
      refresh(true);
    }
  }, [newsfeedDefaultAgentOverride]);

  // Add event listener to show a "refresh button" when the user returns focus
  // to this page after a period of time.
  useEffect(
    () => {
      const onFocus = (_e: FocusEvent) => {
        if (lastBlur.current === null) {
          // Unclear why this case occurs, but it does.
          return;
        }
        const elapsedMillis = new Date().getTime() - lastBlur.current.getTime();
        if (elapsedMillis > 10_800_000) {
          setShowRefreshButton(true);
        }
        lastBlur.current = null;
      };
      const onBlur = () => {
        lastBlur.current = new Date();
      };
      window.addEventListener('focus', onFocus);
      window.addEventListener('blur', onBlur);
      return () => {
        window.removeEventListener('focus', onFocus);
        window.removeEventListener('blur', onBlur);
      };
    },
    props.curFeed.kind === 'feed' ? ['feed', props.curFeed.feed.feed_id] : [props.curFeed.kind, null],
  );

  // ---

  const { result: userInfo, override: overrideUserInfo } = cfe.ApiHook.useApiRead(
    apiClient,
    apiClient.userGet,
    {
      user_id:
        props.curFeed.kind === 'feed' && props.curFeed.feed.publisher ? props.curFeed.feed.publisher.user_id : 'n/a', // Not expected to run
    },
    res => res,
    props.curFeed.kind !== 'feed' || props.curFeed.feed.type['.tag'] !== 'ego',
  );

  // ---

  const navigationType = useNavigationType();

  useEffect(
    () => {
      setOrderBy('default');
      setShowFeedSettingsModal(false);
      if (
        navigationType === 'PUSH' &&
        cfe.ApiData.hasData(newsfeedEntries) &&
        !cfe.ApiData.isLoading(newsfeedEntries)
      ) {
        // If opening a feed via push-return (rather than a push-fresh or pop),
        // take the opportunity to refresh the feed data in the background.
        // See SEP 138 for more details.
        refresh(false);
      }
    },
    props.curFeed.kind === 'feed' ? ['feed', props.curFeed.feed.feed_id] : [props.curFeed.kind, null],
  );

  const renameFeedUrlUpdate = (feed: api.feed.IFeedInfo) => {
    // HACK: Did not fully vet whether the appropriate state in the page is
    // refreshed.
    navToFeed(feed, true);
    setShowFeedSettingsModal(false);
  };

  const toggleFilterVideosOnly = () => {
    scrollTo(window, { top: 0, easing: 'ease-in-out', duration: 150 });
    dispatch(MainActionCreators.setFeedKbCursor(curExtendedFeedId, { kind: 'feed', pos: 0 }));
    setFilterVideosOnly(!filterVideosOnly);
    if (!filterVideosOnly) {
      setToast({ header: 'Showing videos only', icon: 'info' });
    }
  };

  const { blobs: newsfeedBlobs, filterCount } = cfe.NewsfeedHelpers.mkNewsfeedBlobs(
    newsfeedEntries,
    props.curFeed.kind === 'newsfeed' ? feedEmbeds : [],
    showArchived,
    filterVideosOnly,
    props.curFeed.kind === 'newsfeed',
    props.curFeed.kind === 'inbox',
    !agentMode,
  );
  const disableEntryIterNext = filterCount.notVideo > 100;

  const unmountFeedEmbed = (feedId: string) => {
    dispatch(MainActionCreators.removePluggedNewsfeedEntry(feedId));
    dispatch(MainActionCreators.removeNewsfeedFeedEmbed(feedId));
  };

  // null if no position set (newsfeed not loaded);
  const kbCursor = useSelector<store.IAppState, store.NewsfeedKbCursor | null>(
    state => state.feedKbCursor.get(curExtendedFeedId) ?? null,
  );
  const kbBlobIndex = kbCursor?.kind === 'feed' ? kbCursor.pos : null;
  let kbSelectedBlob: cfe.NewsfeedHelpers.NewsfeedBlob | null = null;
  let kbSelectedPrevBlob: cfe.NewsfeedHelpers.NewsfeedBlob | null = null;
  let kbSelectedNextBlob: cfe.NewsfeedHelpers.NewsfeedBlob | null = null;
  if (cfe.ApiData.hasData(newsfeedBlobs) && kbBlobIndex !== null) {
    if (kbBlobIndex < newsfeedBlobs.data.length) {
      kbSelectedBlob = newsfeedBlobs.data[kbBlobIndex];
    }
    if (kbBlobIndex + 1 < newsfeedBlobs.data.length) {
      kbSelectedNextBlob = newsfeedBlobs.data[kbBlobIndex + 1];
    }
    if (kbBlobIndex - 1 >= 0) {
      kbSelectedPrevBlob = newsfeedBlobs.data[kbBlobIndex - 1];
    }
  }

  //
  // Compact toggle handling
  //

  const toggleCompact = () => {
    if (!keyboardControlsActive) {
      // If kb controls are active, then don't scroll since the keyboard cursor
      // will maintain the viewport in the feed. If kb controls aren't active,
      // then scroll to top since otherwise the user will be disoriented as the
      // compressed stories pass their viewport by.
      scrollTo(window, { top: 0, easing: 'ease-in-out', duration: 150 });
    }
    setCompact(!compact);
    if (!compact) {
      setToast({ header: 'Headlines mode', icon: 'info' });
    }
  };

  //
  // Default newsfeed toggle by agent
  //

  const toggleDefaultNewsfeed = () => {
    // Show archived entries so that the agent's own archiving
    // doesn't alter their view of the default feed.
    if (accountInfo?.is_staff) {
      setShowArchived(!newsfeedDefaultAgentOverride);
      setNewsfeedDefaultAgentOverride(!newsfeedDefaultAgentOverride);
    }
  };

  useKeyPress('d', () => {
    toggleDefaultNewsfeed();
  });
  useKeyPress('c', () => {
    // c: Toggle compact mode.
    toggleCompact();
  });
  useKeyPress('v', () => {
    // v: Toggle videos-only mode.
    toggleFilterVideosOnly();
  });

  useKeyPress(
    'j',
    () => {
      // j: Move to next feed entry.
      dispatch(MainActionCreators.setKeyboardControlsActive(true));
      if (kbBlobIndex !== null && kbSelectedBlob) {
        if (kbSelectedNextBlob) {
          dispatch(MainActionCreators.setFeedKbCursor(curExtendedFeedId, { kind: 'feed', pos: kbBlobIndex + 1 }));
          if (props.entryId) {
            if (kbSelectedNextBlob.kind === 'story') {
              navToEntry(kbSelectedNextBlob.story.feed, kbSelectedNextBlob.story.entry);
            } else {
              // If it's a horiz list, it's responsible for switching the entry.
            }
          }
        } else {
          if (!disableEntryIterNext) {
            next();
          }
        }
      }
    },
    kbCursor?.kind !== 'feed',
  );

  useKeyPress(
    'k',
    () => {
      // k: Move to prev feed entry.
      dispatch(MainActionCreators.setKeyboardControlsActive(true));
      if (kbBlobIndex !== null && kbSelectedBlob) {
        if (kbSelectedPrevBlob) {
          dispatch(MainActionCreators.setFeedKbCursor(curExtendedFeedId, { kind: 'feed', pos: kbBlobIndex - 1 }));
          if (props.entryId) {
            if (kbSelectedPrevBlob.kind === 'story') {
              navToEntry(kbSelectedPrevBlob.story.feed, kbSelectedPrevBlob.story.entry);
            } else {
              // If it's a horiz list, it's responsible for switching the entry.
            }
          }
        } else if (newStoriesFrom.length > 0) {
          dispatch(MainActionCreators.setFeedKbCursor(curExtendedFeedId, { kind: 'new-stories' }));
        }
      }
    },
    kbCursor?.kind !== 'feed',
  );

  useKeyPress('R', () => {
    // shift + r: Refresh feed.
    refresh(true);
  });
  useKeyPress(
    'i',
    () => {
      // i: Open explore modal for entry
      if (props.entryId) {
        // Regardless of how the explore was opened, this is responsible for
        // closing explore.
        popEntry();
      } else if (kbSelectedBlob?.kind === 'story') {
        navToEntry(kbSelectedBlob.story.feed, kbSelectedBlob.story.entry);
      }
    },
    !(kbSelectedBlob?.kind === 'story' || props.entryId !== undefined),
  );
  useKeyPress('u', () => {
    // u: Back to feed entry list.
    if (props.entryId) {
      popEntry();
    }
  });
  useKeyPress('Escape', () => {
    // Escape: Back to feed entry list.
    if (props.entryId) {
      popEntry();
    }
  });

  const [showFeedSettingsModal, setShowFeedSettingsModal] = useState(false);

  const showEgoFeedOnboarding =
    isAccountEgoFeed && cfe.ApiData.hasData(newsfeedEntries) && newsfeedEntries.data.length === 0;
  const showNewsfeedOnboarding =
    cfe.ApiData.getData(newsfeedEntries)?.length === 0 &&
    props.curFeed.kind === 'newsfeed' &&
    !props.curFeed.asUsername;

  //
  // Manages cursor position on page load.
  //
  useEffect(() => {
    if (
      (!kbCursor || kbCursor.kind === 'feed') &&
      cfe.ApiData.hasData(newsfeedBlobs) &&
      newsfeedBlobs.data.length > 0 &&
      kbBlobIndex === null
    ) {
      dispatch(MainActionCreators.setFeedKbCursor(curExtendedFeedId, { kind: 'feed', pos: 0 }));
    }
  }, [kbBlobIndex, cfe.ApiData.hasData(newsfeedBlobs)]);

  //
  // Check if onboarding was just completed
  //
  const [onboarded, setOnboarded] = useState(false);
  useEffect(() => {
    const alertParam = getSearchParameter(window.location.search, 'onboarded');
    if (alertParam !== null) {
      setOnboarded(true);
      // Clear out any feed cache that may have been generated before the user
      // started the onboarding flow.
      refresh();
    }
  }, []);

  const auxKbNextSection = React.useCallback(
    () => dispatch(MainActionCreators.setFeedKbCursor(curExtendedFeedId, { kind: 'feed', pos: 0 })),
    [],
  );

  // Auxbox is rendered in the pagelet right sidebar as well as in the main
  // pagelet column on small screens. Styling must support both.
  const auxbox = onboarded ? (
    <OnboardedAuxbox />
  ) : (accountInfo || (props.curFeed.kind === 'newsfeed' && props.curFeed.asUsername)) && newStoriesFrom.length > 0 ? (
    <NewStoriesFromAuxbox
      feedEntryPairs={newStoriesFrom}
      filterVideosOnly={filterVideosOnly}
      navToFeed={navToFeed}
      apiClient={apiClient}
      kbActive={kbCursor?.kind === 'new-stories'}
      kbNextSection={auxKbNextSection}
    />
  ) : !accountInfo ? (
    <LoggedOutWelcomeAuxbox />
  ) : undefined;

  let topScore: number;
  if (agentMode && entriesOrderedBy?.['.tag'] === 'score' && cfe.ApiData.hasData(newsfeedBlobs)) {
    for (const nfBlob of newsfeedBlobs.data) {
      if (nfBlob.kind === 'story') {
        topScore = cfe.ApiHelpers.computeScore(nfBlob.story.entry);
        break;
      }
    }
  }

  return (
    <Pagelet
      title={
        props.curFeed.kind === 'newsfeed'
          ? 'Home'
          : props.curFeed.kind === 'inbox'
            ? 'Inbox'
            : cfe.ApiHelpers.getFeedShortName(props.curFeed.feed)
      }
      gutter={false}
      auxbox={auxbox}
      working={cfe.ApiData.isLoading(newsfeedEntries)}
      customStyleCfg={
        props.curFeed.kind === 'feed'
          ? themeMode === 'light'
            ? props.curFeed.feed.style2?.light
            : themeMode === 'dark'
              ? props.curFeed.feed.style2?.dark
              : undefined
          : undefined
      }
      noBgPrimary
    >
      <Helmet>
        <title>
          {props.curFeed.kind === 'newsfeed'
            ? 'Superego'
            : props.curFeed.kind === 'inbox'
              ? 'Inbox'
              : cfe.ApiHelpers.getFeedShortName(props.curFeed.feed) + ' | Superego'}
        </title>
      </Helmet>
      {props.curFeed.kind === 'feed' ? (
        <PageGutter
          className="tw-flex tw-flex-col tw-gap-y-6"
          style={{
            backgroundColor:
              props.curFeed.kind === 'feed'
                ? themeMode === 'light'
                  ? props.curFeed.feed.style2?.light?.body_bg_color
                  : themeMode === 'dark'
                    ? props.curFeed.feed.style2?.dark?.body_bg_color
                    : undefined
                : undefined,
          }}
        >
          <div className="tw-flex tw-mt-6 tw-gap-x-8 tw-gap-y-4">
            {props.curFeed.feed.profile_image ? <FeedProfileImage feed={props.curFeed.feed} /> : null}
            <div className="tw-grow tw-flex tw-flex-col tw-gap-y-4 tw-justify-center">
              <div className="tw-flex tw-flex-wrap tw-items-center tw-text-primary">
                <div className="tw-flex tw-mr-6 tw-items-center">
                  <span className="tw-text-2xl tw-font-medium tw-mb-0">
                    {cfe.ApiHelpers.getFeedShortName(props.curFeed.feed)}
                  </span>
                </div>
                <div className="tw-flex tw-gap-2">
                  {props.curFeed.feed.perm_public_read ? null : (
                    <Button sm variant="secondary" disabled title="This feed is private: other users can't see it.">
                      <LockIcon size="1rem" offsetUp className="tw-mr-1" />
                      Private
                    </Button>
                  )}
                  {props.curFeed.feed.type['.tag'] !== 'share' &&
                  (agentMode ||
                    (!props.curFeed.feed.for_viewer.perm.admin &&
                      props.curFeed.feed.publisher?.user_id !== accountInfo?.user_id)) ? (
                    <FollowButton feed={props.curFeed.feed} sm />
                  ) : null}
                  {agentMode ||
                  props.curFeed.feed.for_viewer.following ||
                  props.curFeed.feed.type['.tag'] === 'share' ? (
                    <NotifBellButton apiClient={apiClient} feed={props.curFeed.feed} />
                  ) : null}
                  {isAccountEgoFeed && cfe.ApiData.hasData(userInfo) ? (
                    <Button
                      sm
                      onClick={() =>
                        pushModal({
                          kind: 'edit-profile',
                          updateUserInfo: (newUserInfo: api.user.IUserInfo) => overrideUserInfo(newUserInfo),
                          userInfo: userInfo.data,
                        })
                      }
                    >
                      Edit Profile
                    </Button>
                  ) : null}
                  {(props.curFeed.feed.type['.tag'] !== 'share' && props.curFeed.feed.for_viewer.perm.write) ||
                  agentMode ? (
                    <FeedFab
                      onEgo={isAccountEgoFeed ? () => pushModal({ kind: 'ego', src: null }) : undefined}
                      onPost={
                        !isAccountEgoFeed || agentMode
                          ? () => {
                              if (props.curFeed.kind === 'feed') {
                                pushModal({ kind: 'post', feedId: props.curFeed.feed.feed_id });
                              }
                            }
                          : undefined
                      }
                      onPostVideo={
                        agentMode
                          ? () => {
                              if (props.curFeed.kind === 'feed') {
                                const feedId = props.curFeed.feed.feed_id;
                                pushModal({
                                  component: modalProps => (
                                    <AppVideoPostModal
                                      {...modalProps}
                                      formMode={{
                                        feedId,
                                        kind: 'standalone',
                                      }}
                                    />
                                  ),
                                  dupKey: 'post-video',
                                  kind: 'generic',
                                });
                              }
                            }
                          : undefined
                      }
                      onAddLink={
                        props.curFeed.feed.type['.tag'] !== 'ego'
                          ? () => {
                              if (props.curFeed.kind !== 'feed') {
                                return;
                              }
                              pushModal({ kind: 'add', feed: props.curFeed.feed });
                            }
                          : undefined
                      }
                    />
                  ) : null}
                  {props.curFeed.feed.for_viewer.perm.admin || agentMode ? (
                    <>
                      <Button sm title="Show options" onClick={() => setShowFeedSettingsModal(true)}>
                        <SettingsIcon size="1rem" className="tw-mx-1" />
                      </Button>
                      {showFeedSettingsModal ? (
                        <FeedSettingsModal
                          feed={props.curFeed.feed}
                          closeModal={() => setShowFeedSettingsModal(false)}
                          removeFeedCallback={feed => {
                            if (!accountInfo) {
                              return;
                            }
                            navToNewsfeed();
                            dispatch(MainActionCreators.removeFeed(accountInfo.user_id, feed));
                          }}
                          updateFeedCallback={feed => {
                            dispatch(MainActionCreators.updateFeed(feed));
                          }}
                          renameFeedUrlUpdate={renameFeedUrlUpdate}
                        />
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
              {props.curFeed.feed.type['.tag'] === 'hashtag' ? (
                <HashtagFeedInsert apiClient={apiClient} feed={props.curFeed.feed} />
              ) : props.curFeed.feed.type['.tag'] === 'rss' ? (
                <RssFeedInsert apiClient={apiClient} feed={props.curFeed.feed} />
              ) : props.curFeed.feed.type['.tag'] === 'share' ? (
                <ShareFeedInsert apiClient={apiClient} feed={props.curFeed.feed} />
              ) : null}
              {props.curFeed.feed.publisher &&
              cfe.ApiHelpers.showFeedPublisher(accountInfo?.user_id ?? null, props.curFeed.feed) ? (
                <div className="tw-text-primary">
                  <SmartFeedLink
                    goToFeed={navToFeed}
                    apiClient={apiClient}
                    feedRef={`u/${props.curFeed.feed.publisher.user_id}`}
                    displayHref={`/${props.curFeed.feed.publisher.username}`}
                    className="tw-text-primary hover:tw-no-underline"
                  >
                    <span>By {props.curFeed.feed.publisher.name} </span>
                    <span className="tw-text-muted tw-text-sm">@{props.curFeed.feed.publisher.username}</span>
                  </SmartFeedLink>
                </div>
              ) : null}
              {props.curFeed.feed.followers > 0 || props.curFeed.feed.type['.tag'] === 'ego' ? (
                <div className="tw-text-primary">
                  <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 tw-max-w-sm">
                    <div>
                      <span className="tw-font-bold">{props.curFeed.feed.followers}</span> followers
                    </div>
                    {props.curFeed.feed.type['.tag'] === 'ego' && cfe.ApiData.hasData(userInfo) ? (
                      <>
                        <div>
                          <span className="tw-font-bold">{userInfo.data.reputation}</span> karma
                        </div>
                        <div>
                          <span className="tw-font-bold">{userInfo.data.reego}</span> reego
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {agentMode ? (
                <div className="tw-text-primary">
                  <span className="tw-font-bold">{props.curFeed.feed.for_viewer.affinity}</span> affinity
                </div>
              ) : null}
              {props.curFeed.feed.type['.tag'] === 'ego' && cfe.ApiData.hasData(userInfo) ? (
                <div className="tw-text-primary">
                  <div>
                    <span>@{userInfo.data.username}</span>
                    {agentMode ? (
                      <span className="tw-text-muted tw-text-xs">
                        {' '}
                        (
                        {userInfo.data.email ? (
                          <>
                            {userInfo.data.email.addr} verified: {userInfo.data.email.verified ? 'yes' : 'no'}{' '}
                          </>
                        ) : null}
                        <Link to={`/z/agent/user/${userInfo.data.user_id}`}>agent page</Link>)
                      </span>
                    ) : null}
                    {agentMode ? (
                      <div className="tw-text-muted tw-text-xs">
                        <UserExtendedAgentInfo apiClient={apiClient} userId={userInfo.data.user_id} />
                      </div>
                    ) : null}
                    {agentMode ? (
                      <AgentUserCalendarWrapper apiClient={apiClient} userId={userInfo.data.user_id} />
                    ) : null}
                  </div>
                  {userInfo.data.is_subscriber ? (
                    <div>
                      <span className="tw-font-bold tw-mr-2">Subscriber</span>
                      <SubscriberIcon size="1rem" offsetUp />
                    </div>
                  ) : null}
                  {userInfo.data.is_ghost ? (
                    <div>
                      <span className="tw-font-bold tw-mr-2">Account Closed</span>
                    </div>
                  ) : null}
                  {userInfo.data.bio ? <div className="tw-max-w-sm">{userInfo.data.bio}</div> : null}
                  {userInfo.data.website ? (
                    <div>
                      <a href={userInfo.data.website} target="_blank" rel="noopener">
                        {cfe.ApiHelpers.stripUrlScheme(userInfo.data.website)}
                      </a>
                    </div>
                  ) : null}
                  {props.curFeed.feed.type['.tag'] === 'ego' &&
                  props.curFeed.feed.publisher?.user_id !== accountInfo?.user_id ? (
                    <div>
                      <span
                        role="button"
                        className="tw-link"
                        onClick={() => {
                          if (props.curFeed.kind === 'feed' && props.curFeed.feed.publisher) {
                            navToNewsfeedAsUser(props.curFeed.feed.publisher.username);
                          }
                        }}
                      >
                        See their front page
                      </span>
                    </div>
                  ) : null}
                </div>
              ) : null}
              {props.curFeed.feed.blurb && props.curFeed.feed.type['.tag'] !== 'ego' ? (
                <div className="tw-max-w-sm tw-text-primary tw-break-words">
                  {apiUtil.stringToPtags(props.curFeed.feed.blurb, 'tw-mb-0')}
                </div>
              ) : null}
            </div>
          </div>
          {props.curFeed.feed.type['.tag'] === 'ego' ? (
            <div className="tw-px-4">
              <PublishedCollectionsSection
                userId={props.curFeed.feed.publisher!.user_id}
                showNewStreamBtn={!!isAccountEgoFeed}
                navToFeed={navToFeed}
              />
            </div>
          ) : null}
          {isAccountEgoFeed &&
          cfe.ApiData.hasData(newsfeedEntries) &&
          newsfeedEntries.data.length > 0 &&
          !accountInfo.is_subscriber ? (
            <Alert variant="warn">
              <Alert.Heading>Make your ego count</Alert.Heading>
              <Alert.Link to="/subscription">Start a subscription</Alert.Link> so that your ego pays out to authors.
            </Alert>
          ) : null}
        </PageGutter>
      ) : null}
      {props.curFeed.kind === 'newsfeed' && props.curFeed.asUsername ? (
        <Alert variant="warn">
          <Alert.Heading>The world through another pair of eyes</Alert.Heading>
          This is the front page that{' '}
          <Alert.Link to={`/${props.curFeed.asUsername}`}>@{props.curFeed.asUsername}</Alert.Link> sees.{' '}
          <Alert.Link to={newsfeedUrl}>Go back to your own &rarr;</Alert.Link>
        </Alert>
      ) : null}
      {props.curFeed.kind === 'inbox' &&
      cfe.ApiData.hasData(newsfeedEntries) &&
      newsfeedEntries.data.filter(nfEntry => nfEntry.feed.type['.tag'] === 'share').length === 0 ? (
        <Alert variant="warn">
          <Alert.Heading>Inbox is for sharing and discussing stories with friends</Alert.Heading>
          Tap <SendToIcon size="1rem" /> on a story on your front page to get started.
        </Alert>
      ) : null}
      {entriesOrderedBy !== null && !showNewsfeedOnboarding ? (
        <>
          <div
            className="tw-bg-canvas tw-text-primary tw-flex tw-justify-between tw-items-center tw-px-4 tw-pt-4 tw-pb-2"
            style={{
              backgroundColor:
                props.curFeed.kind === 'feed'
                  ? themeMode === 'light'
                    ? props.curFeed.feed.style2?.light?.body_bg_color
                    : themeMode === 'dark'
                      ? props.curFeed.feed.style2?.dark?.body_bg_color
                      : undefined
                  : undefined,
            }}
          >
            <div className="tw-flex tw-items-center">
              {props.curFeed.kind !== 'inbox' ? (
                <span
                  role="button"
                  className="tw-select-none tw-flex tw-items-center"
                  onClick={() =>
                    setOrderBy(entriesOrderedBy && entriesOrderedBy['.tag'] === 'score' ? 'time' : 'score')
                  }
                >
                  <SortIcon size="1rem" />
                  <span className="tw-text-[0.9rem]">{entriesOrderedBy['.tag'] === 'score' ? 'Top' : 'Latest'}</span>
                </span>
              ) : null}
              {props.curFeed.kind !== 'newsfeed' && props.curFeed.kind !== 'inbox' ? (
                <span
                  role="button"
                  className="tw-ml-4"
                  title={showArchived ? 'Showing archived' : 'Hiding archived'}
                  onClick={toggleShowArchived}
                >
                  <ArchiveIcon size="1rem" offsetUp />
                  <span className="tw-ml-1 tw-text-[0.9rem]">{showArchived ? 'Showing' : 'Hidden'}</span>
                </span>
              ) : null}
            </div>
            <div className="tw-flex tw-items-center tw-gap-x-2">
              {agentMode && props.curFeed.kind === 'newsfeed' ? (
                <div
                  role="button"
                  className={clsx('tw-leading-none tw-mr-4', newsfeedDefaultAgentOverride ? 'tw-text-link-blue' : null)}
                  onClick={toggleDefaultNewsfeed}
                >
                  Default
                </div>
              ) : null}
              {resultTs && (Date.now() - resultTs) / 1000 > 3599 ? (
                <Button sm variant="secondary" onClick={() => refresh()}>
                  Refresh • {cfe.Formatter.getTsDistance(new Date(resultTs))} old
                </Button>
              ) : null}
              {agentMode && props.curFeed.kind !== 'inbox' ? (
                <div
                  role="button"
                  className={clsx(
                    'tw-select-none tw-flex tw-items-center tw-py-1 tw-px-2 tw-rounded',
                    compact ? 'tw-bg-purple-300 dark:tw-bg-purple-700' : null,
                  )}
                  title="Headlines mode"
                  onClick={toggleCompact}
                >
                  <CompactIcon size="1.3rem" className="tw-mr-1" />
                  <span className="tw-hidden sm:tw-inline tw-text-[0.9rem]">Headlines</span>
                </div>
              ) : null}
              <div
                role="button"
                className={clsx(
                  'tw-select-none tw-flex tw-items-center tw-py-1 tw-px-2 video-filter-option tw-rounded',
                  filterVideosOnly ? 'tw-bg-purple-300 dark:tw-bg-purple-700' : null,
                )}
                title="Videos only"
                onClick={toggleFilterVideosOnly}
              >
                <VideoIcon size="1.3rem" className="tw-mr-1" />
                <span className="tw-hidden sm:tw-inline tw-text-[0.9rem]">Videos</span>
              </div>
            </div>
          </div>
          <StoryDivider compact={compact} />
        </>
      ) : null}
      <AnimatePresence>
        {showRefreshButton ? (
          <FeedRefreshButton
            refresh={() => refresh(true)}
            dismiss={() => setShowRefreshButton(false)}
            showKeyboardShortcut={keyboardControlsActive}
          />
        ) : null}
      </AnimatePresence>
      {showEgoFeedOnboarding ? (
        <GettingStartedListItem />
      ) : showNewsfeedOnboarding ? (
        <NewsfeedOnboarding />
      ) : (
        <div
          // Padding prevents outline from being cut off
          className="tw-flex tw-flex-col tw-px-[2px]"
        >
          {(props.curFeed.kind === 'newsfeed' || props.curFeed.kind === 'inbox') &&
          cfe.ApiData.hasData(newsfeedBlobs) &&
          newsfeedBlobs.data.length > 0
            ? newsfeedBlobs.data.map((blob, index) => {
                if (blob.kind === 'story') {
                  const nfEntry = blob.story;
                  return (
                    <React.Fragment key={nfEntry.entry.entry_id}>
                      <FeedEntryListItem
                        accountInfo={accountInfo}
                        entry={nfEntry.entry}
                        feed={nfEntry.feed}
                        selected={keyboardControlsActive && kbBlobIndex === index}
                        primaryKbOnly={keyboardControlsActive && kbBlobIndex === index && isExploreOrCpcOpen}
                        inNewsfeed
                        compact={compact}
                        takeKbCursor={() => {
                          dispatch(MainActionCreators.setFeedKbCursor(curExtendedFeedId, { kind: 'feed', pos: index }));
                        }}
                        goToFeed={navToFeed}
                        apiClient={apiClient}
                        agentMode={agentMode}
                        topScore={topScore}
                        rewind={nfEntry.rewind}
                        hideBlurb
                        hideImage={nfEntry.feed.display.mode['.tag'] === 'headlines'}
                        newsfeedInclusionReason={nfEntry.reason}
                      />
                      <StoryDivider compact={compact} xl={props.curFeed.kind === 'inbox'} />
                    </React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment key={blob.feedId}>
                      {/* This divider doubles/complements the divider above it
                      from the preceding entry to give a thicker appearance. */}
                      <StoryDivider compact={compact} />
                      <FeedEntryHorizontalList
                        apiClient={apiClient}
                        accountInfo={accountInfo}
                        feedId={blob.feedId}
                        selected={keyboardControlsActive && kbBlobIndex === index}
                        compact={compact}
                        showArchived={showArchived}
                        filterVideosOnly={filterVideosOnly}
                        startEntryId={blob.startEntryId}
                        subtitle={blob.subtitle}
                        headerClass="tw-pl-6"
                        showFollowButton={false}
                        agentMode={agentMode}
                        unmountSelf={() => unmountFeedEmbed(blob.feedId)}
                      />
                      {/* The transparent scrollbar track adds a natural divider */}
                    </React.Fragment>
                  );
                }
              })
            : props.curFeed.kind === 'feed' && cfe.ApiData.hasData(newsfeedBlobs) && newsfeedBlobs.data.length > 0
              ? newsfeedBlobs.data.map((blob, index) => {
                  if (props.curFeed.kind !== 'feed') {
                    throw Error('Unexpected');
                  }
                  if (blob.kind !== 'story') {
                    throw Error('Unexpected');
                  }
                  const nfEntry = blob.story;
                  return (
                    <React.Fragment key={nfEntry.entry.entry_id}>
                      <FeedEntryListItem
                        accountInfo={accountInfo}
                        entry={nfEntry.entry}
                        feed={props.curFeed.feed}
                        selected={keyboardControlsActive && kbBlobIndex === index}
                        primaryKbOnly={keyboardControlsActive && kbBlobIndex === index && isExploreOrCpcOpen}
                        inNewsfeed={false}
                        compact={compact}
                        bgColor={
                          themeMode === 'light'
                            ? props.curFeed.feed.style2?.light?.body_bg_color
                            : themeMode === 'dark'
                              ? props.curFeed.feed.style2?.dark?.body_bg_color
                              : undefined
                        }
                        takeKbCursor={() => {
                          dispatch(MainActionCreators.setFeedKbCursor(curExtendedFeedId, { kind: 'feed', pos: index }));
                        }}
                        goToFeed={navToFeed}
                        apiClient={apiClient}
                        agentMode={agentMode}
                        topScore={topScore}
                        hideBlurb
                        hideImage={props.curFeed.feed.display.mode['.tag'] === 'headlines'}
                      />
                      <StoryDivider compact={compact} xl={props.curFeed.feed.type['.tag'] === 'ego'} />
                    </React.Fragment>
                  );
                })
              : null}
          {disableEntryIterNext ? (
            <div className="tw-flex tw-justify-center tw-py-4 tw-text-primary tw-text-sm">
              Too few videos to keep searching
            </div>
          ) : hasMore ? (
            <MoreItem fetchMoreEntries={next} />
          ) : null}
          <Spinner.Presence>
            {cfe.ApiData.isLoading(newsfeedEntries) ? (
              <div className="tw-flex tw-justify-center tw-py-4 tw-text-primary">
                <Spinner show={true} lg />
              </div>
            ) : cfe.ApiData.getData(newsfeedEntries)?.length === 0 ? (
              <div className="tw-flex tw-justify-center tw-py-4 tw-text-primary tw-text-sm">Nothing yet.</div>
            ) : !hasMore && !cfe.ApiData.isError(newsfeedEntries) ? (
              <div className="tw-flex tw-justify-center tw-py-4 tw-text-primary tw-text-sm">That's it.</div>
            ) : null}
            {cfe.ApiData.isError(newsfeedEntries) ? (
              <div className="tw-flex tw-flex-col tw-items-center tw-py-4 tw-text-primary tw-text-sm">
                <div className="tw-mb-4">&#9785; Problem loading </div>
                <TryAgainButton onClick={refresh} />
              </div>
            ) : null}
          </Spinner.Presence>
        </div>
      )}
    </Pagelet>
  );
};

const PageGutter = (props: { className?: string; style?: React.CSSProperties; children: React.ReactNode }) => (
  <div className={clsx('tw-px-4', props.className)} style={props.style}>
    {props.children}
  </div>
);

const AgentUserCalendarWrapper = (props: { apiClient: api.SuperegoClient; userId: string }) => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <span role="button" className="tw-text-sm tw-link" onClick={() => setShow(!show)}>
        {show ? 'Hide' : 'Show'} calendar
      </span>
      {show ? <AgentUserCalendar apiClient={props.apiClient} userId={props.userId} /> : null}
    </div>
  );
};

const NotifBellButton = (props: { apiClient: api.SuperegoClient; feed: api.feed.IFeedInfo }) => {
  const [inFlight, setInFlight] = useState(false);
  const {
    result: notifPolicyGet,
    override: overrideNotifyPolicyGet,
    refresh: refreshNotifyPolicyGet,
  } = cfe.ApiHook.useApiRead(
    props.apiClient,
    props.apiClient.feedNotificationPolicyGet,
    {
      feed_id: props.feed.feed_id,
    },
    res => res,
  );
  useEffect(() => {
    // Following a feed may change the server-side notif policy (e.g. following
    // someone turns notifs on automatically) so a refresh is necessary.
    // NOTE: If there isn't already policy data, assume that this is a first
    // load and that useApiRead() is handling the initial fetch.
    if (props.feed.for_viewer.following && cfe.ApiData.hasData(notifPolicyGet)) {
      refreshNotifyPolicyGet();
    }
  }, [props.feed.for_viewer.following]);
  const { apiDo: apiFeedNotificationPolicySet, okToast } = useApiDo(
    props.apiClient,
    props.apiClient.feedNotificationPolicySet,
  );
  if (!cfe.ApiData.hasData(notifPolicyGet)) {
    return null;
  }
  const currentlyNotifying = notifPolicyGet.data.notification_policy['.tag'] === 'notify';
  return (
    <Button
      sm
      outline={currentlyNotifying}
      title={currentlyNotifying ? 'Stop getting notifications' : 'Get notified on updates'}
      onClick={() => {
        const notify = !currentlyNotifying;
        setInFlight(true);
        apiFeedNotificationPolicySet(
          { feed_id: props.feed.feed_id, notify },
          {
            onFinally: () => setInFlight(false),
            onResult: () => {
              if (notify) {
                overrideNotifyPolicyGet({ notification_policy: { '.tag': 'notify' } });
              } else {
                overrideNotifyPolicyGet({ notification_policy: { '.tag': 'none' } });
              }
              okToast(
                (notify ? 'Notifications' : 'Stopped notifications') +
                  ' for ' +
                  cfe.ApiHelpers.getFeedShortName(props.feed),
                notify ? `You'll be notified of new posts on your preferred device.` : undefined,
              );
            },
          },
        );
      }}
    >
      &nbsp;
      <Spinner.Presence>
        {inFlight ? (
          <Spinner color={!currentlyNotifying ? 'light' : undefined} />
        ) : currentlyNotifying ? (
          <NotifSlashedIcon size="1rem" />
        ) : (
          <NotifIcon size="1rem" />
        )}
      </Spinner.Presence>
      &nbsp;
    </Button>
  );
};

const MoreItem = (props: { fetchMoreEntries: () => void }) => {
  const itemRef = useRef(null);
  const disabled = useRef(false);
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !disabled.current) {
        // Disable the observer to prevent thrashing if fetchMoreEntries()
        // fails.
        disabled.current = true;
        props.fetchMoreEntries();
      } else {
        disabled.current = false;
      }
    });
    if (!itemRef.current) {
      return;
    }
    intersectionObserver.observe(itemRef.current);
    return () => {
      if (!itemRef.current) {
        return;
      }
      intersectionObserver.unobserve(itemRef.current);
    };
  }, [props.fetchMoreEntries]);
  return (
    <div
      ref={itemRef}
      role="button"
      className="tw-flex tw-justify-center tw-py-4 tw-text-primary tw-text-sm"
      onClick={props.fetchMoreEntries}
    >
      More
    </div>
  );
};

export default FeedPage;

const HashtagFeedInsert = (props: { apiClient: api.SuperegoClient; feed: api.feed.IFeedInfo }) => {
  if (props.feed.type['.tag'] !== 'hashtag') {
    throw Error('Unexpected');
  }
  const { pushModal } = useModalManager();
  const { result: wikiUrl } = cfe.ApiHook.useApiRead(
    props.apiClient,
    props.apiClient.topicGetWikiUrl,
    {
      feed_id: props.feed.feed_id,
    },
    res => res,
  );

  return (
    <div className="tw-text-primary">
      #{props.feed.url_name}
      {cfe.ApiData.hasData(wikiUrl) && wikiUrl.data.wiki_url ? (
        <a href={wikiUrl.data.wiki_url} className="tw-ml-4" target="_blank">
          Wiki
        </a>
      ) : null}
      {props.feed.prism_id || props.feed.audience_id ? (
        <span
          role="button"
          className="tw-ml-4 tw-link"
          onClick={() => {
            pushModal({
              feedId: props.feed.feed_id,
              kind: 'related-feeds',
            });
          }}
        >
          Related
        </span>
      ) : null}
    </div>
  );
};

const RssFeedInsert = (props: { apiClient: api.SuperegoClient; feed: api.feed.IFeedInfo }) => {
  if (props.feed.type['.tag'] !== 'rss') {
    throw Error('Unexpected');
  }
  const [showDetails, setShowDetails] = useState(false);
  useEffect(() => {
    setShowDetails(false);
  }, [props.feed.feed_id]);
  const { result: rssInfo } = cfe.ApiHook.useApiRead(
    props.apiClient,
    props.apiClient.rssGetRssInfo,
    {
      feed_id: props.feed.feed_id,
    },
    res => res,
    !showDetails,
  );
  return (
    <div className="tw-text-primary">
      Source
      {showDetails && cfe.ApiData.hasData(rssInfo) ? (
        <span>
          <a href={rssInfo.data.rss_url} className="tw-ml-2" target="_blank">
            Link
          </a>
          {rssInfo.data.homepage_url ? (
            <a href={rssInfo.data.homepage_url} className="tw-ml-2" target="_blank">
              Home
            </a>
          ) : null}
        </span>
      ) : (
        <span role="button" className="tw-link tw-ml-2" onClick={() => setShowDetails(true)}>
          {props.feed.primary_domain === 'www.youtube.com' || props.feed.primary_domain === 'youtube.com'
            ? 'YouTube'
            : props.feed.primary_domain?.endsWith('.substack.com')
              ? 'Substack'
              : props.feed.primary_domain === 'www.reddit.com' || props.feed.primary_domain === 'reddit.com'
                ? 'Reddit'
                : 'RSS'}
        </span>
      )}
    </div>
  );
};

const ShareFeedInsert = (props: { apiClient: api.SuperegoClient; feed: api.feed.IFeedInfo }) => {
  if (props.feed.type['.tag'] !== 'share' || !props.feed.members) {
    throw Error('Unexpected');
  }
  const apiClient = useApiClient();
  const { navToFeed } = useNav();
  return (
    <div className="tw-flex tw-flex-wrap tw-gap-3 tw-mt-1">
      {props.feed.members.map(user => {
        return (
          <SmartFeedLink
            key={user.user_id}
            goToFeed={navToFeed}
            apiClient={apiClient}
            feedRef={`u/${user.user_id}`}
            displayHref={'/' + user.username}
            className={clsx(
              'tw-flex tw-items-center',
              'tw-bg-gray-200 hover:tw-bg-gray-300 dark:tw-bg-gray-800 dark:hover:tw-bg-gray-700',
              'tw-text-primary hover:tw-text-primary hover:tw-no-underline ',
              'tw-rounded-2xl tw-pr-4 tw-h-10',
              user.profile_image ? 'tw-pl-1' : 'tw-pl-4',
            )}
          >
            {user.profile_image ? <UserFeedProfileImage user={user} sm className="tw-mr-1.5" /> : null}
            <span>{user.name}</span>
          </SmartFeedLink>
        );
      })}
    </div>
  );
};

const UserExtendedAgentInfo = (props: { apiClient: api.SuperegoClient; userId: string }) => {
  const [showMore, setShowMore] = useState(false);
  const { result } = cfe.ApiHook.useApiRead(
    props.apiClient,
    props.apiClient.userGetUserInfoForAgent,
    {
      user_id: props.userId,
    },
    res => res,
  );
  if (cfe.ApiData.hasData(result)) {
    return (
      <div className="tw-text-primary">
        mobile device registered: {result.data.has_registered_mobile_device ? 'yes' : 'no'} | joined:{' '}
        <DateLine ts={result.data.joined_at} />
        {!showMore && result.data.sign_up_info ? (
          <span role="button" className="tw-link tw-ml-2" onClick={() => setShowMore(true)}>
            more
          </span>
        ) : showMore && result.data.sign_up_info ? (
          <div>
            <span className="tw-font-bold">Sign-up info</span>
            {result.data.sign_up_info.ip_geo_loc ? (
              <>
                <span className="tw-ml-1">city: {result.data.sign_up_info.ip_geo_loc?.city}</span>
                <span className="tw-ml-1">region: {result.data.sign_up_info.ip_geo_loc?.region}</span>
                <span className="tw-ml-1">country: {result.data.sign_up_info.ip_geo_loc?.country}</span>
                <span className="tw-ml-1">hosting_ip: {result.data.sign_up_info.ip_geo_loc?.hosting_ip}</span>
                <span className="tw-ml-1">mobile_ip: {result.data.sign_up_info.ip_geo_loc?.mobile_ip}</span>
                <span className="tw-ml-1">proxy_ip: {result.data.sign_up_info.ip_geo_loc?.proxy_ip}</span>
              </>
            ) : null}
            {result.data.sign_up_info.user_agent ? <div>user-agent: {result.data.sign_up_info.user_agent}</div> : null}
          </div>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};

const NewsfeedOnboarding = () => {
  return (
    <div className="tw-px-6 tw-mt-4">
      <Pagelet.Heading1>It's too quiet...</Pagelet.Heading1>
      <p>
        Restart your <Link to="/onboarding">onboarding</Link> to find great content.
      </p>
    </div>
  );
};

const GettingStartedListItem = () => (
  <div className="tw-px-6 tw-mt-4">
    <Alert variant="warn">
      <Alert.Heading>Getting Started</Alert.Heading>
      <p>
        This is <span className="tw-underline">your</span> ego feed. Other people can follow this.
      </p>
      <p>
        Add stories by <b>giving ego</b> to your favorite works with the <EgoSlice size={20} /> button above.
      </p>
    </Alert>
  </div>
);

const OnboardedAuxbox = React.memo(() => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(window.location.pathname + removeSearchParameter(window.location.search, 'onboarded'), { replace: true });
  }, []);
  return (
    <div className="tw-p-4 tw-bg-highlight tw-rounded-lg tw-text-primary">
      <AuxbarHeading>Welcome!</AuxbarHeading>
      <div className="tw-text-sm">
        <p>
          For the best experience, get our <MobileIcon size="0.9rem" offsetUp /> app:
        </p>
        <MobileStoreOptions />
        <AuxbarHeading className="tw-mt-6">Subscribe</AuxbarHeading>
        <p>
          {/* Intentionally using <a> instead of <Link>. See SubscriptionPage. */}
          Superego is <b>always free</b>. You only need a subscription <b>to ego</b> <EgoSlice size={15} />.{' '}
          <a href="/subscription">Learn more</a>
        </p>
        <AuxbarHeading className="tw-mt-6">Are you an author?</AuxbarHeading>
        <p>
          Go to the <Link to="/author">Author Dashboard</Link> to collect your ego.
        </p>
      </div>
    </div>
  );
});

const NewStoriesFromAuxbox = React.memo(
  (props: {
    feedEntryPairs: api.feed.IFeedEntryPair[];
    filterVideosOnly: boolean;
    navToFeed: (feed: api.feed.IFeedInfo) => void;
    apiClient: api.SuperegoClient;
    kbActive: boolean;
    kbNextSection: () => void;
  }) => {
    const entryIds = props.feedEntryPairs.map(feedEntryPair => feedEntryPair.entry.entry_id);
    const feedEntryStandaloneMap = useFeedEntryStandaloneMap(entryIds);
    const [kbPos, setKbPos] = useState(0);
    useKeyPress(
      'k',
      () => {
        // k: Move to prev feed.
        if (kbPos - 1 >= 0) {
          setKbPos(kbPos - 1);
        } else {
          props.kbNextSection();
        }
      },
      !props.kbActive,
    );
    useKeyPress(
      'j',
      () => {
        // j: Move to next feed.
        if (kbPos + 1 < props.feedEntryPairs.length) {
          setKbPos(kbPos + 1);
        } else {
          props.kbNextSection();
        }
      },
      !props.kbActive,
    );
    useKeyPress(
      'o',
      () => {
        // o: Open feed.
        if (kbPos < props.feedEntryPairs.length) {
          props.navToFeed(props.feedEntryPairs[kbPos].feed);
        }
      },
      !props.kbActive,
    );
    useKeyPress(
      'h',
      () => {
        // h: Forfeit KB control
        props.kbNextSection();
      },
      !props.kbActive,
    );
    useEffect(() => {
      if (props.kbActive && props.feedEntryPairs.length === 0) {
        props.kbNextSection();
      } else if (kbPos > props.feedEntryPairs.length) {
        setKbPos(Math.max(props.feedEntryPairs.length - 1, 0));
      }
    }, [props.feedEntryPairs]);

    const updatedFeedEntryPairs = props.feedEntryPairs.map(
      feedEntryPair =>
        // Prioritize pair from state (b/c it may be updated), fallback to response.
        feedEntryStandaloneMap.get(feedEntryPair.entry.entry_id) ?? feedEntryPair,
    );
    const feedEntryPairsToShow = updatedFeedEntryPairs.filter(
      // While the API will only return entries with `last_visit` unset, the
      // local state may have it set due to a user interaction.
      feedEntryPair =>
        !feedEntryPair.entry.for_viewer.last_visit &&
        (!props.filterVideosOnly ||
          (feedEntryPair.entry.md['.tag'] === 'ready' && feedEntryPair.entry.md.content_type['.tag'] === 'video')),
    );
    if (feedEntryPairsToShow.length === 0) {
      return null;
    }
    return (
      <div className="tw-text-primary tw-bg-primary tw-shadow-sm tw-rounded-lg tw-grow tw-py-3">
        <div className="tw-px-4">
          <Pagelet.Heading3 className="tw-pt-0">New stories from</Pagelet.Heading3>
        </div>
        <ListGroup>
          {feedEntryPairsToShow.map((feedEntryPair, index) => (
            <SmartFeedLink
              key={feedEntryPair.feed.feed_id}
              goToFeed={props.navToFeed}
              apiClient={props.apiClient}
              feedRef={feedEntryPair.feed.feed_id}
              className="tw-text-primary tw-font-normal hover:tw-text-primary hover:tw-no-underline"
            >
              <ListGroup.Item
                kbActive={props.kbActive && index === kbPos}
                mutedHover
                icon={feedEntryPair.feed.profile_image ? <FeedProfileImage feed={feedEntryPair.feed} sm /> : undefined}
                className="!tw-px-4 tw-items-center"
              >
                <span className="tw-text-sm">{cfe.ApiHelpers.getFeedShortName(feedEntryPair.feed)}</span>
              </ListGroup.Item>
            </SmartFeedLink>
          ))}
        </ListGroup>
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.apiClient === nextProps.apiClient &&
    prevProps.feedEntryPairs === nextProps.feedEntryPairs &&
    prevProps.filterVideosOnly === nextProps.filterVideosOnly &&
    prevProps.kbActive === nextProps.kbActive &&
    prevProps.kbNextSection === nextProps.kbNextSection &&
    prevProps.navToFeed === nextProps.navToFeed,
);

const LoggedOutWelcomeAuxbox = React.memo(() => (
  <div className="tw-p-4 tw-bg-highlight tw-rounded-lg tw-text-primary">
    <AuxbarHeading>Join&mdash;it's free!</AuxbarHeading>
    <p className="tw-text-sm">Superego is best on mobile.</p>
    <ButtonLink block to="/get-app">
      <span>
        Get <MobileIcon size="1.1rem" offsetUp /> App
      </span>
    </ButtonLink>

    <LayoutLine className="!tw-my-6" />

    <AuxbarHeading className="tw-mt-3">Stuff worth knowing</AuxbarHeading>
    <div className="tw-flex tw-items-start tw-mb-4">
      <div>
        <EgoSlice size={20} />
      </div>
      <div className="tw-ml-3 tw-text-sm tw-self-center">
        Superego ranks your frontpage by <span className="tw-font-semibold">ego</span>&mdash;$ tips given to authors for
        great work.
      </div>
    </div>

    <AuxbarHeading className="tw-mt-2">Engage your brain</AuxbarHeading>
    <div className="tw-flex tw-items-start tw-mb-4">
      <div>
        <ExploreIcon size="1.2rem" />
      </div>
      <div className="tw-ml-4 tw-text-sm tw-self-center">
        For every story, get the full picture with discussions, Q&A, sources, and more.
      </div>
    </div>

    <AuxbarHeading className="tw-mt-2">Share with friends</AuxbarHeading>
    <div className="tw-flex tw-items-start tw-mb-4">
      <div>
        <SendToIcon size="1.2rem" />
      </div>
      <div className="tw-ml-4 tw-text-sm tw-self-center">
        Use your inbox as a space to meaningfully discuss stories.
      </div>
    </div>

    <AuxbarHeading className="tw-mt-2">Follow anything</AuxbarHeading>
    <div className="tw-flex tw-items-start tw-mb-4">
      <div>
        <DiscoverIcon size="1.2rem" />
      </div>
      <div className="tw-ml-4 tw-text-sm tw-self-center">
        Follow topics, fellow egoists, and feeds: YouTubers, blogs, RSS, and news outlets.
      </div>
    </div>

    <AuxbarHeading className="tw-mt-2">Learn More</AuxbarHeading>
    <ul className="tw-pl-4">
      <li>
        <Link to={{ pathname: '/e/jqJ8zZOFaTqCUVD2QgPaKKDEJJmUmiN7/cpc' }}>
          <span className="tw-link tw-text-sm">Why build Superego?</span>
        </Link>
      </li>
      <li>
        <Link to={{ pathname: '/e/GVllimoK7zJkH-UeCnzf7oBmEYRY-oca' }}>
          <span className="tw-link tw-text-sm">How to use the mobile app</span>
        </Link>
      </li>
    </ul>
    <div className="tw-flex tw-justify-center tw-my-2">
      <Link to={{ pathname: '/e/GVllimoK7zJkH-UeCnzf7oBmEYRY-oca' }}>
        <m.div whileHover="hover" className="tw-relative">
          <img
            src={Config.globalStaticHost + 'pitch/img/iphone_feed.png'}
            className="tw-mr-4 tw-border tw-border-solid tw-border-layout-line-light dark:tw-border-layout-line-dark tw-shadow-md tw-h-[280px]"
          />
          <m.div variants={{ hover: { scale: 1.1 } }} className="tw-absolute tw-top-[35%] tw-left-[25px]">
            <PlayIcon
              size="5rem"
              className={clsx('tw-text-[5rem] tw-opacity-90 tw-text-perpul-light hover:tw-opacity-100')}
            />
          </m.div>
        </m.div>
      </Link>
    </div>
  </div>
));
