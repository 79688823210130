import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';

import * as store from '../state/store';

const SmartFeedLink = (props: {
  goToFeed: (feed: api.feed.IFeedInfo) => void;
  apiClient: api.SuperegoClient;
  feedRef: string; // Can be a feed ID or a hashtag (# prefix)
  className?: string;
  displayHref?: string;
  children?: React.ReactNode;
}) => {
  // Undefined if feedRef is not a feed ID
  const feedFromRef = useSelector<store.IAppState, api.feed.IFeedInfo | undefined>(state =>
    state.feeds.get(props.feedRef),
  );
  const hashtag = props.feedRef.startsWith('#')
    ? props.feedRef.substr(1)
    : props.feedRef.startsWith('t/')
      ? props.feedRef.substr(2)
      : null;
  const isUserEgoFeed = props.feedRef.startsWith('u/');
  const feed = hashtag || isUserEgoFeed ? undefined : feedFromRef;
  if (isUserEgoFeed && !props.displayHref) {
    throw Error('Expected displayHref to be specified.');
  }
  const relUrl = feed ? cfe.ApiHelpers.getUrlPathnameForFeed(feed) : hashtag ? `/t/${hashtag}` : `/f/${props.feedRef}`;
  return (
    <a
      href={props.displayHref ?? relUrl}
      className={props.className}
      onClick={e => {
        // FIXME: Match ctrl key with non-mac & meta key with mac.
        if (e.ctrlKey || e.metaKey) {
          e.stopPropagation();
          if (relUrl.startsWith('/f/')) {
            // /f/ URLs can't be handled by a new page load so convert them to
            // a proper URL first.
            e.preventDefault();
            props.apiClient.feedGet({ feed_id: props.feedRef }).then(resp => {
              if (resp.kind === api.StatusCode.Ok) {
                window.open(cfe.ApiHelpers.getUrlPathnameForFeed(resp.result), '_blank');
              }
            });
          }
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        if (feed) {
          props.goToFeed(feed);
        } else {
          props.apiClient.feedGet({ feed_id: props.feedRef }).then(resp => {
            if (resp.kind === api.StatusCode.Ok) {
              props.goToFeed(resp.result);
            } else if (resp.kind === api.StatusCode.Error) {
              if (resp.error['.tag'] === 'not_found') {
                window.location.href = relUrl;
              }
            }
          });
        }
      }}
    >
      {props.children}
    </a>
  );
};

export const FeedLink = (props: {
  navToFeed: (feed: api.feed.IFeedInfo) => void;
  feed: api.feed.IFeedInfo;
  className?: string;
  children?: React.ReactNode;
  forceNewTab?: boolean;
  onClickAfter?: () => void;
  style?: CSSProperties;
}) => {
  const href = cfe.ApiHelpers.getUrlPathnameForFeed(props.feed);
  return (
    <a
      href={href}
      className={props.className}
      style={props.style}
      onClick={e => {
        e.stopPropagation();
        if (e.ctrlKey || e.metaKey) {
          return;
        }
        if (!props.forceNewTab) {
          e.preventDefault();
          props.navToFeed(props.feed);
          if (props.onClickAfter) {
            props.onClickAfter();
          }
        }
      }}
      target={props.forceNewTab ? '_blank' : undefined}
    >
      {props.children ?? cfe.ApiHelpers.getFeedShortName(props.feed)}
    </a>
  );
};

export const useSmartGoToFeed = (goToFeed: (feed: api.feed.IFeedInfo) => void, apiClient: api.SuperegoClient) => {
  const feedsMap = useSelector<store.IAppState, Map<string, api.feed.IFeedInfo>>(state => state.feeds);
  return (feedRef: string) => {
    const isHashtagFeed = feedRef.startsWith('#') || feedRef.startsWith('t/');
    const isUserEgoFeed = feedRef.startsWith('u/');
    const feed = isHashtagFeed || isUserEgoFeed ? undefined : feedsMap.get(feedRef);
    const relUrl = feed
      ? cfe.ApiHelpers.getUrlPathnameForFeed(feed)
      : isHashtagFeed
        ? `/t/${feedRef.substr(1)}`
        : `/f/${feedRef}`;
    if (feed) {
      goToFeed(feed);
    } else {
      apiClient.feedGet({ feed_id: feedRef }).then(resp => {
        if (resp.kind === api.StatusCode.Ok) {
          goToFeed(resp.result);
        } else if (resp.kind === api.StatusCode.Error) {
          if (resp.error['.tag'] === 'not_found') {
            window.location.href = relUrl;
          }
        }
      });
    }
  };
};

export default SmartFeedLink;
