import { useSelector } from 'react-redux';

import * as store from '../../state/store';

const useLoggedInUserId = (): string | null =>
  useSelector<store.IAppState, string | null>(state => {
    if (!state.loginInfo) {
      throw Error('Expected loginInfo to be set');
    }
    if (state.loginInfo.kind === 'authed' || state.loginInfo.kind === 'assumed') {
      return state.loginInfo.userId;
    } else if (state.loginInfo.kind === 'noauth') {
      return null;
    } else {
      throw Error(`Unexpected loginInfo: ${state.loginInfo}`);
    }
  });

export default useLoggedInUserId;
