import clsx from 'clsx';
import React from 'react';

import { Link } from 'react-router-dom';

const Alert = (props: React.ComponentProps<'div'> & { variant: 'danger' | 'warn' | 'info' }) => (
  <div
    {...props}
    className={clsx(
      'tw-text-[90%] tw-text-primary tw-rounded-sm tw-px-6 tw-py-3',
      props.variant === 'danger'
        ? 'tw-bg-red-400 dark:tw-bg-red-800'
        : props.variant === 'warn'
          ? 'tw-bg-yellow-300 dark:tw-bg-yellow-700'
          : props.variant === 'info'
            ? 'tw-bg-sky-200 dark:tw-bg-sky-700'
            : null,
      props.className,
    )}
  >
    {props.children}
  </div>
);

export const AlertHeading = (props: { className?: string; children: React.ReactNode }) => (
  <div className={clsx('tw-text-base tw-font-bold', props.className)}>{props.children}</div>
);

Alert.Heading = AlertHeading;

export const AlertLink = (props: { className?: string; to: string; children: React.ReactNode }) => (
  <Link to={props.to} className="tw-text-inherit hover:tw-text-inherit tw-font-semibold">
    {props.children}
  </Link>
);

Alert.Link = AlertLink;

export default Alert;
