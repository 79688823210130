import * as api from 'ego-sdk-js';
import React from 'react';

import { useAuthedApiClient } from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import ListGroup from './lib/ListGroup';
import Modal from './lib/Modal';
import { GenericModalProps } from './ModalManagerContext';

import { useKeyPress } from './KeyPressContext';

const ThreadCommentMenuModal = (
  props: GenericModalProps & { commentId: string; deleteSuccessCb: (deletedComment: api.thread.IComment) => void },
) => {
  const { commentId, deleteSuccessCb, ...rest } = props;
  const apiClient = useAuthedApiClient();
  const { apiDo: apiThreadCommentDelete, okToast } = useApiDo(apiClient, apiClient.threadCommentDelete);
  useKeyPress(
    ['u', 'Escape'],
    () => {
      props.close();
    },
    undefined,
    10,
  );
  return (
    <Modal.Container show {...rest}>
      <Modal.Header>
        <Modal.Heading1>Comment Options</Modal.Heading1>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          <ListGroup.Item
            onClick={() => {
              apiThreadCommentDelete(
                { comment_id: props.commentId },
                {
                  onResult: res => {
                    props.deleteSuccessCb(res.comment);
                    okToast('Comment Deleted');
                    props.close();
                  },
                },
              );
            }}
          >
            Delete
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
    </Modal.Container>
  );
};

export default ThreadCommentMenuModal;
