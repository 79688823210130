import clsx from 'clsx';
import { m } from 'framer-motion';
import React from 'react';

import CloseIcon from './icon/CloseIcon';
import CmdInput from './lib/CmdInput';

const FeedRefreshButton = (props: { refresh: () => void; dismiss: () => void; showKeyboardShortcut: boolean }) => {
  return (
    <m.div
      // translateX is needed to center button by counteracting the left of the
      // button being aligned to 50% of the viewport.
      initial={{ translateX: '-50%', top: '-3rem', opacity: 0 }}
      animate={{ top: '3rem', opacity: 1 }}
      exit={{ top: '-3rem', opacity: 0 }}
      transition={{ duration: 0.3 }}
      role="button"
      title="Refresh stories"
      onClick={props.refresh}
      className={clsx(
        'tw-fixed tw-left-1/2 tw-z-20',
        // Vertical & right padding for button provided by close button.
        'tw-pl-3 tw-rounded-lg',
        'tw-bg-perpul-dark dark:tw-bg-perpul-light hover:tw-bg-perpul-light hover:dark:tw-bg-perpul-dark',
        'tw-text-light',
        'tw-flex tw-justify-between',
      )}
    >
      <div className="tw-flex tw-items-center tw-pl-6 tw-pr-2">
        <strong className="tw-mr-6 tw-text-lg">Refresh</strong>
      </div>
      <div className="tw-flex tw-items-center">
        {props.showKeyboardShortcut ? (
          // Assume smaller screens are mobile so keyboard shortcuts are irrelevant.
          <div className="tw-hidden md:tw-inline-block tw-mr-1">
            <CmdInput cmd="Shift + R" />
          </div>
        ) : null}
        <div
          role="button"
          className="tw-p-2"
          onClick={e => {
            e.stopPropagation();
            props.dismiss();
          }}
        >
          <CloseIcon size="1.5rem" />
        </div>
      </div>
    </m.div>
  );
};

export default FeedRefreshButton;
