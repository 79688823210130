"use strict";
//
// These data types make it easier to manage the state of data being fetched
// from the API.
//
Object.defineProperty(exports, "__esModule", { value: true });
exports.sort = exports.reduce = exports.map = exports.filter = exports.apply = exports.toError = exports.splice = exports.nextData = exports.initData = exports.toLoaded = exports.toLoading = exports.toUnknown = exports.isDone = exports.isError = exports.hasData = exports.getData = exports.isLoading = exports.UnknownValue = void 0;
// Exported to allow consumers to use an unknown datum with a stable identity
// which is important for not triggering re-renders.
exports.UnknownValue = { kind: 'unknown' };
function isLoading(d) {
    return d.kind === 'loading' || d.kind === 'loading-data';
}
exports.isLoading = isLoading;
function getData(d) {
    if (d.kind === 'loading-data' || d.kind === 'loaded' || d.kind === 'error-data') {
        return d.data;
    }
    else {
        return null;
    }
}
exports.getData = getData;
function hasData(d) {
    return d.kind === 'loading-data' || d.kind === 'loaded' || d.kind === 'error-data';
}
exports.hasData = hasData;
function isError(d) {
    return d.kind === 'error' || d.kind === 'error-data';
}
exports.isError = isError;
/**
 * Whether the data is in an error or loaded state.
 */
function isDone(d) {
    return d.kind === 'error' || d.kind === 'error-data' || d.kind === 'loaded';
}
exports.isDone = isDone;
function toUnknown() {
    return exports.UnknownValue;
}
exports.toUnknown = toUnknown;
function toLoading(d) {
    if (isLoading(d)) {
        throw Error(`Unexpected state: ${d.kind}`);
    }
    if (hasData(d)) {
        return { kind: 'loading-data', data: d.data };
    }
    else {
        return { kind: 'loading' };
    }
}
exports.toLoading = toLoading;
function toLoaded(d) {
    if (!(isLoading(d) && hasData(d))) {
        throw Error(`Unexpected state: ${d.kind}`);
    }
    return { kind: 'loaded', data: d.data };
}
exports.toLoaded = toLoaded;
function initData(d, init) {
    if (!(isLoading(d) && !hasData(d))) {
        throw Error(`Unexpected state: ${d.kind}`);
    }
    return { kind: 'loading-data', data: init };
}
exports.initData = initData;
function nextData(d, next, f) {
    if (!(isLoading(d) && hasData(d))) {
        throw Error(`Unexpected state: ${d.kind}`);
    }
    return { kind: 'loading-data', data: f(d.data, next) };
}
exports.nextData = nextData;
function splice(d, start, deleteCount, ...items) {
    if (hasData(d)) {
        const newData = d.data.slice(0);
        newData.splice(start, deleteCount, ...items);
        return {
            kind: d.kind,
            data: newData,
        };
    }
    else {
        return d;
    }
}
exports.splice = splice;
function toError(d) {
    if (!isLoading(d)) {
        throw Error(`Unexpected state: ${d.kind}`);
    }
    if (hasData(d)) {
        return { kind: 'loaded', data: d.data };
    }
    else {
        return { kind: 'error' };
    }
}
exports.toError = toError;
function apply(d, fn) {
    if (d.kind === 'error' || d.kind === 'loading' || d.kind === 'unknown') {
        return d;
    }
    else if (d.kind === 'loaded') {
        return {
            data: fn(d.data),
            kind: 'loaded',
        };
    }
    else if (d.kind === 'loading-data') {
        return {
            data: fn(d.data),
            kind: 'loading-data',
        };
    }
    else if (d.kind === 'error-data') {
        return {
            data: fn(d.data),
            kind: 'error-data',
        };
    }
    else {
        throw Error('Unexpected');
    }
}
exports.apply = apply;
function filter(d, f) {
    if (d.kind === 'error' || d.kind === 'loading' || d.kind === 'unknown') {
        return {
            kind: d.kind,
        };
    }
    else if (d.kind === 'loaded') {
        return {
            data: d.data.filter((e, index) => f(e, index)),
            kind: 'loaded',
        };
    }
    else if (d.kind === 'loading-data') {
        return {
            data: d.data.filter((e, index) => f(e, index)),
            kind: 'loading-data',
        };
    }
    else if (d.kind === 'error-data') {
        return {
            data: d.data.filter((e, index) => f(e, index)),
            kind: 'error-data',
        };
    }
    else {
        throw Error('Unexpected');
    }
}
exports.filter = filter;
function map(d, f) {
    if (d.kind === 'error' || d.kind === 'loading' || d.kind === 'unknown') {
        return {
            kind: d.kind,
        };
    }
    else if (d.kind === 'loaded') {
        return {
            data: d.data.map(e => f(e)),
            kind: 'loaded',
        };
    }
    else if (d.kind === 'loading-data') {
        return {
            data: d.data.map(e => f(e)),
            kind: 'loading-data',
        };
    }
    else if (d.kind === 'error-data') {
        return {
            data: d.data.map(e => f(e)),
            kind: 'error-data',
        };
    }
    else {
        throw Error('Unexpected');
    }
}
exports.map = map;
function reduce(d, init, f) {
    if (d.kind === 'error' || d.kind === 'loading' || d.kind === 'unknown') {
        return {
            kind: d.kind,
        };
    }
    else if (d.kind === 'loaded') {
        return {
            data: d.data.reduce(f, init),
            kind: 'loaded',
        };
    }
    else if (d.kind === 'loading-data') {
        return {
            data: d.data.reduce(f, init),
            kind: 'loading-data',
        };
    }
    else if (d.kind === 'error-data') {
        return {
            data: d.data.reduce(f, init),
            kind: 'error-data',
        };
    }
    else {
        throw Error('Unexpected');
    }
}
exports.reduce = reduce;
function sort(d, cmp) {
    if (d.kind === 'error' || d.kind === 'loading' || d.kind === 'unknown') {
        return {
            kind: d.kind,
        };
    }
    else if (d.kind === 'loaded') {
        return {
            data: [...d.data].sort(cmp),
            kind: 'loaded',
        };
    }
    else if (d.kind === 'loading-data') {
        return {
            data: [...d.data].sort(cmp),
            kind: 'loading-data',
        };
    }
    else if (d.kind === 'error-data') {
        return {
            data: [...d.data].sort(cmp),
            kind: 'error-data',
        };
    }
    else {
        throw Error('Unexpected');
    }
}
exports.sort = sort;
