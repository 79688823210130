import * as api from 'ego-sdk-js';
import * as React from 'react';
import { Action, Dispatch } from 'redux';

import { MainActionCreators } from './state/reducer';

/**
 * Converts a string with newline characters into a <div> with <p> tags for
 * each newline-delimited section.
 *
 * @param content The string with newlines to convert.
 */
export function stringToPtags(content: string, bottomMargin?: string): React.ReactNode {
  const contentChunks = content.split('\n');
  return (
    <div>
      {contentChunks.map((text, i) => (
        <p key={i} className={i === contentChunks.length - 1 ? 'tw-mb-0' : bottomMargin}>
          {text}
        </p>
      ))}
    </div>
  );
}

/**
 * Helper for automatically making toast messages to handle errors from API
 * responses.
 *
 * @param routeErrOk If another handler is handling route errors, set this to
 *     true so that this does not make toasts for them.
 */
export function mkToastFromBadStatusCode(
  dispatch: Dispatch<Action>,
  resp: api.Response<any, any>,
  routeErrOk: boolean = false,
) {
  let body;
  if (resp.kind === api.StatusCode.InternalError) {
    body = 'Try again later, sorry!';
  } else if (resp.kind === api.StatusCode.BadAuth) {
    body = 'You need to login again.';
  } else if (resp.kind === api.StatusCode.BadRequest || (resp.kind === api.StatusCode.Error && !routeErrOk)) {
    body = 'Our code is bad :( Try again later, sorry!';
  } else if (resp.kind === api.StatusCode.RateLimit) {
    body = "We're overloaded. Try again later, sorry!";
  } else {
    body = 'This is embarrassing...';
  }
  dispatch(MainActionCreators.addToast({ header: 'Something went wrong', body, icon: 'frown' }));
}

/**
 * Helper for automatically making toast messages for catch errors from fetch.
 * AFAIK, these errors are due to network connectivity issues.
 */
export function mkToastFromHttpError(dispatch: Dispatch<Action>) {
  dispatch(
    MainActionCreators.addToast({
      body: 'Check your Internet connection',
      header: 'Something went wrong',
      icon: 'frown',
    }),
  );
}
