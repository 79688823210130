import React from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';

import Spinner from './lib/Spinner';
import { useThemeMode } from './ThemeModeGate';

const TwitterTweet = React.memo(
  (props: {
    tweetId: string;
    center?: boolean;
    // Useful for prexo slides where we want the user to be able to vertically
    // swipe on tweets to advance the slide. Tweets often take up the majority
    // of a slide's real estate and without this flag, the user will be unable
    // to swipe since the tweet-iframe captures touch interactions.
    xsOverlayCaptureSwipe?: boolean;
    // Useful for prexo slides to minimize the vertical space taken to avoid
    // overflowing the slide.
    hideCards?: boolean;
  }) => {
    const themeMode = useThemeMode();
    return (
      // Target the css class injected by the twitter-embed 3rd party library.
      <div className="tw-relative">
        {props.xsOverlayCaptureSwipe ? (
          <div
            role="button"
            className="tw-block sm:tw-hidden tw-absolute tw-h-full tw-w-full"
            onClick={e => {
              e.stopPropagation();
              window.open(`https://x.com/x/status/${props.tweetId}`, '_blank');
            }}
          />
        ) : null}
        <TwitterTweetEmbed
          key={themeMode}
          options={{
            align: props.center ? 'center' : undefined,
            cards: props.hideCards ? 'hidden' : undefined,
            theme: themeMode,
            width: 360,
          }}
          tweetId={props.tweetId}
          placeholder={
            <div className="tw-flex tw-justify-center tw-my-4">
              <Spinner />
            </div>
          }
        />
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.tweetId === nextProps.tweetId &&
    prevProps.center === nextProps.center &&
    prevProps.xsOverlayCaptureSwipe === nextProps.xsOverlayCaptureSwipe &&
    prevProps.hideCards === nextProps.hideCards,
);

export default TwitterTweet;
