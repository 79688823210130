import React from 'react';

import SourceTraceIcon from './icon/SourceTraceIcon';
import Thread, { ThreadProps } from './Thread';

const ThreadTrace = (props: Omit<ThreadProps, 'headerOpts' | 'rootCommentOps'>) => {
  const help = React.useMemo(
    () => (
      <p>
        Suggest a{' '}
        <a href="https://www.crk.umn.edu/library/primary-secondary-and-tertiary-sources">primary or secondary source</a>{' '}
        that helps people fact-check and spin-check this story.
      </p>
    ),
    [],
  );
  const rootCommentOpts = React.useMemo(
    () => ({
      ezInput: 'link',
      help,
    }),
    [],
  ) as { ezInput: 'link'; help: React.ReactNode };
  const headerOpts = React.useMemo(
    () => ({
      addCommentTitle: 'Add Source',
      icon: <SourceTraceIcon size="1rem" />,
      title: 'Source Trace',
    }),
    [],
  );
  return <Thread {...props} key={props.threadId} headerOpts={headerOpts} rootCommentOpts={rootCommentOpts} />;
};

export default ThreadTrace;
