import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MainActionCreators } from '../state/reducer';
import * as store from '../state/store';

import useLocalStorage, { useLocalStorageSetter } from './hooks/useLocalStorage';

const LocalStorageReduxBridge = (props: { children: React.ReactNode }) => {
  const agentModeSetter = useAgentModeSetter();
  useLocalStorage<boolean>('agentMode', agentMode => {
    if (agentMode !== null) {
      agentModeSetter(agentMode);
    }
  });
  return <>{props.children}</>;
};

export const useAgentMode = () => {
  return useSelector<store.IAppState, boolean>(state => state.agentMode);
};

export const useAgentModeSetter = () => {
  const dispatch = useDispatch();
  const agentModeLocalStorageSetter = useLocalStorageSetter('agentMode');
  return (agentMode: boolean) => {
    dispatch(MainActionCreators.setAgentMode(agentMode));
    agentModeLocalStorageSetter(agentMode);
  };
};

export default LocalStorageReduxBridge;
