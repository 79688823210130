import React from 'react';

const MoreIcon = (props: { size: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height={`${props.size}px`} width={`${props.size}px`}>
    <path
      fill="currentColor"
      d="M342.6 246.6C362.6 226.5 348.5 192 320 192H128C99.64 192 85.28 226.5 105.4 246.6l95.95 96c12.49 12.5 32.86 12.5 45.35 0L342.6 246.6zM128 224h192l-96 96L128 224zM448 416V96c0-35.35-28.65-64-64-64H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320C419.3 480 448 451.3 448 416zM64 448c-17.64 0-32-14.36-32-32V96c0-17.64 14.36-32 32-32h320c17.64 0 32 14.36 32 32v320c0 17.64-14.36 32-32 32H64z"
    />
  </svg>
);

export default MoreIcon;
