import { AnimatePresence, m } from 'framer-motion';
import React from 'react';

const Fade = (props: { open: boolean; initialOpen?: boolean; children: React.ReactNode }) => (
  <m.div
    initial={props.initialOpen ? { display: 'block', opacity: 1 } : { display: 'none', opacity: 0 }}
    animate={
      props.open
        ? { display: 'block', opacity: 1 }
        : {
            opacity: 0,
            transitionEnd: {
              display: 'none',
            },
          }
    }
    exit={{ opacity: 0 }}
    transition={{ opacity: { duration: 0.15 } }}
    // Use inert so that the children of collapsed elements are not focusable
    // and find-in-page ignores the contents.
    // HACK from https://github.com/facebook/react/issues/17157
    // @ts-ignore
    inert={!props.open ? '' : undefined}
  >
    {props.children}
  </m.div>
);

export const FadePresence = (props: { children: React.ReactNode }) => (
  <AnimatePresence mode="popLayout">{props.children}</AnimatePresence>
);

Fade.Presence = FadePresence;

export default Fade;
