import React, { useEffect, useRef } from 'react';

import FeedSearchInput from './FeedSearchInput';
import { useKeyPress } from './KeyPressContext';
import Modal from './lib/Modal';

const SearchModal = (props: { closeModal: (resetStack?: boolean) => void; isForeground: boolean }) => {
  useKeyPress(
    ['u', 'Escape'],
    () => {
      props.closeModal();
    },
    !props.isForeground,
    10,
  );

  const searchRef = useRef<HTMLInputElement | null>(null);
  const modalRef = React.useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef.current]);

  return (
    <Modal.Container ref={modalRef} show={props.isForeground} close={props.closeModal} animate="slide-from-top">
      <Modal.Body>
        <div className="tw-mt-3" />
        <FeedSearchInput
          ref={searchRef}
          onClickAfter={() => {
            props.closeModal(true);
          }}
          kbActive={true}
          targetWindow={modalRef}
          showProfileImage="yes-if-available"
        />
        <div className="tw-mt-3" />
      </Modal.Body>
    </Modal.Container>
  );
};

export default SearchModal;
