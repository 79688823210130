import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { MainActionCreators } from '../../state/reducer';
import * as store from '../../state/store';

import useInboxUnread from './useInboxUnread';
import useUserMeInternal from './useUserMeInternal';

const useInboxUnreadReconciled = () => {
  const dispatch = useDispatch();
  const accountInfo = useUserMeInternal();
  if (!accountInfo) {
    // HACK: Assumes a user can't login without a refresh of the app state.
    return {
      unreadCountReconciled: null,
      unreadRefresh: () => null,
    };
  }
  const inboxEntries = useSelector<store.IAppState, cfe.ApiData.Data<api.newsfeed.IEntryIterResult[]>>(
    state => state.apiCache.inboxEntries.value,
  );
  const inboxEntriesMap = new Map<string, api.newsfeed.INewsfeedEntry>();
  if (cfe.ApiData.hasData(inboxEntries)) {
    for (const iterRes of inboxEntries.data) {
      for (const nfEntry of iterRes.entries) {
        inboxEntriesMap.set(nfEntry.entry.entry_id, nfEntry);
      }
    }
  }

  const { result: inboxUnread, refresh } = useInboxUnread();

  const maxIgnoreMissing = (a?: number, b?: number) =>
    a !== undefined && b !== undefined
      ? Math.max(a, b)
      : a === undefined && b === undefined
        ? undefined
        : a === undefined
          ? b
          : a;
  const maxForIso8601IgnoreMissing = (a?: string, b?: string) =>
    a !== undefined && b !== undefined
      ? a < b
        ? b
        : a
      : a === undefined && b === undefined
        ? undefined
        : a === undefined
          ? b
          : a;
  const aUnsetOrLessThanB = (a?: number, b?: number) =>
    a !== undefined && b !== undefined
      ? a < b
      : a === undefined && b === undefined
        ? false
        : a === undefined
          ? true
          : false;

  React.useEffect(() => {
    if (!inboxUnread) {
      return;
    }
    batch(() => {
      // Reconcile the server-side understanding of what's been seen with the
      // local understanding of what's been seen that may not have propagated to
      // the server yet.
      for (const unreadEntry of inboxUnread.unread_entries) {
        const inboxEntry = inboxEntriesMap.get(unreadEntry.entry_id);
        if (!inboxEntry) {
          continue;
        }
        if (!inboxEntry.entry.for_viewer.seen) {
          continue;
        }
        // NOTE: Don't compare `when` since the timestamp is inconsistent
        // between server & client.
        if (
          aUnsetOrLessThanB(inboxEntry.entry.for_viewer.seen.watermark, unreadEntry.seen_info.watermark) ||
          aUnsetOrLessThanB(inboxEntry.entry.for_viewer.seen.seen_watermark, unreadEntry.seen_info.seen_watermark)
        ) {
          const watermark = Math.max(inboxEntry.entry.for_viewer.seen.watermark, unreadEntry.seen_info.watermark);
          const seenWatermark = maxIgnoreMissing(
            inboxEntry.entry.for_viewer.seen.seen_watermark,
            unreadEntry.seen_info.seen_watermark,
          );
          const newSeenInfo: api.feed.ISeenInfo = {
            seen_watermark: seenWatermark,
            unseen: seenWatermark === undefined || seenWatermark < watermark,
            watermark,
            when: maxForIso8601IgnoreMissing(inboxEntry.entry.for_viewer.seen.when, unreadEntry.seen_info.when),
          };
          // FIXME: Performance: mutateFeedEntry() searches all entries every
          // time O(n^2).
          dispatch(
            MainActionCreators.mutateFeedEntry('newsfeed', inboxEntry.entry.entry_id, nfEntry => {
              if (nfEntry.for_viewer.seen) {
                // NOTE/HACK: Only modify entries that have `seen` set in order
                // to target ONLY entries in the inbox feed.
                nfEntry.for_viewer.seen = newSeenInfo;
              }
            }),
          );
        }
      }
    });
  }, [inboxUnread]);

  let unread: number | null = null;
  if (inboxUnread) {
    unread = inboxUnread.unread_entries.length;
    for (const unreadEntry of inboxUnread.unread_entries) {
      const inboxEntry = inboxEntriesMap.get(unreadEntry.entry_id);
      if (inboxEntry?.entry.for_viewer.seen?.unseen === false) {
        unread -= 1;
      }
    }
  }

  return {
    unreadCountReconciled: unread,
    unreadRefresh: refresh,
  };
};

export default useInboxUnreadReconciled;
