import clsx from 'clsx';
import React from 'react';
import { thumbHashToRGBA } from 'thumbhash';

import { base64ToByteArray } from '../util';

const FeedEntryLeftSwipeTriangles = (props: { count: number; compact?: boolean; th?: string }) => {
  const bgLuminance = React.useMemo(
    () => (props.th ? calculateAvgLuminance(base64ToByteArray(props.th)) : undefined),
    [props.th],
  );
  return (
    <div
      className={clsx(
        'tw-absolute tw-h-full tw-top-0 tw-right-0 tw-flex tw-flex-col tw-justify-center tw-z-10',
        props.compact ? 'tw-gap-y-1' : 'tw-gap-y-4',
      )}
    >
      {Array.from({ length: props.count }, (_, index) => (
        <LeftArrow key={index} bgLuminance={bgLuminance} />
      ))}
    </div>
  );
};

const LeftArrow = (props: { bgLuminance?: number }) => (
  <div
    className={clsx(
      'tw-w-0 tw-h-0',
      'tw-opacity-80',
      'tw-border-[6px]',
      'tw-border-l-0 tw-rounded-sm tw-border-y-transparent tw-border-y-transparent',
      props.bgLuminance
        ? props.bgLuminance > 128
          ? 'tw-border-r-zinc-800'
          : 'tw-border-r-zinc-100'
        : 'tw-border-r-zinc-500 dark:tw-border-r-zinc-400',
    )}
  ></div>
);

function calculateAvgLuminance(hash: ArrayLike<number>): number {
  // Two other methods were considered:
  // 1. Using first 6-bits of thumbhash which is the DC-component.
  // 2. Using the average of just the right-most pixel column.
  //
  // Based on the eyeball test, both of these methods did not seem to work
  // better despite their improvements in compute time.
  const { w, h, rgba } = thumbHashToRGBA(hash);
  let totalLuminance = 0;
  for (let i = 0; i < rgba.length; i += 4) {
    // Ignore alpha channel for luminance
    const r = rgba[i];
    const g = rgba[i + 1];
    const b = rgba[i + 2];
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    totalLuminance += luminance;
  }
  return totalLuminance / (w * h);
}

export default FeedEntryLeftSwipeTriangles;
