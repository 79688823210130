"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCpcPrexo = exports.contentToSlides = exports.SLIDE_MARKER = void 0;
exports.SLIDE_MARKER = '[//]: # (slide)\n';
function count(s, subString) {
    return s.split(subString).length - 1;
}
const contentToSlides = (content, contentImageSpecs, contentVideoSpecs, contentLinkSpecs) => {
    const slidesContent = content.split('[//]: # (slide)\n');
    return slidesContent
        .filter(slideContent => slideContent.trim().length > 0)
        .map(slideContent => ({
        content: slideContent.trim(),
        // It's easier just to duplicate all image & video specs for every slide
        contentImageSpecs,
        contentVideoSpecs,
        contentLinkSpecs,
        // HACK: Use a proper markdown parser to more accurately assess the media type.
        media_type: slideContent.indexOf('youtube.com') > 0 || slideContent.indexOf('ego://') > 0
            ? 'video'
            : slideContent.indexOf('//twitter.com') > 0 ||
                slideContent.indexOf('//www.twitter.com') > 0 ||
                slideContent.indexOf('//x.com') > 0
                ? 'tweet'
                : count(slideContent, '![') === 1
                    ? 'image'
                    : count(slideContent, '![') > 1
                        ? 'images'
                        : undefined,
    }));
};
exports.contentToSlides = contentToSlides;
const isCpcPrexo = (content) => content.indexOf(exports.SLIDE_MARKER) >= 0;
exports.isCpcPrexo = isCpcPrexo;
