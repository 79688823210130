import clsx from 'clsx';
import React from 'react';

interface BadgeProps extends React.ComponentProps<'span'> {
  variant: 'danger' | 'warn' | 'info' | 'success' | 'primary';
  sm?: boolean;
  xs?: boolean;
  wrapOk?: boolean;
}

const Badge = (props: BadgeProps) => {
  const { variant, sm, xs, wrapOk, ...rest } = props;
  return (
    <span
      {...rest}
      className={clsx(
        'tw-inline-flex tw-items-center',
        wrapOk ? null : 'tw-whitespace-nowrap',
        // NOTE: Since it's a <span> inherit the text size from the parent but
        // slightly reduce the size to make room for the padding on the same line.
        'tw-font-semibold tw-rounded',
        props.xs ? 'tw-text-xs' : props.sm ? 'tw-text-sm' : 'tw-text-[90%]',
        props.xs || props.sm ? 'tw-px-1' : 'tw-px-2',
        props.variant === 'danger'
          ? 'tw-bg-red-400 dark:tw-bg-red-700 tw-text-primary'
          : props.variant === 'warn'
            ? 'tw-bg-yellow-400 dark:tw-bg-yellow-600 tw-text-primary'
            : props.variant === 'info'
              ? 'tw-bg-[#bae6fd] tw-text-dark'
              : props.variant === 'success'
                ? 'tw-bg-green-400 dark:tw-bg-green-500 tw-text-dark'
                : props.variant === 'primary'
                  ? 'tw-bg-perpul-light dark:tw-bg-perpul-dark tw-text-light'
                  : null,
        props.className,
      )}
    >
      {props.children}
    </span>
  );
};
export default Badge;
