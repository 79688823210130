import clsx from 'clsx';
import React from 'react';

interface InputProps extends React.ComponentProps<'textarea'> {
  // Optional element to mount to the left of the text entry.
  // A 2.5rem padding is added to make room for the element.
  prefixEle?: React.ReactNode;
  suffixEle?: React.ReactNode;
  // Expands automatically to fit the contents. The value specified is the
  // maximum height the textarea should expand to though a user can enlarge it
  // further.
  autoHeight?: number;
}

const TextAreaInput = React.forwardRef<HTMLTextAreaElement, InputProps>((props, parentRef) => {
  const { autoHeight, prefixEle, suffixEle, ...rest } = props;
  const className = clsx(
    'tw-block tw-w-full tw-rounded-sm focus:tw-rounded-sm tw-bg-zinc-100 dark:tw-bg-zinc-800 tw-p-2.5 tw-text-primary tw-text-sm tw-placeholder-gray-600 dark:tw-placeholder-gray-400 tw-border tw-border-solid tw-border-[#858585] dark:tw-border-[#767676] focus:tw-outline focus:tw-outline-zinc-800 dark:focus:tw-outline-zinc-100',
    props.prefixEle ? 'tw-pl-10' : null,
    props.className,
  );
  const localRef = React.useRef<HTMLTextAreaElement | null>(null);
  const [height, setHeight] = React.useState<number | undefined>(undefined);
  React.useEffect(() => {
    if (localRef.current && autoHeight) {
      // 1. Do not shrink a textarea that it's auto-expanded to.
      // 2. Do not shrink a textarea that's been expanded by the user.
      // 3. Do not auto-expand the textarea beyond the specified size.
      setHeight(
        Math.max(height ?? 0, localRef.current.offsetHeight, Math.min(localRef.current.scrollHeight, autoHeight)),
      );
      if (localRef.current.scrollTop + localRef.current.offsetHeight + 20 >= localRef.current.scrollHeight) {
        // If viewport already at bottom (with buffer), then keep scrolling to
        // bottom as it expands.
        localRef.current.scrollTop = localRef.current.scrollHeight;
      }
    }
  }, [localRef.current, props.value]);
  return (
    <div className="tw-flex tw-items-center">
      {props.prefixEle ? (
        <span className="tw-absolute tw-h-5 tw-w-5 tw-ml-2 -tw-mt-1 tw-text-muted tw-z-10">{props.prefixEle}</span>
      ) : null}
      <textarea
        {...rest}
        ref={ref => {
          localRef.current = ref;
          if (typeof parentRef === 'function') {
            parentRef(ref);
          } else if (parentRef !== null) {
            parentRef.current = ref;
          }
        }}
        className={className}
        style={height ? { height } : undefined}
      />
      {props.suffixEle ? (
        <div>
          <span className="tw-absolute tw-h-5 tw-w-5 -tw-ml-8 -tw-mt-3 tw-text-muted tw-z-10">{props.suffixEle}</span>
        </div>
      ) : null}
    </div>
  );
});

export default TextAreaInput;
