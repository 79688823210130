import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const CaretDownIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={[320, 512]}
    d="M271.1 176H48.08c-42.63 0-64.25 51.77-33.88 81.9l111.9 112c18.63 18.76 49.13 18.76 67.88 0l112-112C335.1 227.8 314.7 176 271.1 176zM159.1 336L47.96 224H271.1L159.1 336z"
  />
);

export default CaretDownIcon;
