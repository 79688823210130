import React from 'react';

interface Handler {
  fn: (key: string, e: KeyboardEvent) => void;
  id: number;
  priority: number;
  options?: KeyPressOptions;
}

interface KeyPressListener {
  addHandler: (
    key: string,
    handler: (key: string, e: KeyboardEvent) => void,
    priority: number,
    options?: KeyPressOptions,
  ) => number; // returns handlerId
  removeHandler: (key: string, handlerId: number) => void;
}

export const KeyPressContext = React.createContext<KeyPressListener>({
  addHandler: () => 0,
  removeHandler: () => null,
});

/**
 * KeyPressContextProvider lets components register callbacks on a per key
 * basis. If two components register for the same key, the one that registered
 * the key press with the highest priority sorted by recency will have *only*
 * its callback triggered.
 *
 * This lets components in the "foreground" take key presses away from
 * background components.
 *
 * Set `options.propagate` to propagate a key press beyond the foreground
 * component.
 *
 * NOTE: "recency" of registration is unreliable. Registrations will happen
 * every time a component re-renders. Trying to plan the order of component
 * re-rendering is a fool's errand.
 */
export const KeyPressContextProvider = (props: { children: React.ReactNode }) => {
  const maxHandlerId = React.useRef(0);
  const handlers = React.useRef<Map<string, Handler[]>>(new Map());
  const providerState: KeyPressListener = {
    addHandler: (
      key: string,
      handler: (key: string, e: KeyboardEvent) => void,
      priority: number,
      options?: KeyPressOptions,
    ): number => {
      if (!handlers.current.has(key)) {
        handlers.current.set(key, []);
      }
      const pressHandlers = handlers.current.get(key)!;
      const newHandlerId = maxHandlerId.current;
      maxHandlerId.current = maxHandlerId.current + 1;
      pressHandlers.push({
        fn: handler,
        id: newHandlerId,
        options,
        priority,
      });
      pressHandlers.sort((handlerA, handlerB) => handlerA.priority - handlerB.priority);
      return newHandlerId;
    },
    removeHandler: (key: string, handlerId: number) => {
      if (!handlers.current.has(key)) {
        handlers.current.set(key, []);
      }
      const pressHandlers = handlers.current.get(key)!;
      for (const [index, pressHandler] of pressHandlers.entries()) {
        if (pressHandler.id === handlerId) {
          pressHandlers.splice(index, 1);
          break;
        }
      }
    },
  };

  React.useEffect(() => {
    const keyPressHandler = (e: KeyboardEvent) => {
      if (e.ctrlKey || e.metaKey) {
        return false;
      }
      const pressHandlers = handlers.current.get(e.key);
      if (!pressHandlers || pressHandlers.length === 0) {
        return false;
      }
      for (let i = pressHandlers.length - 1; i >= 0; i--) {
        const pressHandler = pressHandlers[i];
        const propagate = !!pressHandler.options?.propagate;
        if (pressHandler.options === undefined || !pressHandler.options.handleIfActiveElementIsInput) {
          const activeElementsToIgnore = ['input', 'select', 'textarea'];
          const activeElementTagName = document.activeElement?.tagName.toLowerCase();
          if (
            ((activeElementTagName && activeElementsToIgnore.indexOf(activeElementTagName) !== -1) ||
              // @ts-ignore
              document.activeElement?.isContentEditable) &&
            e.key !== 'Escape'
          ) {
            if (propagate) {
              continue;
            } else {
              return false;
            }
          } else if (activeElementTagName === 'button' && e.key === 'Enter') {
            // If a button is active, then ignore enter as that will conflict with
            // pressing the button.
            if (propagate) {
              continue;
            } else {
              return false;
            }
          }
        }
        pressHandler.fn(e.key, e);
        if (!propagate) {
          return;
        }
      }
    };
    document.body.addEventListener('keydown', keyPressHandler, false);
    return () => {
      document.body.removeEventListener('keydown', keyPressHandler, false);
    };
  }, []);

  return <KeyPressContext.Provider value={providerState}>{props.children}</KeyPressContext.Provider>;
};

interface KeyPressOptions {
  handleIfActiveElementIsInput?: boolean;
  // If set, the key press will continue to propagate to the next handler.
  propagate?: boolean;
}

export const useKeyPress = (
  key: string | string[],
  handler: (key: string, e: KeyboardEvent) => void,
  disabled?: boolean,
  priority?: number,
  options?: KeyPressOptions,
): void => {
  const keyPressContext = React.useContext(KeyPressContext);
  React.useEffect(() => {
    if (disabled) {
      return;
    }
    const keyHandlerIds: Array<[string, number]> = [];
    if (typeof key === 'string') {
      keyHandlerIds.push([key, keyPressContext.addHandler(key, handler, priority ?? 0, options)]);
    } else {
      for (const singleKey of key) {
        keyHandlerIds.push([singleKey, keyPressContext.addHandler(singleKey, handler, priority ?? 0, options)]);
      }
    }
    return () => {
      for (const [singleKey, handlerId] of keyHandlerIds) {
        keyPressContext.removeHandler(singleKey, handlerId);
      }
    };
  }, [key, handler, disabled]);
  return;
};
