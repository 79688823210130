import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';

import useToast from './useToast';

function useApiDo<T, R, E>(
  apiClient: api.SuperegoClient,
  routeMethod: (arg: T) => Promise<api.Response<R, E>>,
  opts?: {
    // By default, useApiDo calls are not abortable since they typically intend
    // to mutate backend state, and that intention should be preserved
    // regardless of whether the hook is unmounted early. However, if useApiDo
    // is used to read data (in lieue of useApiRead for whatever reason), then
    // you can explicitly set it to be abortable.
    abortable?: boolean;
  },
): {
  apiDo: (
    arg: T,
    handlers?: {
      onResult?: (res: R) => void;
      onRouteErr?: (err: E, defaultErrToast: () => void) => void;
      onRespErr?: (resp: api.Response<R, E>) => void;
      onHttpErr?: () => void;
      onAnyErr?: () => void;
      onFinally?: () => void;
    },
  ) => Promise<api.Response<R, E>>;
  okToast: (header: string, body?: string) => void;
  errToast: (header: string, body?: string) => void;
} {
  const { setToast } = useToast();

  const { apiDo: apiDoBase } = cfe.ApiHook.useApiDoBase(apiClient, routeMethod, opts);

  const apiDo = (
    arg: T,
    handlers?: {
      onResult?: (res: R) => void;
      onRouteErr?: (err: E, defaultErrToast: () => void) => void;
      onRespErr?: (resp: api.Response<R, E>) => void;
      onHttpErr?: () => void;
      onAnyErr?: () => void;
      onFinally?: () => void;
    },
  ): Promise<api.Response<R, E>> => {
    const defaultErrToast = () =>
      setToast({ header: 'Something went wrong', body: 'Our code is bad :( Try again later, sorry!' });
    return apiDoBase(arg, {
      onAnyErr: handlers?.onAnyErr,
      onFinally: handlers?.onFinally,
      onHttpErr: () => {
        setToast({ header: 'Something went wrong', body: 'Check your Internet connection', icon: 'frown' });
      },
      onRespErr: resp => {
        const body =
          resp.kind === api.StatusCode.InternalError
            ? 'Try again later, sorry!'
            : resp.kind === api.StatusCode.BadAuth
              ? 'You need to login again.'
              : resp.kind === api.StatusCode.RateLimit
                ? "We're overloaded. Try again later, sorry!"
                : 'This is embarrassing...';
        setToast({ header: 'Something went wrong', body, icon: 'frown' });
        if (handlers?.onRespErr) {
          handlers.onRespErr(resp);
        }
      },
      onResult: handlers?.onResult,
      onRouteErr: e => {
        if (handlers?.onRouteErr) {
          handlers.onRouteErr(e, defaultErrToast);
        } else {
          defaultErrToast();
        }
      },
    });
  };
  return {
    apiDo,
    errToast: (header: string, body?: string) => {
      setToast({ header, body, icon: 'frown' });
    },
    okToast: (header: string, body?: string) => {
      setToast({ header, body, icon: 'check' });
    },
  };
}

export default useApiDo;
