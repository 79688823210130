"use strict";
/**
 * Parses text that is exclusively a markdown link (no prefix or suffix).
 * FIXME: Two back-to-back links are treated as one link.
 *
 * Returns [text, url]
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parsePureMarkdownLink = void 0;
function parsePureMarkdownLink(text) {
    const p = /^\[(.+?)\]\((.+?)\)$/;
    const match = text.match(p);
    if (match) {
        return [match[1], match[2]];
    }
    else {
        return null;
    }
}
exports.parsePureMarkdownLink = parsePureMarkdownLink;
