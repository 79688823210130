import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { scrollTo } from 'scroll-js';

import { MainActionCreators } from '../state/reducer';
import * as store from '../state/store';

import useAgentMode from './hooks/useAgentMode';
import useApiClient from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import CommentIcon from './icon/CommentIcon';
import DiscussionIcon from './icon/DiscussionIcon';
import ThumbsUpIcon from './icon/ThumbsUpIcon';
import { useKeyPress } from './KeyPressContext';
import Button from './lib/Button';
import ListGroup from './lib/ListGroup';
import Spinner from './lib/Spinner';
import TextInput from './lib/TextInput';
import SectionHeaderTab from './SectionHeaderTab';

const DiscussionSection = (props: {
  entry: api.feed.IFeedEntryReference;
  kbSelected: 'going-up' | 'going-down' | null;
  kbNextSection: () => void;
  kbPrevSection: () => void;
  scrollTarget?: HTMLDivElement;
}) => {
  const dispatch = useDispatch();
  const apiClient = useApiClient();
  const agentMode = useAgentMode();

  const keyboardControlsActive = useSelector<store.IAppState, boolean>(state => state.keyboardControlsActive);
  const url = props.entry.strong_ref?.url ?? props.entry.url;

  const { result: discussions, refresh: refreshDiscussion } = cfe.ApiHook.useApiReadCache(
    apiClient,
    apiClient.stimulusGetCommentary,
    { url },
    res => res,
    value => dispatch(MainActionCreators.apiCacheSetStimCommentary(url, value)),
    () =>
      useSelector<store.IAppState, cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.stimulus.IGetCommentaryResult>>>(
        state => state.apiCache.stimCommentary.get(url) ?? cfe.ApiHook.getCacheEmptySingleton(),
      ),
    props.entry['.tag'] === 'notif',
    undefined,
    120,
  );

  const [kbIndex, setKbIndex] = useState(0);

  const filteredDiscussions = (submissions: api.stimulus.ISocialSubmissionReference[]) =>
    submissions.filter(submission => agentMode || !submission.hide);

  useEffect(() => {
    if (props.kbSelected) {
      if (cfe.ApiData.hasData(discussions)) {
        if (props.kbSelected === 'going-down') {
          if (discussions.data.first_party_discussion) {
            setKbIndex(-1);
          } else if (filteredDiscussions(discussions.data.submissions).length > 0) {
            setKbIndex(0);
          } else {
            props.kbNextSection();
          }
        } else {
          if (filteredDiscussions(discussions.data.submissions).length > 0) {
            setKbIndex(filteredDiscussions(discussions.data.submissions).length - 1);
          } else if (discussions.data.first_party_discussion) {
            setKbIndex(-1);
          } else {
            props.kbPrevSection();
          }
        }
      } else {
        if (props.kbSelected === 'going-down') {
          props.kbNextSection();
        } else {
          props.kbPrevSection();
        }
      }
    }
  }, [props.kbSelected]);

  useKeyPress(
    'n',
    () => {
      // n: Select next discussion.
      if (cfe.ApiData.hasData(discussions) && kbIndex + 1 < filteredDiscussions(discussions.data.submissions).length) {
        setKbIndex(kbIndex + 1);
      } else {
        props.kbNextSection();
      }
    },
    !props.kbSelected,
  );

  useKeyPress(
    'p',
    () => {
      // p: Select prev discussion.
      if (cfe.ApiData.hasData(discussions) && filteredDiscussions(discussions.data.submissions).length > 0) {
        if (kbIndex - 1 >= 0) {
          setKbIndex(kbIndex - 1);
        } else if (kbIndex === 0 && discussions.data.first_party_discussion) {
          setKbIndex(-1);
        } else {
          props.kbPrevSection();
        }
      } else {
        props.kbPrevSection();
      }
    },
    !props.kbSelected,
  );

  useKeyPress(
    'Enter',
    () => {
      // enter/o: Open link in new tab
      if (cfe.ApiData.hasData(discussions)) {
        let discussionUrl: string | undefined;
        if (kbIndex === -1 && discussions.data.first_party_discussion) {
          discussionUrl = discussions.data.first_party_discussion.url ?? url;
        } else if (discussions.data.submissions.length > kbIndex) {
          discussionUrl = discussions.data.submissions[kbIndex].url;
        }
        if (discussionUrl) {
          window.open(discussionUrl, '_blank');
        }
      }
    },
    !props.kbSelected,
  );

  useKeyPress(
    'o',
    () => {
      // enter/o: Open link in new tab
      if (cfe.ApiData.hasData(discussions)) {
        let discussionUrl: string | undefined;
        if (kbIndex === -1 && discussions.data.first_party_discussion) {
          discussionUrl = discussions.data.first_party_discussion.url ?? url;
        } else if (discussions.data.submissions.length > kbIndex) {
          discussionUrl = discussions.data.submissions[kbIndex].url;
        }
        if (discussionUrl) {
          window.open(discussionUrl, '_blank');
        }
      }
    },
    !props.kbSelected,
  );

  const { apiDo: apiStimulusDiscussionAdd, okToast } = useApiDo(apiClient, apiClient.stimulusDiscussionAdd);
  const [newDiscussionUrl, setNewDiscussionUrl] = useState('');

  // This is for refreshing the discussion data when a new discussion has been
  // manually added by an agent. The metadata needs to be re-extracted for the
  // story before the new discussion will appear, which takes an unbounded
  // amount of time.
  const [refreshForDiscussion, setRefreshForDiscussion] = useState<[number, string] | null>(null);
  useEffect(() => {
    if (!refreshForDiscussion) {
      return;
    }
    const [retryCount, discussionUrl] = refreshForDiscussion;
    if (
      retryCount > 10 ||
      (cfe.ApiData.hasData(discussions) &&
        discussions.data.submissions.filter(submission => submission.url === discussionUrl).length > 0)
    ) {
      setRefreshForDiscussion(null);
    } else {
      setTimeout(refreshDiscussion, 1000);
      setRefreshForDiscussion([retryCount + 1, discussionUrl]);
    }
  }, [discussions]);

  return (
    <div>
      <SectionHeaderTab
        title="Discussion"
        titleSuffix={
          cfe.ApiData.hasData(discussions) ? (
            <div className="tw-flex tw-items-center">
              <DiscussionIcon size="1rem" offsetUp />
              <span className="tw-ml-1">
                {discussions.data.submissions.filter(submission => !submission.hide).length +
                  (discussions.data.first_party_discussion ? 1 : 0)}
              </span>
            </div>
          ) : undefined
        }
      />
      {cfe.ApiData.hasData(discussions) &&
      (discussions.data.submissions.length > 0 || discussions.data.first_party_discussion) ? (
        <ListGroup>
          {discussions.data.first_party_discussion ? (
            <DiscussionItem
              entryUrl={props.entry.url}
              primaryUrl={url}
              discussion={{ kind: 'first-party', discussion: discussions.data.first_party_discussion }}
              kbSelected={props.kbSelected !== null && keyboardControlsActive && kbIndex === -1}
              refreshDiscussion={refreshDiscussion}
              scrollTarget={props.scrollTarget}
            />
          ) : null}
          {discussions.data.submissions
            .filter(submission => agentMode || !submission.hide)
            .map((submission, index) => (
              <DiscussionItem
                key={submission.url}
                entryUrl={props.entry.url}
                primaryUrl={url}
                discussion={{ kind: 'third-party', discussion: submission }}
                kbSelected={props.kbSelected !== null && keyboardControlsActive && kbIndex === index}
                refreshDiscussion={refreshDiscussion}
                scrollTarget={props.scrollTarget}
              />
            ))}
        </ListGroup>
      ) : cfe.ApiData.hasData(discussions) ? (
        <div className="kb-pad-4-na">
          <span>&mdash;</span>
        </div>
      ) : cfe.ApiData.isError(discussions) ? (
        <div className="kb-pad-4-na">
          <span>Uh oh, unexpected error.</span>
        </div>
      ) : cfe.ApiData.isLoading(discussions) ? (
        <div className="kb-pad-4-na">
          <Spinner className="tw-align-middle" /> <span>Finding trolls...</span>
        </div>
      ) : null}
      {agentMode ? (
        <div className="tw-mx-4 tw-mt-2">
          <div className="tw-flex">
            <Button
              sm
              className="tw-rounded-r-none"
              onClick={() => {
                apiStimulusDiscussionAdd(
                  { url: props.entry.url, discussion_url: newDiscussionUrl },
                  {
                    onResult: () => {
                      okToast('Discussion added');
                      setNewDiscussionUrl('');
                      refreshDiscussion();
                      setRefreshForDiscussion([0, newDiscussionUrl]);
                    },
                  },
                );
              }}
              disabled={newDiscussionUrl.length === 0}
            >
              Add discussion
            </Button>
            <TextInput
              sm
              className="tw-rounded-l-none"
              containerClassName="tw-grow"
              type="text"
              placeholder="Use reddit or hacker news url"
              onChange={e => setNewDiscussionUrl(e.target.value)}
              value={newDiscussionUrl}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

interface FirstPartyDiscussoin {
  kind: 'first-party';
  discussion: api.stimulus.IFirstPartyDiscussion;
}

interface ThirdPartyDiscussion {
  kind: 'third-party';
  discussion: api.stimulus.ISocialSubmissionReference;
}

type EitherDiscussion = FirstPartyDiscussoin | ThirdPartyDiscussion;

const DiscussionItem = (props: {
  entryUrl: string;
  primaryUrl: string;
  discussion: EitherDiscussion;
  kbSelected: boolean;
  refreshDiscussion: () => void;
  scrollTarget?: HTMLDivElement;
}) => {
  const apiClient = useApiClient();
  const agentMode = useAgentMode();

  const { apiDo: apiStimulusDiscussionAdd, okToast } = useApiDo(apiClient, apiClient.stimulusDiscussionAdd);
  const { apiDo: apiStimulusDiscussionHide } = useApiDo(apiClient, apiClient.stimulusDiscussionHide);

  const anchorRef = React.useRef<HTMLAnchorElement>(null);
  React.useEffect(() => {
    if (!props.kbSelected || !anchorRef.current || !props.scrollTarget) {
      return;
    }
    const scrollYTarget =
      anchorRef.current.getBoundingClientRect().top + props.scrollTarget.scrollTop - window.innerHeight / 2 + 100;
    scrollTo(props.scrollTarget, { top: scrollYTarget, easing: 'ease-in-out', duration: 70 });
  }, [props.kbSelected, props.scrollTarget]);

  if (props.discussion.kind === 'first-party') {
    const url = props.discussion.discussion.url ?? props.primaryUrl;
    return (
      <ListGroup.ItemLinkExternal
        sm
        ref={anchorRef}
        key={url}
        href={url}
        kbActive={props.kbSelected}
        mutedHover
        className="!tw-py-1"
        target="_blank"
      >
        <div className="tw-grow tw-flex tw-justify-between tw-gap-x-2">
          <span className="tw-text-link-blue tw-break-all">{cfe.ApiHelpers.getHostnameForDisplay(url)}</span>
          <div className="tw-flex tw-items-center tw-gap-x-2 tw-text-sm">
            <span className="tw-whitespace-nowrap">
              <CommentIcon size="0.875rem" />
              <span className="tw-mr-1" />
              <span>{cfe.Formatter.abbreviateNumber(props.discussion.discussion.comments ?? 0)}</span>
            </span>
            {agentMode ? (
              <span className="tw-whitespace-nowrap">
                <ThumbsUpIcon size={15} />
                <span className="tw-mr-1" />
                <span>{cfe.Formatter.abbreviateNumber(props.discussion.discussion.points ?? 0)}</span>
              </span>
            ) : null}
          </div>
        </div>
      </ListGroup.ItemLinkExternal>
    );
  } else {
    const url = props.discussion.discussion.url;
    return (
      <ListGroup.ItemLinkExternal
        sm
        ref={anchorRef}
        key={url}
        href={url}
        kbActive={props.kbSelected}
        mutedHover
        className="!tw-py-1"
        target="_blank"
      >
        <div className="tw-flex tw-grow tw-justify-between tw-gap-x-2">
          <div style={props.discussion.discussion.hide ? { textDecoration: 'line-through' } : undefined}>
            {props.discussion.discussion['.tag'] === 'hn' ? (
              <span className="tw-text-link-blue tw-break-all">Hacker News</span>
            ) : props.discussion.discussion['.tag'] === 'reddit' ? (
              <span className="tw-text-link-blue tw-break-all">r/{props.discussion.discussion.subreddit}</span>
            ) : null}
            <span className="tw-whitespace-nowrap tw-ml-1">
              &bull; {cfe.Formatter.getTsDistance(props.discussion.discussion.created)}
            </span>
          </div>
          <div className="tw-flex tw-items-center tw-gap-x-2 tw-text-sm">
            {agentMode ? (
              props.discussion.discussion.hide ? (
                <span
                  role="button"
                  className="tw-link"
                  onClick={e => {
                    e.preventDefault();
                    apiStimulusDiscussionAdd(
                      { url: props.entryUrl, discussion_url: url },
                      {
                        onResult: () => {
                          okToast('Discussion unhidden');
                          props.refreshDiscussion();
                        },
                      },
                    );
                  }}
                >
                  unhide
                </span>
              ) : (
                <span
                  role="button"
                  className="tw-link"
                  onClick={e => {
                    e.preventDefault();
                    apiStimulusDiscussionHide(
                      { url: props.entryUrl, discussion_url: url },
                      {
                        onResult: () => {
                          okToast('Discussion hidden');
                          props.refreshDiscussion();
                        },
                      },
                    );
                  }}
                >
                  hide
                </span>
              )
            ) : null}
            <span className="tw-whitespace-nowrap">
              <CommentIcon size="0.875rem" />
              <span className="tw-mr-1" />
              <span>{cfe.Formatter.abbreviateNumber(props.discussion.discussion.num_comments)}</span>
            </span>
            {agentMode ? (
              <span className="tw-whitespace-nowrap">
                <ThumbsUpIcon size={14.5} />
                <span className="tw-mr-1" />
                <span>
                  {cfe.Formatter.abbreviateNumber(
                    props.discussion.discussion['.tag'] === 'hn'
                      ? props.discussion.discussion.points
                      : props.discussion.discussion['.tag'] === 'reddit'
                        ? props.discussion.discussion.ups
                        : 0,
                  )}
                </span>
              </span>
            ) : null}
          </div>
        </div>
      </ListGroup.ItemLinkExternal>
    );
  }
};

export default DiscussionSection;
