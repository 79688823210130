import { useSelector } from 'react-redux';

import * as store from '../../state/store';

import useUserMeInternal from './useUserMeInternal';

const useAgentMode = (): boolean => {
  const accountInfo = useUserMeInternal();
  const agentMode = useSelector<store.IAppState, boolean>(state => state.agentMode);
  return accountInfo !== null && !!accountInfo.is_staff && agentMode;
};

/**
 * Whether the current user has access to agent mode regardless of whether or
 * not it's currently toggled.
 */
export const useIsAgentModeCapable = (): boolean => {
  const accountInfo = useUserMeInternal();
  return accountInfo !== null && !!accountInfo.is_staff;
};

export default useAgentMode;
