import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const DumbbellIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={[640, 512]}
    d="M608 224V152C608 121.1 582.9 96 552 96h-32C517.3 96 514.6 96.38 512 96.75V88C512 57.07 486.9 32 456 32h-32c-30.93 0-56 25.07-56 56V224h-96V88C272 57.07 246.9 32 216 32h-32C153.1 32 128 57.07 128 88v8.75C125.4 96.38 122.8 96 120 96h-32C57.07 96 32 121.1 32 152V224C14.4 224 0 238.4 0 256s14.4 32 31.1 32L32 360C32 390.9 57.07 416 88 416h32c2.75 0 5.375-.375 8-.75V424C128 454.9 153.1 480 184 480h32c30.93 0 56-25.07 56-56V288h96v136c0 30.93 25.07 56 56 56h32c30.93 0 56-25.07 56-56v-8.75C514.6 415.6 517.3 416 520 416h32c30.93 0 56-25.07 56-56V288c17.6 0 32-14.4 32-32S625.6 224 608 224zM120 368h-32c-4.375 0-8-3.625-8-8v-208c0-4.375 3.625-8 8-8h32C124.4 144 128 147.6 128 152v208C128 364.4 124.4 368 120 368zM224 424c0 4.375-3.625 8-8 8h-32c-4.375 0-8-3.625-8-8V88c0-4.375 3.625-8 8-8h32C220.4 80 224 83.62 224 88V424zM464 424c0 4.375-3.625 8-8 8h-32c-4.375 0-8-3.625-8-8V88c0-4.375 3.625-8 8-8h32c4.375 0 8 3.625 8 8V424zM560 360c0 4.375-3.625 8-8 8h-32c-4.375 0-8-3.625-8-8v-208c0-4.375 3.625-8 8-8h32c4.375 0 8 3.625 8 8V360z"
  />
);

export default DumbbellIcon;
