import { useEffect } from 'react';

/**
 * A convenience hook for handling "keydown" keyboard events.
 *
 * Use this instead of a "keypress" handler to handle non-character input such
 * as arrow presses.
 *
 * Also ignores key presses if the user is focused on an input field like
 * a text input, text area, button, etc.
 */
export function useKeyDownEvent(f: (code: string, e: KeyboardEvent) => void) {
  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      // Ignore key presses if user is currently focused on an input.
      const activeElementsToIgnore = ['input', 'select', 'textarea'];
      const activeElementTagName = document.activeElement?.tagName.toLowerCase();
      if (
        (activeElementTagName && activeElementsToIgnore.indexOf(activeElementTagName) !== -1) ||
        // @ts-ignore
        document.activeElement?.isContentEditable
      ) {
        return false;
      } else if (activeElementTagName === 'button' && e.key === 'Enter') {
        // If a button is active, then ignore enter as that will conflict with
        // pressing the button.
        return false;
      }
      f(e.code, e);
    };
    document.body.addEventListener('keydown', keyDownHandler, false);
    return () => {
      document.body.removeEventListener('keydown', keyDownHandler, false);
    };
  });
}

export default useKeyDownEvent;
