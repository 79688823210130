import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { useDispatch } from 'react-redux';

import { MainActionCreators } from '../../state/reducer';

import useApiClient from './useApiClient';
import useHistoryRecorder from './useHistoryRecorder';
import useNav from './useNav';

const useStoryOpener = () => {
  const dispatch = useDispatch();
  const { navToEntry, navToEntryCpc } = useNav();
  const apiClient = useApiClient();
  const historyRecorder = useHistoryRecorder();
  const storyOpener = React.useCallback(
    (feed: api.feed.IFeedInfo, entry: api.feed.IFeedEntryReference, forceExternal: boolean = false) => {
      const viewMode = cfe.ApiHelpers.getViewMode(entry);
      const primaryUrl = entry.strong_ref?.url ?? entry.url;
      const ytVideoId = cfe.ApiHelpers.parseYouTubeVideoIdFromUrl(primaryUrl);
      const tweetId = cfe.ApiHelpers.parseTwitterTweetId(primaryUrl);
      const videoMd = cfe.ApiHelpers.getVideoMetadataExtended(entry);
      if (forceExternal) {
        if (viewMode.cpc?.default) {
          window.open(cfe.ApiHelpers.getUrlPathnameForEntry(entry.entry_id) + '/cpc', '_blank');
        } else if (ytVideoId || tweetId || (videoMd && !cfe.ApiHelpers.isUrlOpenable(primaryUrl))) {
          window.open(cfe.ApiHelpers.getUrlPathnameForEntry(entry.entry_id), '_blank');
        } else if (cfe.ApiHelpers.isUrlOpenable(primaryUrl)) {
          window.open(primaryUrl, '_blank');
          historyRecorder(feed.feed_id, primaryUrl, entry.entry_id);
        } else {
          window.open(cfe.ApiHelpers.getUrlPathnameForEntry(entry.entry_id), '_blank');
        }
      } else {
        if (videoMd && videoMd[2]) {
          navToEntry(feed, entry);
          dispatch(MainActionCreators.setAutoPlayMedia(true));
        } else if (viewMode.cpc?.default) {
          // These are long posts and stories with cpc.
          // FIXME: CpcViewer is responsible for recording the URL to history
          // after a reasonable amount of time.
          navToEntryCpc(feed, entry);
        } else if (ytVideoId || tweetId) {
          // FIXME: Explore is responsible for recording the URL to history
          // after watching video for a reasonable amount of time.
          navToEntry(feed, entry);
          dispatch(MainActionCreators.setAutoPlayMedia(true));
        } else if (cfe.ApiHelpers.isUrlOpenable(primaryUrl)) {
          window.open(primaryUrl, '_blank');
          historyRecorder(feed.feed_id, primaryUrl, entry.entry_id);
        } else {
          // These are posts.
          navToEntry(feed, entry);
        }
      }
    },
    [historyRecorder, navToEntry, navToEntryCpc],
  );
  return React.useMemo(
    () => ({
      discussionPreviewOpener: (feedId: string, entry: api.feed.IFeedEntryReference) => {
        const discussionUrl = entry.discussion_preview?.url;
        if (discussionUrl) {
          window.open(discussionUrl, '_blank');
          if (discussionUrl === entry.url || discussionUrl === entry.url_canonical) {
            // Only mark story as visited if the discussion preview is the story.
            historyRecorder(feedId, entry.url, entry.entry_id);
          }
        }
      },
      doesOpenToExplore: (entry: api.feed.IFeedEntryReference) => {
        const md = cfe.ApiHelpers.getEntryPrimaryMetadata(entry);
        const primaryUrl = entry.strong_ref?.url ?? entry.url;
        const ytVideoId = cfe.ApiHelpers.parseYouTubeVideoIdFromUrl(primaryUrl);
        const tweetId = cfe.ApiHelpers.parseTwitterTweetId(primaryUrl);
        const videoMd = cfe.ApiHelpers.getVideoMetadataExtended(entry);
        if (videoMd && videoMd[2]) {
          return true;
        } else if (ytVideoId || tweetId) {
          return true;
        } else if (md['.tag'] === 'ready' && md.post && md.post.content.length !== 0) {
          return true;
        } else {
          return false;
        }
      },
      storyOpener,
      tracePreviewOpener: (entry: api.feed.IFeedEntryReference) => {
        const body = entry.trace_preview?.body;
        if (body) {
          const parsedTraceUrl = cfe.ThreadHelpers.parsePureMarkdownLink(body);
          if (parsedTraceUrl) {
            const traceUrl = parsedTraceUrl[1];
            apiClient
              .stimulusGetMainEntryBatch({ urls: [traceUrl] })
              .then(resp => {
                if (resp.kind === api.StatusCode.Ok) {
                  const mainEntries = new Map(Object.entries(resp.result.main_entries));
                  const mainEntry = mainEntries.get(traceUrl);
                  if (mainEntry) {
                    storyOpener(mainEntry.feed, mainEntry.entry, true);
                  } else {
                    window.open(traceUrl, '_blank');
                  }
                } else {
                  window.open(traceUrl, '_blank');
                }
              })
              .catch(() => {
                window.open(traceUrl, '_blank');
              });
          }
        }
      },
    }),
    [historyRecorder, storyOpener],
  );
};

export default useStoryOpener;
