import clsx from 'clsx';
import React from 'react';

interface InputProps extends React.ComponentProps<'input'> {
  // Optional element to mount to the left of the text entry.
  // A 2.5rem padding is added to make room for the element.
  prefixEle?: React.ReactNode;
  suffixEle?: React.ReactNode;
  sm?: boolean;
  error?: boolean;
  containerClassName?: string;
}

const TextInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { prefixEle, suffixEle, sm, containerClassName, error, ...rest } = props;
  const className = clsx(
    'tw-block tw-w-full tw-rounded-sm focus:tw-rounded-sm tw-bg-zinc-100 dark:tw-bg-zinc-800 tw-text-primary tw-text-sm',
    'tw-placeholder-gray-600 dark:tw-placeholder-gray-400',
    'tw-border tw-border-solid',
    error ? 'tw-border-red-700' : 'tw-border-[#858585] dark:tw-border-[#767676]',
    'focus:tw-outline focus:tw-outline-zinc-800 dark:focus:tw-outline-zinc-100',
    props.prefixEle ? 'tw-pl-10' : null,
    props.sm ? 'tw-py-[0.3125rem] tw-px-2 tw-leading-none' : 'tw-p-2.5',
    props.className,
  );
  return (
    <div className={clsx('tw-flex tw-items-center', props.containerClassName)}>
      {props.prefixEle ? (
        <span className="tw-absolute tw-h-5 tw-w-5 tw-ml-2 -tw-mt-1 tw-text-muted tw-z-10">{props.prefixEle}</span>
      ) : null}
      <input {...rest} ref={ref} className={className} />
      {props.suffixEle ? (
        <div>
          <span className="tw-absolute tw-h-5 tw-w-5 -tw-ml-8 -tw-mt-3 tw-text-muted tw-z-10">{props.suffixEle}</span>
        </div>
      ) : null}
    </div>
  );
});

interface TextInputFooterProps {
  value: string;
  maxLength: number;
  // A UI improvement to not stress the user with the max length unless they
  // cross the 50% threshold.
  hideMaxLengthUntilHalfway?: boolean;
  // Use this to hide maxLength entirely when it isn't relevant to show to the
  // user.
  hideMaxLength?: boolean;
  left?: string | React.ReactNode;
  // Use this to give the footer absolute positioning so it does not take up
  // layout space. This assumes that it's used on a page that already has a gap
  // layout or equivalent setting equal spacing.
  float?: boolean;
}

export const TextInputFooter = (props: TextInputFooterProps) => (
  <div className="tw-relative">
    <div
      className={clsx('tw-flex tw-justify-between tw-mt-1 tw-text-sm', props.float ? 'tw-absolute tw-w-full' : null)}
    >
      {props.left === undefined ? (
        <span className="tw-mb-5"></span>
      ) : typeof props.left === 'string' ? (
        <span className="tw-text-muted">{props.left}</span>
      ) : (
        props.left
      )}
      {props.value.length > 0 &&
      !props.hideMaxLength &&
      (!props.hideMaxLengthUntilHalfway || props.value.length > props.maxLength / 2) ? (
        <span
          className={clsx(
            props.value.length === props.maxLength ? 'tw-text-red-700 tw-font-semibold' : 'tw-text-muted',
          )}
        >
          {props.value.length.toLocaleString()}/{props.maxLength.toLocaleString()}
        </span>
      ) : null}
    </div>
  </div>
);

export const TextInputFooterForOptional = (props: Omit<TextInputFooterProps, 'left'>) => (
  <TextInputFooter {...props} left="Optional" />
);

export default TextInput;
