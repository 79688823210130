import React from 'react';
import { useDispatch } from 'react-redux';
import { MainActionCreators } from '../../state/reducer';
import * as store from '../../state/store';

const useToast = () => {
  const dispatch = useDispatch();
  return React.useMemo(
    () => ({
      setToast: (toast: Omit<store.IToast, 'id'>) => dispatch(MainActionCreators.addToast(toast)),
    }),
    [],
  );
};
export default useToast;
