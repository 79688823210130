import React from 'react';

import * as Config from '../config';

import AppStoreIcon from './icon/AppStoreIcon';
import GooglePlayIcon from './icon/GooglePlayIcon';
import { ButtonLinkExternal } from './lib/Button';

const MobileStoreOptions = () => (
  <div className="tw-flex tw-flex-col tw-gap-y-4">
    <ButtonLinkExternal
      variant="secondary"
      className="tw-gap-x-1"
      href="https://apps.apple.com/app/apple-store/id1500521120"
    >
      <span className="tw-w-6">
        <AppStoreIcon size="1.2rem" />
      </span>
      <span className="tw-w-20 tw-text-left">iOS</span>
    </ButtonLinkExternal>
    <ButtonLinkExternal
      variant="secondary"
      className="tw-gap-x-1"
      href="https://play.google.com/store/apps/details?id=com.intertimesmobile"
    >
      <span className="tw-w-6">
        <GooglePlayIcon size="1.15rem" />
      </span>
      <span className="tw-w-20 tw-text-left">Android</span>
    </ButtonLinkExternal>

    <img
      src={Config.globalStaticHost + 'mind/img/app-store-qr.svg'}
      width={200}
      height={200}
      className="tw-bg-gray-300 dark:tw-bg-gray-600 tw-aspect-square tw-w-36"
    />
  </div>
);

export default MobileStoreOptions;
