import * as cfe from 'ego-cfe';
import React from 'react';

import { useAuthedApiClient } from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import RssIcon from './icon/RssIcon';
import Modal from './lib/Modal';
import TextEditInput from './lib/TextEditInput';

const FollowRssFeedModal = (props: { close: () => void }) => {
  const apiClient = useAuthedApiClient();
  const { apiDo: apiFeedFollowRss, okToast } = useApiDo(apiClient, apiClient.feedFollowRss);
  return (
    <Modal.Container show close={props.close} animate="slide-from-top">
      <Modal.Header>
        <Modal.Heading1>
          Follow RSS <RssIcon size="1.4rem" offsetUp />
        </Modal.Heading1>
      </Modal.Header>
      <Modal.Body gutter>
        <TextEditInput
          controlId="formUrl"
          label="RSS url"
          placeholder="Follow this url (https://...)"
          initial=""
          maxLength={100}
          minLength={4}
          alwaysShowButton={true}
          buttonLabel="Follow"
          onUpdate={(val, done, error) => {
            const rssUrl = val!.startsWith('http') ? val! : `https://${val!}`;
            apiFeedFollowRss(
              { rss_url: rssUrl },
              {
                onFinally: () => done(rssUrl),
                onResult: res => {
                  okToast(`Following ${cfe.ApiHelpers.getFeedShortName(res.feed)}`);
                  props.close();
                },
                onRouteErr: (err, defaultErrToast) => {
                  if (err['.tag'] === 'bad_rss_url') {
                    error('This is not a valid RSS feed.');
                  } else if (err['.tag'] === 'already_following') {
                    error("You're already following this RSS feed.");
                  } else {
                    defaultErrToast();
                  }
                },
              },
            );
          }}
        />
      </Modal.Body>
    </Modal.Container>
  );
};
export default FollowRssFeedModal;
