import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { router } from '../index';
import { MainActionCreators } from '../state/reducer';
import * as store from '../state/store';
import { getSearchParameter, removeSearchParameter } from '../util';

import useLocalStorage, { useLocalStorageSetter } from './hooks/useLocalStorage';
import { useKeyPress } from './KeyPressContext';

const ThemeModeGate = (props: { children: React.ReactNode }) => {
  const themeModeSetter = useThemeModeSetter();
  useLocalStorage<store.ThemeMode>('themeMode', newThemeMode => {
    if (newThemeMode !== null && !themeLocalStorageDisabled) {
      themeModeSetter(newThemeMode);
    }
  });
  const themeMode = useThemeMode();
  const themeModeCls = useThemeModeCssCls();
  const navigate = router.navigate;
  React.useEffect(() => {
    // Support theme being set by query param.
    // This is used when our mobile app loads a web component to embed in its
    // own UI and requires dark mode to be set.
    const themeParam = getSearchParameter(location.search, 'theme');
    if (themeParam) {
      disableThemeLocalStorage();
      if (themeParam === 'dark') {
        themeModeSetter('dark');
      } else if (themeParam === 'light') {
        themeModeSetter('light');
      }
      navigate(window.location.pathname + removeSearchParameter(window.location.search, 'theme'), { replace: true });
    }
  }, []);
  React.useEffect(() => {
    // HACK: Apply to <body> manually since it's not an element generated by
    // a react component.
    document.body.classList.add('tw-bg-canvas');
    // Necessary to block overscroll history navigation (default on mac) from
    // interfering with swipe menu.
    document.body.classList.add('tw-overscroll-x-none');
  }, []);
  useKeyPress('D', () => {
    themeModeSetter(themeMode === 'dark' ? 'light' : 'dark');
  });
  return <div className={themeModeCls}>{props.children}</div>;
};

export const useThemeMode = (): store.ThemeMode => {
  const themeMode = useSelector<store.IAppState, store.ThemeMode>(state => state.appearance.themeMode);
  return themeMode;
};

export const useThemeModeCssCls = (): string | undefined => {
  const themeMode = useThemeMode();
  return themeMode === 'dark' ? 'tw-dark' : undefined;
};

export const useThemeModeSetter = () => {
  const dispatch = useDispatch();
  const setStorageThemeMode = useLocalStorageSetter<string>('themeMode');
  return (newThemeMode: store.ThemeMode) => {
    dispatch(MainActionCreators.setThemeMode(newThemeMode));
    if (newThemeMode === 'dark') {
      document.documentElement.classList.add('tw-dark');
    } else {
      document.documentElement.classList.remove('tw-dark');
    }
    if (!themeLocalStorageDisabled) {
      if (newThemeMode === 'dark') {
        setStorageThemeMode('dark');
      } else {
        setStorageThemeMode('light');
      }
    }
  };
};

/**
 * When the theme is set via a query parameter (e.g. mobile-text-editor),
 * setting this disables the theme from propagating via local storage to other
 * tabs (unlikely) and future tabs.
 */
let themeLocalStorageDisabled = false;
export const disableThemeLocalStorage = () => (themeLocalStorageDisabled = true);

export default ThemeModeGate;
