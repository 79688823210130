import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React, { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { MainActionCreators } from '../state/reducer';
import * as store from '../state/store';

import FeedItem from './FeedItem';
import useApiClient from './hooks/useApiClient';
import useNav from './hooks/useNav';
import { useKeyPress } from './KeyPressContext';
import Badge from './lib/Badge';
import ListGroup from './lib/ListGroup';
import Modal from './lib/Modal';
import Spinner from './lib/Spinner';

const RelatedFeedsModal = (props: { feedId: string; closeModal: () => void; isForeground: boolean }) => {
  const feed = useSelector<store.IAppState, api.feed.IFeedInfo>(state => state.feeds.get(props.feedId)!);
  const [kbSection, setKbSection] = useState<'prism' | 'audience'>('prism');
  useKeyPress(
    ['u', 'Escape'],
    () => {
      props.closeModal();
    },
    !props.isForeground,
    10,
  );
  return (
    <Modal.Container show={props.isForeground} close={() => props.closeModal()}>
      <Modal.Header>
        <Modal.Heading1>Related Topics</Modal.Heading1>
      </Modal.Header>
      <Modal.Body>
        {feed.prism_id ? (
          <PrismSection
            feed={feed}
            closeModal={props.closeModal}
            isForeground={props.isForeground}
            kbSelected={kbSection === 'prism'}
            kbNextSection={() => {
              if (feed.audience_id) {
                setKbSection('audience');
              }
            }}
          />
        ) : null}
        {feed.audience_id ? (
          <AudienceSection
            feed={feed}
            closeModal={props.closeModal}
            isForeground={props.isForeground}
            kbSelected={kbSection === 'audience'}
            kbPrevSection={() => {
              if (feed.prism_id) {
                setKbSection('prism');
              }
            }}
          />
        ) : null}
      </Modal.Body>
    </Modal.Container>
  );
};

const PrismSection = (props: {
  feed: api.feed.IFeedInfo;
  closeModal: () => void;
  isForeground: boolean;
  kbSelected: boolean;
  kbNextSection: () => void;
}) => {
  if (!props.feed.prism_id) {
    throw Error('Unexpected feed without prism');
  }
  const apiClient = useApiClient();
  const dispatch = useDispatch();
  const feedMap = useSelector<store.IAppState, Map<string, api.feed.IFeedInfo>>(state => state.feeds);
  const keyboardControlsActive = useSelector<store.IAppState, boolean>(state => state.keyboardControlsActive);
  const { navToFeed } = useNav();

  const { result: prismGetRes } = cfe.ApiHook.useApiRead(
    apiClient,
    apiClient.prismGet,
    { prism_id: props.feed.prism_id! },
    res => res,
    undefined,
    {
      onResult: res => dispatch(MainActionCreators.updateFeed(res.root_feed)),
    },
  );
  const { result: prismGroupGetRes } = cfe.ApiHook.useApiRead(
    apiClient,
    apiClient.feedGroupGet,
    { group_id: props.feed.prism_id! },
    res => res,
    undefined,
    {
      onResult: res =>
        batch(() =>
          res.group.members.map(
            member => member['.tag'] === 'feed' && dispatch(MainActionCreators.updateFeed(member.feed)),
          ),
        ),
    },
  );

  const [kbCursorIndex, setKbCursorIndex] = useState(-1);

  useKeyPress(
    'n',
    () => {
      // n: Select next feed.
      if (cfe.ApiData.hasData(prismGroupGetRes)) {
        if (kbCursorIndex < prismGroupGetRes.data.group.members.length - 1) {
          setKbCursorIndex(kbCursorIndex + 1);
        } else {
          props.kbNextSection();
        }
      }
    },
    !cfe.ApiData.hasData(prismGroupGetRes) || !props.isForeground || !props.kbSelected,
    10,
  );

  useKeyPress(
    'p',
    () => {
      // p: Select prev feed.
      if (kbCursorIndex > -1) {
        setKbCursorIndex(kbCursorIndex - 1);
      }
    },
    !cfe.ApiData.hasData(prismGroupGetRes) || !props.isForeground || !props.kbSelected,
    10,
  );

  useKeyPress(
    'Enter',
    () => {
      // enter: Open feed
      if (
        cfe.ApiData.hasData(prismGroupGetRes) &&
        kbCursorIndex >= 0 &&
        kbCursorIndex < prismGroupGetRes.data.group.members.length
      ) {
        const member = prismGroupGetRes.data.group.members[kbCursorIndex];
        if (member['.tag'] !== 'feed') {
          return;
        }
        navToFeed(member.feed);
        props.closeModal();
      } else if (cfe.ApiData.hasData(prismGetRes) && kbCursorIndex === -1) {
        navToFeed(prismGetRes.data.root_feed);
        props.closeModal();
      }
    },
    !cfe.ApiData.hasData(prismGroupGetRes) || !props.isForeground || !props.kbSelected,
    10,
  );

  useKeyPress(
    'o',
    () => {
      // o: Open feed
      if (
        cfe.ApiData.hasData(prismGroupGetRes) &&
        kbCursorIndex >= 0 &&
        kbCursorIndex < prismGroupGetRes.data.group.members.length
      ) {
        const member = prismGroupGetRes.data.group.members[kbCursorIndex];
        if (member['.tag'] !== 'feed') {
          return;
        }
        navToFeed(member.feed);
        props.closeModal();
      } else if (cfe.ApiData.hasData(prismGetRes) && kbCursorIndex === -1) {
        navToFeed(prismGetRes.data.root_feed);
        props.closeModal();
      }
    },
    !cfe.ApiData.hasData(prismGroupGetRes) || !props.isForeground || !props.kbSelected,
    10,
  );

  return (
    <ListGroup>
      {cfe.ApiData.hasData(prismGetRes) ? (
        <FeedItem
          key={feedMap.get(prismGetRes.data.root_feed.feed_id)!.feed_id}
          feed={feedMap.get(prismGetRes.data.root_feed.feed_id)!}
          onClickAfter={() => props.closeModal()}
          hideFollowers={true}
          hideBlurb={true}
          kbActive={keyboardControlsActive && kbCursorIndex === -1}
          titleRight={
            <Badge variant="primary" className="tw-ml-3 tw-text-sm">
              Main Topic
            </Badge>
          }
        />
      ) : null}
      {cfe.ApiData.hasData(prismGroupGetRes) ? (
        prismGroupGetRes.data.group.members.length === 0 ? (
          <div>&mdash;</div>
        ) : (
          prismGroupGetRes.data.group.members.map((member, index) => {
            if (member['.tag'] !== 'feed') {
              return;
            }
            const memberFeed = feedMap.get(member.feed.feed_id);
            if (!memberFeed) {
              return;
            }
            return (
              <FeedItem
                key={memberFeed.feed_id}
                feed={memberFeed}
                onClickAfter={() => props.closeModal()}
                hideFollowers={true}
                hideBlurb={true}
                kbActive={props.kbSelected && keyboardControlsActive && kbCursorIndex === index}
              />
            );
          })
        )
      ) : cfe.ApiData.isLoading(prismGroupGetRes) ? (
        <div className="tw-self-center">
          <Spinner />
        </div>
      ) : null}
    </ListGroup>
  );
};

const AudienceSection = (props: {
  feed: api.feed.IFeedInfo;
  closeModal: () => void;
  isForeground: boolean;
  kbSelected: boolean;
  kbPrevSection: () => void;
}) => {
  if (!props.feed.audience_id) {
    throw Error('Unexpected feed without audience');
  }
  const apiClient = useApiClient();
  const dispatch = useDispatch();
  const feedMap = useSelector<store.IAppState, Map<string, api.feed.IFeedInfo>>(state => state.feeds);
  const keyboardControlsActive = useSelector<store.IAppState, boolean>(state => state.keyboardControlsActive);
  const { navToFeed } = useNav();

  const { result: audienceGroupGetRes } = cfe.ApiHook.useApiRead(
    apiClient,
    apiClient.feedGroupGet,
    { group_id: props.feed.audience_id! },
    res => res,
    undefined,
    {
      onResult: res =>
        batch(() =>
          res.group.members.map(
            member => member['.tag'] === 'feed' && dispatch(MainActionCreators.updateFeed(member.feed)),
          ),
        ),
    },
  );

  const [kbCursorIndex, setKbCursorIndex] = useState(0);

  useKeyPress(
    'n',
    () => {
      // n: Select next feed.
      if (
        cfe.ApiData.hasData(audienceGroupGetRes) &&
        kbCursorIndex < audienceGroupGetRes.data.group.members.length - 1
      ) {
        setKbCursorIndex(kbCursorIndex + 1);
      }
    },
    !cfe.ApiData.hasData(audienceGroupGetRes) || !props.isForeground || !props.kbSelected,
    10,
  );

  useKeyPress(
    'p',
    () => {
      // p: Select prev feed.
      if (kbCursorIndex > 0) {
        setKbCursorIndex(kbCursorIndex - 1);
      } else {
        props.kbPrevSection();
      }
    },
    !cfe.ApiData.hasData(audienceGroupGetRes) || !props.isForeground || !props.kbSelected,
    10,
  );

  useKeyPress(
    'Enter',
    () => {
      // enter: Open feed
      if (
        cfe.ApiData.hasData(audienceGroupGetRes) &&
        kbCursorIndex >= 0 &&
        kbCursorIndex < audienceGroupGetRes.data.group.members.length
      ) {
        const member = audienceGroupGetRes.data.group.members[kbCursorIndex];
        if (member['.tag'] !== 'feed') {
          return;
        }
        navToFeed(member.feed);
        props.closeModal();
      }
    },
    !cfe.ApiData.hasData(audienceGroupGetRes),
    10,
  );

  useKeyPress(
    'o',
    () => {
      // o: Open feed
      if (
        cfe.ApiData.hasData(audienceGroupGetRes) &&
        kbCursorIndex >= 0 &&
        kbCursorIndex < audienceGroupGetRes.data.group.members.length
      ) {
        const member = audienceGroupGetRes.data.group.members[kbCursorIndex];
        if (member['.tag'] !== 'feed') {
          return;
        }
        navToFeed(member.feed);
        props.closeModal();
      }
    },
    !cfe.ApiData.hasData(audienceGroupGetRes) || !props.kbSelected,
    10,
  );

  return (
    <ListGroup>
      {cfe.ApiData.hasData(audienceGroupGetRes) ? (
        audienceGroupGetRes.data.group.members.length === 0 ? (
          <div>&mdash;</div>
        ) : (
          audienceGroupGetRes.data.group.members.map((member, index) => {
            if (member['.tag'] !== 'feed') {
              return;
            }
            const memberFeed = feedMap.get(member.feed.feed_id);
            if (!memberFeed) {
              return;
            }
            return (
              <FeedItem
                key={memberFeed.feed_id}
                feed={memberFeed}
                onClickAfter={() => props.closeModal()}
                hideFollowers={true}
                hideBlurb={true}
                kbActive={props.kbSelected && keyboardControlsActive && kbCursorIndex === index}
              />
            );
          })
        )
      ) : cfe.ApiData.isLoading(audienceGroupGetRes) ? (
        <div className="tw-self-center">
          <Spinner />
        </div>
      ) : null}
    </ListGroup>
  );
};

export default RelatedFeedsModal;
