import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { useDispatch } from 'react-redux';

import { MainActionCreators } from '../../state/reducer';

import { useAuthedApiClient } from './useApiClient';
import useApiDo from './useApiDo';

interface SendToUsers {
  kind: 'users';
  userIds: string[];
}

interface SendToShareFeed {
  kind: 'share-feed';
  shareFeedId: string;
}

export type SendTo = SendToUsers | SendToShareFeed;

const useInboxSend = (url: string, via?: api.feed.IFeedEntryReference) => {
  const dispatch = useDispatch();
  const apiClient = useAuthedApiClient();
  const { apiDo: apiInboxCreateSharedFeed, okToast } = useApiDo(apiClient, apiClient.inboxCreateSharedFeed);
  const { apiDo: apiFeedEntryAdd } = useApiDo(apiClient, apiClient.feedEntryAdd);
  const { apiDo: apiThreadCommentAdd, errToast } = useApiDo(apiClient, apiClient.threadCommentAdd);
  const [inFlightCreate, setInFlightCreate] = React.useState(false);
  const [inFlightAdd, setInFlightAdd] = React.useState(false);
  const [inFlightComment, setInFlightComment] = React.useState(false);
  const sharedFeedId = React.useRef<string | null>(null);

  const addComment = (entryRes: api.feed.EntryAddResult, comment?: string, successCb?: () => void) => {
    const entry =
      entryRes['.tag'] === 'new_entry'
        ? entryRes.new_entry
        : entryRes['.tag'] === 'existing'
          ? entryRes.existing
          : null;
    if (comment && entry && entry['.tag'] === 'share') {
      setInFlightComment(true);
      apiThreadCommentAdd(
        { thread_id: entry.ffa_thread_id, body: comment },
        {
          onFinally: () => setInFlightComment(false),
          onResult: () => {
            okToast('Sent');
            dispatch(MainActionCreators.setForceInboxRefresh(true));
            if (successCb) {
              successCb();
            }
          },
          onRouteErr: (err, defaultErrToast) => {
            if (err['.tag'] === 'slow_down') {
              errToast('Slow down', `Try again in ${cfe.Formatter.secondsToHoursMinsSecsStr(err.wait_period)}`);
            } else {
              defaultErrToast();
            }
          },
        },
      );
    } else {
      okToast('Sent');
      dispatch(MainActionCreators.setForceInboxRefresh(true));
      if (successCb) {
        successCb();
      }
    }
  };

  const onSend = (sendTo: SendTo, comment?: string, successCb?: () => void) => {
    if (sendTo.kind === 'users' && sendTo.userIds.length > 0) {
      setInFlightCreate(true);
      apiInboxCreateSharedFeed(
        { user_ids: sendTo.userIds },
        {
          onFinally: () => setInFlightCreate(false),
          onResult: res => {
            setInFlightAdd(true);
            dispatch(MainActionCreators.updateFeed(res.feed));
            sharedFeedId.current = res.feed.feed_id;
            apiFeedEntryAdd(
              {
                feed_id: sharedFeedId.current,
                set_via: via ? { entry_id: via.entry_id, quote_source: true } : undefined,
                url,
              },
              {
                onFinally: () => setInFlightAdd(false),
                onResult: entryRes => {
                  addComment(entryRes, comment, successCb);
                },
                onRouteErr: (err, defaultErrToast) => {
                  if (err['.tag'] === 'slow_down') {
                    errToast('Slow down', `Try again in ${cfe.Formatter.secondsToHoursMinsSecsStr(err.wait_period)}`);
                  } else {
                    defaultErrToast();
                  }
                },
              },
            );
          },
        },
      );
    } else if (sendTo.kind === 'share-feed') {
      setInFlightAdd(true);
      apiFeedEntryAdd(
        {
          feed_id: sendTo.shareFeedId,
          set_via: via ? { entry_id: via.entry_id, quote_source: true } : undefined,
          url,
        },
        {
          onFinally: () => setInFlightAdd(false),
          onResult: entryRes => {
            addComment(entryRes, comment, successCb);
          },
          onRouteErr: (err, defaultErrToast) => {
            if (err['.tag'] === 'slow_down') {
              errToast('Slow down', `Try again in ${cfe.Formatter.secondsToHoursMinsSecsStr(err.wait_period)}`);
            } else {
              defaultErrToast();
            }
          },
        },
      );
    } else {
      throw Error('Unexpected sendTo variant');
    }
  };

  return {
    inFlight: inFlightCreate || inFlightAdd || inFlightComment,
    onSend,
  };
};

export default useInboxSend;
