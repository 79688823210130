import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { MainActionCreators } from '../../state/reducer';
import * as store from '../../state/store';

import useApiClient from './useApiClient';
import useLoggedInUserId from './useLoggedInUserId';

/*
 * @returns null if it's a logged-out user.
 */
export const useUserMeInternalRaw = (): [cfe.ApiData.Data<api.user.IMeInfo> | null, () => void] => {
  const dispatch = useDispatch();
  const userId = useLoggedInUserId();
  const apiClient = useApiClient();
  const { refresh, result } = cfe.ApiHook.useApiReadCache(
    apiClient,
    apiClient.userMeInternal,
    undefined,
    res => res,
    value => dispatch(MainActionCreators.apiCacheSetMeInfo(value)),
    () =>
      useSelector<store.IAppState, cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.user.IMeInfo>>>(
        appState => appState.apiCache.meInfo,
      ),
    userId === null,
  );
  return [userId === null ? null : result, refresh];
};

export type AccountInfoMaybe = api.user.IMeInfo | null;

/**
 * Assumes that <UserGate> is a parent in the component hierarchy to guarantee
 * that user information is loaded.
 * @returns null if logged-out user.
 */
const useUserMeInternal = (): api.user.IMeInfo | null => {
  const userId = useLoggedInUserId();
  const meInfoCache = useSelector<store.IAppState, cfe.ApiData.Data<api.user.IMeInfo>>(
    appState => appState.apiCache.meInfo.value,
  );
  if (userId) {
    if (!cfe.ApiData.hasData(meInfoCache)) {
      throw Error('Expected meInfo to be loaded');
    }
    return meInfoCache.data;
  } else {
    return null;
  }
};

export const useStaffUserMeInternal = (): api.user.IMeInfo => {
  const userMeInternal = useUserMeInternal();
  if (!userMeInternal || !userMeInternal.is_staff) {
    throw Error('Expected staff user');
  }
  return userMeInternal;
};

export const useUserMeInternalRefreshed = (): api.user.IMeInfo => {
  const [userMeInternal, refresh] = useUserMeInternalRaw();
  if (userMeInternal === null) {
    throw Error('Expected logged-in user');
  } else if (!cfe.ApiData.hasData(userMeInternal)) {
    throw Error('Expected logged-in user account info');
  }
  React.useEffect(() => refresh(), []);
  return userMeInternal.data;
};

export const useUserMeInternalWithRefresher = (): [api.user.IMeInfo | null, () => void] => {
  const [userMeInternal, refresh] = useUserMeInternalRaw();
  if (userMeInternal !== null && !cfe.ApiData.hasData(userMeInternal)) {
    throw Error('Expected logged-in user account info');
  }
  return [userMeInternal ? userMeInternal.data : null, refresh];
};

export default useUserMeInternal;
