import clsx from 'clsx';
import React from 'react';

import CircleQuestion from '../icon/CircleQuestion';

import Button from './Button';
import InputLabel from './InputLabel';
import TextInput, { TextInputFooter } from './TextInput';

/**
 * A component for a simple form composed of a single text edit input + button.
 * Do not use this component for a multi-field form.
 */
const TextEditInput = (props: {
  controlId: string;
  label: string;
  description?: string;
  caution?: string;
  placeholder: string;
  initial: string | undefined;
  maxLength: number;
  onUpdate: (inputVal: string | undefined, done: (newVal?: string) => void, error: (msg: string) => void) => void;
  minLength?: number;
  optional?: boolean;
  alwaysShowButton?: boolean;
  buttonLabel?: string;
  className?: string;
}) => {
  const [inputVal, setInputVal] = React.useState<string | undefined>(props.initial);
  const [inputError, setInputError] = React.useState<string | undefined>(undefined);
  const [inFlight, setInFlight] = React.useState(false);

  const inputOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    if (event.currentTarget.value.length <= props.maxLength) {
      setInputError(undefined);
      const v = event.currentTarget.value;
      setInputVal(v === '' ? undefined : v);
    }
  };

  return (
    <fieldset disabled={inFlight}>
      <div className={clsx('tw-flex tw-flex-col', props.className)}>
        <InputLabel htmlFor={props.controlId}>
          {props.label}
          {props.description ? (
            <span className="tw-ml-1" title={props.description}>
              <CircleQuestion size="1rem" />
            </span>
          ) : null}
        </InputLabel>
        <TextInput
          id={props.controlId}
          type="text"
          placeholder={props.placeholder}
          onChange={inputOnChange}
          value={inputVal ?? ''}
          maxLength={props.maxLength}
        />
        <TextInputFooter
          value={inputVal ?? ''}
          maxLength={props.maxLength}
          left={props.optional ? 'Optional' : undefined}
        />
        {inputError ? <div className="tw-mt-1 tw-text-red-700">{inputError}</div> : null}
        {inputVal !== props.initial || props.alwaysShowButton ? (
          <>
            {props.caution ? <div className="tw-mt-1 tw-text-red-700">{props.caution}</div> : null}
            <Button
              block
              className="tw-mt-1"
              disabled={(props.minLength ?? 0) > (inputVal ?? '').length}
              onClick={() => {
                setInFlight(true);
                setInputError(undefined);
                props.onUpdate(
                  inputVal,
                  (newVal?: string) => {
                    setInFlight(false);
                    if (newVal) {
                      setInputVal(newVal);
                    }
                  },
                  (msg: string) => {
                    setInFlight(false);
                    setInputError(msg);
                  },
                );
              }}
            >
              {props.buttonLabel ?? 'Save'}
            </Button>
          </>
        ) : null}
      </div>
    </fieldset>
  );
};

export default TextEditInput;
