import { m } from 'framer-motion';
import React from 'react';

import FeedItem, { FeedItemProps } from './FeedItem';

export const FeedItemAnimated = (props: FeedItemProps) => (
  <m.div
    initial={{ opacity: 0, height: 0 }}
    animate={{ opacity: 1, height: 'auto' }}
    exit={{ opacity: 0, height: 0 }}
    transition={{ duration: 0.2 }}
  >
    <FeedItem {...props} />
  </m.div>
);
export default FeedItemAnimated;
