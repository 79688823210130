import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';

import * as Config from '../config';

import { StoryLowerMatter, StoryUnderMatter, StoryUpperMatter } from './FeedEntryMatter';
import FeedSearchInput from './FeedSearchInput';
import useApiClient from './hooks/useApiClient';
import useNav from './hooks/useNav';
import useStoryOpener from './hooks/useStoryOpener';
import LayoutLine from './lib/LayoutLine';
import Spinner from './lib/Spinner';
import TextInput from './lib/TextInput';

const FeedEntrySearchInput = (props: {
  onSelect: (feed: api.feed.IFeedInfo, entry: api.feed.IFeedEntryReference) => void;
  gutterClassName?: string;
  closeOnSelect?: boolean;
  suggestCurrentFeed?: api.feed.IFeedInfo;
}) => {
  const [openedFeed, setOpenedFeed] = React.useState<api.feed.IFeedInfo | null>(null);
  const [useLink, setUseLink] = React.useState(false);
  const onSelect = React.useCallback(
    (feed: api.feed.IFeedInfo, entry: api.feed.IFeedEntryReference) => {
      props.onSelect(feed, entry);
      if (props.closeOnSelect) {
        setOpenedFeed(null);
        setUseLink(false);
      }
    },
    [props.onSelect, props.closeOnSelect],
  );
  return (
    <div>
      {openedFeed ? (
        <FeedEntrySelector
          feed={openedFeed}
          onSelect={onSelect}
          gutterClassName={props.gutterClassName}
          switchFeed={() => setOpenedFeed(null)}
        />
      ) : useLink ? (
        <div className={props.gutterClassName}>
          <FeedEntryLinkInput onSelect={onSelect} />
        </div>
      ) : (
        <>
          <FeedSearchInput
            overrideSearchBarClassName={props.gutterClassName}
            kbActive={false}
            onClickOverride={feed => setOpenedFeed(feed)}
            showProfileImage="yes-if-available"
          />
          <div className={clsx('tw-flex tw-flex-row-reverse tw-gap-x-4', props.gutterClassName)}>
            <span role="button" className="tw-mt-1 tw-text-sm tw-link" onClick={() => setUseLink(true)}>
              Use link
            </span>
            {props.suggestCurrentFeed ? (
              <span
                role="button"
                className="tw-mt-1 tw-text-sm tw-link"
                onClick={() => {
                  if (props.suggestCurrentFeed) {
                    setOpenedFeed(props.suggestCurrentFeed);
                  }
                }}
              >
                Jump to {cfe.ApiHelpers.getFeedShortName(props.suggestCurrentFeed)}
              </span>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

const FeedEntryLinkInput = (props: {
  onSelect: (feed: api.feed.IFeedInfo, entry: api.feed.IFeedEntryReference) => void;
}) => {
  const apiClient = useApiClient();
  const asyncId = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const [url, setUrl] = React.useState('');
  const [badLink, setBadLink] = React.useState(false);
  React.useEffect(() => {
    const entryId = cfe.PostHelpers.parseFeedEntryIdFromUrl(url, Config.wwwHost) ?? undefined;
    if (!entryId) {
      if (url.length > 0) {
        // Only set if url isn't blank
        setBadLink(true);
      }
      return;
    }
    if (asyncId.current) {
      clearTimeout(asyncId.current);
    }
    asyncId.current = setTimeout(() => {
      apiClient.feedEntryGet({ entry_id: entryId }).then(resp => {
        if (resp.kind === api.StatusCode.Ok) {
          props.onSelect(resp.result.feed, resp.result.entry);
        } else if (resp.kind === api.StatusCode.Error) {
          setBadLink(true);
        }
      });
    }, 250);
  }, [url]);

  return (
    <div>
      <TextInput
        placeholder="e.g. https://superego.ai/e/..."
        onChange={e => {
          setUrl(e.currentTarget.value);
          setBadLink(false);
        }}
        value={url}
        maxLength={100}
      />
      {badLink ? (
        <div>
          <span className="tw-text-red-700">Bad link</span>
        </div>
      ) : null}
    </div>
  );
};

const FeedEntrySelector = (props: {
  feed: api.feed.IFeedInfo;
  onSelect: (feed: api.feed.IFeedInfo, entry: api.feed.IFeedEntryReference) => void;
  cursor?: string;
  gutterClassName?: string;
  switchFeed?: () => void;
}) => {
  const { navToFeed } = useNav();
  const apiClient = useApiClient();
  const { storyOpener } = useStoryOpener();

  let feedIterRes: cfe.ApiData.Data<api.feed.IEntryIterResult, any>;
  if (!props.cursor) {
    const iterRes = cfe.ApiHook.useApiRead(
      apiClient,
      apiClient.feedEntryIter,
      { feed_id: props.feed.feed_id, limit: 10, order_by: { '.tag': 'time' } },
      res => res,
    );
    feedIterRes = iterRes.result;
  } else {
    const iterNextRes = cfe.ApiHook.useApiRead(
      apiClient,
      apiClient.feedEntryIterNext,
      { cursor: props.cursor, limit: 10 },
      res => res,
    );
    feedIterRes = iterNextRes.result;
  }

  const [showNext, setShowNext] = React.useState(false);

  return (
    <div>
      {!props.cursor ? (
        <div className={clsx('tw-flex tw-gap-x-4 tw-mb-4', props.gutterClassName)}>
          <span className="tw-text-lg tw-font-bold">{cfe.ApiHelpers.getFeedShortName(props.feed)}</span>
          <div
            role="button"
            onClick={() => {
              if (props.switchFeed) {
                props.switchFeed();
              }
            }}
          >
            <span className="tw-link tw-leading-relaxed">Switch</span>
          </div>
        </div>
      ) : null}
      {cfe.ApiData.isLoading(feedIterRes) ? (
        <div className={props.gutterClassName}>
          <Spinner />
        </div>
      ) : cfe.ApiData.hasData(feedIterRes) ? (
        <div>
          {feedIterRes.data.entries.map(entry => (
            <div key={entry.entry_id}>
              <div
                role="button"
                className={clsx('tw-py-4 hover:tw-bg-highlight', props.gutterClassName)}
                onClick={() => props.onSelect(props.feed, entry)}
              >
                <StoryUpperMatter
                  apiClient={apiClient}
                  feed={props.feed}
                  entry={entry}
                  goToFeed={navToFeed}
                  onOpenStory={() => props.onSelect(props.feed, entry)}
                  compact
                  useTitleBadge={false}
                  clampTitle={false}
                />
                <StoryLowerMatter
                  apiClient={apiClient}
                  feed={props.feed}
                  entry={entry}
                  goToFeed={navToFeed}
                  compact={false}
                  showMiniBadgeLine={false}
                  showFromLine={false}
                />
                <StoryUnderMatter feed={props.feed} entry={entry} goToFeed={navToFeed} compact={false} />
                <div
                  role="button"
                  className="tw-link"
                  onClick={e => {
                    e.stopPropagation();
                    storyOpener(props.feed, entry, true);
                  }}
                >
                  Open
                </div>
              </div>
              <LayoutLine className="!tw-my-0" />
            </div>
          ))}
          {feedIterRes.data.has_more && feedIterRes.data.cursor ? (
            showNext ? (
              <div>
                <FeedEntrySelector
                  feed={props.feed}
                  onSelect={props.onSelect}
                  cursor={feedIterRes.data.cursor}
                  gutterClassName={props.gutterClassName}
                />
              </div>
            ) : (
              <div
                role="button"
                className="tw-py-4 hover:tw-bg-highlight tw-text-center"
                onClick={() => setShowNext(true)}
              >
                <span className="tw-link">load more</span>
              </div>
            )
          ) : (
            <div className="tw-py-4 tw-text-center">that's all</div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default FeedEntrySearchInput;
