import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { MainActionCreators } from '../state/reducer';

import { useAuthedApiClient } from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import { useUserMeInternalRefreshed } from './hooks/useUserMeInternal';
import Alert from './lib/Alert';
import Button from './lib/Button';
import InputLabel from './lib/InputLabel';
import LayoutLine from './lib/LayoutLine';
import Modal from './lib/Modal';
import Spinner from './lib/Spinner';
import TextInput from './lib/TextInput';
import ToggleForm from './lib/ToggleForm';
import { useModalManager } from './ModalManagerContext';
import Pagelet, { PageletNav, PageletNavItemLink } from './Pagelet';

const AccountPage = () => {
  return (
    <Routes>
      <Route index element={<AccountHomePage />} />
      <Route path="notifications" element={<AccountNotificationsPage />} />
    </Routes>
  );
};

const AccountNav = () => (
  <PageletNav>
    <PageletNavItemLink to="/account">Personal Info</PageletNavItemLink>
    <PageletNavItemLink to="/account/notifications">Notifications</PageletNavItemLink>
  </PageletNav>
);

const AccountHomePage = () => {
  const dispatch = useDispatch();
  const { pushModal } = useModalManager();
  const apiClient = useAuthedApiClient();
  const accountInfo = useUserMeInternalRefreshed();

  const [inFlightResendEmailVerification, setInFlightResendEmailVerification] = useState(false);

  const [closeAccountConfirmText, setCloseAccountConfirmText] = useState('');

  const { apiDo: apiAccountSeppuku } = useApiDo(apiClient, apiClient.accountSeppuku);

  const resendVerificationEmail = () => {
    setInFlightResendEmailVerification(true);
    apiClient
      .userSendVerificationEmail()
      .then(resp => {
        if (resp.kind === api.StatusCode.Ok) {
          dispatch(
            MainActionCreators.addToast({
              body: 'Please check your email.',
              header: 'Verification Sent',
              icon: 'info',
            }),
          );
        }
      })
      .finally(() => setInFlightResendEmailVerification(false));
  };

  return (
    <Pagelet title="Account -> Personal Info" gutter auxbox={<AccountNav />}>
      <Pagelet.Heading2 className="!tw-mb-6">Personal Information</Pagelet.Heading2>
      <div className="tw-grid tw-grid-cols-3 tw-gap-x-2 tw-my-3">
        <div className="tw-col-span-1">
          <span className="tw-font-bold">Username</span>
        </div>
        <div className="tw-col-span-2">
          <span>{accountInfo.username}</span>
        </div>
      </div>
      <LayoutLine />
      {accountInfo.email ? (
        <>
          <div className="tw-grid tw-grid-cols-3 tw-gap-x-2 tw-my-3">
            <div className="tw-col-span-1">
              <span className="tw-font-bold">Email</span>
            </div>
            <div className="tw-col-span-2">
              <div>
                <div>{accountInfo.email.addr}</div>
                {!accountInfo.email.verified ? (
                  <div>
                    <span className="tw-text-red-700">Unverified</span>{' '}
                    <Spinner.Presence>
                      {inFlightResendEmailVerification ? (
                        <Spinner className="tw-align-middle" sm />
                      ) : (
                        <a href="#" onClick={resendVerificationEmail}>
                          Resend verification e-mail
                        </a>
                      )}
                    </Spinner.Presence>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <LayoutLine />
        </>
      ) : null}
      <div className="tw-grid tw-grid-cols-3 tw-gap-x-2 tw-my-3">
        <div className="tw-col-span-1">
          <span className="tw-font-bold">Name</span>
        </div>
        <div className="tw-col-span-2">
          <span>{accountInfo.name}</span>
        </div>
      </div>
      <LayoutLine />
      <div className="tw-grid tw-grid-cols-3 tw-gap-x-2 tw-my-3">
        <div className="tw-col-span-1">
          <div className="tw-font-bold">Close Account</div>
          <div className="tw-text-sm">This cannot be reversed.</div>
        </div>
        <div className="tw-col-span-2">
          <Button
            block
            variant="danger"
            onClick={() =>
              pushModal({
                animate: 'slide-from-top',
                component: modalProps => (
                  <Modal.Container show={true} {...modalProps}>
                    <Modal.Header>
                      <Modal.Heading1>Close Account Confirmation</Modal.Heading1>
                    </Modal.Header>
                    <Modal.Body gutter>
                      <p>This is irreversible. You will not be able to log-in again.</p>
                      <div>
                        <div className="tw-mb-4">
                          <InputLabel>To confirm, type "Delete {accountInfo.username} forever</InputLabel>
                          <TextInput
                            type="text"
                            value={closeAccountConfirmText}
                            onChange={changeEvent => setCloseAccountConfirmText(changeEvent.currentTarget.value)}
                          />
                        </div>
                        <Button
                          block
                          variant="danger"
                          onClick={() =>
                            apiAccountSeppuku(undefined, {
                              onResult: () => {
                                window.location.href = '/logout';
                              },
                            })
                          }
                          disabled={closeAccountConfirmText !== `Delete ${accountInfo.username} forever`}
                        >
                          Confirm Close Account
                        </Button>
                      </div>
                    </Modal.Body>
                  </Modal.Container>
                ),
                dupKey: 'close-account-confirm',
                kind: 'generic',
              })
            }
          >
            Close Account
          </Button>
        </div>
      </div>
      <LayoutLine />
    </Pagelet>
  );
};

const AccountNotificationsPage = () => {
  const apiClient = useAuthedApiClient();

  const { result: accountNotifDevicePolicyGetRes, refresh: refreshNotifDevicePolicy } = cfe.ApiHook.useApiRead(
    apiClient,
    apiClient.accountNotificationDevicePolicyGet,
    undefined,
    res => res,
    undefined,
  );
  const { apiDo: apiAccountNotifyDevicePolicySet, okToast } = useApiDo(
    apiClient,
    apiClient.accountNotificationDevicePolicySet,
  );

  const { result: accountCommunicationPreferenceGetRes, refresh: refreshCommunicationPreference } =
    cfe.ApiHook.useApiRead(apiClient, apiClient.accountCommunicationPreferenceGet, undefined, res => res, undefined);
  const { apiDo: apiAccountCommunicationPreferenceSet } = useApiDo(
    apiClient,
    apiClient.accountCommunicationPreferenceSet,
  );

  return (
    <Pagelet
      title="Account -> Notifications"
      gutter
      auxbox={<AccountNav />}
      working={cfe.ApiData.isLoading(accountNotifDevicePolicyGetRes)}
    >
      {cfe.ApiData.hasData(accountNotifDevicePolicyGetRes) ? (
        <>
          <Pagelet.Heading2 className="tw-mt-4 tw-mb-6">Device Preference</Pagelet.Heading2>
          <ToggleForm
            className="tw-mb-4"
            label="Mobile"
            blurb="Receive push notifications on your mobile"
            on={accountNotifDevicePolicyGetRes.data.mobile}
            onToggle={(_, ok, err) => {
              apiAccountNotifyDevicePolicySet(
                { mobile: !accountNotifDevicePolicyGetRes.data.mobile },
                {
                  onAnyErr: err,
                  onFinally: ok,
                  onResult: () => {
                    refreshNotifDevicePolicy();
                    okToast('Notification preference updated');
                  },
                },
              );
            }}
          />
          <ToggleForm
            className="tw-mb-4"
            label="Email"
            blurb="Receive notifications via email"
            on={accountNotifDevicePolicyGetRes.data.email}
            onToggle={(_, ok, err) => {
              apiAccountNotifyDevicePolicySet(
                { email: !accountNotifDevicePolicyGetRes.data.email },
                {
                  onAnyErr: err,
                  onFinally: ok,
                  onResult: () => {
                    refreshNotifDevicePolicy();
                    okToast('Notification preference updated');
                  },
                },
              );
            }}
          />
          <Alert variant="info">
            <Alert.Heading>Note on Priority</Alert.Heading>
            <p className="tw-text-sm">
              If you've logged in with a mobile device, we will prioritize sending notifications to your mobile and
              avoid redundant notifications over email.
            </p>
            <Alert.Heading>Necessary Emails</Alert.Heading>
            <p className="tw-text-sm">
              Information related to your account's standing and billing will always be sent over email regardless of
              the above setting.
            </p>
          </Alert>
        </>
      ) : null}
      {cfe.ApiData.hasData(accountCommunicationPreferenceGetRes) ? (
        <>
          <Pagelet.Heading2 className="tw-mt-10 tw-mb-6">Communication</Pagelet.Heading2>
          <ToggleForm
            className="tw-mb-4"
            label="Stuff Worth Knowing Digest"
            blurb="Always sent via email"
            on={accountCommunicationPreferenceGetRes.data.email_digest}
            onToggle={(_, ok, err) => {
              apiAccountCommunicationPreferenceSet(
                { email_digest: !accountCommunicationPreferenceGetRes.data.email_digest },
                {
                  onAnyErr: err,
                  onFinally: ok,
                  onResult: () => {
                    refreshCommunicationPreference();
                    okToast('Communication preference updated');
                  },
                },
              );
            }}
          />
        </>
      ) : null}
    </Pagelet>
  );
};

export default AccountPage;
