import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { useRef, useState } from 'react';

import useApiDo from './hooks/useApiDo';
import CircleQuestionIcon from './icon/CircleQuestion';
import PlusIcon from './icon/PlusIcon';
import VerifiedAuthorIcon from './icon/VerifiedAuthorIcon';
import Button from './lib/Button';
import Collapse from './lib/Collapse';
import InputLabel from './lib/InputLabel';
import ListGroup from './lib/ListGroup';
import Modal from './lib/Modal';
import Spinner from './lib/Spinner';
import TextInput from './lib/TextInput';
import ToolTip from './lib/ToolTip';
import SectionHeaderTab from './SectionHeaderTab';
import { useSmartGoToFeed } from './SmartFeedLink';

const AuthorSection = (props: {
  goToFeed: (feed: api.feed.IFeedInfo) => void;
  apiClient: api.SuperegoClient;
  authors: cfe.ApiData.Data<api.stimulus.ICreatorReference[]>;
  showAgentUI: boolean;
  url: string;
  refreshStimulus: () => void;
}) => {
  const linkedAuthors = cfe.ApiData.hasData(props.authors)
    ? props.authors.data.filter(author => author['.tag'] === 'linked')
    : [];
  const smartGoToFeed = useSmartGoToFeed(props.goToFeed, props.apiClient);
  return (
    <div className="tw-mt-4">
      <SectionHeaderTab title="Authors" />
      <div>
        {cfe.ApiData.isLoading(props.authors) ? (
          <div className="kb-pad-4-na">
            <Spinner />
          </div>
        ) : cfe.ApiData.hasData(props.authors) ? (
          linkedAuthors.length === 0 ? (
            <ToolTip placement="left" overlay={<div>Unclaimed</div>}>
              <span className="kb-pad-4-na">
                &mdash; <span className="tw-ml-1" />
                <CircleQuestionIcon size="1rem" />
              </span>
            </ToolTip>
          ) : (
            <ListGroup>
              {linkedAuthors.map(author => {
                if (author['.tag'] !== 'linked') {
                  return null;
                }
                return (
                  <ListGroup.Item
                    key={author.linked_user.user_id}
                    className="!tw-py-1 tw-items-center"
                    mutedHover
                    onClick={() => smartGoToFeed('u/' + author.linked_user.user_id)}
                  >
                    {author.name} &bull; {author.role}
                    <span className="tw-ml-l" title="Verified Superego user">
                      <VerifiedAuthorIcon size="1rem" offsetUp className="tw-ml-1" />
                    </span>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          )
        ) : cfe.ApiData.isError(props.authors) ? (
          <span className="kb-pad-4-na">Uh oh, unexpected error.</span>
        ) : null}
      </div>
      {props.showAgentUI ? (
        <AddAuthorForm apiClient={props.apiClient} url={props.url} refreshStimulus={props.refreshStimulus} />
      ) : null}
    </div>
  );
};

const AddAuthorForm = (props: { apiClient: api.SuperegoClient; url: string; refreshStimulus: () => void }) => {
  const [showForm, setShowForm] = useState(false);
  const [addAuthorUsername, setAddAuthorUsername] = useState('');
  const [addAuthorUserId, setAddAuthorUserId] = useState<string>('');
  const [addAuthorRole, setAddAuthorRole] = useState<string>('');
  const [addAuthorWeight, setAddAuthorWeight] = useState(1);
  const [searchRes, setSearchRes] = useState<cfe.ApiData.Data<api.search.IQueryAuthorsResult>>({ kind: 'unknown' });
  const asyncSearchIdRef = useRef<ReturnType<typeof setTimeout>>();

  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const q = e.currentTarget.value;
    setAddAuthorUsername(q);
    if (asyncSearchIdRef.current !== undefined) {
      clearTimeout(asyncSearchIdRef.current);
    }
    if (q.length < 3) {
      return;
    }
    setSearchRes({ kind: 'loading' });
    asyncSearchIdRef.current = setTimeout(() => {
      asyncSearchIdRef.current = undefined;
      props.apiClient
        .searchQueryAuthors({ q })
        .then(resp => {
          if (resp.kind === api.StatusCode.Ok) {
            setSearchRes({ kind: 'loaded', data: resp.result });
          } else {
            setSearchRes({ kind: 'error' });
          }
        })
        .catch(() => {
          setSearchRes({ kind: 'error' });
        });
    }, 250);
  };

  const {
    apiDo: apiStaffOpStimulusCreditUser,
    okToast,
    errToast,
  } = useApiDo(props.apiClient, props.apiClient.staffOpStimulusCreditUser);
  return (
    <div>
      <div className="kb-pad-4">
        <Button sm className="tw-mt-1" onClick={() => setShowForm(!showForm)}>
          <PlusIcon size="1rem" /> Add author
        </Button>
      </div>
      <Collapse open={showForm}>
        <div className="tw-px-4 tw-py-4 tw-mt-2 tw-bg-highlight">
          <div className="tw-mb-4 tw-w-full">
            <Modal.Heading4>Credit User with Authorship</Modal.Heading4>
            <InputLabel>Search by username</InputLabel>
            <TextInput
              type="text"
              placeholder="Username"
              value={addAuthorUsername}
              onChange={onUsernameChange}
              suffixEle={cfe.ApiData.isLoading(searchRes) ? <Spinner sm /> : null}
            />
            <div className="tw-text-muted">Only author accounts are shown.</div>
            {cfe.ApiData.hasData(searchRes) ? (
              <ListGroup>
                {searchRes.data.matches.map(match => (
                  <ListGroup.Item
                    key={match.user_id}
                    className="!tw-py-1"
                    mutedHover
                    onClick={e => {
                      setAddAuthorUserId(match.user_id);
                      e.preventDefault();
                    }}
                  >
                    <span className="tw-mr-2 tw-font-semibold">{match.name}</span>
                    <span className="tw-text-muted">@{match.username}</span>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : null}
          </div>
          <div className="tw-mb-4">
            <InputLabel>User ID</InputLabel>
            <TextInput
              type="text"
              value={addAuthorUserId}
              onChange={e => {
                setAddAuthorUserId(e.currentTarget.value);
              }}
            />
          </div>
          <div className="tw-mb-4">
            <InputLabel>Role</InputLabel>
            <TextInput
              type="text"
              value={addAuthorRole}
              onChange={e => {
                setAddAuthorRole(e.currentTarget.value);
              }}
            />
          </div>
          <div className="tw-mb-4">
            <InputLabel>Weight</InputLabel>
            <TextInput
              type="text"
              value={addAuthorWeight.toString()}
              onChange={e => {
                setAddAuthorWeight(parseInt(e.currentTarget.value, 10));
              }}
            />
          </div>
          <Button
            variant="primary"
            disabled={addAuthorUserId.length === 0 || addAuthorRole.length === 0}
            onClick={() =>
              apiStaffOpStimulusCreditUser(
                {
                  role: addAuthorRole,
                  url: props.url,
                  user_id: addAuthorUserId,
                  weight: addAuthorWeight,
                },
                {
                  onResult: () => {
                    okToast('Author credited');
                    setShowForm(false);
                    setAddAuthorUsername('');
                    setAddAuthorUserId('');
                    setAddAuthorRole('');
                    setAddAuthorWeight(1);
                    props.refreshStimulus();
                  },
                  onRouteErr: err => errToast(err['.tag']),
                },
              )
            }
          >
            Credit Author
          </Button>
        </div>
      </Collapse>
    </div>
  );
};

export default AuthorSection;
