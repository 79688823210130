/**
 * WARNING: Keep sync-ed with util-md.ts in mobile.
 */

import { Root, RootContent } from 'mdast';
import { fromMarkdown } from 'mdast-util-from-markdown';

export const isTitleRequired = (content: string): 'no' | 'yes-complex' | 'yes-length' | 'yes-line-count' => {
  if (isMarkdownLineCountExcessive(content)) {
    return 'yes-line-count';
  }
  const tree = fromMarkdown(content);
  if (tree.children.filter(child => isMarkdownComplex(child)).length > 0) {
    return 'yes-complex';
  } else if (getSimpleMarkdownLength(tree) > 255) {
    return 'yes-length';
  } else {
    return 'no';
  }
};

const isMarkdownComplex = (content: RootContent): boolean => {
  if (
    content.type === 'paragraph' ||
    content.type === 'strong' ||
    content.type === 'emphasis' ||
    content.type === 'link'
  ) {
    if (content.children.length === 0) {
      return false;
    } else {
      return content.children.filter(child => isMarkdownComplex(child)).length > 0;
    }
  } else if (content.type === 'text' || content.type === 'inlineCode') {
    return false;
  } else {
    return true;
  }
};

const getSimpleContentLength = (content: RootContent): number => {
  // Only applicable for simple markdown.
  if (
    content.type === 'paragraph' ||
    content.type === 'strong' ||
    content.type === 'emphasis' ||
    content.type === 'link'
  ) {
    if (content.children.length === 0) {
      return 0;
    } else {
      return content.children.map(child => getSimpleContentLength(child)).reduce((prev, cur) => prev + cur);
    }
  } else if (content.type === 'text' || content.type === 'inlineCode') {
    return content.value.length;
  } else {
    return 0;
  }
};

export const getSimpleMarkdownLength = (tree: Root): number => {
  // Only applicable for simple markdown.
  return tree.children.map(child => getSimpleContentLength(child)).reduce((prev, cur) => prev + cur, 0);
};

export const getComplexContentLength = (content: RootContent): number => {
  if (
    content.type === 'paragraph' ||
    content.type === 'strong' ||
    content.type === 'emphasis' ||
    content.type === 'link' ||
    content.type === 'heading' ||
    content.type === 'blockquote' ||
    content.type === 'list' ||
    content.type === 'listItem'
  ) {
    if (content.children.length === 0) {
      return 0;
    } else {
      return content.children.map(child => getComplexContentLength(child)).reduce((prev, cur) => prev + cur);
    }
  } else if (content.type === 'text' || content.type === 'inlineCode' || content.type === 'code') {
    return content.value.length;
  } else {
    return 0;
  }
};

export const getComplexMarkdownLength = (tree: Root): number => {
  return tree.children.map(child => getComplexContentLength(child)).reduce((prev, cur) => prev + cur, 0);
};

const isMarkdownLineCountExcessive = (content: string) => {
  return content.split('\n').length > 15;
};

export const isMarkdownPlaintext = (tree: Root) => {
  if (tree.children.length === 0) {
    return true;
  }
  return (
    tree.children.length === 1 &&
    tree.children[0].type === 'paragraph' &&
    tree.children[0].children.length === 1 &&
    tree.children[0].children[0].type === 'text'
  );
};
