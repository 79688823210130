import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MainActionCreators } from '../state/reducer';
import * as store from '../state/store';

import FeedItem, { ShowProfileImageBehavior } from './FeedItem';
import useApiClient from './hooks/useApiClient';
import Spinner from './lib/Spinner';

const FeedItemEmbed = React.memo(
  (props: { feedId: string; showProfileImage?: ShowProfileImageBehavior }) => {
    const dispatch = useDispatch();
    const apiClient = useApiClient();
    const feed = useSelector<store.IAppState, api.feed.IFeedInfo | undefined>(state => state.feeds.get(props.feedId));

    cfe.ApiHook.useApiRead(apiClient, apiClient.feedGet, { feed_id: props.feedId }, res => res, feed !== undefined, {
      onResult: res => dispatch(MainActionCreators.updateFeed(res)),
    });

    return feed ? (
      <FeedItem feed={feed!} hideFollowers disableKb embed mutedHover showProfileImage={props.showProfileImage} />
    ) : (
      <div className="tw-flex tw-justify-center tw-py-4">
        <Spinner />
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.feedId === nextProps.feedId,
);

export default FeedItemEmbed;
