import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const MobileIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={[384, 512]}
    d="M304 0h-224c-35.35 0-64 28.65-64 64v384c0 35.35 28.65 64 64 64h224c35.35 0 64-28.65 64-64V64C368 28.65 339.3 0 304 0zM320 448c0 8.822-7.178 16-16 16h-224C71.18 464 64 456.8 64 448V64c0-8.822 7.178-16 16-16h224C312.8 48 320 55.18 320 64V448zM224 400H160c-8.836 0-16 7.164-16 16s7.164 16 16 16h64c8.838 0 16-7.164 16-16S232.8 400 224 400z"
  />
);

export default MobileIcon;
