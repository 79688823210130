import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const ImagesIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={[575, 512]}
    d="M160 64H512c17.7 0 32 14.3 32 32V201.4L486.6 144c-12.5-12.5-32.8-12.5-45.3 0L304 281.4 230.6 208c-12.5-12.5-32.8-12.5-45.3 0L128 265.4V96c0-17.7 14.3-32 32-32zM576 96c0-35.3-28.7-64-64-64H160c-35.3 0-64 28.7-64 64V304v16c0 35.3 28.7 64 64 64h80 0H512c35.3 0 64-28.7 64-64V240 240 96zM464 166.6l80 80V320c0 17.7-14.3 32-32 32H278.6l36.7-36.7L464 166.6zM281.4 304l-48 48H160c-17.7 0-32-14.3-32-32v-9.4l80-80L281.4 304zM32 112c0-8.8-7.2-16-16-16s-16 7.2-16 16V352c0 70.7 57.3 128 128 128H464c8.8 0 16-7.2 16-16s-7.2-16-16-16H128c-53 0-96-43-96-96V112zm232 48a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"
  />
);

export default ImagesIcon;
