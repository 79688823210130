import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import { shallowEqual, useSelector } from 'react-redux';

import * as store from '../../state/store';
import useFeedMap from './useFeedMap';

/**
 * This hooks efficiently selects only a subset of feed-entries to avoid
 * triggering a re-render from updates to unrelated entries.
 *
 * WARN: Changing the order of entryIds will result in a re-render.
 */
export const useFeedEntryStandaloneMap = (entryIds: string[]) => {
  const matchedFeedEntryPairs = useSelector<store.IAppState, api.feed.IEntryGetResult[]>(
    state =>
      entryIds
        .filter(entryId => state.apiCache.feedEntryStandalone.has(entryId))
        .map(entryId => state.apiCache.feedEntryStandalone.get(entryId)!.value)
        .filter(feedEntryPairData => cfe.ApiData.hasData(feedEntryPairData))
        .map(feedEntryPairData => cfe.ApiData.getData(feedEntryPairData)!),
    shallowEqual,
  );
  const feedIds = matchedFeedEntryPairs.map(feedEntryPair => feedEntryPair.feed.feed_id);
  const feedMap = useFeedMap(feedIds);

  const feedEntryPairMap = new Map<string, api.feed.IFeedEntryPair>();
  matchedFeedEntryPairs.map(matchedFeedEntryPair =>
    feedEntryPairMap.set(matchedFeedEntryPair.entry.entry_id, {
      entry: matchedFeedEntryPair.entry,
      feed: feedMap.get(matchedFeedEntryPair.feed.feed_id) ?? matchedFeedEntryPair.feed,
    }),
  );
  return feedEntryPairMap;
};
