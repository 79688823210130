import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const PrexoIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={[576, 512]}
    d="M24 0C10.7 0 0 10.7 0 24S10.7 48 24 48H552c13.3 0 24-10.7 24-24s-10.7-24-24-24H24zm8 80V296c0 30.9 25.1 56 56 56H264v46.1l-73 73c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l63-63 63 63c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-73-73V352H488c30.9 0 56-25.1 56-56V80H496V296c0 4.4-3.6 8-8 8H288 88c-4.4 0-8-3.6-8-8V80H32z"
  />
);

export default PrexoIcon;
