import { useSelector } from 'react-redux';

import * as store from '../../state/store';

const useIsAssumedIdentity = (): boolean =>
  useSelector<store.IAppState, boolean>(state => {
    if (!state.loginInfo) {
      throw Error('Expected loginInfo to be set');
    }
    return state.loginInfo.kind === 'assumed';
  });

export default useIsAssumedIdentity;
