"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseFeedEntryIdFromUrl = exports.parseFeedIdFromUrl = exports.parseMarkdownLink = void 0;
/**
 * Parses our special link format in Markdown posts. Depending on the client,
 * the link handling will differ.
 *
 * Returns [feed ID, display link]
 */
function parseMarkdownLink(link) {
    if (!link.startsWith('/f/')) {
        return null;
    }
    const parts = link.split('/');
    if (parts.length < 4) {
        return null;
    }
    const feedId = parts[2];
    const displayHref = '/' + parts.slice(3).join('/');
    return [feedId, displayHref];
}
exports.parseMarkdownLink = parseMarkdownLink;
function parseFeedIdFromUrl(url, wwwPrefix) {
    if (url.startsWith(wwwPrefix)) {
        url = url.substring(wwwPrefix.length);
    }
    if (url.startsWith('/f/')) {
        const parts = url.split('/');
        if (parts.length >= 3) {
            return parts[2];
        }
        else {
            throw Error('Unexpected number of parts');
        }
    }
    return null;
}
exports.parseFeedIdFromUrl = parseFeedIdFromUrl;
function parseFeedEntryIdFromUrl(url, wwwPrefix) {
    if (url.startsWith(wwwPrefix)) {
        url = url.substring(wwwPrefix.length);
    }
    if (url.startsWith('/e/')) {
        const parts = url.split('/');
        if (parts.length >= 3) {
            return parts[2];
        }
        else {
            throw Error('Unexpected number of parts');
        }
    }
    return null;
}
exports.parseFeedEntryIdFromUrl = parseFeedEntryIdFromUrl;
