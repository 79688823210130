import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';

import ActionButton, { ButtonLabel } from './ActionButton';
import EgoMarkdown from './EgoMarkdown';
import { DateLine } from './FeedEntryMatter';
import useApiClient from './hooks/useApiClient';
import useNav from './hooks/useNav';
import CircleDownIcon from './icon/CircleDown';
import CircleQuestionIcon from './icon/CircleQuestion';
import EgoSlice from './icon/EgoSlice';
import LockIcon from './icon/LockIcon';
import SectionHeaderTab from './SectionHeaderTab';
import { FeedLink } from './SmartFeedLink';

const EgoSection = (props: { feed: api.feed.IFeedInfo; entry: api.feed.IFeedEntryReference }) => {
  const { navToFeed } = useNav();
  const apiClient = useApiClient();

  const url = props.entry.strong_ref?.url ?? props.entry.url;

  const {
    result: egoIters,
    hasMore,
    next,
  } = cfe.ApiHook.useApiReadIter(
    apiClient,
    apiClient.stimulusEgoIter,
    { url, limit: 5 },
    apiClient.stimulusEgoIterNext,
    { limit: 10 },
    res => res,
    props.entry.feed_ref_counts === undefined || props.entry.feed_ref_counts.ego === 0,
  );

  return (
    <div className="tw-mt-4">
      <SectionHeaderTab
        title="Ego"
        titleSuffix={
          props.entry.feed_ref_counts ? (
            <div className="tw-flex tw-items-center tw-ml-4">
              <EgoSlice size={16} />
              <span className="tw-ml-1">{props.entry.feed_ref_counts.ego}</span>
            </div>
          ) : undefined
        }
      />
      <div className="kb-pad-4-na tw-pr-4 tw-mb-2">
        {props.entry['.tag'] === 'ego' ? (
          <EgoInfoEntry
            navToFeed={navToFeed}
            egoFeed={props.feed}
            added_at={props.entry.added_at}
            settledStatus={props.entry.settled_status}
            blabber={props.entry.blabber}
            via={props.entry.via}
            locked={props.entry.locked}
          />
        ) : null}
        {cfe.ApiData.hasData(egoIters)
          ? egoIters.data.map(egoIter =>
              egoIter.entries
                .filter(egoInfo => egoInfo.entry_id !== props.entry.entry_id)
                .map(egoInfo => (
                  <EgoInfoEntry
                    key={egoInfo.entry_id}
                    navToFeed={navToFeed}
                    egoFeed={egoInfo.feed}
                    added_at={egoInfo.added_at}
                    settledStatus={egoInfo.settled_status}
                    blabber={egoInfo.blabber}
                    via={egoInfo.via}
                    locked={egoInfo.locked}
                  />
                )),
            )
          : null}
        {hasMore ? (
          <ActionButton title="more" onClick={next} className="tw-w-full tw-py-1">
            <div className="tw-flex tw-items-center tw-justify-center">
              <CircleDownIcon size="1rem" />
              <ButtonLabel text="more" xsHide={false} />
            </div>
          </ActionButton>
        ) : null}
      </div>
    </div>
  );
};

const EgoInfoEntry = (props: {
  navToFeed: (feed: api.feed.IFeedInfo) => void;
  egoFeed: api.feed.IFeedInfo;
  added_at: string;
  settledStatus: api.feed.SettledStatus;
  blabber?: string;
  via?: api.feed.IVia;
  locked: boolean;
}) => {
  const showLock = props.settledStatus['.tag'] === 'unsettled' && props.locked;
  const status =
    props.settledStatus['.tag'] === 'unsettled'
      ? 'pending'
      : props.settledStatus['.tag'] === 'error_no_user'
        ? 'expired'
        : props.settledStatus['.tag'] === 'error_delinquent'
          ? 'expired'
          : props.settledStatus['.tag'] === 'settled'
            ? `$${(props.settledStatus.amount / 100).toFixed(2)}`
            : null;
  const reason =
    props.settledStatus['.tag'] === 'error_no_user'
      ? 'Author was not identified in time.'
      : props.settledStatus['.tag'] === 'error_delinquent'
        ? "User's subscription was delinquent."
        : null;
  return (
    <div className="tw-mb-2">
      <div className="tw-flex tw-justify-between tw-text-xs">
        <div>
          <FeedLink navToFeed={props.navToFeed} feed={props.egoFeed} className="tw-text-primary tw-font-bold" />
          {props.via ? (
            <span>
              {' '}
              from <FeedLink navToFeed={props.navToFeed} feed={props.via.feed} className="tw-text-primary" />
            </span>
          ) : null}
        </div>
        <div className="tw-text-muted">
          {status}
          {showLock ? (
            <span role="button" title="This ego has passed the three day window for reversal and will be paid out.">
              {' '}
              <LockIcon size="0.8rem" offsetUp />
            </span>
          ) : null}{' '}
          {reason ? (
            <span role="button" title={reason}>
              {' '}
              <CircleQuestionIcon size="0.85rem" />
            </span>
          ) : null}{' '}
          &bull; <DateLine ts={props.added_at} />
        </div>
      </div>
      {props.blabber ? <EgoMarkdown variant="comment" content={props.blabber} goToFeed={props.navToFeed} /> : null}
    </div>
  );
};

export default EgoSection;
