import clsx from 'clsx';
import * as api from 'ego-sdk-js';
import React from 'react';

const FeedProfileImage = (props: { feed: api.feed.IFeedInfo; placeholderOk?: boolean } & ProfileImageBaseProps) => {
  const { feed, ...rest } = props;
  if (props.feed.profile_image) {
    return (
      <ProfileImage
        profileImage={props.feed.profile_image}
        shape={props.feed.type['.tag'] === 'ego' ? 'circle' : 'square'}
        {...rest}
      />
    );
  } else if (props.placeholderOk) {
    return <ProfileImageFrame shape={props.feed.type['.tag'] === 'ego' ? 'circle' : 'square'} {...rest} />;
  } else {
    throw Error('Expected profile image');
  }
};

export const UserFeedProfileImage = (
  props: { user: api.user.IUserBasicInfo; placeholderOk?: boolean } & ProfileImageBaseProps,
) => {
  const { user, ...rest } = props;
  if (props.user.profile_image) {
    return <ProfileImage profileImage={props.user.profile_image} shape="circle" {...rest} />;
  } else if (props.placeholderOk) {
    return <ProfileImageFrame shape="circle" {...rest} />;
  } else {
    throw Error('Expected profile image');
  }
};

const ProfileImage = (
  props: {
    profileImage: api.medialib.IFeedProfileImage;
    shape: 'circle' | 'square';
  } & ProfileImageBaseProps,
) => (
  <ProfileImageFrame sm={props.sm} md={props.md} className={props.className} shape={props.shape}>
    <img
      width={props.sm || props.md ? props.profileImage.sm.width : props.profileImage.md.width}
      height={props.sm || props.md ? props.profileImage.sm.height : props.profileImage.md.height}
      src={props.sm || props.md ? props.profileImage.sm.url : props.profileImage.md.url}
      className="tw-w-full tw-h-full"
    />
  </ProfileImageFrame>
);

interface ProfileImageBaseProps {
  sm?: boolean;
  md?: boolean;
  className?: string;
}

const ProfileImageFrame = (
  props: {
    shape: 'circle' | 'square';
    children?: React.ReactNode;
  } & ProfileImageBaseProps,
) => (
  <div
    className={clsx(
      props.sm
        ? 'tw-w-[30px] tw-h-[30px]'
        : props.md
          ? 'tw-w-[36px] tw-h-[36px]'
          : 'tw-w-[120px] tw-h-[120px] sm:tw-w-[180px] sm:tw-h-[180px]',
      'tw-shrink-0 tw-overflow-hidden',
      props.shape === 'circle' ? 'tw-rounded-full' : props.sm || props.md ? 'tw-rounded' : 'tw-rounded-lg',
      props.className,
    )}
  >
    {props.children}
  </div>
);

export default FeedProfileImage;
