import clsx from 'clsx';
import { m } from 'framer-motion';
import React from 'react';

const ToolTip = (props: {
  overlay: React.ReactNode;
  placement: 'left' | 'right' | 'center';
  children: React.ReactNode;
}) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const [childRect, setChildRect] = React.useState<DOMRect>();
  React.useEffect(() => {
    if (divRef.current) {
      setChildRect(divRef.current.getBoundingClientRect());
    }
  }, [divRef.current]);
  // To support tapping the tooltip open on mobile where hover isn't good UX.
  const [pin, setPin] = React.useState(false);
  return (
    <m.div
      initial="hidden"
      animate={pin ? 'visible' : undefined}
      whileHover="visible"
      className="tw-relative tw-inline-flex tw-z-50"
    >
      <div ref={divRef} onClick={() => setPin(!pin)}>
        {props.children}
      </div>
      <m.div
        variants={{
          hidden: {
            opacity: 0,
            scale: 0,
          },
          visible: {
            opacity: 1,
            scale: 1,
            x: props.placement === 'center' ? '-50%' : undefined,
          },
        }}
        transition={{ opacity: { duration: 0.2 }, scale: { duration: 0 }, x: { duration: 0 } }}
        className={clsx(
          'tw-absolute',
          props.placement === 'right' ? 'tw-right-0' : 'tw-left-0',
          'tw-rounded tw-p-4',
          'tw-bg-gray-800 dark:tw-bg-gray-300',
          'tw-text-sm tw-text-light dark:tw-text-dark',
        )}
        style={{ top: childRect?.height }}
      >
        {props.overlay}
      </m.div>
    </m.div>
  );
};

export default ToolTip;
