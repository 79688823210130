// FAB = Floating Action Button
import clsx from 'clsx';
import { m } from 'framer-motion';
import React from 'react';

import EgoSlice from './icon/EgoSlice';
import LinkIcon from './icon/LinkIcon';
import PlusIcon from './icon/PlusIcon';
import SpeakerIcon from './icon/SpeakerIcon';
import VideoIcon from './icon/VideoIcon';
import Badge from './lib/Badge';

export const FeedFab = (props: {
  onEgo?: () => void;
  onPost?: () => void;
  onPostVideo?: () => void;
  onAddLink?: () => void;
}) => {
  const [forceOpen, setForceOpen] = React.useState(false);
  const actionClick = (action: () => void) => {
    return () => {
      action();
      setForceOpen(false);
    };
  };
  return (
    <m.div
      initial="closed"
      whileHover="open"
      animate={forceOpen ? 'open' : undefined}
      variants={{
        closed: {},
        open: {
          transition: {
            staggerChildren: 0.08,
          },
        },
      }}
      className="tw-fixed tw-bottom-16 tw-right-16 tw-z-20"
    >
      <m.div className="tw-flex tw-flex-col-reverse tw-items-center">
        <m.div
          variants={{
            closed: {},
            open: {
              rotateZ: '45deg',
            },
          }}
          transition={{ duration: 0.2 }}
          role="button"
          className={clsx(
            'tw-flex tw-items-center tw-justify-center',
            'tw-bg-perpul-dark dark:tw-bg-perpul-light hover:tw-bg-perpul-light hover:dark:tw-bg-perpul-dark',
            'tw-shadow tw-rounded-full',
            'tw-text-white',
            'tw-w-16 tw-h-16',
          )}
          onClick={() => setForceOpen(!forceOpen)}
        >
          <PlusIcon size="1.25rem" />
        </m.div>
        {props.onAddLink ? (
          <FabAction onClick={actionClick(props.onAddLink)} className="tw-mb-3" label="Link">
            <LinkIcon size="1.25rem" />
          </FabAction>
        ) : null}
        {props.onPostVideo ? (
          <FabAction onClick={actionClick(props.onPostVideo)} className="tw-mb-3" label="Video">
            <VideoIcon size="1.25rem" />
          </FabAction>
        ) : null}
        {props.onPost ? (
          <FabAction onClick={actionClick(props.onPost)} className="tw-mb-3" label="Post">
            <SpeakerIcon size="1.15rem" />
          </FabAction>
        ) : null}
        {props.onEgo ? (
          <FabAction onClick={actionClick(props.onEgo)} className="tw-mb-3" label="Ego">
            <EgoSlice size="1.25rem" />
          </FabAction>
        ) : null}
      </m.div>
    </m.div>
  );
};

export const FabAction = (props: {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
  label?: string;
}) => (
  <m.div
    variants={{
      closed: {
        // Shrink height to reduce whileHover trigger area.
        height: 0,
        opacity: 0,
        scale: 0,
      },
      open: {
        height: '3.5rem',
        opacity: 1,
        scale: 1,
      },
    }}
    transition={{ duration: 0.2 }}
    role="button"
    onClick={props.onClick}
    className={clsx(
      'tw-flex tw-items-center tw-justify-center',
      'tw-bg-perpul-dark dark:tw-bg-perpul-light hover:tw-bg-perpul-light hover:dark:tw-bg-perpul-dark',
      'tw-shadow tw-rounded-full',
      'tw-text-white',
      'tw-w-14 tw-h-14',
      props.className,
    )}
  >
    <div className="tw-relative">
      {props.label ? (
        <div className="tw-absolute tw-right-12 tw-top-0">
          <Badge variant="info">{props.label}</Badge>
        </div>
      ) : null}
      {props.children}
    </div>
  </m.div>
);

export default FeedFab;
