import * as api from 'ego-sdk-js';
import React from 'react';
import { batch, useDispatch } from 'react-redux';

import { MainActionCreators } from '../../state/reducer';

import useApiClient from './useApiClient';
import useInboxUnread from './useInboxUnread';
import useUserMeInternal from './useUserMeInternal';

const inboxUnreadCountRefreshTimeoutId: { timeoutId: ReturnType<typeof setTimeout> | null } = { timeoutId: null };
const seenReportTimeoutId: { timeoutId: ReturnType<typeof setTimeout> | null } = { timeoutId: null };
const entryIdsQueued = new Map<string, number>(); // [entryId, watermark]
// FIXME: Memory leak / grows unbounded.
const entryIdsReported = new Map<string, number>(); // [entryId, watermark]

/**
 * This hook makes use of globals as it's used in many entry components, but we
 * want to batch the feed/entry/seen api call every few seconds.
 *
 * There's different handling for shared entries vs. non-shared entries.
 *
 * NOTE: Once an entry at a given watermark is seen, it will not be reported
 * again as seen until there's an update to the watermark.
 */
const useSeenRecorder = () => {
  const dispatch = useDispatch();
  const accountInfo = useUserMeInternal();
  if (!accountInfo) {
    // HACK: Assumes a user can't login without a refresh of the app state.
    return (_: Array<[api.feed.IFeedInfo, api.feed.IFeedEntryReference]>) => null;
  }
  const apiClient = useApiClient();
  const { refresh } = useInboxUnread();

  return (seen: Array<[api.feed.IFeedInfo, api.feed.IFeedEntryReference]>) => {
    if (!accountInfo) {
      return;
    }
    const newlySeen: Array<[api.feed.IFeedInfo, api.feed.IFeedEntryReference]> = [];
    for (const [feed, entry] of seen) {
      const watermark = entry.for_viewer.seen?.watermark ?? 0;
      const existing = entryIdsReported.get(entry.entry_id);
      if ((existing === undefined || existing < watermark) && !entryIdsQueued.has(entry.entry_id)) {
        newlySeen.push([feed, entry]);
        entryIdsQueued.set(entry.entry_id, watermark);
        // If watermark = 0, rely on feedEntrySeen() further below to mark the
        // shared story as seen.
        if (feed.type['.tag'] === 'share' && entry['.tag'] === 'share' && entry.for_viewer.seen?.watermark) {
          apiClient.threadSeen({ thread_id: entry.ffa_thread_id, watermark: entry.for_viewer.seen.watermark });
        }
      }
    }
    const seenShare = newlySeen.filter(
      ([feed, entry]) =>
        (feed.type['.tag'] === 'share' && entry['.tag'] === 'share') ||
        (feed.type['.tag'] === 'notif' && entry['.tag'] === 'notif'),
    );
    batch(() =>
      seenShare.map(([feed, entry]) => dispatch(MainActionCreators.markFeedEntryAsSeen(feed.feed_id, entry.entry_id))),
    );

    if (!seenReportTimeoutId.timeoutId && entryIdsQueued.size > 0) {
      seenReportTimeoutId.timeoutId = setTimeout(async () => {
        if (entryIdsQueued.size > 0) {
          const queueSnapshot = [...entryIdsQueued.entries()];
          entryIdsQueued.clear();
          await apiClient.feedEntrySeen({ seen_entry_ids: queueSnapshot.map(([entry_id]) => entry_id) });
          for (const [entryId, watermark] of queueSnapshot) {
            entryIdsReported.set(entryId, watermark);
          }
        }
        seenReportTimeoutId.timeoutId = null;
      }, 3000);
    }
    if (seenShare.length > 0 && !inboxUnreadCountRefreshTimeoutId.timeoutId) {
      inboxUnreadCountRefreshTimeoutId.timeoutId = setTimeout(() => {
        refresh();
        inboxUnreadCountRefreshTimeoutId.timeoutId = null;
      }, 10000);
    }
  };
};

export const useSeenRecorderWithDelay = (
  seen: Array<[api.feed.IFeedInfo, api.feed.IFeedEntryReference]>,
  delay: number,
) => {
  const recordSeen = useSeenRecorder();
  React.useEffect(() => {
    const timeoutId = setTimeout(() => recordSeen(seen), delay);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
};

export default useSeenRecorder;
