import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MainActionCreators } from '../../state/reducer';
import { IAppState } from '../../state/store';
import * as apiUtil from '../../util-api';
import useApiClient from './useApiClient';

export function useFeedList(userRef: api.feed.UserRef) {
  const targetUserId =
    userRef['.tag'] === 'id' ? userRef.id : userRef['.tag'] === 'username' ? userRef.username : 'invalid';
  const dispatch = useDispatch();
  const apiClient = useApiClient();
  const nextFeedList = useRef<cfe.ApiData.Data<api.feed.IFeedInfo[]>>({ kind: 'unknown' });
  const feedIdsForUser = useSelector<IAppState, cfe.ApiData.Data<string[]> | undefined>(state =>
    state.feedsByUser.get(targetUserId),
  ) ?? { kind: 'unknown' };
  const doSwap = useRef(cfe.ApiData.isDone(feedIdsForUser) || cfe.ApiData.isLoading(feedIdsForUser));
  const [isRefreshing, setIsRefreshing] = useState(false);

  // If the feed list is refreshed twice, the existing list is purged.
  const refreshCount = useRef(0);

  const abortableApiClient = apiClient.abortable();

  const apiFeedsIter = () => {
    const feedIterNext = (cursor: string) => {
      abortableApiClient
        .feedIterNext({ cursor, limit: 50 })
        .then(resp => {
          if (resp.kind === api.StatusCode.Ok) {
            if (!doSwap.current) {
              dispatch(MainActionCreators.apiFeedsLoad(targetUserId, resp.result));
            } else {
              nextFeedList.current = cfe.ApiData.nextData(nextFeedList.current, resp.result.feeds, (a, b) =>
                a.concat(b),
              );
            }
            if (resp.result.has_more && resp.result.cursor) {
              feedIterNext(resp.result.cursor);
            } else {
              setIsRefreshing(false);
              if (doSwap.current) {
                nextFeedList.current = cfe.ApiData.toLoaded(nextFeedList.current);
                dispatch(MainActionCreators.apiFeedsSwapSet(targetUserId, nextFeedList.current));
              } else {
                doSwap.current = true;
              }
            }
          } else {
            setIsRefreshing(false);
            apiUtil.mkToastFromBadStatusCode(dispatch, resp);
            if (!doSwap.current) {
              dispatch(MainActionCreators.apiFeedsError(targetUserId));
            } else {
              nextFeedList.current = cfe.ApiData.toError(nextFeedList.current);
              dispatch(MainActionCreators.apiFeedsSwapSet(targetUserId, nextFeedList.current));
            }
          }
        })
        .catch(e => {
          setIsRefreshing(false);
          if (!doSwap.current) {
            dispatch(MainActionCreators.apiFeedsError(targetUserId));
          } else {
            nextFeedList.current = cfe.ApiData.toError(nextFeedList.current);
            dispatch(MainActionCreators.apiFeedsSwapSet(targetUserId, nextFeedList.current));
          }
          if (e.name !== 'AbortError') {
            apiUtil.mkToastFromHttpError(dispatch);
          }
        });
    };
    if (!doSwap.current) {
      dispatch(MainActionCreators.apiFeedsLoading(targetUserId));
    } else {
      nextFeedList.current = cfe.ApiData.toLoading(nextFeedList.current);
    }
    setIsRefreshing(true);
    abortableApiClient
      .feedIter({ user_ref: userRef, limit: 50 })
      .then(resp => {
        if (resp.kind === api.StatusCode.Ok) {
          if (!doSwap.current) {
            dispatch(MainActionCreators.apiFeedsLoadFirst(targetUserId, resp.result));
          } else {
            nextFeedList.current = cfe.ApiData.initData(nextFeedList.current, resp.result.feeds);
          }
          if (resp.result.has_more && resp.result.cursor) {
            feedIterNext(resp.result.cursor);
          } else {
            setIsRefreshing(false);
            if (!doSwap.current) {
              doSwap.current = true;
            } else {
              nextFeedList.current = cfe.ApiData.toLoaded(nextFeedList.current);
              dispatch(MainActionCreators.apiFeedsSwapSet(targetUserId, nextFeedList.current));
            }
          }
        } else {
          setIsRefreshing(false);
          apiUtil.mkToastFromBadStatusCode(dispatch, resp);
          if (!doSwap.current) {
            doSwap.current = true;
            dispatch(MainActionCreators.apiFeedsError(targetUserId));
          } else {
            nextFeedList.current = cfe.ApiData.toError(nextFeedList.current);
            dispatch(MainActionCreators.apiFeedsSwapSet(targetUserId, nextFeedList.current));
          }
        }
      })
      .catch(e => {
        setIsRefreshing(false);
        if (!doSwap.current) {
          doSwap.current = true;
          dispatch(MainActionCreators.apiFeedsError(targetUserId));
        } else {
          nextFeedList.current = cfe.ApiData.toError(nextFeedList.current);
          dispatch(MainActionCreators.apiFeedsSwapSet(targetUserId, nextFeedList.current));
        }
        if (e.name !== 'AbortError') {
          apiUtil.mkToastFromHttpError(dispatch);
        }
      });
    return () => abortableApiClient.abort();
  };

  useEffect(() => {
    const abort = apiFeedsIter();
    return abort;
  }, [targetUserId]);

  return {
    isRefreshing,
    refresh: () => {
      nextFeedList.current = cfe.ApiData.toUnknown();
      if (refreshCount.current > 1) {
        dispatch(MainActionCreators.apiFeedsClearByUser(targetUserId));
        doSwap.current = false;
        refreshCount.current = 0;
      }
      apiFeedsIter();
      refreshCount.current += 1;
    },
    userFeedIds: feedIdsForUser,
  };
}
