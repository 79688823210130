import React from 'react';

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99 89.01" height="35px" width="39px">
    <defs>
      <style>
        {`.cls-1{fill:#db1c8e;}.cls-1,.cls-2,.cls-3,.cls-4,.cls-5{fill-rule:evenodd;}.cls-2{fill:#41bded;}.cls-3{fill:#f2db0d;}`}
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M19.47,22.3,54.2,44.51,82.56,13.08A45.42,45.42,0,0,0,55.89,4.35c-13,0-25.44,5.68-34.67,15.91C20.62,20.92,20,21.6,19.47,22.3Z"
        />
        <path
          className="cls-2"
          d="M87,15.55,54.85,45.72V88.4a52,52,0,0,0,25.43-13,46.23,46.23,0,0,0,15-33.84A45.46,45.46,0,0,0,87,15.55Z"
        />
        <path
          className="cls-3"
          d="M32.58,43.77,17.7,36.07l-6.28-3.25L5.81,29.91a52.3,52.3,0,0,0,0,27.72l5.61-2.91,6.28-3.25Z"
        />
        <path
          fill="currentColor"
          d="M19.25,27.17,51.87,46V83.68a39.32,39.32,0,0,1-30-17.33L40.8,55.42a2.62,2.62,0,0,0-2.62-4.53L10.12,67.09a2.62,2.62,0,0,0,2.62,4.53L17.31,69A45.23,45.23,0,0,0,23,76a44.39,44.39,0,0,0,28.85,13c.87.06,1.74.08,2.62.08s1.76,0,2.62-.08a44.5,44.5,0,0,0,30.65-74c-.58-.65-1.18-1.29-1.8-1.9s-1.25-1.22-1.91-1.8A44.51,44.51,0,0,0,17.32,20a45,45,0,0,0-2.62,4.52l4.55,2.62ZM57.11,83.68V45.59L84.05,18.66a39.26,39.26,0,0,1-26.94,65ZM80.34,15,54.05,41.24,21.87,22.66a40.08,40.08,0,0,1,4.86-5.92A39.26,39.26,0,0,1,80.34,15Z"
        />
        <path
          fill="currentColor"
          d="M5.39,63.12l2.86-1.65,4.17-2.41,4.64-2.68,16.65-9.61a2.61,2.61,0,0,0-.15-4.61l-16.5-9.53L12.42,30,8.24,27.54,5.37,25.89A1.66,1.66,0,0,0,2.88,27c-.33,1-.64,2-.91,3A54.89,54.89,0,0,0,2,59.06c.26.93.55,1.86.85,2.77S4.12,64,5.39,63.12ZM6.68,32.67,11,35.15l4.81,2.77,11.4,6.58-11.4,6.58L11,53.86l-4.3,2.48A49.64,49.64,0,0,1,5.24,44.51,49.09,49.09,0,0,1,6.68,32.67Z"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
