import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React, { useEffect, useRef } from 'react';
import FeedItem from './FeedItem';
import FeedSearchInput from './FeedSearchInput';
import { useAuthedApiClient } from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import useUserMeInternal from './hooks/useUserMeInternal';
import CloseCircleIcon from './icon/CloseCircleIcon';
import PlusIcon from './icon/PlusIcon';

import useInboxSend, { SendTo } from './hooks/useInboxSend';
import LinkIcon from './icon/LinkIcon';
import SendToIcon from './icon/SendToIcon';
import { useKeyPress } from './KeyPressContext';
import Button from './lib/Button';
import LayoutLine from './lib/LayoutLine';
import ListGroup from './lib/ListGroup';
import Modal from './lib/Modal';
import TextInput, { TextInputFooterForOptional } from './lib/TextInput';
import SectionHeaderTab from './SectionHeaderTab';

const SendToModal = (props: { closeModal: () => void; isForeground: boolean; entry: api.feed.IFeedEntryReference }) => {
  const apiClient = useAuthedApiClient();
  const accountInfo = useUserMeInternal()!;
  const url = cfe.ApiHelpers.getEntryPrimaryUrl(props.entry);
  const { inFlight, onSend } = useInboxSend(url, props.entry);

  useKeyPress(
    ['u', 'Escape'],
    () => {
      props.closeModal();
    },
    !props.isForeground,
    10,
  );

  const searchRef = useRef<HTMLInputElement | null>(null);
  const modalRef = React.useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef.current]);

  const { result: getShareSuggestionsResult } = cfe.ApiHook.useApiRead(
    apiClient,
    apiClient.inboxGetShareSuggestions,
    undefined,
    res => res,
  );

  const [sendToShareFeed, setSendToShareFeed] = React.useState<api.feed.IFeedInfo | null>(null);
  const [sendToUsers, setSendToUsers] = React.useState<api.feed.IFeedPublisher[]>([]);
  const [menuMode, setMenuMode] = React.useState<'choose-user' | 'choose-any' | 'add-note'>('choose-any');

  const [note, setNote] = React.useState('');

  const sendTo: SendTo | null = sendToShareFeed
    ? { kind: 'share-feed', shareFeedId: sendToShareFeed.feed_id }
    : sendToUsers.length > 0
      ? { kind: 'users', userIds: sendToUsers.map(user => user.user_id) }
      : null;

  const {
    apiDo: apiFeedEntryGetUrlForSharing,
    okToast,
    errToast,
  } = useApiDo(apiClient, apiClient.feedEntryGetUrlForSharing, { abortable: true });

  const sendToUserIdsSet = new Set<string>();
  if (sendTo?.kind === 'users') {
    sendTo.userIds.map(userId => sendToUserIdsSet.add(userId));
  }

  const title = cfe.ApiHelpers.getEntryTitle(props.entry);
  return (
    <Modal.Container ref={modalRef} show={props.isForeground} close={props.closeModal}>
      <Modal.Header>
        <div className="tw-flex tw-justify-between tw-items-center">
          <Modal.Heading1>
            <SendToIcon size="1.2rem" offsetUp /> Send To
          </Modal.Heading1>
          <div className="tw-mt-6">
            <Button
              sm
              variant="secondary"
              onClick={() => {
                apiFeedEntryGetUrlForSharing(
                  { entry_id: props.entry.entry_id },
                  {
                    onResult: async res => {
                      try {
                        await navigator.clipboard.writeText(res.url);
                        okToast('Copied link to clipboard!');
                      } catch (err) {
                        // Errors in trinity because API requires https connection.
                        errToast('Could not copy link to clipboard');
                      }
                    },
                  },
                );
              }}
            >
              <LinkIcon size="1.1rem" className="tw-mr-1" />
              Copy Link
            </Button>
          </div>
        </div>
        {title ? <div className="tw-text-muted">{title}</div> : null}
      </Modal.Header>
      <Modal.Body>
        <div className="tw-mt-4" />
        <Modal.Gutter>
          {sendToShareFeed ? (
            <div className="tw-flex tw-justify-between tw-my-1">
              <span className="tw-text-lg tw-font-medium">
                {cfe.ApiHelpers.getShareFeedName(sendToShareFeed, accountInfo.user_id)}
              </span>
              <div
                role="button"
                onClick={() => {
                  setSendToShareFeed(null);
                  setMenuMode('choose-any');
                }}
              >
                <CloseCircleIcon size="1.5rem" />
              </div>
            </div>
          ) : sendToUsers ? (
            <div>
              {sendToUsers.map(user => (
                <div key={user.user_id} className="tw-flex tw-justify-between tw-my-1">
                  <div>
                    <span className="tw-text-lg tw-font-medium">{user.name}</span>{' '}
                    <span className="tw-text-muted">@{user.username}</span>
                  </div>
                  <div
                    role="button"
                    onClick={() => {
                      setSendToUsers(cur => cur.filter(u => u.user_id !== user.user_id));
                      if (sendToUsers.length === 1) {
                        setMenuMode('choose-any');
                      }
                    }}
                  >
                    <CloseCircleIcon size="1.5rem" />
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </Modal.Gutter>
        {menuMode === 'add-note' && (sendToShareFeed || sendToUsers.length > 0) ? (
          <div className="tw-px-6 tw-mt-2">
            <Button
              sm
              variant="secondary"
              onClick={() => {
                setMenuMode('choose-user');
                if (sendToShareFeed && sendToShareFeed.members) {
                  setSendToShareFeed(null);
                  setSendToUsers(sendToShareFeed.members!.filter(member => member.user_id !== accountInfo.user_id));
                }
              }}
            >
              <PlusIcon size="1rem" />
              <span className="tw-ml-1">Add person</span>
            </Button>
          </div>
        ) : null}
        {menuMode === 'choose-user' && (sendToShareFeed || sendToUsers.length > 0) ? <LayoutLine /> : null}
        {menuMode === 'add-note' ? (
          <Modal.Gutter>
            <div className="tw-mt-6" />
            <form onSubmit={e => e.preventDefault()}>
              <fieldset disabled={inFlight}>
                <TextInput
                  type="text"
                  placeholder="Write a note"
                  onChange={e => setNote(e.currentTarget.value)}
                  value={note}
                />
                <TextInputFooterForOptional maxLength={500} value={note} />
                <Button
                  type="submit"
                  className="tw-mt-4"
                  onClick={() => {
                    if (sendTo) {
                      onSend(sendTo, note, props.closeModal);
                    }
                  }}
                >
                  Send
                </Button>
              </fieldset>
            </form>
          </Modal.Gutter>
        ) : null}
        {menuMode !== 'add-note' ? (
          <div className="tw-my-6">
            <FeedSearchInput
              ref={searchRef}
              kbActive={true}
              targetWindow={modalRef}
              peopleOnly
              onClickOverride={feed => {
                setSendToUsers(cur => [...cur, feed.publisher!]);
                setMenuMode('add-note');
              }}
              omitFeedId={accountInfo?.ego_feed_id}
            />
          </div>
        ) : null}
        {cfe.ApiData.hasData(getShareSuggestionsResult) ? (
          <>
            {menuMode === 'choose-any' && getShareSuggestionsResult.data.recent.length > 0 ? (
              <div className="tw-my-6">
                <SectionHeaderTab title="Recent" />
                <ListGroup>
                  {getShareSuggestionsResult.data.recent.map(feed => (
                    <FeedItem
                      key={feed.feed_id}
                      sm
                      feed={feed}
                      feedNameOverride={cfe.ApiHelpers.getShareFeedName(feed, accountInfo.user_id)}
                      hideFollowers
                      hideFollowBtn
                      onClickOverride={() => {
                        setSendToShareFeed(feed);
                        setMenuMode('add-note');
                      }}
                    />
                  ))}
                </ListGroup>
              </div>
            ) : null}
            {menuMode !== 'add-note' && getShareSuggestionsResult.data.followed_back_by.length > 0 ? (
              <div className="tw-my-6">
                <SectionHeaderTab title="Suggested" />
                <ListGroup>
                  {getShareSuggestionsResult.data.followed_back_by
                    .filter(feed => !feed.publisher || !sendToUserIdsSet.has(feed.publisher.user_id))
                    .map(feed => (
                      <FeedItem
                        key={feed.feed_id}
                        sm
                        feed={feed}
                        hideFollowers
                        hideFollowBtn
                        onClickOverride={() => {
                          setSendToUsers(cur => [...cur, feed.publisher!]);
                          setMenuMode('add-note');
                        }}
                      />
                    ))}
                </ListGroup>
              </div>
            ) : null}
          </>
        ) : null}
      </Modal.Body>
    </Modal.Container>
  );
};

export default SendToModal;
