// Tailwind CSS styles
import './style.css';

import { enableMapSet } from 'immer';
enableMapSet();

import * as cfe from 'ego-cfe';
import { createReducerFunction } from 'immer-reducer';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createStore } from 'redux';

import { MainReducer } from './state/reducer';
import { IAppState } from './state/store';

import MindApp from './components/App';

const initialAppState: IAppState = {
  agentMode: false,
  apiCache: {
    agentTopicTaxonomy: cfe.ApiHook.getCacheEmptySingleton(),
    feedEntries: new Map(),
    feedEntryKeysByFeedId: new Map(),
    feedEntryStandalone: new Map(),
    inboxEntries: cfe.ApiHook.getCacheEmptySingleton(),
    inboxUnread: cfe.ApiHook.getCacheEmptySingleton(),
    meInfo: cfe.ApiHook.getCacheEmptySingleton(),
    newsfeedAsUserEntries: [undefined, cfe.ApiHook.getCacheEmptySingleton()],
    newsfeedEntries: cfe.ApiHook.getCacheEmptySingleton(),
    stimCommentary: new Map(),
    stimGet: new Map(),
    stimRss: new Map(),
    threadReplies: new Map(),
    threadRoot: new Map(),
    threadSiblings: new Map(),
  },
  appearance: {
    themeMode: 'light',
  },
  autoPlayMedia: true,
  exploredItem: null,
  feedKbCursor: new Map(),
  feeds: new Map(),
  feedsByUser: new Map(),
  forceInboxRefresh: false,
  keyboardControlsActive: false,
  loginInfo: null,
  onLoadPathname: window.location.pathname,
  page: {
    feedPage: {
      curFeed: undefined,
    },
  },
  toast: null,
};
const reducerFunction = createReducerFunction(MainReducer, initialAppState);
const store = createStore(reducerFunction);

// Export it so that `router.navigate` is available as a stable navigate()
// that does not trigger a re-render when the page location changes.
export const router = createBrowserRouter([{ path: '*', element: <MindApp /> }]);

const appComponent = (
  <HelmetProvider>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </HelmetProvider>
);

const root = createRoot(document.getElementById('root')!);
root.render(appComponent);
