// Set in HTML for easy server-side control.
declare var GLOBAL_STATIC_HOST: string;
export const globalStaticHost = GLOBAL_STATIC_HOST;
declare var GLOBAL_API_HOST: string;
export const apiHost = GLOBAL_API_HOST;
export const wwwHost = `${window.location.protocol}//${window.location.hostname}`;

export const mediaBreakpoints = {
  '2xlMax': '(max-width: 1536px)',
  '2xlMin': '(min-width: 1536px)',
  lgMax: '(max-width: 1024px)',
  lgMin: '(min-width: 1024px)',
  mdMax: '(max-width: 768px)',
  mdMin: '(min-width: 768px)',
  smMax: '(max-width: 640px)',
  smMin: '(min-width: 640px)',
  xlMax: '(max-width: 1280px)',
  xlMin: '(min-width: 1280px)',
};

export default { apiHost, mediaBreakpoints, wwwHost };
