import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const AngleRightIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={[320, 512]}
    d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"
  />
);

export default AngleRightIcon;
