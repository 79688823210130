import clsx from 'clsx';
import React from 'react';

// FIXME: Select a single type/strategy for size once both options have been
// assessed in a variety of scenarios.
const EgoSlice = (props: { size: number | string; className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36.11 36.13"
    height={typeof props.size === 'number' ? `${props.size}px` : props.size}
    width={typeof props.size === 'number' ? `${props.size}px` : props.size}
    className={clsx(typeof props.size === 'number' ? 'tw-mt-[-1px]' : 'tw-relative -tw-top-[0.09em]', props.className)}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.22,27.57l3.19.86,4.66,1.24,5.17,1.39,18.58,5A2.62,2.62,0,0,0,36,34.18,2.58,2.58,0,0,0,36,32.67L31,14.26,29.65,9.09,28.4,4.43l-.85-3.2A1.66,1.66,0,0,0,25,.25c-.92.45-1.84.93-2.74,1.44A55,55,0,0,0,1.68,22.28C1.2,23.12.75,24,.32,24.84s-.58,2.43.9,2.73ZM23.67,7,25,11.74l1.43,5.36L29.8,29.81l-12.71-3.4L11.73,25l-4.8-1.29A49.85,49.85,0,0,1,23.67,7Z"
      transform="translate(0 0)"
    />
  </svg>
);

export default EgoSlice;
